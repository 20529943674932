import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, Show, Text, Hide } from '@chakra-ui/react';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';
import fallbackGrey from '../../../assets/vectors/fallbackGrey.svg';

export default function ImageMB(props) {
  const { imageObj, refData, fit, pos } = props;
  const { l } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, [props]);

  async function getData() {
    const info = await api.getSpecificEntry(
      refData?._content_type_uid,
      refData?.uid,
      l,
    );
    setData(info.entry);
  }

  imageObj && !data ? setData(imageObj) : !data ? getData() : null;

  // IMAGE DATA VARIABLES
  // desktop:
  const dAlign = data?.info?.styling?.alignment;
  const dText = data?.info?.caption_align;
  const dSrc = data?.info?.image?.url;
  const dAlt = data?.info?.alt_text;
  const dH = data?.info?.styling?.size?.height;
  const dW = data?.info?.styling?.size?.width;
  const dBr = data?.info?.styling?.radius_styling?.value;
  const dCap = data?.info?.caption;
  // tablet:
  const tAlign = data?.tablet?.styling?.alignment;
  const tText = data?.tablet?.caption_align;
  const tSrc = data?.tablet?.image?.url;
  const tAlt = data?.tablet?.alt_text;
  const tH = data?.tablet?.styling?.size?.height;
  const tW = data?.tablet?.styling?.size?.width;
  const tBr = data?.tablet?.styling?.radius_styling?.value;
  const tCap = data?.tablet?.caption;
  // mobile:
  const mAlign = data?.mobile?.styling?.alignment;
  const mText = data?.mobile?.caption_align;
  const mSrc = data?.mobile?.image?.url;
  const mAlt = data?.mobile?.alt_text;
  const mH = data?.mobile?.styling?.size?.height;
  const mW = data?.mobile?.styling?.size?.width;
  const mBr = data?.mobile?.styling?.radius_styling?.value;
  const mCap = data?.mobile?.caption;

  return (
    <Box>
      <Show above={'lg'}>
        <Flex
          direction={'column'}
          w={'100%'}
          align={
            dAlign === 'Left' ? 'start' : dAlign === 'Right' ? 'end' : 'center'
          }
        >
          <Box textAlign={dText}>
            <Image
              fallbackSrc={fallbackGrey}
              src={dSrc}
              alt={dAlt}
              h={dH}
              w={dW}
              borderRadius={
                data?.info?.styling?.border_radius ? dBr || '20px' : 0
              }
              fit={fit || 'auto'}
              objectPosition={pos || 'auto'}
              loading="lazy"
            ></Image>
            {dCap ? <Text>{dCap}</Text> : null}
          </Box>
        </Flex>
      </Show>

      <Show below={'lg'}>
        <Hide below="md">
          <Flex
            direction={'column'}
            w={'100%'}
            align={
              tAlign
                ? tAlign === 'Left'
                  ? 'start'
                  : tAlign === 'Right'
                  ? 'end'
                  : 'center'
                : dAlign === 'Left'
                ? 'start'
                : dAlign === 'Right'
                ? 'end'
                : 'center'
            }
          >
            <Box textAlign={tText || dText}>
              <Image
                fallbackSrc={fallbackGrey}
                src={tSrc || dSrc}
                alt={tAlt || dAlt}
                h={tH || dH}
                w={tW || dW}
                borderRadius={
                  data?.tablet?.styling?.border_radius
                    ? tBr || '20px'
                    : data?.info?.styling?.border_radius
                    ? dBr || '20px'
                    : 0
                }
                fit={fit || 'auto'}
                objectPosition={pos || 'auto'}
              ></Image>
              {tCap ? <Text>{tCap}</Text> : dCap ? <Text>{dCap}</Text> : null}
            </Box>
          </Flex>
        </Hide>
      </Show>

      <Hide above={'md'}>
        <Flex
          direction={'column'}
          w={'100%'}
          align={
            mAlign
              ? mAlign === 'Left'
                ? 'start'
                : mAlign === 'Right'
                ? 'end'
                : 'center'
              : tAlign
              ? tAlign === 'Left'
                ? 'start'
                : tAlign === 'Right'
                ? 'end'
                : 'center'
              : dAlign === 'Left'
              ? 'start'
              : dAlign === 'Right'
              ? 'end'
              : 'center'
          }
        >
          <Box textAlign={mText || tText || dText}>
            <Image
              fallbackSrc={fallbackGrey}
              src={mSrc || tSrc || dSrc}
              alt={mAlt || tAlt || mAlt}
              h={mH || tH || dH}
              w={mW || tW || dW}
              borderRadius={
                data?.mobile?.styling?.border_radius
                  ? mBr || '20px'
                  : data?.tablet?.styling?.border_radius
                  ? tBr || '20px'
                  : data?.info?.styling?.border_radius
                  ? dBr || '20px'
                  : 0
              }
              fit={fit || 'auto'}
              objectPosition={pos || 'auto'}
            ></Image>
            {mCap ? (
              <Text>{mCap}</Text>
            ) : tCap ? (
              <Text>{tCap}</Text>
            ) : dCap ? (
              <Text>{dCap}</Text>
            ) : null}
          </Box>
        </Flex>
      </Hide>
    </Box>
  );
}

// import React, {
//   useState,
//    useLayoutEffect,
//   useRef,
// } from 'react';
import React from 'react';
import { Flex, Link, Box, Heading, Button, Text } from '@chakra-ui/react';
import RichTextEditorMB from '../../rte';
// import ButtonMB from '../../button';
import RightArrow from '../../../icons/rightArrow';

export default function AdBanner(props) {
  const { data } = props;
  return (
    // <Flex
    //   ref={ref}
    //   align={'center'}
    //   justify={'center'}
    //   w={'100%'}
    //   maxW={'1398px'}
    //   bgImage={`${data?.bg_image?.url}?width=${width}&height=${height}`}
    //   bgPosition={'center'}
    //   bgSize={'cover'}
    //   minH={'195px'}
    //   bgRepeat={'no-repeat'}
    //   mx={'auto'}
    //   py={'1rem'}
    //   alignSelf={'stretch'}
    //   my={'16px'}
    // >
    <Flex
      // ref={ref}
      align={'center'}
      justify={'center'}
      bgImage={`${data?.bg_image?.url}`}
      bgSize={'cover'}
      bgPos={'center'}
      bgRepeat={'no-repeat'}
      w="100%"
      mx={'auto'}
      py={'24px'}
      alignSelf={'stretch'}
      my={'16px'}
    >
      <Flex align={'center'} justify={'center'} w={'fit-content'} mx={'1rem'}>
        <Flex maxW={'758px'} direction="column" align={'center'}>
          {/* <RichTextEditorMB data={data?.ad_block?.head_and_desc} /> */}
          <Flex direction={'column'} align={'center'}>
            <Heading
              color={data?.head_color?.replace(' ', '')}
              fontSize={{ base: '1.5rem', lg: '2.25rem' }}
              fontWeight={'700'}
              // letterSpacing={'-1px'}
              lineHeight={{ base: '1.8', md: '2.375rem', lg: '3.375rem' }}
            >
              {data?.headline}
            </Heading>
            <Flex
              direction={'column'}
              align={'center'}
              color={data?.color?.replace(' ', '')}
            >
              <Box>
                <RichTextEditorMB data={data?.description} />
              </Box>

              {data?.as_button ? (
                <Button
                  as={Link}
                  href={data.cta?.href}
                  bgColor={'white'}
                  color={'black'}
                  target={data.new_tab ? '_blank' : '_self'}
                  borderRadius={'40px'}
                  size={{ base: 'small', md: 'medium' }}
                >
                  <Flex gap={'.5rem'} align={'center'}>
                    <Text>{data.cta?.title}</Text>
                    <Box _hover={{ textDecor: 'none' }}>
                      <RightArrow />
                    </Box>
                  </Flex>
                </Button>
              ) : (
                <Flex gap={'1rem'} align={'center'}>
                  {data?.cta?.title && (
                    <>
                      <Link
                        href={data?.cta?.href}
                        fontSize={'1rem'}
                        fontWeight={'600'}
                        lineHeight={'1.25rem'}
                        target={data?.new_tab ? '_blank' : '_self'}
                      >
                        {data?.cta?.title}
                      </Link>
                      <RightArrow style={{ fontWeight: 600 }} />
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

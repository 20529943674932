import { Flex, Text, Link, useTheme } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

export default function AdGroup(props) {
  const { data, numOfSlots } = props;

  const [s1, setS1] = useState();
  const [s2, setS2] = useState();
  const [s3, setS3] = useState();
  const [s4, setS4] = useState();

  const theme = useTheme();

  const group = [
    s1 && random(s1),
    s2 && random(s2),
    s3 && random(s3),
    s4 && random(s4),
  ];

  useEffect(() => {
    assignSlots();
  }, [data]);

  function assignSlots() {
    filterAdGroup(data?.slot_1, setS1);
    filterAdGroup(data?.slot_2, setS2);
    filterAdGroup(data?.slot_3, setS3);
    filterAdGroup(data?.slot_4, setS4);
  }

  function filterAdGroup(slotData, slotFunction) {
    const set = [];
    slotData?.advertisement_blocks?.map(
      (x) => x.publish_details?.time && set.push(x),
    );
    slotFunction(set);
  }

  function random(slot) {
    return slot[0]?.ad[Math.floor(Math.random() * slot[0].ad.length)];
  }

  return (
    <Flex direction={'column'} w={'100%'} gap={'34px'} mb={'34px'}>
      {group.slice(0, numOfSlots).map((item, i) => {
        if (item) {
          return (
            <Link key={i} href={item.link} target={'_blank'}>
              <Flex direction={'column'}>
                <Flex
                  bg={theme.colors.Grey4}
                  h="19px"
                  flex={1}
                  align={'center'}
                >
                  <Text
                    mx={'auto'}
                    textAlign={'center'}
                    color={theme.colors.Grey1}
                    fontWeight={'600'}
                    fontSize={'.875rem'}
                    lineHeight={'1.192rem'}
                    letterSpacing={'.22em'}
                  >
                    ADVERTISEMENT
                  </Text>{' '}
                </Flex>
                <Flex
                  h={'250px'}
                  w={'100%'}
                  align={'start'}
                  justify={'start'}
                  bgImage={`${item.ad_image?.url}?height=250`}
                  bgSize={'contain'}
                  bgPos={'top center'}
                  bgRepeat={'no-repeat'}
                ></Flex>
              </Flex>
            </Link>
          );
        }
      })}
    </Flex>
  );
}

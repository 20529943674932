import { Field } from 'formik';
import { SimpleGrid, Radio, RadioGroup, Box } from '@chakra-ui/react';
import { formatOptions } from './formhelperFunctions';
const RadioButtons = (props) => {
  const { item, theme, values, setFieldValue } = props;
  return (
    <>
      {!item.field_options.includes('Option') && (
        <RadioGroup
          placeholder={item.placeholder_text}
          variant="outline"
          name={item.field_name}
          id={item.field_name}
          onChange={(value) => setFieldValue(item.field_name, value)}
          margin="4px 0"
        >
          <SimpleGrid columns={[1, null, 2]} spacing={4}>
            {formatOptions(item.field_options).map((option, j) => (
              <Field
                as={Radio}
                alignItems="top"
                isRequired={item.required}
                key={j}
                value={option || ''}
                isChecked={values[item.field_name] === option}
                borderColor={
                  values[item.field_name] === option
                    ? theme.colors.ValidGreen
                    : theme.colors.BlackText
                }
                colorScheme="white"
                sx={{
                  '::before': {
                    color: theme.colors.ValidGreen,
                  },
                }}
                _hover={{
                  backgroundColor: theme.colors.LightGreen,
                }}
              >
                <Box
                  fontWeight={values[item.field_name] === option ? 700 : 400}
                  marginTop="-5px"
                >
                  {option}
                </Box>
              </Field>
            ))}
          </SimpleGrid>
        </RadioGroup>
      )}
    </>
  );
};

export default RadioButtons;

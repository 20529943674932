import React from 'react';
import { Text, Flex, Heading, Link, Button, Box } from '@chakra-ui/react';
import UpRightFromSquare from '../../../icons/upRightFromSq';

export default function InfoCard(props) {
  const { data } = props;

  return (
    <Flex
      direction={'column'}
      align={{ base: 'center', md: 'start' }}
      m={'1rem'}
      justify={'space-around'}
      gap={'1rem'}
      textAlign={{ base: 'center', md: 'initial' }}
    >
      <Heading
        as={'h2'}
        fontSize={{ base: '1.5rem', md: '2rem', lg: '2.5rem' }}
        fontWeight={'700'}
        lineHeight={{ base: '2.125rem', md: '2.75rem', lg: '3rem' }}
      >
        {data?.title}
      </Heading>
      <Text
        fontSize={{ base: '1rem', md: '1.5rem' }}
        fontWeight={'700'}
        lineHeight={{ base: '1.875rem', md: '2rem', lg: '1.875rem' }}
      >
        {data?.headline}
      </Text>
      <Text
        fontSize={{ base: '1rem', md: '1.25rem' }}
        fontWeight={'400'}
        lineHeight={{ base: '1.5rem', md: '1.625rem', lg: '2.313rem' }}
      >
        {data?.text}
      </Text>
      {data?.as_button ? (
        // <ButtonMB buttonObj={data?.link} />
        <Button
          mt={{ base: '5px', md: 'initial', lg: '24px' }}
          size={data?.button_size?.toLowerCase()}
          variant={data?.button_type?.toLowerCase()}
          as={Link}
          href={data?.link?.href}
        >
          {data?.link?.title}
        </Button>
      ) : (
        <Flex
          align={'center'}
          gap={'1rem'}
          fontSize={'1rem'}
          fontWeight={'600'}
          lineHeight={'1.25rem'}
        >
          <Link
            href={data?.link?.href}
            target={data?.new_window ? '_blank' : '_self'}
          >
            {data?.link?.title}
          </Link>
          {data?.external_link ? (
            <UpRightFromSquare fasize={'fa-sm'} />
          ) : (
            <Box className={'fa-solid fa-arrow-right-long'} />
          )}
        </Flex>
      )}
    </Flex>
  );
}

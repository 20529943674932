import React, { useEffect, useState } from 'react';
import { SkipNavContent } from '@chakra-ui/skip-nav';

import api from '../utils/api';
import {
  Flex,
  Box,
  Hide,
  Link,
  Show,
  Text,
  Icon,
  useTheme,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Metadata from '../components/metadata';
import AllAlert from '../components/message_alert/all_alert';
import LinkBanner from '../components/newsArticleComp/AllNewsLinkBanner';
import ArticleHeader from '../components/newsArticleComp/articleHeader/ArticleHeader.js';
import IconBar from '../components/newsArticleComp/socialsIcons';
import AuthorBlock from '../components/newsArticleComp/authorBlock';
import RelatedStories from '../components/newsArticleComp/relatedStories';
import RichTextEditorMB from '../components/modularBlocks/rte';
import BlockGroup from '../components/newsArticleComp/advertisements';
import BlockContainer from '../components/modularBlocks/container';
import CpeSection from '../components/newsArticleComp/cpeSection/CpeSection';
import ImageMB from '../components/modularBlocks/image';
// import ResourceAuthor from '../components/newsArticleComp/resourceAuthor';
// import AuthorCarousel from '../components/newsArticleComp/authorCarousel';
// import CtaSection from '../components/newsArticleComp/ctaSection';
import VideoPlayer from '../components/modularBlocks/videoPlayer';
import Loading from '../components/loadingIndicator/loading';
import { Helmet } from 'react-helmet';
import logger from 'utils/logger';

export default function Article(props) {
  const { ct } = props;

  // const { article } = useParams();
  const [data, setData] = useState();
  const [body, setBody] = useState([]);
  const [time, setTime] = useState();

  const path = window.location.pathname;
  useEffect(() => {
    setBody([]);
    getData();
  }, [ct, path]);
  useEffect(() => {
    timeToRead();
  }, [body]);
  async function getData() {
    const info = await api.getArticle(
      ct,
      path,
      'en-us',
      'article_details.author,related_stories.article',
    );
    buildArticle(info[0]);
    setData(info[0]);
  }
  const theme = useTheme();

  function buildArticle(x) {
    logger.log('props x in buildArticle', x);
    const images = [];
    let marker = images.length;
    x.article_body?.map((entry) => {
      const wStyles = entry.image
        ? entry.image.image_block[0]
        : entry.video_player
        ? entry.video_player
        : entry.rte?.custom_styles?.length >= 1
        ? `<div class="${entry.rte?.custom_styles
            ?.join()
            .replaceAll(' ', '')
            .replaceAll(',', ' ')}">${entry.rte?.rich_text_editor}</div>`
        : entry.rte?.rich_text_editor;

      return setBody((current) => [...current, wStyles]);
    });
    return setBody((current) => {
      current.map((entry, i) => {
        logger.log('entry iterating in setBody', entry);
        if (typeof entry === 'object') {
          images.push(entry);
          // current[i] = `||images[${marker}]||`;
          current[i] = `||${JSON.stringify(current[i])}||`;
          return marker++;
        }
        return;
      });

      const total = current.join('').replaceAll('<p></p>', '').split('</p>');
      const top = total.slice(0, 3).join('</p>') + '</p>';
      const bottom = total.slice(3).join('</p>') + '</p>';
      return [top.split('||'), bottom.split('||')];
    });
  }

  function timeToRead() {
    const content = document?.getElementById('article')?.innerText;
    const words = content?.trim().split(' ').length;
    const wpm = 225;
    setTime(Math.ceil(words / wpm));
  }
  logger.log('ARTICLE DATA: ', data);

  return (
    <main id="main">
      <Helmet>
        {/* Set document title */}
        <title>{data?.article_details?.headline}</title>
        {/* Set structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            // TODO: there is likely some improvements that need to be made with the schema / structured data
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: data?.article_details?.headline,
            datePublished: data?.article_details?.date_published,
            dateModified: data?.article_details?.updated_at,
            author: data?.article_details?.author,
            image: data?.main_image?.url,
            description: data?.article_details?.description,
          })}
        </script>
      </Helmet>
      <SkipNavContent />
      {data ? (
        <Box h="100%">
          {data.seo && <Metadata data={data.seo} override={data} />}
          <AllAlert />
          {/* All News Banner */}

          <LinkBanner
            data={
              ct === 'resource_entry'
                ? { title: 'All Resources', href: '/resources' }
                : { title: 'All News', href: '/news' }
            }
          />
          <Box maxW={'1440px'} mx={'auto'} h="100%">
            {/* TOP SECTION */}
            <ArticleHeader
              data={data}
              ct={ct}
              ArticleTitle={data.article_details?.headline}
            />
            {/* Optional Banner Image Used on News Articles */}
            {ct === 'news_article' && data.main_image && (
              <Flex
                align={'center'}
                justify={'center'}
                ml={{ base: '35px', md: '50px', lg: '62px' }}
                mr={{ base: '35px', md: '50px', lg: '76px' }}
                mb={{ base: '12px', md: '28px', lg: '35px' }}
                h={{ base: '124px', md: '384px', lg: '626px' }}
                bgImage={data.main_image.url}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                borderRadius={'15px'}
              ></Flex>
            )}
            {/* Main container that contains sidebar and article */}
            <Flex
              gap={{ base: '5%', lg: '6%' }}
              h={'100%'}
              ml={{ base: '35px', md: '50px', lg: '62px' }}
              mr={{ base: '35px', md: '50px', lg: '76px' }}
              direction={{ base: 'column-reverse', md: 'row' }}
              mt={!data.main_image && { base: '12px', md: '28px', lg: '69px' }}
            >
              {/* Left Side Related stories and Ads */}
              <Flex direction={'column'} minW={'200px'} maxW={'286'} flex={1}>
                <RelatedStories data={data.related_stories?.article} />
                {ct === 'news_article' && (
                  <Hide below={'md'}>
                    <BlockGroup numOfSlots={4} type={'group'} />
                  </Hide>
                )}
                {ct === 'news_blurb' && (
                  <Hide below={'md'}>
                    <BlockGroup numOfSlots={1} type={'group'} />
                  </Hide>
                )}
              </Flex>

              {/* Main article section */}
              <Flex h={'100%'} maxW={'100%'} flex={1} direction={'column'}>
                {/* Author(news)/Links Header */}
                <Flex
                  borderBottom={`solid 1px ${theme.colors.Black}`}
                  pb={{ base: '29px', md: '17px', lg: '24px' }}
                >
                  {/* Author Section News */}
                  <Flex flex={1}>
                    {ct === 'news_article' && (
                      <AuthorBlock
                        time={time}
                        data={data.article_details?.author}
                      />
                    )}
                  </Flex>

                  {/* Socials Section */}
                  <Flex flex={1} justify={'end'}>
                    <IconBar />
                  </Flex>
                </Flex>

                {/* ----------------------- */}
                {/* Article Body */}
                <Box id="article" className="Article-Body" mt={'29px'}>
                  {body && (
                    <>
                      {body[0]?.map((seg, i) => {
                        if (
                          seg.includes('image_block') &&
                          typeof seg === JSON
                        ) {
                          return <ImageMB key={i} refData={JSON.parse(seg)} />;
                        }
                        if (seg.includes('video') && typeof seg === JSON) {
                          return <VideoPlayer key={i} data={JSON.parse(seg)} />;
                        }
                        return (
                          <RichTextEditorMB key={i} data={seg} px={'initial'} />
                        );
                      })}
                    </>
                  )}
                  {ct === 'news_article' && (
                    <Box my={'30px'}>
                      <Show above={'md'}>
                        <BlockGroup type={'banner'} />
                      </Show>
                    </Box>
                  )}
                  {body && (
                    <>
                      {body[1]?.map((seg, i) => {
                        if (seg === '') {
                          return;
                        }
                        if (seg.includes('uid') && typeof seg === JSON) {
                          return <ImageMB key={i} refData={JSON.parse(seg)} />;
                        }
                        return (
                          <RichTextEditorMB
                            key={i}
                            data={seg}
                            px={'initial'}
                            // mobile={true}
                          />
                        );
                      })}
                    </>
                  )}
                </Box>
                {/* ----------------------- */}

                {/* Post body section with image and button */}
                {data.cpe_eligible && <CpeSection data={data} ct={ct} />}
                {ct === 'news_blurb' && (
                  <Flex
                    direction={'column'}
                    pt={{ base: '25px', md: '32px' }}
                    pb={{ base: '25px', md: '60px' }}
                    gap={'11px'}
                  >
                    <Link
                      href={data.external_cta?.href}
                      color={'DarkGreen'}
                      fontSize={'1.25rem'}
                      lineHeight={'1.25rem'}
                      fontWeight={'600'}
                      target={'_blank'}
                    >
                      {'Full story'} <Icon as={FaExternalLinkAlt} ml={'14px'} />
                    </Link>
                    <Text
                      color={theme.colors.Grey1}
                      fontSize={'.875rem'}
                      fontWeight={'400'}
                      lineHeight={'1.188rem'}
                      letterSpacing={'.03em'}
                    >
                      Source: {data.source}
                    </Text>
                    <Box my={'30px'}>
                      <Show above={'md'}>
                        <BlockGroup type={'banner'} />
                      </Show>
                    </Box>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Box>
          {data.components && <BlockContainer data={data.components} />}
        </Box>
      ) : (
        <Loading minH={'100vh'} ct={'news'} />
      )}
    </main>
  );
}

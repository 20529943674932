import React from 'react';
import BackgroundBanner from './templates/backgroundImage';
import MultiBanner from './templates/multiImage';
import SingleBanner from './templates/singleImage';

export default function HomeBanner(props) {
  const { data } = props;

  return (
    <>
      {data?.image_style === 'Background' ? (
        <BackgroundBanner {...props} />
      ) : data?.image_style === 'Multiple' ? (
        <MultiBanner {...props} />
      ) : (
        <SingleBanner {...props} />
      )}
    </>
  );
}

import {
  Flex,
  Heading,
  Link,
  //  Text,
  Image,
} from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import RichTextEditorMB from '../../../rte';
import fallbackGrey from '../../../../../assets/vectors/fallbackGrey.svg';
import RightArrow from '../../../../icons/rightArrow';

export function VCard(props) {
  const { data, newTab } = props;
  return (
    <Flex
      direction={'column'}
      maxW={'461px'}
      minH={{ sm: '425px', md: '579px', lg: '596px' }}
      h={'100%'}
      border={'solid #cccccc 1px'}
      borderRadius={'20px'}
      gap={'16px'}
      p={{ base: '.75rem', md: '1rem' }}
      boxShadow={'0px 24px 34px rgba(0, 0, 0, 0.03)'}
      flexGrow={1}
      align={'stretch'}
    >
      {data?.image?.url && (
        <Image
          fallbackSrc={fallbackGrey}
          src={`${data?.image?.url}?width=400`}
          alt={data?.image_alt}
          h={{ base: '252px', md: '319px' }}
          borderRadius={'12px 12px 0px 0px'}
          // bgPos={'center'}
          fit="cover"
        />
      )}
      <Flex
        direction={'column'}
        // h={'100%'}
        flexGrow={1}
        // justify={'space-around'}
        gap={'16px'}
      >
        <Heading
          fontSize={'1rem'}
          fontWeight={{ base: '600', lg: '800' }}
          lineHeight={'1.25rem'}
        >
          {data?.title?.toUpperCase()}
        </Heading>
        {/* <Text
          fontSize={{ base: '.875rem', md: '1.25rem' }}
          fontWeight={'400'}
          lineHeight={{ base: '1.25rem', md: '2.125rem' }}
        >
          {data?.description}
        </Text> */}
        {data?.description_rte && (
          <RichTextEditorMB
            data={data?.description_rte}
            px={'initial'}
            style={['card']}
            fontSize={{ base: '.875rem', md: '1.25rem' }}
            fontWeight={'400'}
            lineHeight={{ base: '1.25rem', md: '2.125rem' }}
          />
        )}
        {data?.cta_button?.button_link?.title && (
          <Flex mt={'10px'} gap={'1rem'} align="center">
            <Link
              href={data?.cta_button?.button_link?.href}
              fontFamily={'Lato'}
              fontSize={{ base: '1rem' }}
              fontWeight={{ base: '600', lg: '800' }}
              lineHeight={'1.25rem'}
              color={'DarkGreen'}
              target={
                data?.cta_button?.new_window
                  ? '_blank'
                  : newTab
                  ? newTab
                  : '_self'
              }
            >
              {data?.cta_button?.button_link?.title}
            </Link>
            <RightArrow color={'DarkGreen'} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export function HCard(props) {
  const { data, newTab } = props;
  return (
    <Flex
      border={'solid #cccccc 1px'}
      borderRadius={'20px'}
      gap={'1rem'}
      p={'1rem'}
      flexGrow={1}
    >
      {data?.image?.url && (
        <Image
          src={`${data?.image?.url}?width=400`}
          alt={data?.image_alt_text}
          // h={'235px'}
          w={'235px'}
          borderRadius={'20px 0px 0px 20px'}
          fit="cover"
        />
      )}
      <Flex
        direction={'column'}
        // justify={'center'} //removed during inv-221 to make content justify start
        gap={'16px'}
      >
        <Heading
          fontSize={'1rem'}
          fontWeight={{ base: '600', lg: '800' }}
          lineHeight={'1.25rem'}
        >
          {data?.title?.toUpperCase()}
        </Heading>
        {/* <Text
          fontSize={{ lg: '1.25rem' }}
          fontWeight={'400'}
          lineHeight={'2.125rem'}
        >
          {data?.description}
        </Text> */}
        {data?.description_rte && (
          <RichTextEditorMB
            data={data?.description_rte}
            px={'initial'}
            style={['card']}
            fontSize={{ base: '.875rem', md: '1.25rem' }}
            fontWeight={'400'}
            lineHeight={{ base: '1.25rem', md: '2.125rem' }}
          />
        )}
        {data?.cta_button?.button_link?.title && (
          <Flex gap={'1rem'} align="center" mt={'10px'}>
            <Link
              href={data?.cta_button?.button_link?.href}
              fontSize={{ lg: '1rem' }}
              fontWeight={{ base: '600', lg: '800' }}
              lineHeight={'1.25rem'}
              color={'DarkGreen'}
              target={
                data?.cta_button?.new_window
                  ? '_blank'
                  : newTab
                  ? newTab
                  : '_self'
              }
            >
              {data?.cta_button?.button_link?.title}
            </Link>
            <RightArrow color={'DarkGreen'} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Box,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Flex,
  Button,
  Checkbox,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { WithSearch } from '@elastic/react-search-ui';
// import FilterIcon from '../../../assets/vectors/filterIcon.svg';
import CustomDatePicker from '../datePicker/CustomDatePicker';

export default function MobileFilter(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { filter, filters, setTags, tags, erl, ...rest } = props;
  const faArrow = 'fa-solid fa-arrow-right-long';
  const [cat, setCat] = useState();
  const [view, setView] = useState(0);
  const theme = useTheme();

  return (
    <Box w={'48%'} {...rest}>
      <Button
        display={{ base: 'flex', md: 'none' }}
        border={`solid 1px ${theme.colors.Grey4}`}
        borderRadius={'50px'}
        bg={'White'}
        w={'100%'}
        onClick={onOpen}
        justifyContent={'space-between'}
        px={'24px'}
      >
        <Text
          fontFamily={'Lato'}
          fontSize={'1rem'}
          fontWeight={'400'}
          lineHeight={'1.188rem'}
          color={theme.colors.Grey1}
        >
          Filter by
        </Text>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'full'}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          <ModalHeader
            borderBottom={'1px solid #D6D6D6'}
            fontFamily={'Lato'}
            fontSize={'1.563rem'}
            lineHeight={'1.875rem'}
            fontWeight={'700'}
          >
            {view === 1 && (
              <Box
                display={'inline-block'}
                onClick={() => setView(() => view - 1)}
                mr={'14px'}
              >
                <span className="fa-solid fa-arrow-left-long" />
              </Box>
            )}
            {view === 1 && (cat.title || 'Date Range')}
            {view === 0 && 'All Filters'}
          </ModalHeader>
          <ModalCloseButton onClick={() => setView(0)} />
          <ModalBody px={'0'}>
            {view === 0 && (
              <Flex direction={'column'}>
                {!erl && (
                  <Flex
                    direction={'column'}
                    py={'16px'}
                    px={'24px'}
                    w={'100%'}
                    borderBottom={`1px solid ${theme.colors.Grey4}`}
                    onClick={() => {
                      setView(() => view + 1);
                      return setCat('date');
                    }}
                  >
                    <Flex justify={'space-between'} align={'center'}>
                      <Heading
                        weight={'700'}
                        fontFamily={'Open Sans'}
                        fontSize={'1.125rem'}
                        lineHeight={'1.532rem'}
                      >
                        Date Range
                      </Heading>
                      <Box className={faArrow} />
                    </Flex>
                  </Flex>
                )}
                {filter?.map((group, index) => {
                  return (
                    <Flex
                      direction={'column'}
                      key={index}
                      py={'16px'}
                      px={'24px'}
                      // border={'solid #00000010 1px'}
                      display={
                        filters?.includes(
                          group.title?.toLowerCase().replaceAll(' ', '_'),
                        )
                          ? 'block'
                          : 'none'
                      }
                      w={'100%'}
                      borderBottom={`solid 1px ${theme.colors.Grey4}`}
                      onClick={() => {
                        setView(() => view + 1);
                        return setCat(group);
                      }}
                    >
                      <Flex justify={'space-between'} align={'center'}>
                        <Heading
                          weight={'700'}
                          fontFamily={'Open Sans'}
                          fontSize={'1.125rem'}
                          lineHeight={'1.532rem'}
                        >
                          {group.title}
                        </Heading>
                        <Box className={faArrow} />
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            )}
            {view === 1 &&
              (cat === 'date' ? (
                <Flex direction={'column'} gap={'7px'}>
                  <Box px="35px">
                    <CustomDatePicker />
                  </Box>
                </Flex>
              ) : (
                <Flex direction={'column'} gap={'7px'}>
                  {cat.options?.map((choice, i) => {
                    return (
                      <Checkbox
                        px={'24px'}
                        key={i}
                        fontFamily={'Open Sans'}
                        fontSize={'1rem'}
                        lineHeight={'1.375rem'}
                        colorScheme={'green'}
                        onChange={(e) => {
                          return e.target.checked
                            ? setTags([
                                ...tags,
                                `${cat.title.toLowerCase()}=${choice}`,
                              ])
                            : setTags(() => {
                                return tags.filter((x) => {
                                  return (
                                    x !== `${cat.title.toLowerCase()}=${choice}`
                                  );
                                });
                              });
                        }}
                        isChecked={
                          tags.includes(`${cat.title.toLowerCase()}=${choice}`)
                            ? true
                            : false
                        }
                      >
                        {choice}
                      </Checkbox>
                    );
                  })}
                </Flex>
              ))}
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <WithSearch
              mapContextToProps={({ clearFilters, addFilter }) => ({
                clearFilters,
                addFilter,
              })}
            >
              {({ clearFilters, addFilter }) => {
                return (
                  <>
                    <Button
                      onClick={() => {
                        setTags([]);
                        return clearFilters();
                      }}
                      bgColor={'inherit'}
                      color={'DarkGreen'}
                      fontFamily={'Lato'}
                      weight={'600'}
                      fontSize={'1rem'}
                      lineHeight={'1.25rem'}
                    >
                      Clear All Filters
                    </Button>
                    <Button
                      size={'md'}
                      color={'IAPPGreen'}
                      border={'solid 1px'}
                      borderRadius={'50px'}
                      bgColor={'inherit'}
                      onClick={() => {
                        tags?.map((tag) => {
                          return addFilter('tags', tag);
                        });
                        onClose();
                      }}
                    >
                      Apply
                    </Button>
                  </>
                );
              }}
            </WithSearch>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

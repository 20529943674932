import React, { memo, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import api from '../../../../utils/api';
import { useParams } from 'react-router-dom';
import Vertical from './vertical';
import Horizontal from './horizontal';

export default function TabCarousel(props) {
  const { data } = props;
  const { l } = useParams();
  const [carousel, setCarousel] = useState();
  const PureHorizontal = memo(Horizontal);

  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntry(
      data?.carousel_tabs[0]?._content_type_uid,
      data?.carousel_tabs[0]?.uid,
      l,
    );

    setCarousel(info.entry.tab_carousel);
  }
  return (
    <Box>
      {carousel?.v_layout && <Vertical data={carousel} />}
      {!carousel?.v_layout && (
        <Box maxW={'1440px'} mx={{ base: '1rem', xl: 'auto' }} py={'2rem'}>
          <PureHorizontal data={carousel} />
        </Box>
      )}
    </Box>
  );
}

import { useTheme, Flex, Link, Text, Box } from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import MenuPromo from '../menuPromo';
import RightArrow from '../../../../icons/rightArrow';

export default function DesktopMenu(props) {
  const { data, setActive, setMenu } = props;
  const theme = useTheme();
  return (
    <>
      <Box
        bg={theme.colors.Grey4}
        borderBottom={`1px solid ${theme.colors.Grey5}`}
      >
        {data?.title_link.href ? (
          <Box mx={'2%'} my={'16px'}>
            <Link
              href={data?.title_link?.href}
              fontWeight={'700'}
              fontSize={'1.375rem'}
              lineHeight={'1.873rem'}
              color={'DarkGreen'}
            >
              {data?.title_link?.title || data?.title}
            </Link>
          </Box>
        ) : (
          <Text
            fontWeight={'700'}
            fontSize={'1.375rem'}
            lineHeight={'1.873rem'}
            color={'DarkGreen'}
            mx={'2%'}
            my={'16px'}
          >
            {data?.title || data?.title_link?.title}
          </Text>
        )}
      </Box>

      <Flex
        bg={'#FBFBFB'}
        maxW={'100%'}
        w={`${25 * data?.link_columns?.length + 20}%`}
      >
        {data?.link_columns?.map((column, i) => {
          return (
            <Flex
              key={i}
              flex={1}
              minW={'20%'}
              direction={'column'}
              gap={'50px'}
              borderRight={
                i < data?.link_columns?.length - 1
                  ? `1px solid ${theme.colors.Grey3}`
                  : 'none'
              }
              my={'40px'}
            >
              {column.link_section?.map((linkSection, i) => {
                return (
                  <Flex key={i}>
                    <Flex
                      direction={'column'}
                      gap={'16px'}
                      // border={'green solid 2px'}
                      mx={'10%'}
                    >
                      {linkSection.section_title_link?.href ? (
                        <Link
                          href={linkSection.section_title_link?.href}
                          fontWeight={'700'}
                          fontSize={'1rem'}
                          lineHeight={'1.36rem'}
                        >
                          {linkSection.section_title_link?.title ||
                            linkSection.section_title ||
                            ''}
                        </Link>
                      ) : linkSection.section_title ? (
                        <Text
                          fontWeight={'700'}
                          fontSize={'1rem'}
                          lineHeight={'1.36rem'}
                        >
                          {linkSection.section_title}
                        </Text>
                      ) : (
                        ''
                      )}
                      {linkSection.link?.map((link, i) => {
                        return (
                          <Link
                            key={i}
                            href={link.href}
                            onClick={() => {
                              setActive(false);
                              setMenu();
                            }}
                            fontSize={'1rem'}
                            lineHeight={'1.363rem'}
                            fontWeight={'400'}
                          >
                            <Text>{link.title}</Text>
                          </Link>
                        );
                      })}
                    </Flex>
                  </Flex>
                );
              })}
              {column.view_all_link?.href && (
                <Flex
                  gap={'16px'}
                  align={'center'}
                  fontFamily={'Lato'}
                  fontSize={'.75rem'}
                  lineHeight={'.9rem'}
                  fontWeight={'700'}
                >
                  <Link href={column.view_all_link?.href} ml={'10%'}>
                    {column.view_all_link?.title}
                  </Link>
                  <RightArrow />
                  {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
                </Flex>
              )}
            </Flex>
          );
        })}
        {data?.menu_promo[0] && <MenuPromo data={data?.menu_promo[0]} />}
      </Flex>
    </>
  );
}

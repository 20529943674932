import { Box, Heading, Text } from '@chakra-ui/react';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../utils/api';
import RichTextEditorMB from '../rte';

export default function TableMB(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  // const [sortOrder, setSortOrder] = useState('asc');
  // const [sortedColumn, setSortedColumn] = useState(null);
  const [outerBoxHeight, setOuterBoxHeight] = useState(null);
  const tableRef = useRef(null);
  const paddingBottom = 20;

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }

  // Used for handling the size/width of the page, so the scroll overflow scales properly and dynamically.
  // Combined with the paddingBottom and the tableRef variables created above so scroll bar shows below the table.
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        setOuterBoxHeight(tableRef.current.clientHeight);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [tableRef]);

  // Sorting logic for each column. Sorts normally by default/however content is set up in ContentStack.
  // function sortColumn(columnIndex) {
  //   if (sortedColumn === columnIndex) {
  //     setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  //   } else {
  //     setSortedColumn(columnIndex);
  //     setSortOrder('desc');
  //   }

  //   setNewData(prevData => {
  //     const sortedColumns = prevData.columns[columnIndex].cell_content.sort(
  //       (a, b) => {
  //         if (sortOrder === 'desc') {
  //           return a.localeCompare(b);
  //         } else {
  //           return b.localeCompare(a);
  //         }
  //       },
  //     );

  //     return {
  //       ...prevData,
  //       columns: prevData.columns.map((col, i) => {
  //         if (i === columnIndex) {
  //           return {
  //             ...col,
  //             cell_content: sortedColumns,
  //           };
  //         } else {
  //           return col;
  //         }
  //       }),
  //     };
  //   });
  // }

  return (
    <Box
      className="ScrollableTable"
      maxW={{ base: '84%', md: '781px', lg: '1145px' }}
      w={{ md: '86%', lg: '80%' }}
      mx={'auto'}
      my="16px"
      height={outerBoxHeight ? `${outerBoxHeight + paddingBottom}px` : 'auto'}
      overflowX="auto"
    >
      {newData && (
        <Box ref={tableRef}>
          <Box marginBottom={{ lg: '36px', base: '34px' }}>
            <Heading
              fontWeight="700"
              fontSize={{ lg: '32px', md: '25px', base: '20px' }}
              textColor="#006955"
            >
              {newData.table_title}
            </Heading>
          </Box>
          <Box display="flex" flexDir="row">
            <Box
              display="flex"
              flex="row"
              borderTop="1px solid #DDDFE1"
              borderBottom="1px solid #DDDFE1"
              borderLeft="1px solid #DDDFE1"
              margin={'auto'}
            >
              {newData.columns?.map((column, i) => {
                return (
                  <Box key={i}>
                    <Box
                      display="flex"
                      flexDir="row"
                      paddingX="30px"
                      justifyContent="left"
                      width="auto"
                      height="50px"
                      textColor="white"
                      backgroundColor="#006955"
                    >
                      <Text alignSelf="center" fontWeight="500" fontSize="18px">
                        {column.column_heading}
                      </Text>
                      {/* {newData.sort_button && (
                        <Box
                          alignSelf="center"
                          as={Button}
                          bgColor="#006955"
                          _hover={{ bgColor: '#006955' }}
                          onClick={() => sortColumn(i)}
                        >
                          <FontAwesomeIcon
                            height="15px"
                            width="15px"
                            color="white"
                            icon={
                              sortedColumn === i && sortOrder === 'desc'
                                ? solid('arrow-up')
                                : solid('arrow-down')
                            }
                          />
                        </Box>
                      )} */}
                    </Box>
                    {column.cell_content?.map((cellContent, i) => {
                      return (
                        <Box
                          key={i}
                          width="auto"
                          minH="72px"
                          paddingX="30px"
                          borderTop="1px solid #DDDFE1"
                          borderRight="1px solid #DDDFE1"
                          borderCollapse="collapse"
                          display="flex"
                          flexWrap="wrap"
                          alignItems="center"
                        >
                          <Box maxW="312px">
                            <RichTextEditorMB
                              data={cellContent}
                              px="initial"
                              fontSize="16px"
                              style={['card']}
                              wordWrap={'break-word'}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import { React, useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';

import RightCircleArrow from 'components/icons/rightCircleArrow/rightCircleArrow';

import { getValidations, getInitialValues } from './formhelperFunctions';

import { Text, Button, Box, Flex, useTheme } from '@chakra-ui/react';

import { Formik, Form } from 'formik';
import SingleLine from './singleLine';
import CountryList from './countryList';
import ZipCode from './zipCode';
import PhoneNumber from './phoneNumber';
import Email from './email';
import Url from './url';
import MultiLine from './multiLine';
import Date from './date';
import SingleDropdown from './singleDropdown';
import CheckBox from './checkBox';
import RadioButtons from './radio';
import StateList from './stateList';
import BaseField from './baseField';

// const getCountryCodes = (country) => {
//   const countryCodes = [];
//   country.countries.map((codes) => {
//     countryCodes.push(codes.phone_code);
//   });
//   return countryCodes;
// };

export default function FormComponent(props) {
  const { data } = props;
  const { l } = useParams();
  const [formData, setFormData] = useState();
  const theme = useTheme();

  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setFormData(info.entry);
  }

  const initialValues = getInitialValues(formData);
  const validationSchema = getValidations(formData);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
      }}
    >
      {({ errors, touched, values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Flex
            paddingTop="48px"
            maxWidth="480px"
            mx="auto"
            wrap="wrap"
            flexDirection="column"
            px="16px"
          >
            <Box
              color={theme.colors.BlackText}
              fontWeight="700"
              marginBottom="42px"
            >
              <Text
                fontSize="24px"
                display="inline"
                borderBottom={`4px solid ${theme.colors.GreenStroke}`}
                marginBottom="42px"
              >
                {formData?.title}
              </Text>
            </Box>
            {!formData?.description.internal_only && (
              <Text fontSize="16px" marginBottom="24px">
                {formData?.description.description}
              </Text>
            )}
          </Flex>
          <Flex
            maxWidth="480px"
            mx="auto"
            wrap="wrap"
            paddingBottom="48px"
            rowGap="16px"
          >
            {formData?.section[0]?.field?.map((item, i) => {
              const errorOrValidColor =
                errors[item.field_name] && touched[item.field_name]
                  ? theme.colors.Raspberry
                  : theme.colors.Grey2;

              const errorOrValidText =
                errors[item.field_name] && touched[item.field_name]
                  ? theme.colors.Raspberry
                  : theme.colors.BlackText;

              if (item.field_type === 'singleLine') {
                return (
                  <BaseField
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    key={i}
                    item={item}
                    labelColor={errorOrValidText}
                  >
                    <SingleLine
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                      values={values}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'countryList') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <CountryList
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'stateList') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <StateList
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'zipCode') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <ZipCode
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                      values={values}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'phoneNumber') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <PhoneNumber
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                      errorOrValidText={errorOrValidText}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'email') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <Email
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                      errorOrValidText={errorOrValidText}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'url') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <Url
                      item={item}
                      values={values}
                      errorOrValidColor={errorOrValidColor}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'multiLine') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <MultiLine
                      item={item}
                      values={values}
                      errorOrValidColor={errorOrValidColor}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'date') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <Date
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                      values={values}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'singleDropdown') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <SingleDropdown
                      item={item}
                      errorOrValidColor={errorOrValidColor}
                      values={values}
                    />
                  </BaseField>
                );
              } else if (item.field_type === 'checkbox') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <CheckBox item={item} theme={theme} />
                  </BaseField>
                );
              } else if (item.field_type === 'radio') {
                return (
                  <BaseField
                    key={i}
                    item={item}
                    errors={errors}
                    touched={touched}
                    theme={theme}
                    labelColor={errorOrValidText}
                  >
                    <RadioButtons
                      item={item}
                      values={values}
                      theme={theme}
                      setFieldValue={setFieldValue}
                    />
                  </BaseField>
                );
              } else {
                return '';
              }
            })}
            <Box display="block" width="100%">
              <Button
                marginTop="8px"
                variant="primary"
                rightIcon={<RightCircleArrow />}
                mx="8px"
                type="submit"
                borderRadius="32px"
              >
                {formData?.submit_button_label || 'Submit'}
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import {
  Box,
  Flex,
  Image,
  Hide,
  Button,
  Show,
  Text,
  Link,
} from '@chakra-ui/react';
import UtilNav from '../util_nav';
import Menu from '../main_nav/menus';
import useLanguageCheck from '../../../hooks/useLanguageCheck';
import MobileMenu from './menus/mobileMenu';
import { BsList, BsXLg } from 'react-icons/bs';
import MainButtonGroup from './buttonGroup';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import LeftArrow from '../../icons/leftArrow';
import logger from 'utils/logger';

export default function MainNav(props) {
  const { setActive, setMenu, active, menu } = props;
  const [data, setData] = useState();
  const [mobileOpen, setMobileOpen] = useState();
  const [back, setBack] = useState(false);
  const [menuGroup, setMenuGroup] = useState(true);

  // smv is Seconday Menu View for the sub menues in mobile
  const [smv, setSMV] = useState(false);
  let { l } = useParams();
  l = useLanguageCheck(l);
  useEffect(() => {
    getData(l);
  }, [l]);
  useLayoutEffect(() => {
    handleResize();
  }, []);
  const handleResize = () => {
    setMenuGroup(true);
  };
  window.addEventListener('resize', handleResize);
  async function getData(l) {
    const info = await api.getAllEntriesWithRef(
      'main_nav',
      'megamenu.pages.menu.menu.menu_promo',
      l,
    );

    setData(info[0]);
  }

  logger.log('Mega Data: ', data);
  return (
    <Flex
      mx={'auto'}
      direction={'column'}
      justify={'center'}
      h={'104px'}
      gap={'17%'}
      bg={'inherit'}
      color={'inherit'}
    >
      <Hide below="md">
        <Box borderBottom={'1px solid #F5F5F5'} py={'18px'}>
          <UtilNav
            setOpen={setMobileOpen}
            defOpen={mobileOpen}
            setActive={setActive}
            viewBack={setBack}
            setSMV={setSMV}
            setMenuGroup={setMenuGroup}
            onMouseOver={() => setActive(false)}
            {...props}
          />
        </Box>
      </Hide>
      <Flex>
        {/* This is the initial state of the menu
            in the mobile state. The hambuger button
            will show */}
        {!mobileOpen && (
          <Hide above="lg">
            <Flex flex={1}>
              <MobileMenu
                data={data?.megamenu[0]?.pages}
                open={mobileOpen}
                setOpen={setMobileOpen}
                icon={<BsList />}
                defOpen={false}
                direction={'ltr'}
                back={back}
                viewBack={setBack}
                smv={smv}
                setSMV={setSMV}
                menuGroup={menuGroup}
                setMenuGroup={setMenuGroup}
                setActive={setActive}
                {...props}
              />
            </Flex>
          </Hide>
        )}
        {/* Back button that shows in secondary mobile menu */}
        {back && (
          <Hide above="lg">
            <Flex flex={1}>
              <Button
                onClick={(e) => {
                  e.preventDefault;
                  setSMV(false);
                  setBack(false);
                }}
                bg={'inherit'}
                color={'inherit'}
                border={'none'}
                size={'md'}
                // _hover={'none'}
              >
                <Flex gap={'.5rem'} align={'center'}>
                  <LeftArrow />
                  {/* <FontAwesomeIcon icon={solid('arrow-left-long')} /> */}
                  <Text>Back</Text>
                </Flex>
              </Button>
            </Flex>
          </Hide>
        )}
        {/* This is a place holder container to keep alignment
            correct whene on the main mobile menu */}
        {mobileOpen && !back && <Flex flex={1} />}

        {/* Logo: this will apear to the far left in desktop
            and in the center on mobile */}
        <Flex
          flex={{ base: 1, lg: 2 }}
          justifyContent={{ base: 'center', lg: 'start' }}
        >
          <Link href={'/'}>
            <Image
              src={data?.logo?.image?.url}
              alt={data?.logo?.alt_text}
              h="39"
              mx={{ lg: '16px' }}
            />
          </Link>
        </Flex>

        {/* This will be the main nav links section on Desktop only */}
        <Hide below="lg">
          <Flex
            flexGrow={3}
            justifyContent={'center'}
            bg={'inherit'}
            // we are adding the extra height to make up for the gap in the parent container
            h={'117%'}
            onMouseLeave={() => setActive(false)}
          >
            <Flex
              flex={1}
              justify={'center'}
              fontSize={'16px'}
              fontWeight={'500'}
            >
              {data?.megamenu[0]?.pages?.map((page, i) => {
                return (
                  <Box key={i} mx={'1%'}>
                    {page.menu && (
                      <Button
                        as={Link}
                        href={page.menu.menu[0]?.title_link?.href}
                        px={0}
                        size={'md'}
                        // border={'none'}
                        bg={'inherit'}
                        color={'inherit'}
                        _hover={{ textDecor: 'underline' }}
                        onClick={() => {
                          !menu
                            ? setActive(!active)
                            : page.menu.menu[0] == menu
                            ? setActive(!active)
                            : active === false && setActive(true);

                          setMenu(page.menu.menu[0]);
                        }}
                        onMouseOver={() => {
                          //   ? setActive(!active)
                          //   : page.menu.menu[0] == menu
                          //   ? setActive(!active)
                          //   : active === false && setActive(true);
                          setActive(true);
                          setMenu(page.menu.menu[0]);
                        }}
                        // border={'solid hotpink 2px'}
                        h={'150%'}
                      >
                        {page.menu.menu[0]?.title}
                      </Button>
                    )}
                    {page.link && (
                      <Button
                        px={0}
                        size={'md'}
                        border={'none'}
                        key={i}
                        as={Link}
                        href={page.link.link?.href}
                        onClick={() => {
                          setActive(false);
                          setMenu();
                        }}
                        bg={'inherit'}
                        color={'inherit'}
                        _hover={{ textDecor: 'underline' }}
                      >
                        {page.link.link?.title}
                      </Button>
                    )}
                  </Box>
                );
              })}
            </Flex>
            {active && (
              <Menu data={menu} setActive={setActive} setMenu={setMenu} />
            )}
          </Flex>
        </Hide>
        {/* Green buttons on Right on Desktop */}
        <Show above="lg">
          <Flex flex={2} justifyContent={'end'} mx={{ lg: '16px' }}>
            <MainButtonGroup data={data} />
          </Flex>
        </Show>
        {/* Green button controls on mobile. Needed seperately
            since the go inside a menu on mobile */}
        {menuGroup && (
          <Show below="lg">
            <Flex flex={1} justifyContent={'end'}>
              <MainButtonGroup data={data} />
            </Flex>
          </Show>
        )}
        {/* This is the main menu in mobile when active
            will show an "X" icon */}
        {mobileOpen && (
          <Hide above="lg">
            <Flex flex={1} justifyContent={'end'}>
              <MobileMenu
                data={data?.megamenu[0]?.pages}
                open={mobileOpen}
                setOpen={setMobileOpen}
                icon={<BsXLg />}
                defOpen={true}
                direction={'rtl'}
                back={back}
                viewBack={setBack}
                smv={smv}
                setSMV={setSMV}
                menuGroup={menuGroup}
                setMenuGroup={setMenuGroup}
                buttonData={data}
                setActive={setActive}
              />
            </Flex>
          </Hide>
        )}
      </Flex>
      {/* This controls if the desktop menu is visible */}
      {/* {active && <Menu data={menu} setActive={setActive} setMenu={setMenu} />} */}
    </Flex>
  );
}

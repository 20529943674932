import { Flex, Link, Text, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../utils/api';
import ChevronRightIcon from '../icons/chevronRight';

export default function Navigation() {
  const data = useLocation().pathname;
  const [type, setType] = useState([]);
  const links = data.split('/');
  const [names, setNames] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    links.map((page, i) => {
      if (page.length > 1) {
        switch (page) {
          case 'certify':
            setType((current) => [...current, page]);
            setNames((current) => [...current, 'Certification']);
            break;
          case 'train':
            setType((current) => [...current, page]);
            setNames((current) => [...current, 'Training']);
            break;
          case 'enterprise-services':
            setType((current) => [...current, page]);
            setNames((current) => [...current, 'Enterprise Services']);
            break;
          case 'c':
            setNames((current) => [...current, 'Corporate Landing Page']);
            break;
          case 'l':
            setNames((current) => [...current, 'Marketing Landing Page']);
            break;
          default:
            if (i === 1) {
              isOverview(page);
            }
            isSubPage(links.slice(0, i).join('/').concat(':title'), page);
            setNames((current) => [...current, page]);
        }
      }
    });
  }

  async function isOverview(page) {
    const info = await api.getSchema(`${page}_overview`);

    if (info?.length > 0) {
      return setType((current) => [...current, page]);
    }
    return;
  }

  async function isSubPage(path, page) {
    const info = await api.getUID(path);

    if (info?.length > 0) {
      return setType((current) => [...current, page]);
    }
    return;
  }

  return (
    <Box px={'2%'}>
      <Flex
        align={'center'}
        maxW={{ lg: '1200px', base: '100%' }}
        mx={{ base: '1rem', lg: 'auto' }}
        py={'16px'}
        px={{ base: '16px', md: '24px', lg: '32px' }}
        gap={'.5rem'}
      >
        <Link
          href={'/'}
          fontSize={{ lg: '16px', base: '14px' }}
          letterSpacing="0.02em"
          opacity="0.9"
        >
          HOME
        </Link>
        <ChevronRightIcon
          fontSize={{ lg: '16px', base: '14px' }}
          letterSpacing="0.02em"
          opacity="0.9"
        />
        {links.slice(1, links.length).map((page, i) => {
          let finalLabel;
          if (names[i]) {
            const total = names[i].split('-');
            const caps = [];
            total.map((word) => {
              return caps.push(word[0].toUpperCase() + word.slice(1));
            });
            finalLabel = caps.join(' ');
          }

          if (type.includes(page)) {
            return (
              <Flex key={i} gap={'.5rem'} align={'center'}>
                <Link
                  href={`${links.slice(0, i + 2).join('/')}`}
                  fontSize={{ lg: '16px', base: '14px' }}
                  // letterSpacing="-0.02em"
                  opacity="0.9"
                >
                  {finalLabel?.toUpperCase()}
                </Link>{' '}
                {i !== links.length - 2 && (
                  <ChevronRightIcon
                    fontSize={{ lg: '16px', base: '14px' }}
                    letterSpacing="0.02em"
                    opacity="0.9"
                  />
                )}
              </Flex>
            );
          }
          if (i === links.length - 2) {
            return (
              <Flex
                key={i}
                gap={'.5rem'}
                fontSize={{ lg: '16px', base: '14px' }}
                letterSpacing="0.02em"
                opacity="0.9"
                align={'center'}
                fontWeight={'600'}
              >
                <Text>{finalLabel?.toUpperCase()}</Text>
              </Flex>
            );
          }
          // return (
          //   <Flex
          //     key={i}
          //     gap={'.5rem'}
          //     fontSize={{ lg: '16px', base: '14px' }}
          //     letterSpacing="-0.02em"
          //     opacity="0.9"
          //     align={'center'}
          //     fontWeight={i === links.length - 2 ? '600' : 'initial'}
          //   >
          //     <Text>{finalLabel}</Text>
          //     {i !== links.length - 2 && (
          //       <ChevronRightIcon
          //         fontSize={{ lg: '16px', base: '14px' }}
          //         opacity="0.9"
          //       />
          //     )}
          //   </Flex>
          // );
        })}
      </Flex>
    </Box>
  );
}

import {
  Flex,
  Heading,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
  useTheme,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import RichTextEditorMB from '../rte';
import RightArrow from '../../icons/rightArrow';
import InnerAccordion from './innerAccordion';

export default function AccordionMB(props) {
  const { refData } = props;
  const theme = useTheme();
  const { l } = useParams();
  const [data, setData] = useState();
  const [cols, setCols] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntryWithRef(
      refData?._content_type_uid,
      refData?.uid,
      l,
      'entry.inner_accordion',
    );
    setData(info.entry);
    divideCols(info.entry);
  }

  function divideCols(data) {
    const one = data.entry?.slice(
      0,
      data.entry.length % 2 === 0
        ? Math.floor(data.entry.length / 2)
        : Math.floor(data.entry.length / 2) + 1,
    );
    const two = data.entry?.slice(
      data.entry.length % 2 === 0
        ? Math.floor(data.entry.length / 2)
        : Math.floor(data.entry.length / 2) + 1,
    );
    setCols(() => [one, two]);
  }
  return (
    <Box
      id={data?.title?.toLowerCase().replaceAll(' ', '-')}
      bgColor={'#EBEBEB'}
      py={{ base: '24px', md: '32px' }}
    >
      <Box
        maxW={{ base: '84%', md: '781px', lg: '1145px' }}
        w={{ md: '86%', lg: '80%' }}
        mx={'auto'}
      >
        <Flex direction={'column'} gap={'16px'}>
          {data?.heading && (
            <Heading fontSize="1.5rem" fontWeight={'700'} lineHeight="2.88rem">
              {data?.heading}
            </Heading>
          )}
          {data?.heading_text && (
            <Box fontSize="1rem" lineHeight="1.5rem">
              <RichTextEditorMB data={data?.heading_text} px="initial" />
            </Box>
          )}
        </Flex>
        <Flex>
          <Accordion allowToggle w={'100%'}>
            <Flex
              direction={
                data?.two_column ? { base: 'column', md: 'row' } : 'column'
              }
              gap={data?.two_column ? { base: '16px', md: '24px' } : '-24px'}
              w={'100%'}
            >
              {cols?.map((col, i) => {
                return (
                  <Box
                    key={i}
                    w={data?.two_column ? { base: '100%', md: '50%' } : '100%'}
                    mt={
                      i === 1 && data?.two_column
                        ? { base: '-32px', md: 'initial' }
                        : i === 1
                        ? { base: '-16px', md: '-24px' }
                        : 'initial'
                    }
                  >
                    {col.map((entry, i) => {
                      return (
                        <AccordionItem
                          key={i}
                          bgColor="white"
                          my={{ base: '16px', md: '24px' }}
                          mx={'auto'}
                          borderRadius={'16px'}
                          boxShadow="0px 4px 4px 0px rgba(30, 30, 30, 0.35)"
                          border={`2px solid ${theme.colors.Grey3}`}
                        >
                          {({ isExpanded }) => {
                            return (
                              <>
                                <AccordionButton
                                  _expanded={{ color: theme.colors.GreenLink }}
                                  gap={'2rem'}
                                  fontWeight={'600'}
                                  borderRadius={'16px 16px 0 0'}
                                  padding="16px"
                                >
                                  <Box
                                    flex="1"
                                    textAlign="left"
                                    color={theme.colors.BlackText}
                                    fontSize="1.25rem"
                                    fontWeight="700"
                                  >
                                    {entry.title}
                                  </Box>
                                  <AccordionIcon
                                    w={12}
                                    h={12}
                                    color={theme.colors.BlackText}
                                    my="-16px"
                                  />
                                </AccordionButton>

                                <AccordionPanel padding="0">
                                  <Box
                                    key={i}
                                    fontWeight={'400'}
                                    fontSize="1rem"
                                    p="16px"
                                  >
                                    <RichTextEditorMB
                                      data={entry.text}
                                      px="initial"
                                      fontSize="1rem"
                                    />
                                  </Box>

                                  {entry.inner_accordion?.length > 0 && (
                                    <InnerAccordion
                                      data={entry.inner_accordion[0]}
                                    />
                                  )}
                                  {entry.cta_link?.title && (
                                    <Flex
                                      gap={'1rem'}
                                      align="center"
                                      p={
                                        entry.inner_accordion?.length > 0
                                          ? '16px'
                                          : '0px 16px 16px 16px'
                                      }
                                      borderTop={
                                        entry.inner_accordion?.length > 0
                                          ? `2px solid ${theme.colors.Grey3}`
                                          : 'none'
                                      }
                                    >
                                      <Link
                                        href={entry.cta_link?.href}
                                        fontSize="1rem"
                                        fontWeight="700"
                                        lineHeight={'1.25rem'}
                                        color={theme.colors.DarkGreen}
                                      >
                                        {entry.cta_link?.title}
                                      </Link>
                                      <RightArrow
                                        style={{
                                          color: theme.colors.DarkGreen,
                                        }}
                                      />
                                    </Flex>
                                  )}
                                </AccordionPanel>
                              </>
                            );
                          }}
                        </AccordionItem>
                      );
                    })}
                  </Box>
                );
              })}
            </Flex>
          </Accordion>
        </Flex>
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import DeskFilter from './deskFilter';
import MobileFilter from './modalFilter';
import EntityFilter from './entityFilter';
import api from '../../../utils/api';

/**
 *
 * @param {{
 *   type?: 'mobile' | 'entity' | 'desk',
 *   ct: string,
 *   filters: Parameters<typeof DeskFilter>[0]['filters'],
 * }} param0
 * @returns
 */
export default function FilterContainer({
  type = 'desk',
  ct,
  filters,
  ...props
}) {
  const [filter, setFitler] = useState([]);
  const [more, setMore] = useState([]);

  useEffect(() => {
    buildFilter(ct);
  }, [ct]);
  useEffect(() => {
    return setMore(Array.from({ length: filter?.length }, () => false));
  }, [filter]);

  // This section will build out the filter object that is used in the sidebar and for handling tags
  async function buildFilter(ct) {
    setFitler([]);
    const total = [];
    if (ct === 'news_overview') {
      const info1 = await api.getSchema('news_article');
      const info2 = await api.getSchema('news_blurb');
      schemaMap([info1, info2], total);
    }
    if (ct === 'resources_overview') {
      const info = await api.getSchema('resource_entry');
      schemaMap([info], total);
    }
    if (ct && !ct.includes('overview')) {
      const info = await api.getSchema(ct);
      schemaMap([info], total);
    }
    // These are the listing page types, you will need to ammend the list if future pages are added
    if (
      ct === 'subject_page' ||
      ct === 'legislation_page' ||
      ct === 'tools_and_trackers_page' ||
      ct === 'web_conferences_page'
    ) {
      const info1 = await api.getSchema('news_article');
      const info2 = await api.getSchema('resource_entry');
      schemaMap([info1, info2], total);
    }

    if (total.length > 0) {
      return total.map((entry) => {
        return setFitler((current) => [
          ...current,
          { title: entry.title, options: [...entry.options] },
        ]);
      });
    }
  }

  //  both parameters should be arrays
  function schemaMap(info, array) {
    const title = new Set(); // title set will be used to filter the results and make sure we don't get duplicate info
    info.map((info) => {
      return info?.map((entry) => {
        if (entry.uid === 'tags_group') {
          entry.schema.map((group) => {
            // this is building out the object that will become each tag section with title and options to be selected
            const obj = { title: group.display_name, options: [] };

            group.enum?.choices?.map((choice) => {
              if (choice.key) {
                return obj.options.push(choice.key);
              }
              return obj.options.push(choice.value);
            });
            // We are checking the set here to see if the entry is a duplicate.
            if (!title.has(obj.title)) {
              title.add(obj.title);
              return array.push(obj);
            }
            return;
          });
        }
        return;
      });
    });
  }

  if (type === 'mobile') {
    return (
      <MobileFilter
        ct={ct}
        filter={filter}
        more={more}
        // setMore={setMore}
        filters={filters}
        {...props}
      />
    );
  }
  if (type === 'entity') {
    return (
      <EntityFilter
        filter={filter}
        filters={filters}
        more={more}
        setMore={setMore}
        {...props}
      />
    );
  }
  return (
    <DeskFilter
      filter={filter}
      more={more}
      setMore={setMore}
      filters={filters}
      {...props}
    />
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SkipNavLink } from '@chakra-ui/skip-nav';
import { Provider } from 'react-redux';
// import { useSelector, useDispatch } from 'react-redux';
// import { actions } from './store/reducer';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SkipNavLink
        color={'white'}
        position={'absolute'}
        style={{
          padding: '.5rem 1rem',
          border: 'solid white 2px',
          borderRadius: '20px',
          margin: '.5rem',
          color: 'black',
        }}
      >
        Skip to Content
      </SkipNavLink>
      <App />
    </BrowserRouter>
  </Provider>,
);

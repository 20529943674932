import React from 'react';
import { Box, Flex, Link, Divider, useTheme } from '@chakra-ui/react';
import MenuPromo from '../../menuPromo';
export default function SubMenu(props) {
  const { smd, setOpen, defOpen, viewBack, setSMV, setMenuGroup } = props;
  const theme = useTheme();
  return (
    <Box
      borderRadius={'none'}
      w={'100%'}
      position={'absolute'}
      top={'100%'}
      bg={theme.colors.Grey5}
      zIndex={10000}
      color={theme.colors.Black}
    >
      <Box
        color={'DarkGreen'}
        my={'20px'}
        px={'16px'}
        onClick={() => {
          setOpen(!defOpen);
        }}
      >
        {smd.title_link?.href ? (
          <Box
            as={Link}
            href={smd.title_link?.href}
            fontWeight={'700'}
            fontSize={'1.25rem'}
            lineHeight={'1.7rem'}
          >
            {smd.title_link?.title || smd.title}
          </Box>
        ) : (
          <Box
            fontWeight={'700'}
            fontSize={'1.25rem'}
            lineHeight={'1.7rem'}
            px={'16px'}
          >
            {smd.title}
          </Box>
        )}
      </Box>
      <Divider />
      {smd.link_columns?.map((column, i) => {
        return (
          <Box key={i} px={'16px'}>
            <Box>
              {column.link_section?.map((section, i) => {
                return (
                  <Box key={i}>
                    <Box key={i} my={'30px'}>
                      <Flex direction={'column'} gap={'1rem'}>
                        {section.section_title_link?.href ? (
                          <Link
                            to={section.section_title_link?.href}
                            fontWeight={'700'}
                            fontSize={'1.125rem'}
                            lineHeight={'1.531rem'}
                            onClick={() => {
                              setOpen(false);
                              viewBack(false);
                              setSMV(false);
                              setMenuGroup(true);
                            }}
                          >
                            {section.section_title_link?.title}
                          </Link>
                        ) : (
                          <Box
                            fontWeight={'700'}
                            fontSize={'1.125rem'}
                            lineHeight={'1.531rem'}
                          >
                            {section.section_title_link?.title ||
                              section.section_title ||
                              ''}
                          </Box>
                        )}

                        {section.link?.map((link, i) => {
                          return (
                            <Box
                              key={i}
                              fontWeight={'400'}
                              fontSize={'1rem'}
                              lineHeight={'1.363rem'}
                              onClick={() => {
                                setOpen(false);
                                viewBack(false);
                                setSMV(false);
                                setMenuGroup(true);
                              }}
                            >
                              <Link href={link.href}>{link.title}</Link>
                            </Box>
                          );
                        })}
                        {column.view_all_link?.href && (
                          <Flex gap={'1rem'} align={'center'}>
                            <Link
                              href={column.view_all_link.href}
                              fontFamily={'Lato'}
                              fontWeight={'400'}
                              // fontSize={'.75rem'}
                              // lineHeight={'.9rem'}
                            >
                              {column.view_all_link.title}
                            </Link>
                            <Box className="fa-solid fa-arrow-right-long" />
                          </Flex>
                        )}
                      </Flex>
                    </Box>

                    <Divider />
                  </Box>
                );
              })}
            </Box>

            {/* {column.view_all_link?.href && (
              <Link href={column.view_all_link.href}>
                {column.view_all_link.title}
              </Link>
            )} */}
          </Box>
        );
      })}
      <MenuPromo data={smd.menu_promo[0]} />
    </Box>
  );
}

import { Box, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../utils/api';
import SimpleCardBody from './SimpleCardBody';
import ComplexCardBody from './ComplexCardBody';
import RichTextEditorMB from '../rte/index';

// In ContentStack this is both the 'Card, Simple Info' which has a uid of information_card
// and the 'Card, Complex Info' cards which has the uid of card_complex_info

export default function InformationCards(props) {
  const { data, isComplex } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }
  return (
    <Box
      maxH="100%"
      position="relative"
      maxW={{ lg: '1200px', base: '100%' }}
      mx={{ base: '1rem', lg: 'auto' }}
      paddingTop={'16px'}
      paddingBottom={{ base: '25px', md: '45px', lg: '50px' }}
      px={{ base: 'none', md: '24px', lg: '32px' }}
    >
      {newData && (
        <Box
          display="flex"
          flexDir="column"
          justifyContent={{ md: 'flex-start', base: 'center' }}
          alignContent={{ md: 'flex-start', base: 'center' }}
        >
          {newData.section?.map((cardSection, i) => {
            return (
              <Box key={i}>
                <Box marginTop="55px" marginBottom="40px">
                  <Heading marginBottom="2px">
                    {cardSection?.section_title}
                  </Heading>
                  <RichTextEditorMB
                    data={cardSection?.section_subhead}
                    px={'initial'}
                  ></RichTextEditorMB>
                </Box>
                <Box
                  display="flex"
                  flexDir="row"
                  flexWrap="wrap"
                  columnGap={{ lg: '20px', md: '45px' }}
                  rowGap={{ lg: '50px', md: '45px', base: '25px' }}
                  justifyContent={{ lg: 'flex-start', base: 'center' }}
                >
                  {cardSection?.section_card?.map((group, i) => {
                    return (
                      <Box key={i}>
                        {isComplex ? (
                          <ComplexCardBody group={group}></ComplexCardBody>
                        ) : (
                          <SimpleCardBody group={group}></SimpleCardBody>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

import React from 'react';
import { Flex, Hide, Link, Button } from '@chakra-ui/react';

export default function MainButtonGroup(props) {
  const { data, store, hideStore } = props;

  return (
    <Flex
      align={'center'}
      // border={'teal 2px solid'}
      flex={1}
      justifyContent={!store ? 'end' : 'center'}
      pr={!store ? { base: '16px', lg: 'initial' } : ''}
      gap={'1rem'}
      color="ContrastGreen"
    >
      <Flex
        // border={'pink 2px solid'}
        align={'center'}
        color={'ContrastGreen'}
        fontSize={'1rem'}
        fontWeight={'600'}
      >
        {/* Store Link */}

        {!hideStore && (
          <Hide below="md">
            <Link href={data?.store_link?.href}>{data?.store_link?.title}</Link>
          </Hide>
        )}

        {store && (
          <Hide above="sm">
            <Link href={data?.store_link?.href}>{data?.store_link?.title}</Link>
          </Hide>
        )}
      </Flex>

      {/* Log In Link */}
      <Button
        as={Link}
        size={'md'}
        variant={'secondary'}
        href={data?.login?.href}
        border={'1.5px solid'}
        borderRadius="40px"
        color={'ContrastGreen'}
        bgColor={'inherit'}
      >
        {data?.login?.title}
      </Button>
    </Flex>
  );
}

import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import BlockContainer from '../../container';
import InfoCard from '../infoCard';

export default function ThreeColumns(props) {
  const { data } = props;
  return (
    <Flex
      id="three-col-container"
      direction={{ base: 'column', lg: 'row' }}
      mx={'auto'}
      w={'100%'}
      h={'100%'}
      align={'center'}
      // borderBottom="1px black solid"
      // border={'lime solid 2px'}
      gap={{ lg: '57px' }}
    >
      {/* // section one */}
      <Flex
        // justify={'center'}
        direction={'column'}
        mx={{ base: 'auto' }}
        mr={{ lg: 0 }}
        mb={{ base: '20px', lg: '0' }}
        flex={1}
        // border={'pink solid 2px'}
        alignSelf={'stretch'}
        overflowY={'hidden'}
        css={{ '&::-webkit-scrollbar': { display: 'none' } }}
        display={{ base: 'flex', lg: 'none' }}
        // borderRadius={'30px'}
      >
        <Flex h={'100%'} direction={'column'}>
          {data[0]?.section?.components?.map((comp, i) => {
            return (
              <Flex
                key={i}
                // border={'solid teal 2px'}
                alignSelf={'stretch'}
                w={'100%'}
                // px={'12px'}
              >
                {comp.title_line ? (
                  <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                    {comp.title_line.title}
                  </Heading>
                ) : comp.info_card ? (
                  <InfoCard key={i} data={comp.info_card} />
                ) : (
                  <BlockContainer key={i} data={[comp]} px="initial" />
                )}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      <Flex
        flex={2}
        // w={{ base: '100%', lg: '66%' }}

        direction={{ base: 'column', md: 'row' }}
        // minH={{ sm: '314', md: '364px', lg: '314px' }}
        // border={'orange solid 2px'}
        alignSelf={'stretch'}
        gap={{ base: '20px', md: '10%', lg: '57px' }}
      >
        {/* // section two & three */}

        {data.map((section, index) => {
          return (
            <Flex
              key={index}
              mx={'auto'}
              display={index === 0 ? { base: 'none', lg: 'flex' } : 'flex'}
              // px={'8px'}
              // width={{ base: '100%', md: '50%' }}
              // border={'pink solid 2px'}
              align={'center'}
              overflowY={'hidden'}
              css={{ '&::-webkit-scrollbar': { display: 'none' } }}
              // borderRadius={'30px'}
              direction={'column'}
            >
              {section.section?.components?.map((comp, i) => {
                return (
                  <Flex
                    key={i}
                    // px={'12px'}
                    w="100%"
                    // border={'solid teal 2px'}
                  >
                    {comp.title_line ? (
                      <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                        {comp.title_line.title}
                      </Heading>
                    ) : comp.info_card ? (
                      <InfoCard key={i} data={comp.info_card} />
                    ) : (
                      <BlockContainer key={i} data={[comp]} px="initial" />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}

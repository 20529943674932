import React, { useRef } from 'react';
import { Text, Box, Button, useTheme } from '@chakra-ui/react';
import { ReactComponent as XIcon } from 'components/icons/x/x.svg';
import RichTextEditorMB from 'components/modularBlocks/rte';

const PopOut = ({ i, j, expanded, setExpanded, member, mt }) => {
  const dividerRef = useRef(null);

  const theme = useTheme();

  const handleExpansionClick = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = null;
    setExpanded(newExpanded);
  };

  return (
    <>
      {member?.headshot[0]?.bio ? (
        <Box
          display={expanded[i] === j ? 'block' : 'none'}
          gridColumn={'1 / -1'}
          w={{ md: '100%', base: '220px' }}
          mt={mt}
        >
          <Box
            display="flex"
            flexDir={{ md: 'row', base: 'column-reverse' }}
            backgroundColor={theme.colors.Grey5}
            padding="24px"
          >
            <Box
              display="flex"
              flexDirection={{ lg: 'row', base: 'column' }}
              gap="24px"
              ref={dividerRef}
            >
              <Box
                backgroundColor={theme.colors.Grey5}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                textAlign="start"
                gap="8px"
                minW={{ lg: '20%', md: '30%', base: '100%' }}
                maxW={{ lg: '20%', md: '30%', base: '100%' }}
              >
                <Text fontWeight="700" fontSize="1.25rem" lineHeight="1.5rem">
                  {member?.headshot[0]?.title}
                </Text>
                <Text
                  fontSize=".875rem"
                  fontWeight="700"
                  lineHeight="1.3125rem"
                >
                  {member?.headshot[0]?.staff_title}
                </Text>
                <Text
                  fontSize=".875rem"
                  fontWeight="400"
                  lineHeight="1.3125rem"
                >
                  {member?.headshot[0]?.staff_info}
                </Text>
              </Box>
              <Box
                textAlign="start"
                paddingRight={{ lg: '30px', base: 'none' }}
                borderTop={{
                  base: `1px solid ${theme.colors.Grey2}`,
                  lg: 'none',
                }}
                borderLeft={{
                  base: 'none',
                  lg: `1px solid ${theme.colors.Grey2}`,
                }}
              >
                <RichTextEditorMB
                  paddingLeft={{ base: '0', lg: '24px' }}
                  paddingTop={{ base: '24px', lg: '0' }}
                  fontSize={{ md: '1rem', base: '.875rem' }}
                  fontWeight="400"
                  letterSpacing="10%"
                  data={member?.headshot[0]?.bio}
                ></RichTextEditorMB>
              </Box>
            </Box>
            <Button
              display="flex"
              justifyContent="flex-end"
              alignItems="start"
              variant="unstyled"
              onClick={() => {
                handleExpansionClick(i);
              }}
            >
              <XIcon fill={theme.colors.GreenLink} width="16px" height="16px" />
            </Button>
          </Box>
        </Box>
      ) : (
        // Start here - if there is no bio what should the card arrangement be?
        <Box
          display={expanded[i] === j ? 'block' : 'none'}
          gridColumn={'1 / -1'}
          mt={mt}
        >
          <Box
            backgroundColor={theme.colors.Grey5}
            borderRadius="27px"
            padding="16px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            textAlign="center"
            gap="8px"
          >
            <Button
              display="flex"
              justifyContent="flex-end"
              alignItems="start"
              variant="unstyled"
              onClick={() => {
                handleExpansionClick(i);
              }}
              size={1}
            >
              <XIcon fill={theme.colors.GreenLink} width="16px" height="16px" />
            </Button>
            <Text fontWeight="700" fontSize="1.25rem" lineHeight="1.5rem">
              {member?.headshot[0]?.title}
            </Text>
            <Text fontSize=".875rem" fontWeight="700" lineHeight="1.3125rem">
              {member?.headshot[0]?.staff_title}
            </Text>
            <Text fontSize=".75rem" fontWeight="400" letterSpacing="10%">
              {member?.headshot[0]?.staff_info}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PopOut;

import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export default function SectionBanner(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data._content_type_uid,
      data.uid,
      l,
      'theme',
    );

    setNewData(info?.entry);
  }
  return (
    <Box
      as={Flex}
      flexDirection={{ md: 'row', sm: 'column-reverse' }}
      justifyContent={{ md: 'initial', sm: 'center' }}
      alignContent={{ md: 'initial', sm: 'center' }}
      maxH={{ lg: '250px', sm: 'initial' }}
      backgroundColor={newData?.theme[0]?.colors?.background_color}
    >
      <Box
        as={Flex}
        flexDirection="column"
        w={{ lg: '60%', md: '55%', sm: '95%' }}
        pl={{ md: '5%', sm: '5%' }}
        py={{ md: '0', sm: '4%' }}
        justifyContent="center"
        gap="20px"
      >
        <Heading
          as="h1"
          color={newData?.theme[0]?.colors?.text_color}
          fontStyle="normal"
          fontWeight="700"
          fontSize={{ lg: '60px', md: '40px', sm: '32px' }}
          lineHeight={{ lg: '72px', md: '48px', sm: '38px' }}
          // letterSpacing="-1px"
        >
          {newData?.section_title}
        </Heading>
        <Box
          h="3px"
          w={{ md: '100px', sm: '92px' }}
          backgroundColor="PrimaryGreen"
        ></Box>
        <Text
          color={newData?.theme[0]?.colors?.text_color}
          fontWeight="400"
          fontSize={{ lg: '20px', sm: '16px' }}
          lineHeight={{ lg: '27px', md: '24px', sm: '22px' }}
        >
          {newData?.description}
        </Text>
      </Box>
      <Box as={Flex} w={{ lg: '40%', md: '45%', sm: '100%' }}>
        <Image
          src={newData?.image?.url}
          alt={newData?.image_alt_text}
          objectFit="cover"
          w="100%"
          maxH="100%"
          clipPath={{
            lg: 'polygon(0 0, 100% 0, 100% 100%, 15% 100%)',
            md: 'polygon(0 0, 100% 0, 100% 100%, 15% 100%)',
            sm: '0',
          }}
        />
      </Box>
    </Box>
  );
}

import React from 'react';
import { Box, Button, Flex, Link, Heading, Hide } from '@chakra-ui/react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import UpRightFromSquare from '../../../icons/upRightFromSq';

export default function SectionHeader(props) {
  const { data, newTab, contained } = props;

  return (
    <Box
      py={'16px'}
      px={contained ? '0' : { lg: '60px' }}
      maxW={'1264px'}
      mx={'auto'}
      // border={'orange dashed 2px'}
    >
      <Flex
        // border={'brown dashed 2px'}
        w={{ base: '100%-32px', lg: '100%' }}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        justify={{ md: 'space-between' }}
        align={{ md: 'center' }}
        mx={{ base: '16px', lg: 'auto' }}
      >
        <Flex
          // border={'orange dashed 2px'}
          flexDirection="row"
          justifyContent="space-between"
          w={'100%'}
        >
          <Heading as="h2" alignSelf="center" w={'100%'}>
            {data?.header_title?.title || data?.title}
          </Heading>
          <Hide below="md">
            {/* <ButtonMB buttonObj={data?.header_button || data? /> */}
            {data?.button_link?.title && (
              <>
                {data?.external_link ? (
                  <Button
                    size={data?.button_size?.toLowerCase()}
                    variant={data?.button_type?.toLowerCase()}
                    as={Link}
                    href={data?.button_link?.href}
                    target={
                      data?.new_window ? '_blank' : newTab ? newTab : '_self'
                    }
                    gap="10px"
                  >
                    {data?.button_link?.title}
                    {/* <FontAwesomeIcon icon={solid('up-right-from-square')} /> */}
                    <UpRightFromSquare />
                  </Button>
                ) : (
                  <Button
                    size={data?.button_size?.toLowerCase()}
                    variant={data?.button_type?.toLowerCase()}
                    as={Link}
                    href={data?.button_link?.href}
                    target={
                      data?.new_window ? '_blank' : newTab ? newTab : '_self'
                    }
                  >
                    {data?.button_link?.title}
                  </Button>
                )}
              </>
            )}
          </Hide>
        </Flex>
      </Flex>
    </Box>
  );
}

import React, { useState, useEffect } from 'react';
import { WithSearch } from '@elastic/react-search-ui';
// import {
//   Menu,
//   MenuButton,
//   MenuList,
//   MenuItem,
//   Button,
//   useTheme,
// } from '@chakra-ui/react';
// import ChevronDownIcon from 'components/icons/chevronDown';
// import FaIcon from 'components/icons/faIcon';

const defaultSortFilters = [
  {
    label: 'Most Recent',
    index: 'general_desc',
  },
  {
    label: 'Most Relevant',
    index: 'general',
  },
  {
    label: 'Alphabetically',
    index: 'general_alphabetical',
  },
];

/**
 * @typedef {{
 *  label: string,
 *  index: string
 * }} SortFilter
 * @typedef {{
 *  sort: string,
 *  setSort: (string) => void,
 *  sortFilters: SortFilter[]
 * }} SortProps
 * @param {SortProps} props
 * @return {JSX.Element}
 */
export default function Sort({
  sort,
  setSort,
  sortFilters = defaultSortFilters,
}) {
  const [currentFilter, setCurrentFilter] = useState(null);
  // const theme = useTheme();
  useEffect(() => {
    if (currentFilter === null && Array.isArray(sortFilters)) {
      setCurrentFilter(sortFilters[0].label);
    }
  }, [sortFilters]);
  return (
    <WithSearch
      mapContextToProps={({ addFilter, setFilter, filters }) => ({
        setFilter,
        filters,
        addFilter,
      })}
    >
      {({ filters, setFilter, addFilter }) => {
        filters.forEach((f, index) => {
          f.title === 'sort' &&
            (() => {
              console.warn('POTENTIALLY SETTING LABEL TO SORT', f.values[0], f);
              setSort(f.values[0]);
            })();
        });
        // return (
        //   <Menu>
        //     <MenuButton
        //       as={Button}
        //       rightIcon={<ChevronDownIcon fasize={'fa-xs'} m={'4px'} />}
        //       borderRadius={'50px'}
        //       bgColor={'white'}
        //       border={`solid 1px ${theme.colors.Grey3}`}
        //       _hover={{ bg: 'DarkGreen', color: 'white' }}
        //       _active={{ bg: 'DarkGreen', color: 'white' }}
        //       fontSize={'1rem'}
        //       fontWeight={'400'}
        //       fontFamily={'Lato'}
        //     >
        //       {currentFilter}
        //     </MenuButton>
        //     <MenuList borderRadius={'20px'} py={0} overflow={'hidden'}>
        //       {sortFilters?.map(({ label, index }, i) => {
        //         return (
        //           <MenuItem
        //             key={i}
        //             _focus={{ bg: theme.colors.Grey3 }}
        //             value={index}
        //             onClick={(e) => {
        //               setCurrentFilter(label);
        //               sort
        //                 ? setFilter('sort', e.target.value)
        //                 : addFilter('sort', e.target.value);
        //               return setSort(e.target.value);
        //             }}
        //             justifyContent={'space-between'}
        //           >
        //             {label}
        //             {label === currentFilter && <FaIcon faIcon={'fa-check'} />}
        //           </MenuItem>
        //         );
        //       })}
        //     </MenuList>
        //   </Menu>
        // );
      }}
    </WithSearch>
  );
}

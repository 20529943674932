import { Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import LatestResources from './resourcesFeaturedBanner';
import MiniCarousel from './carousel';

export default function ResourcesHero(props) {
  const { refData } = props;
  const { l } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const info = await api.getSpecificEntryWithRef(
      refData?._content_type_uid,
      refData?.uid,
      l,
      'carousel.item_one.reference,carousel.items.reference',
    );
    setData(info?.entry);
  }

  return (
    <Flex
      bgColor={data?.dark_background ? 'Black' : '#f4f4f4'}
      color={data?.dark_background ? 'White' : 'Black'}
      pb={'40px'}
    >
      <Flex
        direction={'column'}
        maxW={'1296px'}
        mx={'auto'}
        px={'16px'}
        w={'100%'}
      >
        <Heading
          as={'h2'}
          paddingTop="40px"
          paddingBottom="32px"
          lineHeight={'1.95rem'}
          fontWeight={'700'}
          fontSize={'1.625rem'}
        >
          {data?.headline?.toUpperCase()}
        </Heading>
        <Flex
          direction={{
            base: 'column',
            lg: 'row',
          }}
          // gap={{ lg: '30px' }}
        >
          {data?.carousel && (
            <MiniCarousel
              data={data?.carousel}
              variant={'resources'}
              background={data?.dark_background}
            />
          )}

          <LatestResources listTitle={data?.list_title} />
        </Flex>
      </Flex>
    </Flex>
  );
}

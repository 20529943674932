import { useState } from 'react';
import logger from 'utils/logger';

export default function usePrefilters() {
  const [prefilter, setFilter] = useState();
  function buildPrefilters(ct, parent, type) {
    logger.log('PREFILTER BUILDER: ', ct);
    if (ct === 'resources_overview') {
      setFilter(
        '["category:resource_entry","tags:internal_class=resource_article"]',
      );
    }
    if (ct === 'news_overview') {
      setFilter('["category:news_article","category:news_blurb"]');
    }
    if (!prefilter && parent && type) {
      setFilter(
        `"tags:${encodeURIComponent(parent)}=${encodeURIComponent(type)}"`,
      );
    }
  }

  return [prefilter, buildPrefilters];
}

import React from 'react';
import { Heading, Text, Box, Link } from '@chakra-ui/react';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import { Link as RouterLink } from 'react-router-dom';

export default function NoPage() {
  return (
    <main style={{ minHeight: '100vh' }} id="main">
      <SkipNavContent />
      {/* <Navigation /> */}
      <Box textAlign={'center'} my="20">
        <Heading as={'h1'}>Oops,</Heading>
        <Text>This page is coming soon!</Text>
        <Link as={RouterLink} to="/home">
          Return Home
        </Link>
      </Box>
    </main>
  );
}

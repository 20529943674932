import React from 'react';
import { Box, Image, Link, Text, useTheme } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const MainCard = ({ i, j, expanded, setExpanded, member, group }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        alignSelf="center"
        display="flex"
        flexDir="column"
        alignItems="center"
        textAlign="center"
        justifyContent="space-between"
        boxShadow={'2px 4px 8px 0 rgba(30, 30, 30, 0.25)'}
        borderTopWidth="8px"
        borderTopColor={group.color?.replace(' ', '')}
        borderRadius="0px 0px 24px 24px"
        paddingBottom="16px"
        paddingX="8px"
        minH="300px"
        w="215px"
      >
        <Image
          h="125px"
          w="125px"
          borderRadius="0px 0px 8px 8px"
          objectFit="cover"
          src={member?.headshot[0]?.staff_image?.url}
          alt={member?.headshot[0]?.staff_image_alt_text}
        />
        <Box
          paddingY="8px"
          borderBottom={`1px solid ${theme.colors.Grey3}`}
          width="100%"
        >
          <Text
            fontFamily="Lato"
            fontSize="1rem"
            fontWeight="700"
            color={theme.colors.BlackText}
            lineHeight="1.2rem"
          >
            {member?.headshot[0]?.title}
          </Text>
        </Box>
        <Text
          fontSize=".875rem"
          fontWeight="700"
          lineHeight="1.3125rem"
          color={theme.colors.Grey1}
          noOfLines={2}
        >
          {member?.headshot[0]?.staff_title}
        </Text>
        <Text
          fontSize=".75rem"
          color={theme.colors.BlackText}
          lineHeight="1.125rem"
          noOfLines={3}
        >
          {member?.headshot[0]?.staff_info}
        </Text>
        <Box display="flex" gap="4px">
          <Link
            fontFamily="open sans"
            fontWeight="700"
            fontSize=".75rem"
            color={theme.colors.GreenLink}
            textDecoration="underline"
            onClick={() => {
              const newExpanded = [...expanded];
              newExpanded[i] = newExpanded[i] === j ? null : j;
              setExpanded(newExpanded);
            }}
          >
            {expanded[i] === j ? 'See Less' : 'See More'}
          </Link>
          <FontAwesomeIcon
            color={theme.colors.GreenLink}
            fontSize="1rem"
            icon={
              expanded[i] === j
                ? solid('circle-chevron-left')
                : solid('circle-chevron-down')
            }
          />
        </Box>
      </Box>

      {expanded[i] === j && (
        <Box
          marginLeft="70px"
          width="30%"
          height="44px"
          backgroundColor={theme.colors.Grey5}
          clipPath="polygon(50% 10%, 10% 100%, 90% 100%)"
        ></Box>
      )}
    </Box>
  );
};

export default MainCard;

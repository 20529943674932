import React from 'react';
import { Flex, Link } from '@chakra-ui/react';

// import {
// FaFacebookSquare,
// FaLinkedin,
// FaTwitter,
//   FaPrint,
// } from 'react-icons/fa';
import LinkedinIcon from '../../icons/socials/linkedin';
import TwitterIcon from '../../icons/socials/twitter';
import FaIcon from '../../icons/faIcon';

export default function IconBar() {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: '5px', lg: '17px' }}
    >
      <Flex
        gap={{ base: '5px', lg: '17px' }}
        align={{ base: 'start', lg: 'center' }}
        mt={{ md: '1rem', lg: 'initial' }}
      >
        <Link
          href={
            'https://www.linkedin.com/shareArticle?mini=true&url=@linkedURL&title=@Model.Title&summary=@Model.Summary'
          }
          target={'_blank'}
          title={'LinkedIn'}
          isExternal
        >
          <Flex
            color={'white'}
            bg={'DarkGreen'}
            borderRadius={'4000px'}
            h={{ base: '25px', md: '30px', lg: '52px' }}
            w={{ base: '25px', md: '30px', lg: '52px' }}
            align={'center'}
            justify={'center'}
          >
            {/* <Icon
              as={FaLinkedin}
              boxSize={{ base: '.75rem', md: '1rem', lg: '1.5rem' }}
            /> */}
            <LinkedinIcon
              fasize={'2xl'}
              transform={{
                base: 'scale(.75)',
                md: 'scale(1)',
                lg: 'scale(1.5)',
              }}
            />
          </Flex>
        </Link>
        <Link
          href={'https://twitter.com/intent/tweet?text=@twitterText'}
          target={'_blank'}
          title={'Twitter'}
          isExternal
        >
          <Flex
            color={'white'}
            bg={'DarkGreen'}
            borderRadius={'4000px'}
            h={{ base: '25px', md: '30px', lg: '52px' }}
            w={{ base: '25px', md: '30px', lg: '52px' }}
            align={'center'}
            justify={'center'}
          >
            {/* <Icon
              as={FaTwitter}
              boxSize={{ base: '.75rem', md: '1rem', lg: '1.5rem' }}
            /> */}
            <TwitterIcon
              fasize={'2xl'}
              transform={{
                base: 'scale(.75)',
                md: 'scale(1)',
                lg: 'scale(1.5)',
              }}
            />
          </Flex>
        </Link>
      </Flex>
      <Flex
        gap={{ base: '5px', lg: '17px' }}
        align={{ base: 'start', lg: 'center' }}
        mt={{ md: '1rem', lg: 'initial' }}
      >
        <Link
          href={'https://www.facebook.com/sharer/sharer.php'}
          target={'_blank'}
          title={'Facebook'}
          isExternal
        >
          <Flex
            color={'white'}
            bg={'DarkGreen'}
            borderRadius={'4000px'}
            h={{ base: '25px', md: '30px', lg: '52px' }}
            w={{ base: '25px', md: '30px', lg: '52px' }}
            align={'center'}
            justify={'center'}
          >
            {/* <Icon
              as={FaFacebookSquare}
              boxSize={{ base: '.75rem', md: '1rem', lg: '1.5rem' }}
            /> */}
            <FaIcon
              faIcon={'fa-square-facebook'}
              variant={'fa-brands'}
              fasize={'2xl'}
              transform={{
                base: 'scale(.75)',
                md: 'scale(1)',
                lg: 'scale(1.5)',
              }}
            />
          </Flex>
        </Link>
        <Flex
          as={'button'}
          onClick={() => window.print()}
          color={'white'}
          bg={'DarkGreen'}
          borderRadius={'4000px'}
          h={{ base: '25px', md: '30px', lg: '52px' }}
          w={{ base: '25px', md: '30px', lg: '52px' }}
          align={'center'}
          justify={'center'}
          aria-label="Print"
        >
          {/* <Icon
            as={FaPrint}
            boxSize={{ base: '.75rem', md: '1rem', lg: '1.5rem' }}
          /> */}
          <FaIcon
            faIcon={'fa-print'}
            fasize={'2xl'}
            aria-label="Print"
            transform={{
              base: 'scale(.75)',
              md: 'scale(1)',
              lg: 'scale(1.5)',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

import React, { useState } from 'react';
import {
  Box,
  Link,
  Flex,
  useTheme,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import ChevronDownIcon from '../../../icons/chevronDown';

export default function ConferenceMobileMenu(props) {
  const { data } = props;
  const navTitle = data?.nav_links[0].link?.title.toUpperCase();
  const navLinks = data?.nav_links;
  const menuItems = data?.menu_items;

  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const generalInfoIndex = navLinks?.findIndex(
    (navlink) => navlink?.link?.title.trim().toLowerCase() === 'general info',
  );

  const SubnavBar = () => {
    const [selectedEntry, setSelectedEntry] = useState(0);

    const handleEntryClick = (index) => {
      setSelectedEntry(index);
      index != generalInfoIndex ? handleCloseModal() : handleOpenModal();
    };

    const handleGenInfoClick = (index) => {
      setSelectedEntry(index);
      setShowSubMenu(!showSubMenu);
      handleCloseModal();
    };

    return (
      <Flex
        direction={'column'}
        alignItems={'center'}
        bg={'white'}
        width={'100vw'}
      >
        {navLinks?.map((navlink, index) => (
          <Box
            key={index}
            px={4}
            py={2}
            bg={selectedEntry === index ? theme.colors.Grey1 : 'transparent'}
            borderBottom={
              selectedEntry === index
                ? `4px solid ${theme.colors.PrimaryGreen}`
                : 'none'
            }
            width={'100vw'}
            onClick={() => handleEntryClick(index)}
            mb={2}
          >
            {generalInfoIndex === index ? (
              <>
                <Box
                  fontSize={'20px'}
                  fontWeight={'700'}
                  lineHeight={'normal'}
                  color={'Grey1'}
                  onClick={() => handleGenInfoClick(index)}
                >
                  General Information
                  <ChevronDownIcon px={'9px'} />
                </Box>
                {showSubMenu && (
                  <VStack
                    divider={<StackDivider borderColor="white" />}
                    align={'stretch'}
                    spacing={0}
                  >
                    <Box height={'10px'}></Box>
                    {menuItems.map((menuItem, idx) => (
                      <Link
                        fontSize={'20px'}
                        fontWeight={'700'}
                        color={'white'}
                        bg={'PrimaryGreen'}
                        pl={'50px'}
                        py={'15px'}
                        key={idx}
                        href={menuItem.link.href}
                      >
                        {menuItem.link.title}
                      </Link>
                    ))}
                  </VStack>
                )}
              </>
            ) : (
              <Link
                href={navlink?.link?.href}
                fontSize={'20px'}
                fontWeight={'700'}
                lineHeight={'normal'}
                color={selectedEntry === index ? 'white' : theme.colors.Grey1}
                cursor={'default'}
              >
                {navlink?.link?.title}
              </Link>
            )}
          </Box>
        ))}
      </Flex>
    );
  };

  return (
    <>
      <Flex
        as={Button}
        rightIcon={<ChevronDownIcon fasize={'fa-xs'} m={'4px'} />}
        width={'100vw'}
        bg={'white'}
        justifyContent={'space-between'}
        onClick={handleOpenModal}
      >
        {navTitle}
      </Flex>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size={'full'}>
        <ModalOverlay bgColor={'white'} />
        <ModalContent
          bg={'transparent'}
          boxShadow={'none'}
          width={'100vw'}
          height={'100vh'}
          padding={'0'}
        >
          <SubnavBar />
        </ModalContent>
      </Modal>
    </>
  );
}

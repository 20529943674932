import React from 'react';
import { Button, Link, Flex, Heading } from '@chakra-ui/react';
import RichTextEditorMB from '../../../modularBlocks/rte/index';

// import ButtonMB from '../../../modularBlocks/button';

export default function BackgroundBanner(props) {
  const { data, newTab } = props;
  return (
    <Flex
      w={'100%'}
      minH={'450px'}
      bgImage={data?.image?.url}
      bgSize={'cover'}
      bgPos={'center'}
      direction={'column'}
      justify={'center'}
      align={'center'}
    >
      <Flex
        direction={'column'}
        w={'100%'}
        minH={'inherit'}
        backgroundColor={
          data?.theme[0]?.background_color
            ? data?.theme[0]?.background_color.includes('#')
              ? `${data?.theme[0]?.background_color}80`
              : `${data?.theme[0]?.background_color?.replace(' ', '')}Opaque`
            : null
        }
        color={data?.theme[0]?.text_color || 'Black'}
        justify={'center'}
        align={'center'}
      >
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          gap={'2.5rem'}
        >
          <Flex
            direction={'column'}
            gap={'1rem'}
            justify={'center'}
            align={'center'}
            px={'16px'}
          >
            <Heading
              as="h1"
              fontWeight={'800'}
              fontSize={'2.5rem'}
              lineHeight={'2.813rem'}
              textAlign={'center'}
            >
              {data?.headline}
            </Heading>
            <RichTextEditorMB
              fontWeight={'500'}
              fontSize={'1.25rem'}
              lineHeight={'1.5rem'}
              data={data?.rich_text_editor}
            ></RichTextEditorMB>
          </Flex>

          {/* {data?.button && (
            <Box>
              {data?.theme[0]?.background_color === 'Primary Green' ? (
                <ButtonMB buttonObj={data?.button} bgColor={'white'} />
              ) : (
                <ButtonMB buttonObj={data?.button} />
              )}
            </Box>
          )} */}
          {data?.cta_button?.button_link?.title && (
            <>
              {data?.cta_button?.external_link ? (
                <Button
                  size={data?.cta_button?.button_size?.toLowerCase()}
                  variant={data?.cta_button?.button_type?.toLowerCase()}
                  as={Link}
                  href={data?.cta_button?.button_link?.href}
                  target={
                    data?.cta_button?.new_window
                      ? '_blank'
                      : newTab
                      ? newTab
                      : '_self'
                  }
                >
                  {data?.cta_button?.button_link?.title}
                </Button>
              ) : (
                <Button
                  size={data?.cta_button?.button_size?.toLowerCase()}
                  variant={data?.cta_button?.button_type?.toLowerCase()}
                  as={Link}
                  href={data?.cta_button?.button_link.href}
                  target={
                    data?.cta_button?.new_window
                      ? '_blank'
                      : newTab
                      ? newTab
                      : '_self'
                  }
                >
                  {data?.cta_button?.button_link?.title}
                </Button>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

import React, { useState } from 'react';
import {
  Flex,
  Text,
  Button,
  Box,
  Input,
  IconButton,
  useTheme,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withSearch } from '@elastic/react-search-ui';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import { FaCalendarAlt } from 'react-icons/fa';
import { adjustToLocalTimezone } from '../../../utils/adjustToLocalTimezone';
import './CustomDatePicker.css';
import logger from 'utils/logger';

// register the 'en-GB' locale for date-fns
registerLocale('en-GB', enGB);

// the main custom date picker component
const CustomDatePicker = ({
  addFilter,
  removeFilter,
  filters,
  setSearchTerm,
}) => {
  const theme = useTheme();
  // state hooks for start and end dates
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  logger.log('filters in custom date picker', filters);
  logger.log('startDate', startDate);
  logger.log('endDate', endDate);

  // function to handle the apply action
  const handleApply = () => {
    logger.log('Raw Start Date:', startDate);
    logger.log('Raw End Date:', endDate);
    if (startDate || endDate) {
      // adjust dates to ISO format
      const startDateISO = startDate
        ? adjustToLocalTimezone(new Date(startDate)).setHours(0, 0, 0, 0)
        : null;
      const endDateValue = endDate || new Date();
      const endDateISO = adjustToLocalTimezone(new Date(endDateValue));
      endDateISO.setHours(23, 59, 59, 999);

      logger.log(
        'Start Date:',
        startDateISO ? new Date(startDateISO).toISOString() : null,
      );
      logger.log('End Date ISO:', new Date(endDateISO).toISOString());
      // remove existing date filters
      removeFilter('Start');
      removeFilter('End');

      // add new date filters
      if (startDateISO) {
        addFilter('Start', new Date(startDateISO).toISOString());
      }
      addFilter('End', new Date(endDateISO).toISOString());
    }
  };

  // log filters whenever they are updated
  // useEffect(() => {
  //   logger.log('Filters updated:', filters);
  // }, [filters]);

  // function to clear all filters
  const clearAllFilters = () => {
    removeFilter('Start');
    removeFilter('End');
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Flex
      direction="column"
      gap="16px"
      maxW="100%"
      boxSizing="border-box"
      className="date-range-container"
    >
      <Text
        as="label"
        fontWeight="extrabold"
        fontSize="1.2rem"
        lineHeight="1.5rem"
        mb={1}
      >
        DATE RANGE
      </Text>

      <Flex direction="column" mb={2}>
        <Text
          as="label"
          fontWeight="600"
          fontSize="1rem"
          lineHeight="1.362rem"
          mb={1}
        >
          Start Date
        </Text>
        <Box
          position="relative"
          borderBottom={`2px solid ${theme.colors.DarkGreen}`}
          className="input-container"
        >
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            locale="en-GB"
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            placeholderText="dd/mm/yyyy"
            customInput={
              <Input
                border="none"
                placeholder="dd/mm/yyyy"
                w="100%"
                className="custom-input"
              />
            }
            calendarClassName="custom-datepicker"
            popperContainer={({ children }) => (
              <div style={{ opacity: 1 }}>{children}</div>
            )}
          />
          <IconButton
            position="absolute"
            right="0px"
            top="50%"
            transform="translateY(-50%)"
            aria-label="Open calendar"
            icon={<FaCalendarAlt />}
            onClick={() =>
              document
                .querySelector('.react-datepicker__input-container input')
                .focus()
            }
            variant="ghost"
            className="calendar-icon"
          />
        </Box>
      </Flex>

      <Flex direction="column" mb={2}>
        <Text
          as="label"
          fontWeight="600"
          fontSize="1rem"
          lineHeight="1.362rem"
          mb={1}
        >
          End Date
        </Text>
        <Box
          position="relative"
          borderBottom={`2px solid ${theme.colors.DarkGreen}`}
          className="input-container"
        >
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            locale="en-GB"
            dateFormat="dd/MM/yyyy"
            minDate={startDate}
            maxDate={new Date()}
            placeholderText="dd/mm/yyyy"
            customInput={
              <Input
                border="none"
                placeholder="dd/mm/yyyy"
                w="100%"
                b="none"
                className="custom-input"
              />
            }
            calendarClassName="custom-datepicker"
            popperContainer={({ children }) => (
              <div style={{ opacity: 1 }}>{children}</div>
            )}
          />
          <IconButton
            position="absolute"
            right="0px"
            top="50%"
            transform="translateY(-50%)"
            aria-label="Open calendar"
            icon={<FaCalendarAlt />}
            onClick={() =>
              document
                .querySelectorAll('.react-datepicker__input-container input')[1]
                .focus()
            }
            variant="ghost"
            className="calendar-icon"
          />
        </Box>
      </Flex>

      <Flex className="button-container">
        <Button
          onClick={() => handleApply()}
          isDisabled={
            !startDate || (endDate && new Date(endDate) < new Date(startDate))
          }
          backgroundColor={`${theme.colors.ContrastGreen}`}
          color="white"
          borderRadius="20px"
          px={6}
          p={0}
          fontSize="sm"
          className="cta-button"
        >
          Apply Dates
        </Button>
        <Button
          onClick={() => clearAllFilters()}
          isDisabled={!startDate && !endDate}
          variant="outline"
          borderColor={`${theme.colors.DarkGreen}`}
          color={`${theme.colors.DarkGreen}`}
          borderRadius="20px"
          px={6}
          fontSize="sm"
          className="clear-button"
        >
          Clear Dates
        </Button>
      </Flex>
    </Flex>
  );
};

export default withSearch(
  ({ addFilter, removeFilter, filters, setSearchTerm }) => ({
    addFilter,
    removeFilter,
    filters,
    setSearchTerm,
  }),
)(CustomDatePicker);

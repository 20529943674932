import {
  Flex,
  useBreakpointValue,
  Hide,
  Show,
  // Heading,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { HSlide, MobileHSlide } from './slides';
import { Carousel, CarouselSlide, useCarousel } from './components';

function Horizontal(props) {
  const { data } = props;
  const [index, setIndex] = React.useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slidesPerView = useBreakpointValue({
    base: 1,
  });
  const [ref, slider] = useCarousel({
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({
        base: 16,
        md: 24,
      }),
    },
    slideChanged: (slider) => {
      setIndex(slider.track.details.rel);
      return setCurrentSlide(slider.track.details.rel);
    },
  });
  return (
    <Flex
      direction={'column'}
      px={'30px'}
      bgColor={data?.bg_color?.replace(' ', '')}
    >
      <Hide below={'md'}>
        {/* Titles section */}
        <Flex justify={'center'} my={'60px'}>
          {data?.entries?.map((entry, i) => {
            return (
              <Flex
                key={i}
                onClick={() => {
                  slider.current?.moveToIdx(i);
                  return setIndex(i);
                }}
                flex={1}
                justify={'center'}
                fontSize={'1.5rem'}
                fontWeight={'700'}
                lineHeight={'1.8rem'}
                textAlign={'center'}
                // border={'lime solid 2px'}
                mx={'9px'}
              >
                <Text
                  // border={'solid lime 2px'}
                  noOfLines={2}
                  as={'h3'}
                  fontFamily={'Lato'}
                  fontSize={'1.25rem'}
                  fontWeight={'700'}
                  lineHeight={'1.5rem'}
                  // letterSpacing={'-.02em'}
                  w={'fit-content'}
                  color={
                    i === index ? data?.tab_color?.replace(' ', '') : '#797979'
                  }
                  borderBottom={i === index && 'solid 5px'}
                  borderBottomColor={
                    (i === index && entry.underline_color?.replace(' ', '')) ||
                    'ContrastGreen'
                  }
                  _hover={{ cursor: 'pointer' }}
                >
                  {entry.title}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {/* Cards Section */}
        <Carousel
          ref={ref}
          direction="row"
          // width={'full'}
          minH={{ base: '425px', lg: '475px' }}
        >
          {data?.entries?.map((entry, i) => {
            return (
              <CarouselSlide key={i}>
                <HSlide
                  data={entry}
                  slider={slider}
                  index={index}
                  setIndex={setIndex}
                  currentSlide={currentSlide}
                  slidesPerView={slidesPerView}
                  length={data?.entries.length}
                />
              </CarouselSlide>
            );
          })}
        </Carousel>
      </Hide>
      <Show below={'md'}>
        <Flex direction={'column'} gap={'1rem'}>
          {data?.entries?.map((card, i) => {
            return <MobileHSlide key={i} data={card} />;
          })}
        </Flex>
      </Show>
    </Flex>
  );
}

export default Horizontal;

import { Field } from 'formik';
import { Select } from '@chakra-ui/react';
import { ReactComponent as SortArrows } from 'components/icons/sortArrows/sortArrows.svg';
import states from './formData';
const StateList = (props) => {
  const { item, errorOrValidColor } = props;
  return (
    <Field
      as={Select}
      name={item.field_name}
      id={item.field_name}
      placeholder={item.placeholder_text || 'State'}
      variant="outline"
      icon={<SortArrows />}
      focusBorderColor={errorOrValidColor}
      borderColor={errorOrValidColor}
      isRequired={item.required}
    >
      {states.map((item, i) => (
        <option key={i} value={item}>
          {item}
        </option>
      ))}
    </Field>
  );
};

export default StateList;

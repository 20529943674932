import { Box, Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../utils/api';
import MainCard from './mainCard';
import PopOut from './popOut';

export default function MeetTheStaff(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    getData(l);
  }, [l]);

  useEffect(() => {
    if (newData) {
      setExpanded(newData.card_group.map(() => false));
    }
  }, [newData]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data?._content_type_uid,
      data?.uid,
      l,
      'card_group.headshot.headshot',
    );

    setNewData(info.entry);
  }

  return (
    <Box px={'2%'} my={10}>
      {newData && (
        <Box
          maxW={'1240px'}
          mx={{ base: '1rem', md: 'auto' }}
          display="flex"
          flexDirection="column"
          justifyContent={{ base: 'center', md: 'start' }}
          alignContent={{ base: 'center', md: 'start' }}
        >
          {newData.headline && (
            <Box textAlign={{ base: 'center', md: 'start' }}>
              <Heading display="inline" fontSize="1.5rem">
                {newData.headline}
              </Heading>
            </Box>
          )}
          <Box>
            {newData.card_group?.map((group, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  justifyContent={{ base: 'center', md: 'start' }}
                  alignContent={{ base: 'center', md: 'start' }}
                  flexWrap="wrap"
                  gap="40px"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={{ base: 'center', md: 'start' }}
                    alignItems={{ base: 'center', md: 'start' }}
                    textAlign={{ base: 'center', md: 'start' }}
                  >
                    {group.group_title && (
                      <Text
                        fontWeight="700"
                        fontSize="1.25rem"
                        pt="24px"
                        mb="-8px"
                      >
                        {group.group_title}
                      </Text>
                    )}

                    <Box
                      display="grid"
                      gridTemplateColumns={{
                        lg: 'repeat(5, 1fr)',
                        md: 'repeat(3,1fr)',
                        base: 'repeat(1, 1fr)',
                      }}
                      gridGap={{ md: '24px', base: '16px' }}
                      marginTop={{ md: '24px', base: '16px' }}
                      gridAutoFlow={'dense'}
                    >
                      {group.headshot?.map((member, j) => {
                        if (member?.headshot[0]?.bio) {
                          return [
                            <MainCard
                              key={i}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              group={group}
                            />,
                            <PopOut
                              key={i + 'pop'}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              mt={'-30px'}
                            />,
                          ];
                        }
                        return (
                          <Box key={j} w={'215px'}>
                            <MainCard
                              key={i}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                              group={group}
                            />
                            <PopOut
                              key={i + 'pop'}
                              i={i}
                              member={member}
                              j={j}
                              expanded={expanded}
                              setExpanded={setExpanded}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
}

/**
 * @typedef {{
 *  sort_filters: string[],
 *  sort_filter_indices: string[],
 * }} EntityListingFilterInfo
 * @typedef {{
 *  label: string,
 *  index: string
 * }} SortFilter
 *
 * @param {EntityListingFilterInfo} info
 * @return {SortFilter[]}
 */
export function associateFilterIndices(info) {
  return info?.sort_filters.map((label, key) => ({
    label,
    index: info.sort_filter_indices?.[key],
  }));
}

import React, { useState, useEffect } from 'react';
import { SearchBox, withSearch } from '@elastic/react-search-ui';
import { Flex, VisuallyHidden } from '@chakra-ui/react';

export default function CustomSearchBox() {
  const [searchValue, setSearchValue] = useState(() => {
    const savedValue = localStorage.getItem('searchValue');
    return savedValue !== null ? savedValue : '';
  });

  // Update localStorage whenever searchValue changes
  useEffect(() => {
    localStorage.setItem('searchValue', searchValue);
  }, [searchValue]);

  // This section adds a custom icon for the Search bar
  // This function is fired by an observer and renders the icon in the search bar
  const insertIcon = () => {
    const icon = document.createElement('div');
    const input = document.getElementById('searchbox-input');
    icon.setAttribute('id', 'icon');
    icon.classList.add('fa-solid', 'fa-magnifying-glass', 'search-icon');
    input.parentElement.prepend(icon);
    return observer.disconnect();
  };

  const observer = new MutationObserver(() => {
    if (
      document.getElementById('searchbox-input') &&
      !document.getElementById('icon')
    ) {
      insertIcon();
    }
    return;
  });

  // Handler function to clear the search input and search term
  const handleClearSearch = ({ setSearchTerm }) => {
    setSearchValue('');
    setSearchTerm('');
  };

  const ClearSearchBox = withSearch(
    ({ setSearchTerm, shouldClearFilters }) => ({
      setSearchTerm,
      shouldClearFilters,
    }),
  )(({ setSearchTerm, shouldClearFilters }) => {
    return (
      <div className="sui-search-box__close">
        {searchValue && ( // Only show the clear button when there's text input
          <button onClick={() => handleClearSearch({ setSearchTerm })}>
            <i className="fa-sharp fa-solid fa-xmark-large"></i>
          </button>
        )}
      </div>
    );
  });

  // Handler function to update the search value
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <Flex
      justify={{ base: 'center', md: 'end' }}
      className={'search-box-container'}
      width={'100%'}
      mb={{ base: '-25px', md: 'initial' }}
    >
      <label id="searchbox-label" htmlFor="Search-Box">
        <VisuallyHidden>{'search'}</VisuallyHidden>
      </label>

      <Flex width={'100%'} className="search-icon-container">
        <SearchBox
          id={'searchbox'}
          inputProps={{
            placeholder: 'Search',
            'aria-labelledby': 'searchbox-label',
            value: searchValue,
            onChange: handleSearchChange, // Update search value on change
          }}
        />
        {observer.observe(document.body, {
          childList: true,
          subtree: true,
          attributes: true,
        })}

        <ClearSearchBox />
      </Flex>
    </Flex>
  );
}

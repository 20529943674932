import React from 'react';
import logger from 'utils/logger';
import {
  Box,
  //   Container,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
// import ReferenceCarousel from '../carousels/refCarousel';
import Carousel from '../carousels/carousel';

export default function TabMB(props) {
  const { data, contained } = props;
  const tabTitles = [];

  data?.map((each) => {
    tabTitles.push(each.tab?.title);
  });
  return (
    <Box
      as="section"
      mx={'auto'}
      maxW={'1264px'}
      px={contained ? 'initial' : { base: '16px', md: '24px', lg: '60px' }}
      // border={'solid green 2px'}
    >
      <Stack spacing={16}>
        <Tabs
          size={'lg'}
          align="start"
          variant={'with-line'}
          // border={'gray dotted 2px'}
          isLazy
        >
          <TabList gap={{ base: '16px', md: 'initial' }} flexWrap={'wrap'}>
            {tabTitles.map((title, i) => (
              <Tab
                key={i}
                border={{ base: 'solid 1px', md: 'none' }}
                borderRadius={{ base: '10px', md: 'initial' }}
                fontWeight={'600'}
                fontSize={{ base: '1rem', md: '1.25rem' }}
                color={{ base: '#1E1E1E', md: ' #6D6E71' }}
                lineHeight={'27px'}
                _selected={{
                  color: { base: 'White', md: '#1E1E1E' },
                  backgroundColor: { base: 'DarkGreen', md: 'initial' },
                  borderBottom: { md: '6px solid  #6DB650' },
                }}
                mr={'5%'}
                px={{ base: '8px', md: '0' }}
                py={'8px'}
                flexWrap={'nowrap'}
              >
                {title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {data?.map((panel, i) => {
              return (
                <TabPanel p={'0'} key={i}>
                  <Box>
                    {panel.tab?.contents[0]?.carousel ? (
                      <Carousel data={panel.tab?.contents[0]?.carousel} />
                    ) : panel.tab?.contents[0]?.reference_carousel ? (
                      <Carousel
                        refData={panel.tab?.contents[0]?.reference_carousel}
                      />
                    ) : (
                      logger.error('Error: unsupported tab content type')
                    )}
                  </Box>
                </TabPanel>
              );
              //
            })}
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}

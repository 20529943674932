import { Box, Image, Link, Text } from '@chakra-ui/react';
import ComplexCardImgOverlay from './ComplexCardImgOverlay';
import ComplexCardFooter from './ComplexCardFooter';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ComplexCardBody(props) {
  const { group } = props;

  const iconSelect = {
    'Calendar Days Icon': <FontAwesomeIcon icon={solid('calendar-days')} />,
    'Map Pin Icon': <FontAwesomeIcon icon={solid('map-pin')} />,
  };
  return (
    <Box
      role={'group'}
      as={group.card_url && Link}
      href={group.card_url ? group.card_url : ''}
      target={group.new_window ? '_blank' : '_self'}
      _hover={
        group.card_url
          ? {
              textDecoration: 'none',
              border: '2px solid #CCCCCC',
              boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
              paddingX: '17px',
              paddingTop: '17px',
            }
          : {}
      }
      _focus={
        group.card_url
          ? {
              textDecoration: 'none',
              border: '2px solid #CCCCCC',
              boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
              paddingX: '23px',
              paddingTop: '23px',
            }
          : {}
      }
      display="flex"
      flexDirection="column"
      border="1px solid #CCCCCC"
      boxShadow="0px 24px 34px 0px rgba(0,0,0,0.03)"
      borderRadius="20px"
      h="470px"
      w="362px"
      paddingX="18px"
      paddingTop="18px"
      paddingBottom="20px"
      overflow="hidden"
      position="relative"
    >
      <Box
        position="relative"
        paddingBottom="18px"
        display="flex"
        justifyContent="center"
      >
        <Image
          loading="lazy"
          alt={group.image.alt_text}
          src={group.image.url}
          fit="cover"
          borderRadius="16px 16px 0px 0px"
          height="124px"
          width="321px"
        ></Image>
        <ComplexCardImgOverlay
          iconSelect={iconSelect}
          info={group}
        ></ComplexCardImgOverlay>
      </Box>
      <Box height="160px" overflow="hidden">
        <Text
          as={'h4'}
          fontSize="18px"
          textTransform="uppercase"
          paddingBottom="5px"
        >
          {group.card_title}
        </Text>
        <Text fontSize="16px" fontWeight="600">
          {group.card_subtext}
        </Text>
        <Text
          fontFamily="Open Sans"
          fontWeight="600"
          textColor="#1E1E1E"
          fontSize={{ md: '15px', base: '14px' }}
          lineHeight="25.5px"
          letterSpacing="-6%"
        >
          {group.card_text}
        </Text>
      </Box>
      <ComplexCardFooter
        iconSelect={iconSelect}
        info={group.card_footer}
      ></ComplexCardFooter>
    </Box>
  );
}

import * as yup from 'yup';

export const getValidations = (formData) => {
  const isValidUrl = (url) => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  };

  const yupShape = {};
  formData?.section[0]?.field?.map((item) => {
    let validator = {};
    switch (item.field_type) {
      case 'singleDropdown':
        validator = yup.string();
        if (item.required) {
          validator = validator.required('This is required');
        }
        break;
      case 'email':
        validator = yup.string().transform((value) => value.trim());
        if (item.required) {
          validator = validator.required('This is required');
        }
        validator = validator.email('Invalid email address');
        break;
      case 'zipCode':
        validator = yup
          .string()
          .transform((value) => value.trim())
          .matches(
            /^[0-9\- ]*$/,
            'Only numbers and dashes and spaces are allowed',
          );
        if (item.required) {
          validator = validator.required('This is required');
        }
        break;
      case 'url':
        validator = yup.string().transform((value) => value.trim());
        if (item.required) {
          validator = validator.required('This is required');
        } else {
          validator = validator.nullable();
        }
        validator = validator.test(
          'is-url-valid',
          'URL must begin with https:// or http://',
          (value) => {
            if (!value) return true;
            return isValidUrl(value);
          },
        );
        break;
      case 'phoneNumber':
        validator = yup
          .string()
          .transform((value) => value.replace(/\s+/g, ''))
          .max(20, `Must be less than 20 digits`)
          .min(7, `Must be more than 7 digits`)
          .matches(
            /^[+()\d-]*$/,
            'Phone can only contain numbers, spaces, parentheses, dashes and an optional leading +',
          )
          .matches(
            /^(?!0)[1-9].*|^\+.*$/,
            'Phone number cannot start with a 0',
          );
        if (item.required) {
          validator = validator.required('This is required');
        }
        break;
      case 'date':
        validator = yup.date();
        if (item.validations.future_past_date_ === 'past') {
          validator = validator.max(new Date(), 'Date must be in the past');
        }
        if (item.validations.future_past_date_ === 'future') {
          validator = validator.min(new Date(), 'Date must be in the future');
        }
        if (item.required) {
          validator = validator.required('This is required');
        }
        break;
      case 'radio':
        validator = yup.string();
        if (item.required) {
          validator = validator.required('This is required');
        }
        break;

      case 'checkbox':
        validator = yup.array();

        if (item.required) {
          validator = validator.required('This is required');
          validator = validator.test(
            'is-at-least-one-checked',
            'At least one checkbox must be checked',
            (value) => value?.length > 0,
          );
        } else {
          validator = validator.nullable();
        }

        break;
      default:
        validator = yup
          .string()
          .transform((value) => value.trim())
          .matches(
            /^[^<>\\{}|^~[\]=%]*$/,
            'Cannot contain <, >, \\, {, }, |,^, ~,[, ], %, =',
          );
        if (item.validations.min_character) {
          validator = validator.min(
            item.validations.min_character,
            `Must be at least ${item.validations.min_character} characters`,
          );
        }
        if (item.validations.max_character) {
          validator = validator.max(
            item.validations.max_character,
            `Must be less than ${item.validations.max_character} characters`,
          );
        }
        if (item.required) {
          validator = validator.required('This is required');
        }
        break;
    }
    yupShape[item.field_name] = validator;
  });
  const FieldSchema = yup.object().shape(yupShape);
  return FieldSchema;
};

export const formatOptions = (options) => {
  const trimmedStr = options.substring(1, options.length - 1).trim();
  const formattedArr = trimmedStr.split(',').map((element) => {
    element.trim();
    return element;
  });
  return formattedArr;
};

export const getInitialValues = (formData) => {
  const fieldValues = {};
  formData?.section[0]?.field?.map((item) => {
    if (item.field_type === 'checkbox') {
      fieldValues[item.field_name] = [];
    } else {
      fieldValues[item.field_name] = '';
    }
  });
  return fieldValues;
};

export const getCountries = (country) => {
  const countryArray = [];
  country.countries.map((country) => {
    countryArray.push(country.country_name);
  });
  return countryArray;
};

import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import api from '../../../utils/api';
import ThreeCard from './threeCard';

export default function Stack(props) {
  const { data, refData, contained } = props;
  const [newData, setNewData] = useState();
  async function getData() {
    const info = await api.getSpecificEntry(
      refData?._content_type_uid,
      refData?.uid,
    );
    setNewData(info.entry);
  }
  data ? setNewData(data) : !newData ? getData() : '';

  return (
    <Box
      backgroundColor={newData?.light_grey_background ? '#f4f4f4' : 'white'}
      py={'32px'}
    >
      <Box
        maxW={'1440px'}
        mx={'auto'}
        px={contained ? 'initial' : { base: '16px', md: '24px', lg: '60px' }}
      >
        {newData && <ThreeCard data={newData.three_stack_promo} />}
      </Box>
    </Box>
  );
}

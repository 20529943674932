import React from 'react';
import { Box } from '@chakra-ui/react';

const ChevronLeftIcon = ({ variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-chevron-left ${fasize}`}
      {...rest}
    />
  );
};

export default ChevronLeftIcon;

import { useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Link,
  Text,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { React, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../utils/api';
import ChevronDownIcon from '../../icons/chevronDown';
import ArrowRight from '../../icons/rightArrow';

export default function MenuMB(props) {
  const { data, refData } = props;
  const { l } = useParams();
  const [value, setValue] = useState();
  const [name, setName] = useState();
  const [newData, setNewData] = useState();

  async function getData() {
    const info = await api.getSpecificEntry(
      refData._content_type_uid,
      refData.uid,
      l,
    );
    setNewData(info.entry.help_drop_menu);
  }

  data ? setNewData(data) : !newData ? getData() : null;

  return (
    <Box
      as={Flex}
      direction="column"
      py={'80px'}
      align="center"
      justify="center"
      maxW="100%"
    >
      <Heading
        // border="1px solid blue"
        fontWeight="700"
        fontSize={{ md: '16px', base: '12px' }}
        lineHeight={{ md: '16px' }}
        letterSpacing="0.1em"
        paddingBottom={{ md: '12px', sm: 'none' }}
      >
        {newData?.heading_title}
      </Heading>
      <Box
        as={Stack}
        direction="row"
        align="center"
        justify="center"
        justifyContent="center"
      >
        <Box
          as={Stack}
          // border="1px red solid"
          direction={{ md: 'row', base: 'column' }}
          align={{ md: 'end', base: 'start' }}
          // textAlign="end"
          gap={{ md: 5, base: -1 }}
          pl={{ md: 0, base: 4 }}
        >
          <Text
            fontWeight="600"
            // letterSpacing="-0.02em"
            pl={{ md: '0', base: 1 }}
            fontSize={{ md: '28px', base: '24px' }}
            alignSelf={{ md: 'end', base: 'start' }}
          >
            {newData?.question_title}
          </Text>
          <Menu maxW={{ md: '593px', base: '370px' }} overflow="hidden">
            <MenuButton
              marginTop="-0.5em"
              as={Button}
              borderBottom="1px"
              borderRadius={0}
              color="#1A5D8F"
              maxW={{ md: '593px', base: '370px' }}
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize={{
                md: 'clamp(1rem, -37.1614rem + 10.3139vw, 24rem)',
                base: '12px',
              }}
              backgroundColor="white"
              _hover={{ backgroundColor: 'white' }}
              _active={{ backgroundColor: 'white' }}
            >
              {value ? value : newData?.answers?.url_path[0]?.link?.title}{' '}
              {/* <FontAwesomeIcon overflow="visible" icon={faChevronDown} /> */}
              <ChevronDownIcon overflow={'visible'} />
            </MenuButton>
            <MenuList
              marginTop={-2}
              color="#1A5D8F"
              // fontSize={{ md: '24px -5em', base: '14px -1em' }}
              fontSize={{
                md: 'clamp(1rem, -37.1614rem + 10.3139vw, 24rem)',
                base: '12px',
              }}
              overflow="hidden"
              whiteSpace="nowrap"
              align="end"
              maxW={{ md: '600px', base: '370px' }}
              borderRadius={0}
              onClick={(e) => setValue(e.target.value)}
            >
              {newData?.answers?.url_path?.map((link, i) => {
                return (
                  <MenuItem
                    key={i}
                    color="#5C5C5C"
                    fontWeight="400"
                    fontSize={{
                      md: 'clamp(1rem, -37.1614rem + 10.3139vw, 24rem)',
                      base: '12px',
                    }}
                    _hover={{
                      fontWeight: '700',
                      color: '#1A5D8F',
                      backgroundColor: 'rgba(27, 95, 142, 0.13)',
                      whiteSpace: 'nowrap',
                      fontSize: {
                        md: 'clamp(1rem, -37.1614rem + 10.3139vw, 24rem)',
                        base: '12px',
                      },
                    }}
                    _focus={{
                      fontWeight: '700',
                      color: '#1A5D8F',
                      backgroundColor: 'rgba(27, 95, 142, 0.13)',
                    }}
                    onClick={(e) => setName(e.target.name)}
                    // newPath={link.link.href}
                    name={link.link?.href}
                    value={link.link?.title}
                  >
                    {link.link?.title}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </Box>
        <Link
          as={Flex}
          onClick={() =>
            (window.location.href = name
              ? name
              : newData?.answers?.url_path[0]?.link?.href)
          }
          // href={name ? name : newData?.answers?.url_path[0]?.link?.href}
          border="1px"
          borderColor="#1A5D8F"
          borderRadius="100%"
          backgroundColor="#1A5D8F"
          w={10}
          h={10}
          align={'center'}
          justify={'center'}
          _hover={{ textDecor: 'none', boxShadow: 'outline' }}
          _focus={{ boxShadow: 'outline' }}
        >
          <ArrowRight color="white" fasize={'fa-xl'} />
          {/* <Link > */}
          {/* <FontAwesomeIcon
              icon={faArrowRight}
              color="white"
              size="xl"
              padding={10}
            /> */}

          {/* </Link> */}
        </Link>
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Flex, Heading, Link, Box } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import api from '../../../utils/api';
import RichTextEditorMB from '../rte';
import RightArrow from '../../icons/rightArrow';

export default function ContentCallOut(props) {
  const { refData } = props;
  const { l } = useParams();
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntryWithRef(
      refData?._content_type_uid,
      refData?.uid,
      l,
      'theme',
    );
    setData(info.entry);
  }
  return (
    <Flex width={'100%'} justify={'center'} py={'32px'}>
      <Flex
        bgColor={data?.theme[0]?.colors?.background_color?.replace(' ', '')}
        color={data?.theme[0]?.colors?.text_color?.replace(' ', '')}
        w={{ base: '100% - 2rem', md: '744px', lg: '946px' }}
        py={{ base: '1rem', md: '2rem' }}
        borderRadius={'20px'}
        direction={
          data?.image_right
            ? { base: 'column-reverse', md: 'row' }
            : { base: 'column', md: 'row' }
        }
        mx={{ base: '1rem', md: 'auto' }}
      >
        {!data?.image_right && (
          <Flex
            // border={'red solid 2px'}
            mx={{ base: '1rem', md: '2rem', lg: '1.5rem' }}
            bgImage={`url(${data?.image?.url})`}
            bgSize={'cover'}
            h={{ base: '196px', md: '343px', lg: '396px' }}
            w={{ base: `100%-2rem`, md: '264px', lg: '366px' }}
            bgPosition={'center'}
            bgRepeat={'no-repeat'}
            borderRadius={'8px'}
          />
        )}
        <Flex
          direction={'column'}
          mx={{ base: '1rem', md: '2.5rem' }}
          maxW={{ base: '100%', md: '50%' }}
        >
          <Heading
            fontSize={{ base: '1.625rem', md: '1.875rem', lg: '2.5rem' }}
            fontWeight={'700'}
            lineHeight={{ base: '1.953rem', md: '2.25rem', lg: '3rem' }}
            mb={'16px'}
          >
            {data?.heading}
          </Heading>
          <Box
            fontSize={{ base: '1rem', lg: '1.125rem' }}
            fontWeight={'400'}
            lineHeight={{ base: '1.362rem', lg: '1.532rem' }}
            mb={{ base: '22px', md: '29px', lg: '26px' }}
          >
            <RichTextEditorMB
              data={data?.text}
              px={'initial'}
              //   style={data?.text?.custom_styles}
            />
          </Box>

          {data?.cta_link?.title && (
            <Flex gap={'1rem'} align="center">
              <Link
                href={data?.cta_link?.href}
                fontSize={{ base: '1rem' }}
                fontWeight={{ base: '600' }}
                lineHeight={'1.25rem'}
                // color={'#246C60'}
              >
                {data?.cta_link?.title}
              </Link>
              {/* <FontAwesomeIcon
                icon={solid('arrow-right-long')}
                // color={'#246C60'}
              /> */}
              <RightArrow />
            </Flex>
          )}
        </Flex>
        {data?.image_right && (
          <Flex
            // border={'red solid 2px'}
            mx={{ base: '1rem', md: '2rem', lg: '1.5rem' }}
            bgImage={`url(${data?.image?.url})`}
            bgSize={'cover'}
            h={{ base: '196px', md: '343px', lg: '396px' }}
            w={{ base: '100% - 2rem', md: '264px', lg: '366px' }}
            bgPosition={'center'}
            bgRepeat={'no-repeat'}
            borderRadius={'8px'}
          />
        )}
      </Flex>
    </Flex>
  );
}

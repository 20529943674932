import React, { useEffect, useState } from 'react';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import api from '../utils/api';
import { Flex, Box, Text, Heading, useTheme } from '@chakra-ui/react';

import Metadata from '../components/metadata';
import AllAlert from '../components/message_alert/all_alert';
import LinkBanner from '../components/newsArticleComp/AllNewsLinkBanner';
import ArticleHeader from '../components/newsArticleComp/articleHeader/ArticleHeader';
import IconBar from '../components/newsArticleComp/socialsIcons';
import BlockContainer from '../components/modularBlocks/container';
import CpeSection from '../components/newsArticleComp/cpeSection/CpeSection';
import AuthorCarousel from '../components/newsArticleComp/authorCarousel';
import CtaSection from '../components/newsArticleComp/ctaSection';
// import RightArrow from '../components/icons/rightArrow';
import Card from '../components/modularBlocks/carousels/carousel/carouselCard';
import useDateFormat from '../hooks/useDateFormat';
import useResize from '../hooks/useResize';
import Loading from '../components/loadingIndicator/loading';

export default function ResourceEntry(props) {
  const { ct } = props;

  const [data, setData] = useState();
  const [dateBuilder] = useDateFormat(); // date builder uses 2 variables(timestamp, format) format should be "month_year" or "ap"
  const [pub, setPub] = useState();
  const [update, setUpdate] = useState();
  const path = window.location.pathname;
  const theme = useTheme();

  useEffect(() => {
    getData();
  }, [ct]);

  async function getData() {
    const info = await api.getArticle(
      ct,
      path,
      'en-us',
      'article_details.author,related_resources.resource_entry',
    );

    setData(info[0]);
    setPub(() =>
      dateBuilder(
        new Date(info[0].article_details?.date).getTime(),
        'month_year',
      ),
    );
    {
      info[0].article_details?.date_updated != null
        ? setUpdate(() =>
            dateBuilder(
              new Date(info[0].article_details?.date_updated).getTime(),
              'month_year',
            ),
          )
        : setUpdate(null);
    }
  }

  return (
    <main id="main">
      <SkipNavContent />
      {data ? (
        <Box h="100%">
          {data.seo && <Metadata data={data.seo} override={data} />}
          <AllAlert />
          {/* All News Banner */}

          <LinkBanner data={{ title: 'All Resources', href: '/resources' }} />

          <Box maxW={'1220px'} mx={'auto'} h="100%">
            {/* TOP SECTION */}
            <ArticleHeader data={data} ct={ct} />

            {/* Optional Headline banner with Image used on Resource Entries */}
            {data.article_details?.headline && (
              <Flex
                ml={{ base: '35px', md: '50px', lg: '62px' }}
                mr={{ base: '35px', md: '50px', lg: '76px' }}
                mb={{ base: '12px', md: '28px', lg: '35px' }}
                h={{ base: '128px', md: '225px', lg: '347px' }}
                bgImage={data.headline_banner?.image?.url}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                borderRadius={'15px'}
                textOverflow={'ellipsis'}
              >
                <Flex
                  align={'center'}
                  justify={'center'}
                  borderRadius={'inherit'}
                  h={'100%'}
                  w={'100%'}
                  bgGradient={data.headline_banner?.image_gradient}
                >
                  <Text
                    as={'h1'}
                    fontFamily={'Lato'}
                    fontWeight={'800'}
                    fontSize={{ base: '1.5rem', md: '2.5rem' }}
                    lineHeight={'3rem'}
                    textAlign={'center'}
                    color={
                      data.headline_banner?.white_text
                        ? theme.colors.White
                        : theme.colors.Black
                    }
                  >
                    {data.article_details?.headline}
                  </Text>
                </Flex>
              </Flex>
            )}
            {data.entry_summary && (
              <Text
                ml={{ base: '35px', md: '50px', lg: '62px' }}
                mr={{ base: '35px', md: '50px', lg: '76px' }}
                fontFamily={'Lato'}
                fontSize={{ base: '.75rem', md: '1.563rem' }}
                lineHeight={'1.875rem'}
                letterSpacing={'.015em'}
                fontWeight={'800'}
              >
                {data.entry_summary}
              </Text>
            )}

            {/* Main container that contains sidebar and article */}
            <Flex
              gap={{ base: '5%', lg: '6%' }}
              h={'100%'}
              ml={{ base: '35px', md: '50px', lg: '62px' }}
              mr={{ base: '35px', md: '50px', lg: '76px' }}
              direction={{ base: 'column-reverse', md: 'row' }}
              mt={!data.main_image && { base: '12px', md: '28px', lg: '69px' }}
              // border={'solid 1px hotpink'}
            >
              {/* Main article section */}
              <Flex
                h={'100%'}
                maxW={'100%'}
                flex={1}
                direction={'column'}
                // border={'solid 1px green'}
                // pl={{ base: '5%', lg: '6%' }}
              >
                {/* Author(news)/Links Header */}
                <Flex
                  // justify={'space-between'}
                  borderBottom={`solid ${theme.colors.Black} 1px`}
                  pb={{ base: '29px', md: '17px', lg: '24px' }}
                >
                  {/* Author Section News */}
                  <Flex flex={1}>
                    <Flex
                      direction={'column'}
                      gap="11px"
                      letterSpacing={'.03%'}
                    >
                      <Flex gap={'1rem'}>
                        <Text
                          fontSize={{ base: '.75rem', md: '1.563rem' }}
                          lineHeight={'2.125rem'}
                          fontWeight={'600'}
                        >
                          Published:
                        </Text>
                        <Text
                          fontSize={{ base: '.75rem', md: '1.563rem' }}
                          lineHeight={'2.125rem'}
                          letterSpacing={'.03%'}
                        >
                          {pub}
                          {/* <DateBuilder raw={data.article_details?.date} /> */}
                        </Text>
                      </Flex>
                      <Flex gap={'1rem'}>
                        {update && (
                          <Text
                            fontSize={{ base: '.75rem', md: '1.563rem' }}
                            lineHeight={'2.125rem'}
                            fontWeight={'600'}
                          >
                            Last updated:
                          </Text>
                        )}
                        <Text
                          fontSize={{ base: '.75rem', md: '1.563rem' }}
                          lineHeight={'2.125rem'}
                        >
                          {/* <DateBuilder
                            raw={data.article_details?.date_updated}
                          /> */}
                          {update}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>

                  {/* Socials Section */}
                  <Flex flex={1} justify={'end'}>
                    <IconBar />
                  </Flex>
                </Flex>

                <AuthorCarousel data={data.article_details?.author} />

                {(data.co_published_field ||
                  data.resource_entry_cta_buttons_) && (
                  <CtaSection
                    coPub={data.co_published_field}
                    ctaGroup={data.resource_entry_cta_buttons_}
                  />
                )}

                {/* Article Body */}
                <Box id="article" className="Article-Body" mt={'29px'}>
                  <BlockContainer
                    data={data.article_body}
                    rtecontainerpx={'initial'}
                    px={'initial'}
                    contained={'true'}
                  />
                </Box>
                {/*  */}

                {/* Post body section with image and button */}
                {data.cpe_eligible && <CpeSection data={data} ct={ct} />}
              </Flex>
            </Flex>
            {data.related_resources.length === 3 && (
              <RelatedResources array={data.related_resources} />
            )}
          </Box>

          {/* {data.page_content && <BlockContainer data={data.page_content} />} */}
        </Box>
      ) : (
        <Loading minH={'100vh'} />
      )}
    </main>
  );
}

const RelatedResources = ({ array, index }) => {
  const [size, handleResize] = useResize();

  useEffect(() => {
    handleResize();
  }, []);

  let cards = array.slice(0, 2);

  if (size === 'lg') {
    cards = array;
  }

  window.addEventListener('resize', handleResize);

  return (
    <Flex
      key={index}
      direction={'column'}
      ml={{ base: '35px', md: '50px', lg: '62px' }}
      mr={{ base: '35px', md: '50px', lg: '76px' }}
      my={{ lg: '65px' }}
      gap={{ sm: '28px', md: '41px', lg: '48px' }}
    >
      <Heading fontWeight={'700'} fontSize={{ base: '1.5rem', md: '2.5rem' }}>
        Related Resources
      </Heading>
      <Flex
        gap={'4%'}
        justify={'space-between'}
        direction={{ base: 'column', md: 'row' }}
      >
        {cards?.map((sub) => {
          return sub?.resource_entry?.map((data, i) => {
            return (
              <Card
                key={i}
                data={data}
                titleProp={data.tags_group?.type}
                overrideAlt={sub.override_image_alt}
                override={sub.override_image?.url}
                my={'8px'}
              />
            );
          });
        })}
      </Flex>
    </Flex>
  );
};

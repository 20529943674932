// import { Gallery } from './gallery';
import Keen from './gallery/keen';
import React, { memo } from 'react';

export default function AuthorCarousel(props) {
  const { data } = props;
  // const PureGallery = memo(Gallery);
  const PureKeen = memo(Keen);

  const inputArray = data;
  const threeSlideArray = inputArray.reduce((resultArray, item, index) => {
    const perSlide = 3; // items per slide
    const chunkIndex = Math.floor(index / perSlide);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  const fourSlideArray = inputArray.reduce((resultArray, item, index) => {
    const perSlide = 4; // items per slide
    const chunkIndex = Math.floor(index / perSlide);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <>
      {' '}
      {/* <PureGallery
        {...props}
        lgCards={fourSlideArray}
        mdCards={threeSlideArray}
        smCards={data}
      /> */}
      <PureKeen
        {...props}
        lgCards={fourSlideArray}
        mdCards={threeSlideArray}
        smCards={data}
      />
    </>
  );
}

import React from 'react';
import { Flex } from '@chakra-ui/react';
import RichTextEditorMB from '../../modularBlocks/rte';
import ButtonMB from '../../modularBlocks/button';

export default function CtaSection({ coPub, ctaGroup }) {
  return (
    <Flex
      justify={'space-between'}
      //   align={'start'}
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: '20px', md: '0' }}
      py={{ base: '22px', md: '50px' }}
      // border={'solid hotpink 2px'}
    >
      {coPub && coPub !== '<p><span></span></p>' && (
        <Flex
          justify={'start'}
          // flex={1}
          // border={'hotpink solid 2px'}
        >
          <RichTextEditorMB data={coPub} px={'inital'} w={'100%'} />
        </Flex>
      )}
      {(ctaGroup.cta_button_1 ||
        ctaGroup.cta_button_2 ||
        ctaGroup.cta_button_3) && (
        <Flex
          // flex={2}
          // border={'lime solid 2px'}
          gap={{ base: '11px', md: '13px' }}
          direction={{ base: 'column', md: 'row' }}
          flexWrap={'wrap'}
          justify={coPub ? { base: 'initial', md: 'end' } : 'initial'}
        >
          {ctaGroup.cta_button_1?.map((button, i) => {
            return (
              <ButtonMB
                refData={button}
                key={i}
                // border={'solid red 2px'}
                mx={{ lg: '12px' }}
              />
            );
          })}
          {ctaGroup.cta_button_2?.map((button, i) => {
            return (
              <ButtonMB
                refData={button}
                key={i}
                // border={'solid red 2px'}
                mx={{ lg: '12px' }}
                // justifyContent={!coPub && 'start'}
              />
            );
          })}
          {ctaGroup.cta_button_3?.map((button, i) => {
            return (
              <ButtonMB
                refData={button}
                key={i}
                // border={'solid red 2px'}
                mx={{ lg: '12px' }}
              />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}

import {
  Box,
  // Button,
  Heading,
  Text,
  Link,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FaIcon from '../../icons/faIcon';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../utils/api';
import tableOfContentsSquaresLeft from '../../../assets/vectors/tableOfContentsSquaresLeft.svg';
import tableOfContentsSquaresRight from '../../../assets/vectors/tableOfContentsSquaresRight.svg';
import logger from 'utils/logger';

export default function TableOfContents(props) {
  const { data, newTab, alt } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data?._content_type_uid,
      data?.uid,
      l,
      'table_of_contents_expanded.section.cta_link.news_article',
    );

    logger.log('table_of_contents', info);

    setNewData(info.entry);
  }

  logger.log('data in TableOfContents', data);
  return (
    <Box
      _before={{
        content: '""',
      }}
      _after={{ content: '""' }}
      backgroundImage={`url(${tableOfContentsSquaresLeft}), url(${tableOfContentsSquaresRight})`}
      backgroundPosition="top left, top right"
      backgroundRepeat="no-repeat"
      //   border="5px solid green"
    >
      {newData && (
        <Box
          as={Flex}
          maxW={'1440px'}
          mx={'auto'}
          paddingY="32px"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDir="column"
            width={{ md: '92%', base: '75%' }}
            justifyContent="center"
          >
            <Box
              as={Flex}
              flexDirection="column"
              marginTop="0px"
              marginBottom="8px"
              gap="8px"
              textAlign="start"
              alignItems="start"
              paddingRight={{ md: 'none', base: '28px' }}
            >
              {/* <FontAwesomeIcon
                justifySelf="start"
                fontSize="3vh"
                color="#5E9C44"
                icon={
                  newData.icon === 'Book'
                    ? solid('book-open')
                    : newData.icon === 'School'
                    ? solid('school')
                    : logger.log('unknown icon')
                }
              /> */}
              <FaIcon
                data-icon={alt}
                faIcon={`fa-${newData.icon.replaceAll(' ', '-').toLowerCase()}`}
                // fasize={'fa-2xl'}
                color={'PrimaryGreen'}
              />
              <Heading paddingBottom="8px">{newData.headline_text}</Heading>
              <Text fontWeight="400" fontSize="16px" letterSpacing="-2%">
                {newData.description_text}
              </Text>
              <Box
                alignSelf="start"
                marginTop="0px"
                as="button"
                fontFamily="Lato"
                fontWeight="700"
                fontSize="14px"
                color="#006955"
                letterSpacing="-2%"
                onClick={isOpen ? onClose : onOpen}
              >
                [
                {isOpen
                  ? newData.cta_open_close_text?.cta_close_text
                  : newData.cta_open_close_text?.cta_open_text}
                ]
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignSelf="center"
              // paddingLeft="10px"
              width={{ lg: '90%', md: '90%', base: '100%' }}
            >
              {isOpen &&
                newData.table_of_contents_expanded.section?.map(
                  (chapter, i) => {
                    return (
                      <Box
                        as={Flex}
                        flexDirection="column"
                        // border="1px purple solid"
                        borderBottom="1px solid #CECECE"
                        key={i}
                        paddingY="16px"
                        paddingLeft="0px"
                      >
                        <Text
                          fontFamily="Lato"
                          fontWeight="700"
                          fontSize="20px"
                          // letterSpacing="-2%"
                          marginBottom="8px"
                        >
                          {chapter.section_title}
                        </Text>
                        {chapter.cta_link?.map((cta, i) => {
                          return (
                            <Box as={Flex} key={i}>
                              <Link
                                marginY="4px"
                                color="#006955"
                                fontWeight="700"
                                fontSize="16px"
                                letterSpacing="-2%"
                                href={cta.link.href}
                                target={
                                  cta.new_window ? '_blank' : newTab || '_self'
                                }
                              >
                                {cta.link.title}
                              </Link>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  },
                )}
            </Box>
            {isOpen && (
              <Box
                alignSelf="start"
                as="button"
                fontFamily="Lato"
                fontWeight="700"
                fontSize="14px"
                color="#006955"
                letterSpacing="-2%"
                marginTop="16px"
                marginBottom="0px"
                onClick={onClose}
              >
                [{newData.cta_open_close_text?.cta_close_text}]
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

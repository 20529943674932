import { Box, Flex, Link } from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { useEffect, useState } from 'react';

import api from '../../../utils/api';
import FaIcon from '../../icons/faIcon';
// import LangSelect from '../../langSelect/refactor';

export default function UtilNav(props) {
  const { setOpen, viewBack, setSMV, setActive, setMenuGroup } = props;
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getAllEntries('utility_nav');
    setData(info);
  }
  return (
    <Flex
      align={'center'}
      justify={'space-between'}
      w={{ base: '102%', lg: '80%' }}
      mx={'auto'}
      px={{ base: '42px' }}
    >
      <Flex
        w={'100%'}
        h={'24px'}
        gap={'1rem'}
        // border={'pink solid 2px'}
        justify={{ base: 'center', md: 'end' }}
      >
        {data?.utility_links?.map((entry, i) => {
          return (
            <Box
              key={i}
              fontSize={{ base: '.875rem' }}
              lineHeight={{ base: '1.192rem' }}
              fontWeight={'400'}
              onClick={() => {
                setOpen(false);
                viewBack(false);
                setSMV(false);
                setActive(false);
                setMenuGroup(true);
              }}
            >
              {entry.link?.title && (
                <Link
                  href={entry.link?.href}
                  target={entry.new_window ? '_blank' : '_self'}
                >
                  {entry.link?.title}
                </Link>
              )}
            </Box>
          );
        })}

        <Box borderLeft={'solid 1px'} borderColor={'inherit'} pl={'19px'}>
          <Link
            href={data?.search_link?.url_path}
            aria-label="Click to go to the search page"
          >
            {/* <FontAwesomeIcon
              icon={solid('magnifying-glass')}
              color={'inherit'}
            /> */}

            <FaIcon faIcon={'fa-magnifying-glass'} color={'inherit'} />
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}

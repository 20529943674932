import React, { useEffect, useState } from 'react';
import AllAlert from '../components/message_alert/all_alert';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import Metadata from '../components/metadata';
import Banner from '../components/modularBlocks/banner';
import BlockContainer from '../components/modularBlocks/container';
import InteriorHeader from '../components/modularBlocks/section/interior_section_header';
import SectionBanner from '../components/modularBlocks/sectionBanner';
import Navigation from '../components/navigation';
import Loading from '../components/loadingIndicator/loading';
import logger from 'utils/logger';

export default function PageTemplate(props) {
  const { info } = props;
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setData(info);
  }

  logger.log('pages template data', info);
  return (
    <main className="main">
      <SkipNavContent />
      {data ? (
        <div>
          {data.seo && <Metadata data={data.seo} override={data} />}
          <AllAlert />
          {data.interior_section_header?.length > 0 && (
            <InteriorHeader data={data.interior_section_header[0]} />
          )}
          {data.section_hero_banner?.length > 0 && (
            <SectionBanner data={data.section_hero_banner[0]} />
          )}

          {/* <BreadCrumbs
            data={data.breadcrumb}
            currentPage={data.title}
            display={data.breadcrumb}
          /> */}

          {data.hero_banner && <Banner data={data.hero_banner} />}
          {data.show_breadcrumb && <Navigation />}
          {data.page_content && <BlockContainer data={data.page_content} />}
          {/* {data.entry_listing && (
            <EntityListing refData={data.entry_listing[0]} />
          )} */}
        </div>
      ) : (
        <Loading minH={'100vh'} />
      )}
    </main>
  );
}

import { Box, Flex, Link, Text, Image, useTheme } from '@chakra-ui/react';
import React from 'react';
export default function MenuPromo(props) {
  const { data } = props;
  const theme = useTheme();
  return (
    <Box bg={theme.colors.Grey4} maxW={{ lg: '324px' }}>
      <Flex
        direction={{ base: 'column', md: 'row', lg: 'column' }}
        // align={'center'}
        height={'100%'}
        // border={'dotted purple 2px'}
      >
        {data?.promo_content?.map((card, i) => {
          return (
            <Flex
              key={i}
              // border={'green dashed 2px'}
              flexGrow={1}
              justify={'center'}
              my={{ base: 'initial', md: '20px', lg: 'initial' }}
              mx={{ base: '20px', md: 'initial', lg: '20px' }}
              py={{ base: '20px', md: 'initial', lg: '20px' }}
              px={'40px'}
              borderRight={
                data?.promo_content?.length === 2 && i === 0
                  ? { md: `1px solid ${theme.colors.Grey3}`, lg: 'none' }
                  : 'none'
              }
              borderBottom={
                data?.promo_content?.length === 2 && i === 0
                  ? {
                      base: `1px solid ${theme.colors.Grey3}`,
                      md: 'none',
                      lg: `1px solid ${theme.colors.Grey3}`,
                    }
                  : 'none'
              }
            >
              <Flex
                direction={'column'}
                textAlign={'center'}
                gap={'1rem'}
                my={'10px'}
                // border={'solid 2px red'}
              >
                <Box maxH={data?.promo_content?.length === 2 ? 115 : 170}>
                  <Image
                    src={`${card.image?.url}?height=${
                      data?.promo_content?.length === 2 ? 115 : 170
                    }`}
                    alt={card.image_alt}
                    loading={'lazy'}
                    mx={'auto'}
                  />
                </Box>

                <Link
                  href={card.title?.href}
                  fontWeight={'700'}
                  fontSize={'1.125rem'}
                  lineHeight={'1.532rem'}
                >
                  <Text noOfLines={3}>{card.title?.title}</Text>
                </Link>
                {data?.promo_content?.length === 1 && (
                  <>
                    <Text
                      fontWeight={'400'}
                      fontSize={'.75rem'}
                      lineHeight={'1rem'}
                      borderTop={`1px solid ${theme.colors.Grey3}`}
                      p={'1rem'}
                      noOfLines={5}
                    >
                      {card.description}
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}

import React from 'react';
import { Heading, Image, Flex, Button, Link } from '@chakra-ui/react';
import RichTextEditorMB from '../../../../rte';
// import ButtonMB from '../../../../button';
export function VSlide(props) {
  const { data } = props;
  return (
    <Flex
      w={'100%'}
      border={'teal solid 2px'}
      direction={'column'}
      align={'center'}
      bgColor="white"
      borderRadius={'40px'}
      color={'black'}
      // minH={'630px'}
      maxW={'741px'}
      p={'6.5%'}
      justify={'space-around'}
      mx={{ base: '1rem', md: 'initial' }}
      gap={'1rem'}
    >
      <Image
        src={data?.badge?.badge_image?.url}
        alt={data?.badge?.image_alt_text}
      />
      <Flex
        mb={{ base: '24px', md: '24px', lg: '36px' }}
        textAlign={'center'}
        direction={'column'}
        maxW={'550px'}
      >
        <Heading fontSize={'1.5rem'} fontWeight={'700'} lineHeight={'1.8rem'}>
          {data?.badge?.image_title}
        </Heading>
        <RichTextEditorMB
          data={data?.badge?.image_description}
          px="initial"
          style={['card']}
        />
      </Flex>

      <Flex
        gap={'1rem'}
        justify={'center'}
        direction={{ base: 'column', md: 'row' }}
        mx={'1rem'}
      >
        {data?.badge?.button?.map((button, i) => {
          return (
            <Button
              // border={'red solid 2px'}
              key={i}
              mx={{ base: 'auto', md: 'initial' }}
              alignment={'start'}
              size={{
                base: 'small',
                md: button.button_size?.toLowerCase(),
              }}
              variant={button.button_type?.toLowerCase()}
              as={Link}
              href={button.button_link?.href}
              target={button.new_window ? '_blank' : '_self'}
            >
              {button.button_link?.title}
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}

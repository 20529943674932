import { Box } from '@chakra-ui/react';
import React from 'react';

export default function UpRightFromSquare({ variant, fasize, ...rest }) {
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-up-right-from-square ${fasize}`}
      {...rest}
    />
  );
}

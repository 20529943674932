import { Box } from '@chakra-ui/react';
import React from 'react';

export default function LeftArrow({ variant, fasize, ...rest }) {
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-arrow-left-long ${fasize}`}
      {...resizeTo}
    />
  );
}

import { Gallery } from './gallery';
import React, { useEffect, useState, memo } from 'react';
import api from '../../../../utils/api';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import logger from 'utils/logger';

export default function Carousel(props) {
  const { l } = useParams();
  const [cards, setCards] = useState([]);
  const { data, refData } = props;
  const PureGallery = memo(Gallery);
  useEffect(() => {
    data
      ? setCards(() => [data.item_one, data.item_two, ...data.items])
      : refData
      ? getCards()
      : logger.warn('Carousel ERROR');
  }, []);

  // The following code is used when entries are a reference
  // ------------------------------------------------------------------------------------------
  async function getCards() {
    const { entries } = refData;
    const entryOne = refData.entry_one;
    const entryTwo = refData.entry_two;
    const dataArray = [entryOne, entryTwo, entries];
    const returnedArray = [];

    dataArray.forEach((entry, i) => {
      entry.map(async (info) => {
        const cardCall = await api.getSpecificEntry(
          info._content_type_uid,
          info.uid,
          l,
        );
        const type = { content_type: info._content_type_uid };
        const newObj = { ...type, ...cardCall.entry };
        returnedArray.push(newObj);
        if (returnedArray.length === entries.length + 2) {
          return setCards(returnedArray);
        }
        return;
      });
    });
  }
  // ----------------------------------------------------------------------------------------

  const inputArray = cards;
  const threeSlideArray = inputArray.reduce((resultArray, item, index) => {
    const perSlide = 3; // items per slide
    const chunkIndex = Math.floor(index / perSlide);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  const twoSlideArray = inputArray.reduce((resultArray, item, index) => {
    const perSlide = 2; // items per slide
    const chunkIndex = Math.floor(index / perSlide);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <Box
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      <PureGallery
        {...props}
        lgCards={threeSlideArray}
        mdCards={twoSlideArray}
        smCards={cards}
      />
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { FiveCard, FourCard, SixCard, ThreeCard, TwoCard } from './layouts';

export default function TrainingStacks(props) {
  const { data } = props;
  const { l } = useParams();
  const [cards, setCards] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );
    setCards(info?.entry?.training_cards_section?.content_card);
  }

  return (
    <Flex direction={'column'}>
      {cards?.length === 2 && <TwoCard data={cards} />}
      {cards?.length === 3 && <ThreeCard data={cards} />}
      {cards?.length === 4 && <FourCard data={cards} />}
      {cards?.length === 5 && <FiveCard data={cards} />}
      {cards?.length === 6 && <SixCard data={cards} />}
    </Flex>
  );
}

import React, { useState, useEffect } from 'react';
import { Flex, Spinner, Box, useTheme } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Loading({ minH, ct, ...rest }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const showSpinnerTimeoutId = setTimeout(() => {
      setShowSpinner(true);
    }, 500);

    // Timeout for redirection
    const redirectTimeout = 5000; // Set the timeout for redirection (e.g., 10000 milliseconds = 10 seconds)
    const redirectTimeoutId = setTimeout(() => {
      // Logic to determine the redirection path based on contentType
      if (ct === 'news') {
        navigate('/news');
      }
    }, redirectTimeout);

    return () => {
      clearTimeout(showSpinnerTimeoutId);
      clearTimeout(redirectTimeoutId);
    };
  }, [navigate]);

  return (
    <>
      {showSpinner ? (
        <Flex minH={minH} {...rest}>
          <Spinner m={'auto'} size={'xl'} speed="1s" />
        </Flex>
      ) : (
        <Box color={theme.colors.White} minH={'100vh'}></Box>
      )}
    </>
  );
}

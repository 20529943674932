import {
  Box,
  Button,
  Modal,
  ModalBody,
  //   ModalCloseButton,
  ModalContent,
  Heading,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../utils/api';
import RichTextEditorMB from '../rte';
import LegendModal from './legendModal';
import CtaButton from '../button/ctaButton';
import logger from 'utils/logger';

export default function ModalMB(props) {
  const { data, px } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }

  return (
    <Box>
      {newData && (
        <Box display="flex">
          <Button onClick={onOpen}>{newData.modal_link_text}</Button>
          <Box as={Modal} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bg="#F5f5f5" />
            <ModalContent
              borderRadius="46px"
              minH={{ lg: '207px', md: '200px', base: '200px' }}
              minW={{ lg: '915px', md: '760px', base: '320px' }}
              //   maxH={{ lg: '607px', md: '531px', base: '80%' }}
              //   maxW={{ lg: '915px', md: '834px', base: '80%' }}
              display="flex"
              flexDirection="column"
              padding={{ lg: '24px', md: '24px', base: '12px' }}
              gap={{ lg: '60px', md: '40px', base: '20px' }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                paddingTop={{ md: '12px', base: '8px' }}
              >
                <Heading
                  fontWeight="700"
                  fontSize={{ lg: '30px', md: '25px', base: '25px' }}
                  flex="1"
                  textAlign="center"
                >
                  {newData.inner_modal_title_text}
                </Heading>
                <Button
                  variant="unstyled"
                  justifySelf="flex-end"
                  onClick={onClose}
                >
                  <FontAwesomeIcon fontSize="3vh" icon={solid('xmark')} />
                </Button>
              </Box>

              {newData.modal_content.map((block, i) => {
                if (block.legend_modal) {
                  return (
                    <Box w={'100%'} key={i}>
                      <LegendModal data={block.legend_modal.legend_modal[0]} />
                    </Box>
                  );
                } else if (block.rte) {
                  return (
                    <Box
                      my="2"
                      key={i}
                      //   border={'dashed green 2px'}
                      w={'100%'}
                      px={'2%'}
                    >
                      <RichTextEditorMB
                        as={ModalBody}
                        key={i}
                        data={block.rte.rich_text_editor}
                        style={block.rte.custom_styles}
                        px={px}
                      />
                    </Box>
                  );
                } else if (block.cta_button_block) {
                  return (
                    <Box as={ModalBody} w={'100%'} key={i}>
                      <CtaButton
                        data={block.cta_button_block.cta_button_block[0]}
                      />
                    </Box>
                  );
                } else {
                  logger.warn('Error');
                }
              })}
            </ModalContent>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  Link,
  Spacer,
  Grid,
  Heading,
  useTheme,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../utils/api';

export default function MainFooter() {
  const { l } = useParams();
  const [data, setData] = useState();
  const theme = useTheme();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await API.getAllEntriesWithRef(
      'main_footer',
      'social_media',
      l,
    );

    setData(info[0]);
  }

  return (
    <Box>
      {data ? (
        <Container
          maxW={'8xl'}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
          borderBottom={`1px solid ${theme.colors.Black}`}
          flexWrap="wrap"
          spacing={4}
        >
          {/* Logo && Top Row */}
          <Box padding="5px" justifyContent="start">
            <Link href="/">
              <Image
                alignSelf="start"
                height={10}
                width={20}
                src={data.logo?.url}
                alt={data.logo_alt_text}
              />
            </Link>
          </Box>
          <Spacer />
          {/* All Sections Split */}
          <Box
            as={Stack}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={6}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'start' }}
          >
            <Box
              as={Flex}
              alignContent="flex-start"
              flexFlow="column wrap"
              textAlign="start"
              gap={5}
              maxW={{ base: '100%', md: '38%' }}
              pl={{ base: '0', md: '5px' }}
              pr={{ base: '0', md: '5px' }}
            >
              {/* About Section */}
              <Flex
                alignContent="flex-start"
                flexFlow="column wrap"
                textAlign="start"
                gap={5}
              >
                <Heading
                  fontSize={{ lg: '24px', md: '16px', sm: '24px' }}
                  fontWeight="700"
                >
                  {data.about_section?.title}
                </Heading>
                <Text
                  fontSize={{ lg: '16px', base: '14px' }}
                  fontWeight="400"
                  lineHeight={{ lg: '22px', md: '25px', base: '21px' }}
                  color={theme.colors.White}
                  opacity="0.8"
                >
                  {data.about_section?.about_description_multi}
                </Text>
                {/* <RichTextEditorMB rteObj={data.about_section.about_description.json_rte} /> */}
              </Flex>
              {/* Social Media Section */}
              <Flex
                alignContent="flex-start"
                alignItems="start"
                justify={{ base: 'space-between', md: 'space-between' }}
                align={{ base: 'start', md: 'start' }}
              >
                {data.social_media[0]?.social_link?.map((social, i) => {
                  return (
                    <Box
                      as="button"
                      key={i}
                      m={'2'}
                      w={10}
                      h={10}
                      border="grey solid 2px"
                      borderRadius="50%"
                      alignItems="center"
                      justifyContent="center"
                      aria-label="Button to social media"
                    >
                      <Link
                        target={'_blank'}
                        href={social.link?.href}
                        aria-label={`Follow us on ${social.fa_icon}`}
                      >
                        <Box
                          className={`fa-brands fa-${social.fa_icon.toLowerCase()} fa-lg`}
                          color={theme.colors.White}
                        ></Box>
                      </Link>
                    </Box>
                  );
                })}
              </Flex>
              {/* About Links section */}
              <Box>
                <Grid templateColumns="repeat(2, 1fr)" justifyItems="start">
                  {data.about_links?.about_link?.map((link, i) => {
                    return (
                      <Box key={i} paddingTop="10px" paddingBottom="10px">
                        <Link
                          fontSize="sm"
                          style={{ textDecoration: 'underline' }}
                          href={link.href}
                        >
                          {link.title}
                        </Link>
                      </Box>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            {/* Become a Member section */}

            <Box
              as={Flex}
              alignContent="flex-start"
              flexFlow="column wrap"
              textAlign="start"
              gap={5}
              maxW={{ base: '100%', md: '38%' }}
            >
              <Heading
                justifyContent="left"
                fontSize={{ lg: '24px', md: '16px', sm: '24px' }}
                fontWeight="700"
                spacing={4}
              >
                {data.become_a_member_section?.title}
              </Heading>

              <Text
                fontSize={{ lg: '16px', base: '14px' }}
                fontWeight="400"
                lineHeight={{ lg: '22px', md: '25px', base: '21px' }}
                color="#FFFFFF"
                opacity="0.8"
              >
                {data.become_a_member_section?.bam_description}
              </Text>

              {/* <RichTextEditorMB rteObj={data.become_a_member_section.description.json_rte} /> */}
              <Box
                alignSelf={{ base: 'center', md: 'start' }}
                as="button"
                textColor="White"
                border="2px"
                borderRadius="25px"
                borderColor="White"
                paddingY="8px"
                paddingX="24px"
                minW={{ base: '100%', md: '20%' }}
              >
                <Link
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  href={data.become_a_member_section?.url_path}
                >
                  {data.become_a_member_section?.button_title}
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      ) : (
        ''
      )}
    </Box>
  );
}

import { useEffect } from 'react';
/* eslint-disable */

const GTMHeadInjector = () => {
  useEffect(() => {
    const gtmId =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'GTM-M79VV6'
        : 'GTM-P6C6HT5';
    // Function to insert the script at the top of the head
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      // Getting the first child of head
      var firstHeadChild = d.head.firstChild;
      // Prepending the script as the first child
      d.head.insertBefore(j, firstHeadChild);
    })(window, document, 'script', 'dataLayer', gtmId);
  }, []);

  return null; // This component doesn't render anything
};
/* eslint-enable */
export default GTMHeadInjector;

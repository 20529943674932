import React from 'react';
import { Box } from '@chakra-ui/react';

const FaIcon = ({ faIcon, variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-solid'} ${
        faIcon || 'fa-question'
      } ${fasize}`}
      {...rest}
    />
  );
};

export default FaIcon;

import { Box } from '@chakra-ui/react';
const LinkedinIcon = ({ variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-brands'} fa-linkedin ${fasize}`}
      {...rest}
    />
  );
};

export default LinkedinIcon;

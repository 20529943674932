import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import BlockContainer from '../../container';
import InfoCard from '../infoCard';
export default function FourColumns(props) {
  const { data, widths } = props;
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      gap={{ base: '16px', md: '32px' }}
    >
      {data?.map((sec, index) => {
        return (
          <Flex
            key={index}
            // border={'teal solid 2px'}
            width={{ base: '100%', md: `${widths[index]}%` }}
            direction={'column'}
            alignItems={'center'}
          >
            {sec.section?.components?.map((comp, i) => {
              return (
                <Flex
                  direction={'column'}
                  // w={comp.video_player ? '100%' : 'auto'}
                  w={'100%'}
                  key={i}
                  // px={comp.video_player ? '0' : '16px'}
                  // border={'dotted purple 2px'}
                  h={'100%'}
                  align={'stretch'}
                >
                  {comp.title_line ? (
                    <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                      {comp.title_line.title}
                    </Heading>
                  ) : comp.info_card ? (
                    <InfoCard key={i} data={comp.info_card} />
                  ) : (
                    <BlockContainer key={i} data={[comp]} px="initial" />
                  )}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
      {/* <Flex
        // border={'teal solid 2px'}
        width={{ base: '100%', md: `${widths[0]}%` }}
        direction={'column'}
        alignItems={'center'}
      >
        {data[0]?.section?.components?.map((comp, i) => {
          return (
            <Flex
              direction={'column'}
              w={comp.video_player ? '100%' : 'auto'}
              key={i}
              px={comp.video_player ? '0' : '16px'}
              // border={'dotted purple 2px'}
            >
              {comp.title_line ? (
                <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                  {comp.title_line.title}
                </Heading>
              ) : comp.info_card ? (
                <InfoCard key={i} data={comp.info_card} />
              ) : (
                <BlockContainer key={i} data={[comp]} px="initial" />
              )}
            </Flex>
          );
        })}
      </Flex>
      <Flex
        // border={'teal solid 2px'}
        width={{ base: '100%', md: `${widths[1]}%` }}
        direction={'column'}
      >
        {data[1]?.section?.components?.map((comp, i) => {
          return (
            <Flex
              direction={'column'}
              w={comp.video_player ? '100%' : 'auto'}
              key={i}
              px={comp.video_player ? '0' : '16px'}
              // border={'dotted purple 2px'}
            >
              {comp.title_line ? (
                <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                  {comp.title_line.title}
                </Heading>
              ) : comp.info_card ? (
                <InfoCard key={i} data={comp.info_card} />
              ) : (
                <BlockContainer key={i} data={[comp]} px="initial" />
              )}
            </Flex>
          );
        })}
      </Flex>
      <Flex
        // border={'teal solid 2px'}
        width={{ base: '100%', md: `${widths[2]}%` }}
        direction={'column'}
      >
        {data[2]?.section?.components?.map((comp, i) => {
          return (
            <Flex
              direction={'column'}
              w={comp.video_player ? '100%' : 'auto'}
              key={i}
              px={comp.video_player ? '0' : '16px'}
              // border={'dotted purple 2px'}
            >
              {comp.title_line ? (
                <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                  {comp.title_line.title}
                </Heading>
              ) : comp.info_card ? (
                <InfoCard key={i} data={comp.info_card} />
              ) : (
                <BlockContainer key={i} data={[comp]} px="initial" />
              )}
            </Flex>
          );
        })}
      </Flex>
      <Flex
        // border={'teal solid 2px'}
        width={{ base: '100%', md: `${widths[3]}%` }}
        direction={'column'}
      >
        {data[3]?.section?.components?.map((comp, i) => {
          return (
            <Flex
              direction={'column'}
              w={comp.video_player ? '100%' : 'auto'}
              key={i}
              px={comp.video_player ? '0' : '16px'}
              // border={'dotted purple 2px'}
            >
              {comp.title_line ? (
                <Heading key={i} fontWeight={'700'} fontSize={'1.875rem'}>
                  {comp.title_line.title}
                </Heading>
              ) : comp.info_card ? (
                <InfoCard key={i} data={comp.info_card} />
              ) : (
                <BlockContainer key={i} data={[comp]} px="initial" />
              )}
            </Flex>
          );
        })}
      </Flex> */}
    </Flex>
  );
}

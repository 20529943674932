import React, { useState } from 'react';
import MainNav from './main_nav/index.js';
import { chakra, useTheme } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

function Header() {
  const [menu, setMenu] = useState();
  const [active, setActive] = useState(false);
  const location = window.location.pathname;
  const dark = location == '/' ? true : location == '/home' ? true : false;
  const theme = useTheme();
  return (
    <>
      <chakra.header
        // border={'orange solid 1px'}
        id="header"
        background={
          dark === true
            ? { base: theme.colors.Black, md: theme.colors.Black }
            : theme.colors.White
        }
        color={
          dark === true
            ? { base: theme.colors.Black, md: theme.colors.White }
            : theme.colors.Black
        }
        w="100%"
        py={'1.5%'}
        position={'relative'}
      >
        <MainNav
          active={active}
          setActive={setActive}
          setMenu={setMenu}
          menu={menu}
          // dark={dark}
        />
      </chakra.header>

      <Outlet />
    </>
  );
}

export default React.memo(Header);

import { configureStore } from '@reduxjs/toolkit';
import yourReducer from './reducer';

const store = configureStore({
  reducer: {
    // Add your reducers here
    yourReducer: yourReducer,
  },
});

export default store;

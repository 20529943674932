import { Box, Button, Flex, Heading, Text, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import FaIcon from '../../icons/faIcon';
import UpRightFromSquare from '../../icons/upRightFromSq';

export default function PromoBanner(props) {
  const { data, newTab, alt } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data._content_type_uid,
      data.uid,
      l,
      'theme',
      'button_block',
    );
    setNewData(info.entry);
  }
  return (
    <Box
      as={Flex}
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      backgroundColor={newData?.theme[0]?.colors?.background_color}
    >
      <Box
        my="32px"
        mx={{ lg: 60, md: 20, base: 5 }}
        gap="16px"
        as={Flex}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        textAlign="center"
      >
        <Box>
          <Heading
            fontWeight="700"
            fontSize={{ base: '24px', sm: '24px', md: '32px' }}
            color={newData?.theme[0]?.colors?.text_color}
            maxWidth="700px"
            display="inline"
          >
            {newData?.headline_text}
          </Heading>

          {newData?.include_icon && newData.icon?.font_awesome && (
            <FaIcon
              px="16px"
              pb={{ base: '2px', md: '5px', lg: '5px' }}
              data-icon={alt}
              alt={newData?.icon.font_awesome}
              faIcon={`fa-${newData?.icon?.font_awesome
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              fasize={'fa-2xl'}
              color={
                newData?.icon?.icon_color
                  ? newData?.icon?.icon_color
                  : newData?.theme[0]?.colors?.text_color
                  ? newData?.theme[0]?.colors?.text_color
                  : '#000000'
              }
            />
          )}
        </Box>

        <Text
          fontWeight="400"
          fontSize={{ base: '16px', md: '20px', lg: '20px' }}
          lineHeight={{ base: '22px', md: '27px', lg: '34px' }}
          color={newData?.theme[0]?.colors?.text_color}
          textAlign="center"
          alignSelf="center"
          maxWidth="700px"
        >
          {newData?.description}
        </Text>
        <Box>
          {newData?.button?.button_link?.title && (
            <>
              {newData?.button?.external_link ? (
                <Button
                  size={newData?.button?.button_size?.toLowerCase()}
                  variant={newData?.button?.button_type?.toLowerCase()}
                  as={Link}
                  href={newData?.button?.button_link?.href}
                  target={
                    newData?.button?.new_window
                      ? '_blank'
                      : newTab
                      ? newTab
                      : '_self'
                  }
                  gap="10px"
                >
                  {newData?.button?.button_link?.title}
                  <UpRightFromSquare />
                </Button>
              ) : (
                <Button
                  size={newData?.button?.button_size?.toLowerCase()}
                  variant={newData?.button?.button_type?.toLowerCase()}
                  as={Link}
                  href={newData?.button?.button_link?.href}
                  target={
                    newData?.button?.new_window
                      ? '_blank'
                      : newTab
                      ? newTab
                      : '_self'
                  }
                >
                  {newData?.button?.button_link?.title}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

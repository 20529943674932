import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Text,
  Flex,
  Image,
  Link,
  VStack,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function Banner(props) {
  const { data, newTab } = props;

  return (
    <Box
      bgColor={'black'}
      //  border={'red solid 1px'}
      minH={'fit-content'}
    >
      <Flex
        direction={
          data?.background?.img_align
            ? { base: 'column', md: 'row' }
            : { base: 'column-reverse', md: 'row' }
        }
        // border={'plum solid 2px'}
        justify={'space-between'}
        maxW={'1440px'}
        mx={'auto'}
      >
        {data?.background?.img_align && (
          <Flex width={{ base: '100%', lg: '55%' }} justify={'end'}>
            <Image
              src={data?.background?.image?.url}
              alt={data?.background?.img_alt}
              maxH={'451px'}
              objectFit={'cover'}
            ></Image>
          </Flex>
        )}
        <VStack
          // border={'orange solid 2px'}
          justify={'center'}
          align={'start'}
          color={'white'}
          w={{ base: '100%', lg: '45%' }}
          pl={'2%'}
          gap={'2%'}
          mb={'1%'}
        >
          <Heading
            // as={'h1'}
            fontWeight={'800'}
            fontSize={'40px'}
            lineHeight={'45px'}
            // letterSpacing={'-.05rem'}
          >
            {data?.title}
          </Heading>
          <Text fontWeight={'500'} fontSize={'20px'} lineHeight={'24px'}>
            {data?.text}
          </Text>
          <Link
            // border={'yellow solid 2px'}
            as={RouterLink}
            to={data?.button?.href}
            fontSize={'20px'}
            fontWeight={'600'}
            w={'50%'}
          >
            <Flex align={'center'}>
              {data?.cta_button?.button_link?.title && (
                <>
                  {data?.cta_button?.external_link ? (
                    <Button
                      size={data?.button?.button_size?.toLowerCase()}
                      variant={data?.button?.button_type?.toLowerCase()}
                      as={Link}
                      href={data?.button?.button_link?.href}
                      target={
                        data?.button?.new_window
                          ? '_blank'
                          : newTab
                          ? newTab
                          : '_self'
                      }
                      gap="10px"
                    >
                      {data?.button?.button_link?.title}
                      <FontAwesomeIcon icon={solid('up-right-from-square')} />
                    </Button>
                  ) : (
                    <Button
                      size={data?.cta_button?.button_size?.toLowerCase()}
                      variant={data?.cta_button?.button_type?.toLowerCase()}
                      as={NavLink}
                      to={data?.cta_button?.button_link?.href}
                      target={
                        data?.cta_button?.new_window
                          ? '_blank'
                          : newTab
                          ? newTab
                          : '_self'
                      }
                    >
                      {data?.cta_button?.button_link?.title}
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </Link>
        </VStack>
        {!data?.background?.img_align && (
          <Flex width={{ base: '100%', lg: '55%' }} justify={'end'}>
            <Image
              src={data?.background?.image?.url}
              alt={data?.background?.img_alt}
              maxH={'451px'}
              objectFit={'cover'}
            ></Image>
          </Flex>
        )}
      </Flex>
      <Divider />
    </Box>
  );
}

import React, { useEffect } from 'react';
import { Flex, Text, Link, Box, Heading } from '@chakra-ui/react';
// import { Link as RouterLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useTitleColor from '../../../../../hooks/useTitleColor';
import RightArrow from '../../../../icons/rightArrow';

export default function Card(props) {
  const { data } = props;

  const cardTitle = data?.reference[0]?.tags_group?.type;
  const [color, colorChoice] = useTitleColor(cardTitle);
  useEffect(() => {
    colorChoice();
  }, []);
  return (
    <Flex
      align={{ base: 'center', large: 'start' }}
      // border={'pink solid 2px'}
      w={{ base: '100%', md: '380px', lg: '393px' }}
      mx={{ base: 'auto', md: 'initial' }}
    >
      <Box
        // border={'green solid 2px'}
        bgImage={
          data?.image_override?.url ||
          data?.reference[0]?.image?.url ||
          data?.reference[0]?.headline_banner?.image?.url
        }
        bgSize={'cover'}
        borderRadius={'20px'}
        h={'500px'}
        w={'100%'}
        alt={data?.image_alt}
      >
        <Flex
          // bgGradient="linear(to-b, rgba(0, 0, 0, 0) 0%,  #000000 100%)"
          h="100%"
          w="100%"
          pl={{ lg: '10%' }}
          pb={{ lg: '15%' }}
          justify={'start'}
          align="end"
          borderRadius={'10px'}
        >
          {/* Inner Card displaying data */}
          <Box
            bgColor={'white'}
            w={{ base: '100%', lg: '80%' }}
            minH={'35%'}
            borderRadius={'10px'}
            py={'5%'}
            mx={{ base: '1rem', lg: 'initial' }}
            mb={{ base: '1rem', lg: 'initial' }}
            color={'black'}
          >
            {/* Top line with Color box on left */}
            <Flex
              direction={'column'}
              mx={'auto'}
              // border={'solid brown 2px'}
              minH={'150px'}
              justify={'space-between'}
            >
              <Flex w={'100%'} h={'20px'} align={'center'} gap={'10px'}>
                <Box w={'5%'} h={'24px'} bgColor={color}></Box>
                <Heading
                  fontSize={'.875rem'}
                  lineHeight={'1.05rem'}
                  fontWeight={'700'}
                  letterSpacing={'-.02em'}
                  color={'black'}
                >
                  {cardTitle}
                </Heading>
              </Flex>
              <Text
                fontSize={'1rem'}
                fontWeight={'600'}
                lineHeight={'1.375rem'}
                m={'5%'}
                color={'#1E1E1E'}
                align={'start'}
                noOfLines={3}
              >
                {data?.title_override ||
                  data?.reference[0]?.article_details?.headline}
              </Text>
              <Link
                mx={'5%'}
                align={'start'}
                href={data?.link?.href || data?.reference[0]?.url}
                // color="DarkGreen"
              >
                <Flex gap={'16px'} align={'center'}>
                  <Text
                    fontSize={'1rem'}
                    fontWeight={'600'}
                    lineHeight={'1.25rem'}
                  >
                    {data?.link?.title || 'Read More'}
                  </Text>

                  <RightArrow />
                </Flex>
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

import { Flex, Heading, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';

export default function RelatedStories(props) {
  const [stories, setStories] = useState([]);
  const { data } = props;
  useEffect(() => {
    if (data.length < 5) {
      getData();
    } else {
      setStories(data.slice(0, 5));
    }
  }, [data]);

  async function getData() {
    const limit = 5 - data.length;
    const articles =
      limit > 0
        ? await api.getLimitedEntries(
            'news_article',
            limit,
            'article_details.date',
          )
        : [];
    const blurbs =
      limit > 0
        ? await api.getLimitedEntries(
            'news_blurb',
            limit,
            'article_details.date',
          )
        : [];

    const combinedArticles = articles.concat(blurbs);

    // Sort the combinedArticles by date in descending order
    combinedArticles.sort(
      (a, b) =>
        new Date(b.article_details.date) - new Date(a.article_details.date),
    );

    // Take only as many articles/blurbs as needed to fill up to 5 stories
    const additionalStories = combinedArticles.slice(0, limit);

    // Combine the selected articles with the additional fetched stories
    setStories([...data, ...additionalStories]);
  }

  return (
    <Flex
      direction={'column'}
      gap={'16px'}
      mb={{ base: '34px', md: '83px', lg: '48px ' }}
    >
      <Heading
        fontSize={'1.25rem'}
        lineHeight={'1.5rem'}
        letterSpacing={'-.02em'}
        fontWeight={'700'}
      >
        RELATED STORIES
      </Heading>
      <Flex direction={'column'} gap={{ base: '43px', md: '8xp', lg: '45px' }}>
        {stories.slice(0, 5).map((article, i) => (
          <Link key={i} href={article.url}>
            <Flex
              borderLeft={'solid 3px'}
              borderLeftColor={'PrimaryGreen'}
              minH={{ base: '63px', md: '53px', lg: '63px' }}
            >
              <Text
                noOfLines={3}
                ml={'18px'}
                fontFamily={'Lato'}
                fontSize={{ base: '1.125rem', md: '1rem', lg: '1.125rem' }}
                fontWeight={'700'}
                lineHeight={{ base: '1.35rem', md: '1.2rem', lg: '1.35rem' }}
              >
                {article.article_details?.headline}
              </Text>
            </Flex>
          </Link>
        ))}
      </Flex>
    </Flex>
  );
}

import React from 'react';
import { Button, Link, Flex, Image, Heading, Box } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import RichTextEditorMB from '../../../../rte';
// import ButtonMB from '../../../../button';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { CarouselIconButton } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export function HSlide(props) {
  const {
    data,
    newTab,
    slidesPerView,
    slider,
    length,
    index,
    setIndex,
    currentSlide,
  } = props;
  return (
    <Flex
      justify={'center'}
      // border={'pink solid 2px'}
      direction={{ base: 'column', md: 'row' }}
      maxW={'1440px'}
      py={'2rem'}
      _hover={{
        cursor:
          'url(https://ssl.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur),default',
      }}
    >
      <Flex
        // w={{ base: '100%', lg: '60%' }}
        flex={1}
        justify={'space-between'}
        // border={'orange solid 2px'}
        px={'1rem'}
        align={'center'}
        minH={{ md: '400px', lg: '475px' }}
      >
        <CarouselIconButton
          onClick={() => {
            slider.current?.prev();
            return setIndex(index - 1);
          }}
          icon={<IoChevronBackOutline style={{ color: 'ContrastGreen' }} />}
          aria-label="Previous slide"
          disabled={currentSlide === 0}
        />
        <Box
          border={'solid #E0E0E0 1px'}
          borderRadius={'22px'}
          w={'83%'}
          h={'85%'}
          boxShadow={'0px 4px 4px rgba(151,151,151,.25)'}
          position={'relative'}
          zIndex={0}
        >
          <Flex
            bgColor={'white'}
            direction="column"
            border={'1px #E0E0E0 solid'}
            borderRadius={'22px'}
            align={'center'}
            boxShadow={'0px 4px 4px rgba(151,151,151,.25)'}
            justify={'space-around'}
            p={'1.5rem'}
            gap={{ base: '.5rem', lg: '2rem' }}
            w={'80%'}
            position={'absolute'}
            top={'-9%'}
            right={'10%'}
            zIndex={10}
            minH={{ md: '400px', lg: '475px' }}
            textAlign={'center'}
          >
            <Image
              src={data?.badge?.badge_image?.url}
              alt={data?.badge?.image_alt_text}
            />
            <Heading
              fontSize={'1.5rem'}
              fontWeight={'700'}
              lineHeight={'1.8rem'}
            >
              {data?.badge?.image_title}
            </Heading>
          </Flex>
        </Box>

        <CarouselIconButton
          onClick={() => {
            slider.current?.next();
            return setIndex(index + 1);
          }}
          icon={<IoChevronForwardOutline style={{ color: 'ContrastGreen' }} />}
          aria-label="Next slide"
          disabled={currentSlide + Number(slidesPerView) === length}
        />
      </Flex>

      <Flex
        // w={{ base: '100%' }}
        flex={1}
        // border={'solid red 2px'}
        direction={'column'}
        justify={'center'}
        align={'start'}
        gap={{ base: '1rem', md: '1.75rem' }}
        // pr={{ base: 'initial', md: '5rem', lg: '10rem' }}
      >
        <Heading fontSize={'1.5rem'} fontWeight={'700'} lineHeight={'1.8rem'}>
          {data?.badge?.image_title}
        </Heading>
        <RichTextEditorMB data={data?.badge?.image_description} px="initial" />
        <Flex gap={'2.5%'} justify={'center'}>
          {data?.badge?.button?.map((button, i) => {
            return (
              // <ButtonMB
              //   buttonObj={button}
              //   key={i}
              //   bgColor={i % 2 !== 0 ? 'white' : '#5E9C44'}
              //   minH={'50px'}
              // />
              <div key={i}>
                {button.button_link?.title && (
                  <>
                    {button.external_link ? (
                      <Button
                        size={{
                          base: 'small',
                          md: button.button_size?.toLowerCase(),
                        }}
                        variant={button.button_type?.toLowerCase()}
                        as={Link}
                        href={button.button_link?.href}
                        target={
                          button.new_window
                            ? '_blank'
                            : newTab
                            ? newTab
                            : '_self'
                        }
                        gap="10px"
                      >
                        {button.button_link?.title}
                        <FontAwesomeIcon icon={solid('up-right-from-square')} />
                      </Button>
                    ) : (
                      <Button
                        size={{
                          base: 'small',
                          md: button.button_size?.toLowerCase(),
                        }}
                        variant={button.button_type?.toLowerCase()}
                        as={Link}
                        href={button.button_link?.href}
                        target={
                          button.new_window
                            ? '_blank'
                            : newTab
                            ? newTab
                            : '_self'
                        }
                      >
                        {button.button_link?.title}
                      </Button>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
export function MobileHSlide(props) {
  const { data, newTab } = props;
  return (
    <Flex
      border={'#E0E0E0 solid 1px'}
      direction={'column'}
      align={'center'}
      borderRadius={'20px'}
      p={'6.5%'}
      justify={'space-around'}
      gap={'1rem'}
    >
      <Image
        src={data?.badge?.badge_image?.url}
        alt={data?.badge?.image_alt_text}
      />
      <Flex gap={'1rem'} textAlign={'center'} direction={'column'}>
        <Heading fontSize={'1.5rem'} fontWeight={'700'} lineHeight={'1.8rem'}>
          {data?.badge?.image_title}
        </Heading>
        <RichTextEditorMB data={data?.badge?.image_description} px="initial" />
      </Flex>

      <Flex
        gap={'1rem'}
        justify={'center'}
        direction={{ base: 'column', md: 'row' }}
      >
        {data?.badge?.button.map((button, i) => {
          return (
            <div key={i}>
              {button.button_link?.title && (
                <>
                  {button.external ? (
                    <Button
                      size={{
                        base: 'small',
                        md: button.button_size?.toLowerCase(),
                      }}
                      variant={button.button_type?.toLowerCase()}
                      as={Link}
                      href={button.button_link?.href}
                      target={
                        button.new_window ? '_blank' : newTab ? newTab : '_self'
                      }
                    >
                      {button.button_link?.title}
                    </Button>
                  ) : (
                    <Button
                      size={{
                        base: 'small',
                        md: button.button_size?.toLowerCase(),
                      }}
                      variant={button.button_type?.toLowerCase()}
                      as={NavLink}
                      to={button.button_link?.href}
                      target={
                        button.new_window ? '_blank' : newTab ? newTab : '_self'
                      }
                    >
                      {button.button_link?.title}
                    </Button>
                  )}
                </>
              )}
            </div>
          );
        })}
      </Flex>
    </Flex>
  );
}

import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Link,
  useTheme,
  Box,
} from '@chakra-ui/react';
import RichTextEditorMB from '../../rte';
import RightArrow from '../../../icons/rightArrow';

export default function InnerAccordion(props) {
  const { data } = props;
  const theme = useTheme();
  return (
    <Flex direction={'column'}>
      {' '}
      <Accordion allowToggle>
        {data?.entry?.map((entry, i) => {
          return (
            <AccordionItem key={i} border={'none'}>
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionButton
                      _expanded={{ color: theme.colors.GreenLink }}
                      gap={'2rem'}
                      fontWeight={'600'}
                      padding="16px"
                      borderTop={`2px solid ${theme.colors.Grey3}`}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        color={theme.colors.BlackText}
                        fontSize="1.25rem"
                        fontWeight="700"
                      >
                        {entry.title}
                      </Box>
                      <AccordionIcon
                        w={10}
                        h={10}
                        color={theme.colors.BlackText}
                        my="-16px"
                      />
                    </AccordionButton>
                    <AccordionPanel py={'0'}>
                      <RichTextEditorMB
                        data={entry.text}
                        px="initial"
                        fontSize={'1rem'}
                      />
                      {entry.cta_link?.title && (
                        <Flex
                          gap={'1rem'}
                          align="center"
                          mt={'16px'}
                          p="0 24px 16px 0"
                        >
                          <Link
                            href={entry.cta_link?.href}
                            fontSize="1rem"
                            fontWeight="700"
                            lineHeight="1.5rem"
                            color={theme.colors.DarkGreen}
                          >
                            {entry.cta_link?.title}
                          </Link>
                          <RightArrow
                            style={{ color: theme.colors.DarkGreen }}
                          />
                        </Flex>
                      )}
                    </AccordionPanel>
                  </>
                );
              }}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Flex>
  );
}

import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../utils/api';
import logger from 'utils/logger';

export default function TrainingHeader(props) {
  const { data, alt } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data._content_type_uid,
      data.uid,
      l,
      'theme',
    );

    logger.log('training_section_header', 'theme', info);

    setNewData(info.entry);
  }
  return (
    <Box
      as={Flex}
      flexDirection="column"
      // border="solid 1px black"
      justifyContent="center"
      alignContent="center"
      backgroundColor={newData?.theme[0]?.colors?.background_color}
      // color={newData?.theme[0]?.colors?.text_color}
    >
      <Box
        mt="32px"
        mx={{ lg: 60, md: 20, base: 5 }}
        gap={2}
        as={Flex}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        // border="solid 1px red"
        textAlign="center"
      >
        {newData?.include_icon ? (
          <Box
            as={Flex}
            justifyContent="center"
            alignItems="center"
            // border="solid 1px yellow"
            flexDirection="row"
          >
            <Box
              w="30px"
              h="1px"
              // bgGradient="linear(to-r, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0))"
              bgGradient={newData?.icon?.border_color}
              mr={2}
            ></Box>
            {newData?.icon?.fa_icon ? (
              <FontAwesomeIcon
                // alignSelf="center"
                data-icon={alt}
                h="19px"
                w="20px"
                color="#5E9C44"
                icon={
                  newData?.icon?.font_awesome === 'School'
                    ? icon({ name: 'school' })
                    : newData?.icon?.font_awesome === 'Envelope'
                    ? icon({ name: 'envelope' })
                    : newData?.icon?.font_awesome === 'Certificate'
                    ? icon({ name: 'certificate' })
                    : newData?.icon?.font_awesome === 'Users'
                    ? icon({ name: 'users' })
                    : newData?.icon?.font_awesome === 'User'
                    ? icon({ name: 'user' })
                    : newData?.icon?.font_awesome === 'Rectangle'
                    ? icon({ name: 'rectangle' })
                    : newData?.icon?.font_awesome === 'Presentation Screen'
                    ? icon({ name: 'presentation-screen' })
                    : newData?.icon?.font_awesome === 'Laptop'
                    ? icon({ name: 'laptop' })
                    : newData?.icon?.font_awesome === 'Computer'
                    ? icon({ name: 'computer' })
                    : newData?.icon?.font_awesome === 'Megaphone'
                    ? icon({ name: 'megaphone' })
                    : newData?.icon?.font_awesome === 'People Roof'
                    ? icon({ name: 'people-roof' })
                    : newData?.icon?.font_awesome === 'Globe'
                    ? icon({ name: 'globe' })
                    : newData?.icon?.font_awesome === 'Inbox'
                    ? icon({ name: 'inbox' })
                    : newData?.icon?.font_awesome === 'Map Location Dot'
                    ? icon({ name: 'map-location-dot' })
                    : newData?.icon?.font_awesome === 'Arrow Up Right Dots'
                    ? icon({ name: 'arrow-up-right-dots' })
                    : newData?.icon?.font_awesome === 'Person Chalkboard'
                    ? icon({ name: 'person-chalkboard' })
                    : newData?.icon?.font_awesome === 'Screen Users'
                    ? icon({ name: 'screen-users' })
                    : newData?.icon?.font_awesome === 'Certificate'
                    ? icon({ name: 'certificate' })
                    : newData?.icon?.font_awesome === 'Award'
                    ? icon({ name: 'award' })
                    : newData?.icon?.font_awesome === 'Building'
                    ? icon({ name: 'building' })
                    : newData?.icon?.font_awesome === 'Podium'
                    ? icon({ name: 'podium' })
                    : newData?.icon?.font_awesome === 'Handshake'
                    ? icon({ name: 'handshake' })
                    : newData?.icon?.font_awesome === 'Circle Question'
                    ? icon({ name: 'circle-question' })
                    : newData?.icon?.font_awesome === 'Square Question'
                    ? icon({ name: 'square-question' })
                    : newData?.icon?.font_awesome === 'Question'
                    ? icon({ name: 'question' })
                    : newData?.icon?.font_awesome === 'Arrow Progress'
                    ? icon({ name: 'arrow-progress' })
                    : newData?.icon?.font_awesome === 'Calendar Star'
                    ? icon({ name: 'calendar-star' })
                    : newData?.icon?.font_awesome === 'Book Open'
                    ? icon({ name: 'book-open' })
                    : newData?.icon?.font_awesome === 'Books'
                    ? icon({ name: 'books' })
                    : newData?.icon?.font_awesome === 'Book Open Cover'
                    ? icon({ name: 'book-open-cover' })
                    : newData?.icon?.font_awesome === 'Print'
                    ? icon({ name: 'print' })
                    : newData?.icon?.font_awesome === 'Newspaper'
                    ? icon({ name: 'newspaper' })
                    : logger.warn('unknown icon')
                }
              />
            ) : (
              <Image
                // alignSelf="center"
                h="19px"
                w="20px"
                src={newData?.icon?.image?.url}
                alt={newData?.icon?.image_alt_text}
              />
            )}
            <Box
              w="30px"
              h="1px"
              // bgGradient="linear(to-r, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0))"
              bgGradient={newData?.icon?.border_color}
              ml={2}
            ></Box>
          </Box>
        ) : (
          ''
        )}
        {/* <Icon /> */}
        <Heading
          fontWeight="700"
          fontSize={{ base: '24px', md: '32px', lg: '36px' }}
          lineHeight={{ base: '28.8px', md: '38.4px', lg: '48px' }}
          color={newData?.theme[0]?.colors?.text_color}
        >
          {newData?.header_title}
        </Heading>
        <Text
          fontWeight="400"
          fontSize={{ base: '14px', md: '16px', lg: '20px' }}
          lineHeight={{ base: '20px', md: '24px', lg: '34px' }}
          color={newData?.theme[0]?.colors?.secondary_text_color}
        >
          {newData?.description}
        </Text>
      </Box>
    </Box>
  );
}

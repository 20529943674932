import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import api from '../../../utils/api';

export default function Meetings(props) {
  const { data, newTab } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data?._content_type_uid,
      data?.uid,
      l,
      'meetings.meeting',
    );

    setNewData(info.entry);
  }

  // Sorting between upcoming and past meetings pulled from ContentStack
  const now = new Date();
  let upcomingMeetings = [];
  let pastMeetings = [];

  if (newData?.meetings) {
    upcomingMeetings = newData.meetings
      .map((meetingGroup) =>
        meetingGroup.meeting.filter((meeting) => new Date(meeting.date) > now),
      )
      .flat();

    pastMeetings = newData.meetings
      .map((meetingGroup) =>
        meetingGroup.meeting.filter((meeting) => new Date(meeting.date) <= now),
      )
      .flat();
  }

  const sortedUpcomingMeetings = upcomingMeetings.sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  );
  const sortedPastMeetings = pastMeetings.sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  );
  const recentPastMeetings = sortedPastMeetings.slice(0, 3);

  function getAbbreviatedMonth(date) {
    const months = [
      'Jan.',
      'Feb.',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug.',
      'Sept.',
      'Oct.',
      'Nov.',
      'Dec.',
    ];
    return months[date.getMonth()];
  }

  return (
    <Box
      maxW={'1440px'}
      px={{ base: '16px', md: '24px', lg: '60px' }}
      mx={'auto'}
    >
      <Box
        marginTop={{ lg: '32px', md: '32px', sm: '0px' }}
        h="1px"
        w={{ md: '100%', sm: '0%' }}
        backgroundColor="#000000"
        opacity="25%"
        alignSelf="center"
        justifySelf="center"
        maxW={'1185px'}
      ></Box>
      {newData && (
        <Box
          display="flex"
          flexDir={{ md: 'row', sm: 'column' }}
          justifyContent={{ lg: 'flex-start', md: 'space-between' }}
          gap={{ lg: '90px', md: 'none', sm: '30px' }}
          marginY="32px"
        >
          <Box
            display="flex"
            flexDir="column"
            gap={{ md: '16px', base: '8px' }}
            maxW={{ lg: '350px', md: '40%' }}
            paddingX={{ md: 'none', base: '30px' }}
            alignItems={{ md: 'flex-start', base: 'center' }}
            textAlign={{ md: 'start', base: 'center' }}
          >
            <FontAwesomeIcon
              fontSize="5vh"
              color="#5E9C44"
              icon={solid('calendar-check')}
            />
            <Heading
              fontWeight="700"
              fontSize={{ lg: '40px', base: '30px' }}
              marginTop={{ md: 'none', base: '5px' }}
            >
              {newData.title_text}
            </Heading>
            <Box
              h="1px"
              w={{ md: '139px', sm: '139px' }}
              backgroundColor="#000000"
              opacity="25%"
            ></Box>
            <Text
              fontWeight="500"
              fontSize={{ lg: '20px', base: '18px' }}
              marginTop={{ md: 'none', base: '15px' }}
              marginBottom={{ md: 'none', base: '15px' }}
            >
              {newData.description_text}
            </Text>
            <Box>
              {newData.cta_button?.button_link?.title && (
                <>
                  {newData.cta_button?.external_link ? (
                    <Button
                      size={newData.cta_button?.button_size?.toLowerCase()}
                      variant={newData.cta_button?.button_type?.toLowerCase()}
                      as={Link}
                      href={newData.cta_button?.button_link?.href}
                      target={
                        newData.cta_button?.new_window
                          ? '_blank'
                          : newTab
                          ? newTab
                          : '_self'
                      }
                      gap="10px"
                    >
                      {newData.cta_button?.button_link?.title}
                      <FontAwesomeIcon icon={solid('up-right-from-square')} />
                    </Button>
                  ) : (
                    <Button
                      size={newData.cta_button?.button_size?.toLowerCase()}
                      variant={newData.cta_button?.button_type?.toLowerCase()}
                      as={NavLink}
                      to={newData.cta_button?.button_link?.href}
                      target={
                        newData.cta_button?.new_window
                          ? '_blank'
                          : newTab
                          ? newTab
                          : '_self'
                      }
                    >
                      {newData.cta_button?.button_link?.title}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDir="column"
            gap="32px"
            w={{ lg: '745px', md: '55%' }}
          >
            {upcomingMeetings.length > 0 ? (
              <Box>
                <Heading marginBottom="16px" fontSize="25px" fontWeight="700">
                  Upcoming
                </Heading>
                {sortedUpcomingMeetings.map((meetingList, i) => {
                  return (
                    <Box
                      key={i}
                      className={
                        i === sortedUpcomingMeetings.length - 1
                          ? 'LastMeeting Hoverable'
                          : 'Hoverable'
                      }
                      borderTop="1px solid #BFBFBF"
                      borderLeft="1px solid #BFBFBF"
                      borderRight="1px solid #BFBFBF"
                      _hover={{
                        bg: '#F7F7F7',
                      }}
                      minH="150px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      paddingX="48px"
                      gap="8px"
                    >
                      <Box display="flex" gap="10px" alignItems="center">
                        <Text
                          fontWeight="400"
                          fontSize={{ md: '18px', base: '12px' }}
                          opacity=".5"
                        >
                          {` ${new Date(meetingList.date)
                            .getUTCDate()
                            .toString()
                            .padStart(2, '0')} ${getAbbreviatedMonth(
                            new Date(meetingList.date),
                          )} ${new Date(meetingList.date).getFullYear()}`}
                        </Text>
                        <Box
                          h="20px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="1px solid #8C0A39"
                          borderRadius="20px"
                          paddingX="14px"
                        >
                          <Text
                            textColor="#8C0A39"
                            fontWeight="600"
                            fontSize="14px"
                          >
                            {meetingList.online_in_person.toUpperCase()}
                          </Text>
                        </Box>
                      </Box>
                      <Text
                        fontWeight="700"
                        fontSize={{ lg: '20px', sm: '18px' }}
                      >
                        {meetingList.meeting_title}
                      </Text>
                      <Box display="flex" gap="10px" alignItems="center">
                        <Link
                          fontWeight="600"
                          fontSize={{ lg: '16px', base: '12px' }}
                          href={meetingList.rsvp_link?.href}
                        >
                          {meetingList.rsvp_link?.title}
                        </Link>
                        <FontAwesomeIcon icon={solid('arrow-right-long')} />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box>
                <Heading
                  marginBottom={{ lg: '16px', base: '12px' }}
                  fontSize="25px"
                  fontWeight="700"
                >
                  Upcoming
                </Heading>
                <Text fontWeight="500" fontSize={{ lg: '20px', base: '18px' }}>
                  No upcoming meetings scheduled at this time
                </Text>
              </Box>
            )}
            {pastMeetings.length > 0 && (
              <Box>
                <Heading marginBottom="12px" fontSize="25px" fontWeight="700">
                  Past
                </Heading>
                {recentPastMeetings.map((meetingList, i) => {
                  return (
                    <Box
                      key={i}
                      className={
                        i === recentPastMeetings.length - 1 ? 'LastMeeting' : ''
                      }
                      borderTop="1px solid #BFBFBF"
                      borderLeft="1px solid #BFBFBF"
                      borderRight="1px solid #BFBFBF"
                      paddingY="24px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      paddingX="48px"
                      gap="8px"
                    >
                      <Box display="flex" gap="10px" alignItems="center">
                        <Text
                          fontWeight="400"
                          fontSize={{ md: '18px', base: '12px' }}
                          opacity=".5"
                        >
                          {` ${new Date(meetingList.date)
                            .getDate()
                            .toString()
                            .padStart(2, '0')} ${getAbbreviatedMonth(
                            new Date(meetingList.date),
                          )} ${new Date(meetingList.date).getFullYear()}`}
                        </Text>
                        <Box
                          h="20px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="1px solid #8C0A39"
                          borderRadius="20px"
                          paddingX="14px"
                        >
                          <Text
                            textColor="#8C0A39"
                            fontWeight="600"
                            fontSize="14px"
                          >
                            {meetingList.online_in_person.toUpperCase()}
                          </Text>
                        </Box>
                      </Box>
                      <Text
                        fontWeight="700"
                        fontSize={{ lg: '20px', sm: '18px' }}
                      >
                        {meetingList.meeting_title}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

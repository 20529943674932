import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import api from '../../../utils/api';
// import RichTextEditorMB from '../rte';
import AdBanner from './banner';

export default function AdBlock(props) {
  const { data } = props;
  const [adInfo, setAd] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntry(data?._content_type_uid, data?.uid);

    setAd(info.entry);
  }
  return (
    <>
      {adInfo?.ad_block?.ad ? (
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={'1rem'}
          my={'1rem'}
          mx={{ base: '0', md: '1rem', xl: 'auto' }}
          maxW={'1440px'}
        >
          {adInfo?.ad_block?.ad?.map((ad, i) => {
            return (
              <Flex key={i} flexGrow={1}>
                <AdBanner data={ad} />
              </Flex>
            );
          })}
        </Flex>
      ) : null}
    </>
  );
}

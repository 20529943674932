import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import {
  Box,
  Text,
  Stack,
  Flex,
  Heading,
  Link,
  Image,
  useTheme,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import LazyBackground from '../../lazyLoading';
import FallbackGrey from '../../../assets/vectors/fallbackGrey.svg';
// import RightArrow from '../../icons/rightArrow';
export default function Cards(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const theme = useTheme();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data._content_type_uid,
      data.uid,
      l,
      'card.theme',
    );
    setNewData(info.entry);
  }
  return (
    <Box
      maxH="100%"
      position="relative"
      maxW={'1440px'}
      mx={'auto'}
      py="10px"
      px={{ base: '16px', md: '24px', lg: '60px' }}
    >
      <Flex
        // border={'orange dotted 3px'}
        flexDirection="row"
        flexWrap={{ md: 'none', base: 'wrap' }}
        justifyContent="center"
        gap={{ base: '16px', lg: '20px' }}
        mx={'auto'}
      >
        {newData?.card?.map((card, i) => {
          const numCards = newData.card.length;
          if (!card) return null;
          return (
            <LazyBackground
              role="group"
              //   border={'pink dashed 3px'}
              as={Link}
              href={card.url_path?.href}
              key={i}
              display={'flex'}
              color="white"
              flexDirection="column"
              _transition="all 5s ease"
              _hover={{
                textDecoration: 'none',
                transform: 'scale(1.01)',
              }}
              background={{
                md: 'none',
                base: `${card.card_background_color}`,
              }}
              flex={{
                xl: '1 1 0',
                lg: '1 1 0',
                md: '0 1 auto',
                base: '0 1 auto',
              }}
              // minH={{ lg: '570px', md: '462px', base: '194px' }}
              w={{
                xl: '0',
                lg: '0',
                md: numCards === 3 ? '31%' : '48%',
                base: '100%',
              }}
              borderRadius={{
                lg: '20px 20px 0 0',
                md: '20px 20px 0 0',
                base: '20px',
              }}
              padding={{ lg: '0', md: '0', base: '4' }}
            >
              <Box
                flexGrow={{ lg: '2', md: '2', base: '0' }}
                borderTopRadius={{ xl: '20px', lg: '20px', md: '20px' }}
                // minH={{ lg: '75% -.25em', md: '30%', base: 'none' }}
                maxH={{ lg: '310px', md: '222px', base: 'none' }}
                minH={{ lg: '310px', md: '222px', base: 'none' }}
                width="100%"
                opacity="1"
                // border="yellow 1px solid"
              >
                <Image
                  // height={{ lg: '310px', md: '223px', base: 'none' }}
                  width="100%"
                  height="100%"
                  alt={card.alt_text}
                  src={`${card.image?.url}?width=400`}
                  display={{ md: 'block', base: 'none' }}
                  borderTopRadius={{ xl: '20px', lg: '20px', md: '20px' }}
                  objectFit="cover"
                  loading="lazy"
                  fallbackSrc={FallbackGrey}
                />
              </Box>
              <Stack
                px={{ lg: 4, md: 3, base: 'none' }}
                py={{ lg: 4, md: 3, base: 'none' }}
                justifyContent={{
                  lg: 'space-between',
                  md: numCards === 3 ? 'space-between' : 'space-around',
                  base: 'space-between',
                }}
                background={card.card_background_color}
                color={
                  card.card_background_color === '#F0F9EA' ||
                  card.card_background_color === '#F4F4F4'
                    ? theme.colors.BlackText
                    : theme.colors.White
                }
                _hover={{ textDecoration: 'none' }}
                _groupHover={{ textDecoration: 'none' }}
                // minH={{ lg: '25% +0.25em', md: '30%', base: 'none' }}
                minH={{
                  lg: '226px',
                  md: numCards === 3 ? '280px' : '240px',
                  base: '194px',
                }}
                maxH={{}}
                w={'100%'}
              >
                <Heading
                  as={'h2'}
                  textAlign="start"
                  fontSize={{ md: '3xl', base: '22px' }}
                  fontWeight="700"
                  _groupHover={{ textDecoration: 'underline' }}
                >
                  {card.heading_title}
                </Heading>
                <Text
                  fontSize={{ md: '18px', base: '16px' }}
                  lineHeight="20px"
                  fontWeight="400"
                  _groupHover={{ textDecoration: 'none' }}
                  textDecoration="none"
                  textAlign="start"
                >
                  {card.description}
                </Text>
                <Flex gap={'2%'} align={'center'}>
                  <Text
                    fontWeight="600"
                    alignSelf="start"
                    _groupHover={{
                      textDecoration: 'underline',
                      color:
                        card.card_background_color === '#F0F9EA' ||
                        card.card_background_color === '#F4F4F4'
                          ? theme.colors.BlackText
                          : theme.colors.White,
                    }}
                    // border={'green solid 2px'}
                  >
                    {card.url_path?.title}
                  </Text>
                  <FontAwesomeIcon
                    icon={solid('arrow-right-long')}
                    color={
                      card.card_background_color === '#F0F9EA' ||
                      card?.card_background_color === '#F4F4F4'
                        ? theme.colors.BlackText
                        : theme.colors.White
                    }
                  />
                  {/* <RightArrow
                    fasize={'fa-large'}
                    color={{
                      md: 'white',
                      base: 'white',
                    }}
                    _hover={{ textDecoration: 'none' }}
                  /> */}
                </Flex>
              </Stack>
            </LazyBackground>
          );
        })}
      </Flex>
    </Box>
  );
}

import { useEffect } from 'react';

const GoogleTagManagerNoScript = () => {
  useEffect(() => {
    // Create start and end comment nodes
    const startComment = document.createComment(
      'Google Tag Manager (noscript)',
    );
    const endComment = document.createComment(
      'End Google Tag Manager (noscript)',
    );

    const id =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'GTM-M79VV6'
        : 'GTM-P6C6HT5';

    // Create the noscript element
    const noScript = document.createElement('noscript');

    // Create the iframe element
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    // Append the iframe to the noscript element
    noScript.appendChild(iframe);

    // Insert the start comment, noscript element, and end comment as the first nodes in the body
    document.body.insertBefore(endComment, document.body.firstChild); // Insert end comment first (it will be at the top otherwise)
    document.body.insertBefore(noScript, document.body.firstChild); // Then insert the noscript (pushes end comment down)
    document.body.insertBefore(startComment, document.body.firstChild); // Finally, insert start comment (pushes everything else down)
  }, []);

  return null;
};

export default GoogleTagManagerNoScript;

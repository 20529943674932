import { Field } from 'formik';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import EnvelopeIcon from 'components/icons/envelope/envelope';
const Email = (props) => {
  const { item, errorOrValidColor, errorOrValidText } = props;
  return (
    <Field as={InputGroup}>
      <InputLeftElement pointerEvents="none">
        <EnvelopeIcon color={errorOrValidText} />
      </InputLeftElement>
      <Input
        name={item.field_name}
        id={item.field_name}
        type="email"
        placeholder={item.placeholder_text}
        variant="outline"
        focusBorderColor={errorOrValidColor}
        borderColor={errorOrValidColor}
        isRequired={item.required}
      />
    </Field>
  );
};

export default Email;

import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';
import MessageAlert from '..';
import { Box } from '@chakra-ui/react';

export default function AllAlert(props) {
  const { l } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getAllEntries('message_alert', l);

    setData(info[0]);
  }

  return (
    <Box>
      {data ? (
        <Box>
          {data.active && data.page_display !== 'Home only' ? (
            <MessageAlert />
          ) : null}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
}

import { Box, Flex, Hide, Show } from '@chakra-ui/react';
import React from 'react';
import { VCard, HCard } from '../cards';

export function TwoCard(props) {
  const { data, contained } = props;

  return (
    <Flex
      gap={'1rem'}
      w="100%"
      maxW="1140px"
      mx={'auto'}
      px={contained ? 0 : '1rem'}
      py={'.5rem'}
      direction={{ base: 'column', md: 'row' }}
      justify={{ base: 'start', md: 'center' }}
      minH={'579px'}
      align={{ base: 'center', md: 'initial' }}
    >
      {data?.map((card, i) => {
        return (
          <Flex key={i}>
            <VCard data={card} />
          </Flex>
        );
      })}
    </Flex>
  );
}
export function ThreeCard(props) {
  const { data, reverse, contained } = props;

  return (
    <Flex
      gap={'1rem'}
      w="100%"
      h={'100%'}
      maxW="1140px"
      mx={'auto'}
      justify={'space-evenly'}
      px={contained ? 0 : '1rem'}
      py={'.5rem'}
      direction={
        reverse
          ? { base: 'column', lg: 'row-reverse' }
          : { base: 'column', lg: 'row' }
      }
      // border={'solid teal 2px'}
    >
      <Hide below={'lg'}>
        <Flex
          gap={'1rem'}
          direction={
            reverse
              ? { base: 'column', lg: 'row-reverse' }
              : { base: 'column', lg: 'row' }
          }
        >
          {data && <VCard data={reverse ? data[2] : data[0]} />}
          <Flex direction={'column'} gap={'1rem'}>
            {reverse
              ? data?.slice(0, 2).map((card, i) => {
                  return (
                    <Flex key={i} flexGrow={1}>
                      <HCard data={card} />
                    </Flex>
                  );
                })
              : data?.slice(1, 3).map((card, i) => {
                  return (
                    <Flex key={i} flexGrow={1}>
                      <HCard data={card} />
                    </Flex>
                  );
                })}
          </Flex>
        </Flex>
      </Hide>
      <Show below={'lg'} gap={'1rem'}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={'1rem'}
          align={{ base: 'center', md: 'start' }}
          mx={'auto'}
        >
          {data?.slice(0, 2).map((card, i) => {
            return (
              <Flex key={i} alignSelf={'stretch'}>
                <VCard data={card} />
              </Flex>
            );
          })}
        </Flex>
        <Flex alignSelf={{ base: 'center', md: 'start' }}>
          {data && (
            <>
              <Hide below={'md'}>
                <HCard data={data[2]} />
              </Hide>
              <Show below={'md'}>
                <VCard data={data[2]} />
              </Show>
            </>
          )}
        </Flex>
      </Show>
    </Flex>
  );
}

export function FourCard(props) {
  const { data } = props;
  return (
    <Flex direction={'column'}>
      <Hide below="lg">
        <ThreeCard data={data?.slice(0, 3)} />
        <Flex px={'1rem'} py={'.5rem'} maxW="1140px" mx={'auto'}>
          {data && <HCard data={data[3]} />}
        </Flex>
      </Hide>
      <Show below="lg">
        <Box mx={'auto'} px={'1rem'} py={'.5rem'}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: 'initial', md: '1rem' }}
          >
            {data?.slice(0, 2).map((card, i) => {
              return (
                <Flex key={i} my={'.5rem'}>
                  <VCard data={card} />
                </Flex>
              );
            })}
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: 'initial', md: '1rem' }}
          >
            {data?.slice(2, 4).map((card, i) => {
              return (
                <Flex key={i} my={'.5rem'}>
                  <VCard data={card} />
                </Flex>
              );
            })}
          </Flex>
        </Box>
      </Show>
    </Flex>
  );
}

export function FiveCard(props) {
  const { data, contained } = props;
  return (
    <Flex
      direction={'column'}
      gap={'1rem'}
      w="100%"
      maxW="1140px"
      mx={'auto'}
      px={contained ? 0 : '1rem'}
      py={'.5rem'}
    >
      <Hide below="lg">
        <Flex gap={'1rem'}>
          {data?.slice(0, 3).map((card, i) => {
            return (
              <Box key={i}>
                <VCard data={card} />
              </Box>
            );
          })}
        </Flex>
        <Flex gap={'1rem'}>
          {data?.slice(3, 5).map((card, i) => {
            return (
              <Box key={i}>
                <HCard data={card} />
              </Box>
            );
          })}
        </Flex>
      </Hide>
      <Show below="lg">
        <Flex direction={'column'} gap={'-1rem'}>
          <ThreeCard data={data?.slice(0, 3)} />
          <TwoCard data={data?.slice(3, 5)} />
        </Flex>
      </Show>
    </Flex>
  );
}

export function SixCard(props) {
  const { data } = props;
  return (
    <Flex direction={'column'}>
      <ThreeCard data={data?.slice(0, 3)} />
      <ThreeCard data={data?.slice(3, 6)} reverse={true} />
    </Flex>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, Flex, Heading, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import api from '../../../../utils/api';
export default function LatestResources(props) {
  const { listTitle } = props;
  const { l } = useParams();
  const [articles, setArticle] = useState([]);
  const limit = 5;
  useEffect(() => {
    setArticle([]);
    fillAritcle(limit);
  }, []);

  // This will pull most recent & fill with resources
  async function fillAritcle(limit) {
    const info = await api.getLimitedEntries(
      'resource_entry',
      // number of stories to fetch
      limit,
      // descending order by date.
      'article_details.date_updated',
      // locale
      l,
    );
    // Checking to see if article is in CS array before adding
    info.map((entry, i) => {
      if (
        entry.uid === articles[0]?.uid ||
        entry.uid === articles[1]?.uid ||
        entry.uid === articles[2]?.uid ||
        entry.uid === articles[3]?.uid
      ) {
        return null;
      }

      return setArticle((current) => [...current, entry]);
    });
  }
  return (
    <Flex
      direction={'column'}
      // border={'red dotted 2px'}
      w={{ base: '100%', lg: '30%' }}
      pl={'30px'}
      my={{ base: '54px', md: '66px', lg: 'initial' }}
      h={{ lg: '90%' }}
    >
      <Flex
        mx={{ base: 'auto', md: 'initial', lg: 'auto' }}
        // mx={{ md: 'auto' }}

        direction={{ base: 'column', md: 'row', lg: 'column' }}
        flexWrap={'wrap'}
        // border={'yellow dashed 2px'}
        h={'100%'}
        gap={{ base: '24px', md: '18px', large: '24px' }}
        align={{ large: 'center' }}
        justify={{ md: 'space-between' }}
      >
        <Heading
          as={'h3'}
          fontWeight={'700'}
          fontSize={'1rem'}
          lineHeight={{ base: '1.2rem' }}
          mb={'14.7px'}
          w={{ base: '100%', md: '33%', lg: '100%' }}
          mx={{ lg: 'auto' }}
          // border={'teal dashed 2px'}
        >
          {listTitle?.toUpperCase()}
        </Heading>
        {articles?.map((articles, i) => {
          return (
            <Flex
              key={i}
              borderLeft={'solid 3px'}
              borderLeftColor={'PrimaryGreen'}
              px={'20px'}
              mb={'4%'}
              minH={'58px'}
              mx={{ lg: 'auto' }}
              w={{ base: '100%', md: '33%', lg: '100%' }}
              // border={'gray dashed 2px'}
            >
              <Heading
                as={'h3'}
                noOfLines={{ base: 3, md: 2, lg: 3 }}
                fontWeight={'700'}
                fontSize={'1rem'}
                lineHeight={'1.313rem'}
                // letterSpacing={'-0.02em'}
              >
                <Link href={articles?.url}>
                  <Text noOfLines={2}>
                    {' '}
                    {articles?.article_details?.headline}
                  </Text>
                </Link>
              </Heading>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}

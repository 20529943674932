import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  useDisclosure,
  AspectRatio,
  Box,
  Flex,
} from '@chakra-ui/react';

import PlayIcon from '../../../../assets/vectors/playIcon.svg';
export default function VideoModal(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = props;
  return (
    <Flex align={'stretch'}>
      <Box
        bgImage={data?.thumbnail?.url}
        bgSize={'cover'}
        // minH={'314px'}
        h={'100%'}
        onClick={onOpen}
        mx={'auto'}
        style={{ cursor: 'pointer' }}
        bgPosition={'center'}
        bgRepeat={'no-repeat'}
        borderRadius={'30px'}
        w={'100%'}
      >
        <Flex
          h={'inherit'}
          borderRadius={'30px'}
          bgColor={'rgba(35,35,35,.45)'}
          // border={'cyan solid 3px'}
          // minH={'314px'}
          align={'center'}
          justify={'center'}
          w={'100%'}
        >
          <Image h={'64px'} w={'64px'} m={'50px'} src={PlayIcon} />
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW={'75%'}>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio ratio={16 / 9} my={'3%'}>
              <video autoPlay muted controls>
                <source src={data?.video_url} type="video/mp4" />
              </video>
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

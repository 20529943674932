import { Box, Flex, Heading, Text } from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../utils/api';
import FaIcon from '../../../icons/faIcon';
import logger from 'utils/logger';

export default function InteriorHeader(props) {
  const { data, alt } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data._content_type_uid,
      data.uid,
      l,
      'theme',
    );
    setNewData(info.entry);
  }
  logger.log('NEW DATA in interior header', newData);

  const imageUrl = newData?.background?.image?.url;
  const opacity = newData?.background?.opacity;
  return (
    <Box
      as={Flex}
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      backgroundColor={newData?.theme[0]?.colors?.background_color}
      position="relative"
      zIndex="-2"
    >
      {imageUrl && (
        <Box
          as="span"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundImage={`url(${imageUrl})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          opacity={opacity !== false ? '0.2' : 'none'}
          zIndex="-1" // Place the image behind the content
        />
      )}
      <Box
        my={'32px'}
        mx={{ lg: 60, md: 20, base: 5 }}
        gap="16px"
        as={Flex}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        textAlign="center"
      >
        <Box>
          <Heading
            fontWeight="700"
            fontSize={{ base: '25px', md: '32px', lg: '36px' }}
            color={newData?.theme[0]?.colors?.text_color}
            maxWidth="700px"
            display="inline"
          >
            {newData?.header_title}
          </Heading>

          {newData?.include_icon && newData?.icon?.font_awesome && (
            <FaIcon
              px="16px"
              pb={{ base: '2px', md: '5px', lg: '5px' }}
              data-icon={alt}
              alt={newData.icon?.alt_text}
              faIcon={`fa-${newData?.icon?.font_awesome
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              fasize={'fa-2xl'}
              color={
                newData?.icon?.icon_color
                  ? newData?.icon?.icon_color
                  : newData?.theme[0]?.colors?.text_color
                  ? newData?.theme[0]?.colors?.text_color
                  : '#ffffff'
              }
            />
          )}
        </Box>
        <Text
          fontWeight="400"
          fontSize={{ base: '16px', md: '20px', lg: '22px' }}
          lineHeight={{ base: '22px', md: '27px', lg: '30px' }}
          // letterSpacing="-0.05em"
          color={newData?.theme[0]?.colors?.text_color}
        >
          {newData?.description}
        </Text>
      </Box>
    </Box>
  );
}

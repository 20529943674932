import { Flex, Heading, Link, Text, Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export function VCard(props) {
  const { data } = props;
  return (
    <Flex
      direction={'column'}
      maxW={'461px'}
      minH={{ sm: '425px', md: '579px', lg: '596px' }}
      h={'100%'}
      border={'solid #cccccc 1px'}
      borderRadius={'20px'}
      gap={'.625rem'}
      p={{ base: '.75rem', md: '1rem' }}
      boxShadow={'0px 24px 34px rgba(0, 0, 0, 0.03)'}
      flexGrow={1}
    >
      <Image
        src={`${data?.image?.url}?width=400`}
        alt={data?.image_alt_text}
        h={{ base: '252px', md: '319px' }}
        borderRadius={'20px 20px 0px 0px'}
        fit="fill"
      />
      <Flex
        direction={'column'}
        h={'100%'}
        flexGrow={1}
        justify={'space-around'}
        gap={'.625rem'}
      >
        <Heading
          fontSize={'1rem'}
          fontWeight={{ base: '600', lg: '800' }}
          lineHeight={'1.25rem'}
        >
          {data?.title}
        </Heading>
        <Text
          fontSize={{ base: '.875rem', md: '1.25rem' }}
          fontWeight={'400'}
          lineHeight={{ base: '1.25rem', md: '2.125rem' }}
        >
          {data?.description}
        </Text>
        <Flex gap={'1rem'} align="center">
          <Link
            as={RouterLink}
            to={data?.link?.href}
            fontSize={{ lg: '1rem' }}
            fontWeight={{ base: '600', lg: '800' }}
            lineHeight={'1.25rem'}
            color={'#006955'}
          >
            {data?.link?.title}
          </Link>
          <FontAwesomeIcon icon={solid('arrow-right-long')} color={'#006955'} />
        </Flex>
      </Flex>
    </Flex>
  );
}

export function HCard(props) {
  const { data } = props;
  return (
    <Flex
      border={'solid #cccccc 1px'}
      borderRadius={'20px'}
      gap={'1rem'}
      p={'1rem'}
      flexGrow={1}
    >
      <Image
        src={`${data?.image?.url}?width=400`}
        alt={data?.image_alt_text}
        h={'235px'}
        w={'235px'}
        borderRadius={'20px 0px 0px 20px'}
        fit="fill"
      />
      <Flex direction={'column'} justify={'space-around'}>
        <Heading fontSize={'1rem'} fontWeight={'700'} lineHeight={'1.25rem'}>
          {data?.title}
        </Heading>
        <Text
          fontSize={{ lg: '1.25rem' }}
          fontWeight={'400'}
          lineHeight={'2.125rem'}
        >
          {data?.description}
        </Text>
        <Flex gap={'1rem'} align="center">
          <Link
            as={RouterLink}
            to={data?.link?.href}
            fontSize={{ lg: '1rem' }}
            fontWeight={{ base: '600', lg: '800' }}
            lineHeight={'1.25rem'}
            color={'#246C60'}
          >
            {data?.link?.title}
          </Link>
          <FontAwesomeIcon icon={solid('arrow-right-long')} color={'#246C60'} />
        </Flex>
      </Flex>
    </Flex>
  );
}

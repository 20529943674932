import React from 'react';
import CpeButton from './cpeButton';
import CpeEligible from '../../../assets/cpeBadges/CPE_eligible.png';
import { Flex, Box, Link, Text, Image } from '@chakra-ui/react';

// cpeSection component displays a section at the bottom of certain content types indicating eligibility for CPE credits
export default function CpeSection(props) {
  const { ct } = props;

  return (
    // main container for the CPE section with overall styling
    <Flex
      direction={'column'}
      gap={'16px'}
      borderTop={
        ct === 'resource_entry' ? 'solid #ABABAB 1px' : 'solid black 1px'
      }
      w={'100%'}
      my={'20px'}
      pt={'20px'}
      pb={'4px'}
      px={'4px'}
      textAlign={'center'}
      alignSelf={'center'}
    >
      {/* inner container to arrange badge, text, and button based on screen size */}
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        align={{ base: 'stretch', lg: 'center' }}
        justify={{ base: 'center', lg: 'space-between' }}
        w={'100%'}
        bg={'#F4F4F4'}
        p={'24px'}
        mx={'auto'}
      >
        {/* container for the badge and the text */}
        <Flex
          align={'center'}
          w={{ base: '100%', lg: 'auto' }}
          mb={{ base: '16px', lg: '0' }}
        >
          {/* box to hold the badge image */}
          <Box w={'64px'} h={'64px'} flexShrink={0}>
            <Image
              src={CpeEligible}
              alt={'CPE credit badge'}
              w={'64px'}
              h={'64px'}
            />
          </Box>
          {/* text indicating CPE eligibility */}
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            color={'#1E1E1E'}
            fontStyle={'italic'}
            textAlign={{ base: 'left', lg: 'left' }}
            ml={{ base: '16px', lg: '24px' }}
            flex={{ base: '1 1 auto', lg: '1 1 auto' }}
          >
            This article is eligible for Continuing Professional Education
            credits. Please self-submit according to CPE policy guidelines.
          </Text>
        </Flex>
        {/* container for the CPE button */}
        <Box
          w={{ base: '100%', lg: 'auto' }}
          textAlign={'center'}
          mt={{ base: '16px', lg: '0' }}
        >
          <CpeButton />
        </Box>
      </Flex>

      {/* link for contributor guidelines, shown only if the content type is not 'resource_entry' */}
      {ct !== 'resource_entry' && (
        <Flex
          fontSize={'.875rem'}
          lineHeight="1.25rem"
          fontWeight={'600'}
          color={'DarkGreen'}
          align={'center'}
          mt={'24px'}
        >
          <Link href={'/news/write-for-us'}>
            Interested in writing for us? Visit our Contributor Guidelines{' '}
            <Text as={'span'} whiteSpace={'nowrap'}>
              Page <Box className="fa-solid fa-arrow-right-long" ml={'16px'} />
            </Text>
          </Link>
        </Flex>
      )}
    </Flex>
  );
}

import React from 'react';

import QuestionMarkIcon from 'components/icons/questionMark/questionMark';

import { ReactComponent as InfoCircle } from 'components/icons/infoCircle/infoCircle.svg';
import { ReactComponent as XIcon } from 'components/icons/x/x.svg';
import { Box, Flex, Tooltip, FormLabel } from '@chakra-ui/react';

const BaseField = (props) => {
  const { item, children, labelColor, errors, touched, theme } = props;

  const lineWidth =
    item.field_width === 'Half' ? { base: '100%', lg: '50%' } : '100%';
  const isRequired = item.required === true ? true : false;

  return (
    <Flex
      flexDirection="column"
      width={lineWidth}
      boxSizing="border-box"
      px="16px"
    >
      {item.field_name && (
        <Flex gap="4px">
          <FormLabel fontSize="14px" mr="0px" color={labelColor} mb="4px">
            {isRequired ? (
              <>
                <Box display="inline" color={theme.colors.Raspberry}>
                  *{' '}
                </Box>
                {item.field_name}
              </>
            ) : (
              item.field_name
            )}
          </FormLabel>
          {item.field_description && (
            <Tooltip placement="top" label={item.field_description}>
              <Box>
                <QuestionMarkIcon />
              </Box>
            </Tooltip>
          )}
        </Flex>
      )}
      {children}
      {item.field_name &&
        ((errors[item.field_name] && item.field_type?.includes('checkbox')) ||
        (errors[item.field_name] && touched[item.field_name]) ? (
          <Flex
            fontWeight="700"
            fontSize="12px"
            color={theme.colors.Raspberry}
            alignItems="center"
            gap="4px"
            paddingTop="4px"
          >
            <XIcon fill={theme.colors.Raspberry} width="10px" height="10px" />
            <Box>{errors[item.field_name]}</Box>
          </Flex>
        ) : item.field_info ? (
          <Flex
            fontWeight="700"
            fontSize="12px"
            color={theme.colors.BlackText}
            alignItems="center"
            gap="4px"
            paddingTop="4px"
          >
            <InfoCircle
              fill={theme.colors.BlackText}
              width="12px"
              height="12px"
            />
            <Box>{item.field_info}</Box>
          </Flex>
        ) : null)}
    </Flex>
  );
};
export default BaseField;

import React, { useMemo } from 'react';
import { Flex, Box, Text, Image, Link, useTheme } from '@chakra-ui/react';
import Fallback from 'assets/vectors/fallbackGrey.svg';
import useTitleColor from 'hooks/useTitleColor';
import FaIcon from 'components/icons/faIcon';
import { formatDate } from './formatDate';
import logger from 'utils/logger';

const CustomResourceView = ({ result }) => {
  logger.log('CustomResourceView, has result:', !!result, result);
  if (!result) {
    return null;
  }
  const { published_dtm: publishedDtm } = result;
  logger.log('publishedDtm', { publishedDtm });

  const date = useMemo(() => {
    return formatDate(publishedDtm);
  }, [publishedDtm]);

  const { gated, type, original } = useMemo(() => {
    const includes = (element) => (result.tags || []).includes(element);
    return {
      gated: includes('internal_class=member_gated'),
      type: includes('internal_class=resource_article')
        ? 'Resource Article'
        : undefined,
      original: includes('internal_class=iapp_original'),
    };
  }, [...result.tags]);
  const theme = useTheme();
  const [color] = useTitleColor(type);
  return (
    <Flex
      key={result.objectID}
      as={Link}
      href={result.uri}
      border={`solid 1px ${theme.colors.Grey4}`}
      p="8px"
      justify={'space-between'}
      borderRadius={'20px'}
      w={{ base: '100%', lg: '48.5%' }}
      h={'174px'}
      _hover={{
        textDecor: 'none',
        boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.07)',
      }}
      role="group"
      mb={{ base: '20px', md: '16px', lg: '2%' }}
      position={'relative'}
    >
      {gated && (
        <Flex
          position="absolute"
          boxSize={'35px'}
          justify={'center'}
          align={'center'}
          right={'8px'}
          bgColor={'WhiteOpaque'}
        >
          <FaIcon faIcon={'fa-lock'} color={'PrimaryGreen'} />
        </Flex>
      )}
      <Flex
        direction={'column'}
        gap={'16px'}
        flex={1}
        // border={'solid hotpink 1px'}
        m={'8px'}
        justify={'start'}
      >
        <Flex
          // border={'solid lime 2px'}
          gap={'.02em'}
          flexWrap={'wrap'}
          whiteSpace={'nowrap'}
        >
          {type && (
            <Flex
              gap={'10px'}
              // flex={2}
              mr={'10px'}
              ml={'-16px'}
              align={'center'}
              // border={'solid 2px hotpink'}
            >
              <Box w={'15px'} h={'24px'} bgColor={color} />
              <Text
                fontFamily={'Lato'}
                fontWeight={'700'}
                fontSize={'.875rem'}
                lineHeight={'1.05rem'}
              >
                {type}
              </Text>
            </Flex>
          )}

          {original && (
            <Flex
              whiteSpace={'nowrap'}
              flex={1}
              maxW={'min-content'}
              color={'DarkGreen'}
              borderColor={'DarkGreen'}
              border={'solid 1px'}
              w={'max-content'}
              borderRadius={'20px'}
              textAlign={'center'}
              px={'16px'}
            >
              <Text
                letterSpacing={'.035em'}
                fontWeight={'600'}
                fontSize={'.875rem'}
                lineHeight={'1.188rem'}
              >
                IAPP ORIGINAL
              </Text>
            </Flex>
          )}
        </Flex>

        {/* <Text>{`${date.getDay()} ${date.getMonth()} ${date.getFullYear()}`}</Text> */}
        {date && (
          <Text
            color={'#4D4D4D'}
            fontWeight={'600'}
            fontSize={'.875rem'}
            lineHeight={'1.188rem'}
            letterSpacing={'.08em'}
          >
            {date}
          </Text>
        )}

        <Text
          fontWeight={'600'}
          fontSize={'1rem'}
          lineHeight={'1.688rem'}
          _groupHover={{ textDecor: 'underline' }}
          noOfLines={date ? 2 : 3}
          textOverflow={'ellipsis'}
        >
          {result.name}
        </Text>
      </Flex>
      <Flex
        // border={'red solid 2px'}
        h={'100%'}
        flex={0.75}
        maxW={'25%'}
        borderRadius="0 15px 15px 0"
      >
        {result.category === 'news_article' && (
          <Image
            src={result.imageUri}
            fallbackSrc={Fallback}
            fit={'cover'}
            borderRadius={'0 15px 15px 0'}
            //    loading={'lazy'}
          />
        )}
      </Flex>
    </Flex>
  );
};
export default CustomResourceView;

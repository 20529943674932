import React, { useState } from 'react';
import { Box, Link, Flex, useTheme } from '@chakra-ui/react';
import DropdownMenu from '../dropDown';
import { Link as RouterLink } from 'react-router-dom';

export default function ConferenceDesktopNav(props) {
  const { data } = props;
  const navLinks = data?.nav_links;

  const [selectedEntry, setSelectedEntry] = useState(0);

  const SubnavBar = () => {
    const theme = useTheme();
    const generalInfoIndex = navLinks?.findIndex(
      (navlink) => navlink?.link?.title.trim().toLowerCase() === 'general info',
    );

    const handleEntryClick = (index) => {
      setSelectedEntry(index);
    };

    return (
      <Flex pt={'32px'} justifyContent={'center'} alignItems={'flex-start'}>
        <Flex
          alignItems={'flex-start'}
          gap={'43px'}
          flexShrink={'0'}
          borderBottom={`1px solid ${theme.colors.Grey5}`}
        >
          {navLinks?.map((navlink, index) => (
            <Box
              role={'group'}
              key={index}
              px={4}
              py={2}
              bg={selectedEntry === index ? theme.colors.Grey1 : 'transparent'}
              borderBottom={
                selectedEntry === index
                  ? `4px solid ${theme.colors.PrimaryGreen}`
                  : 'none'
              }
              borderTopRadius="md"
              onClick={() => handleEntryClick(index)}
              _hover={{
                bg: 'Grey2',
                borderBottom:
                  selectedEntry !== index
                    ? `4px solid ${theme.colors.Grey2}`
                    : `4px solid ${theme.colors.PrimaryGreen}`,
              }}
              mr={2}
            >
              {generalInfoIndex === index ? (
                <DropdownMenu
                  data={data}
                  selectedEntry={selectedEntry}
                  index={index}
                />
              ) : (
                <Link
                  as={RouterLink}
                  to={{ pathname: navlink?.link?.href, state: selectedEntry }}
                  //   href={navlink?.link?.href}
                  fontSize={'20px'}
                  fontWeight={'700'}
                  lineHeight={'normal'}
                  color={
                    selectedEntry === index
                      ? theme.colors.White
                      : theme.colors.Grey1
                  }
                  _hover={{
                    textDecoration: 'none',
                    color:
                      selectedEntry !== index
                        ? theme.colors.White
                        : theme.colors.Grey1,
                  }}
                  _groupHover={{ color: 'white' }}
                  cursor={'default'}
                >
                  {navlink?.link?.title}
                </Link>
              )}
            </Box>
          ))}
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <SubnavBar />
    </>
  );
}

import {
  Box,
  // Flex,
  Hide,
  HStack,
  IconButton,
  Show,
  Skeleton,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { Carousel, CarouselSlide, useCarousel } from '../carousel';
import Card from '../carouselCard';
import useResize from '../../../../../hooks/useResize';

export const Gallery = (props) => {
  const { lgCards, mdCards, smCards, rootProps, width, background } = props;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
  });
  const [size, handleResize] = useResize();
  const hasPrevious = currentSlide !== 0;
  const hasNextLg = currentSlide < lgCards?.length - 1;
  const hasNextMd = currentSlide < mdCards?.length - 1;
  const hasNextSm = currentSlide < smCards?.length - 1;

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener('resize', handleResize);
  return (
    <Stack
      w={width ? { base: '100%', lg: '70%' } : '100%'}
      spacing="24px"
      {...rootProps}
      mx={'auto'}
    >
      {size && (
        <Box
          position="relative"
          sx={{
            _hover: {
              '> button': {
                display: 'inline-flex',
              },
            },
          }}
        >
          <Carousel ref={ref}>
            {size !== 'sm' &&
              lgCards?.map((slide, i) => (
                <CarouselSlide key={i}>
                  {slide.map((card, i) => {
                    return <Card key={i} data={card} fallback={<Skeleton />} />;
                  })}
                </CarouselSlide>
              ))}

            {size === 'sm' &&
              smCards?.map((card, i) => {
                return (
                  <CarouselSlide key={i}>
                    <Card data={card} fallback={<Skeleton />} />
                  </CarouselSlide>
                );
              })}
          </Carousel>

          {hasPrevious && (
            <CarouselIconButton
              background={background}
              pos="absolute"
              left={{ base: '-2', lg: '-4' }}
              top="40%"
              transform="translateY(-50%)"
              onClick={() => slider.current?.prev()}
              icon={<IoChevronBackOutline />}
              aria-label="Previous Slide"
            />
          )}
          <Hide below={'lg'}>
            {hasNextLg && (
              <CarouselIconButton
                background={background}
                pos="absolute"
                right={'0'}
                top="40%"
                transform="translateY(-50%)"
                onClick={() => slider.current?.next()}
                icon={<IoChevronForwardOutline />}
                aria-label="Next Slide"
              />
            )}
          </Hide>
          <Hide below={'md'}>
            <Hide above={'lg'}>
              {hasNextMd && (
                <CarouselIconButton
                  background={background}
                  pos="absolute"
                  right="0"
                  top="40%"
                  transform="translateY(-50%)"
                  onClick={() => slider.current?.next()}
                  icon={<IoChevronForwardOutline />}
                  aria-label="Next Slide"
                />
              )}
            </Hide>
          </Hide>
          <Show below="md">
            {hasNextSm && (
              <CarouselIconButton
                background={background}
                pos="absolute"
                right="-2"
                top="40%"
                transform="translateY(-50%)"
                onClick={() => slider.current?.next()}
                icon={<IoChevronForwardOutline />}
                aria-label="Next Slide"
              />
            )}
          </Show>
          <HStack
            width="full"
            justify="center"
            py="4"
            mt={{ base: '23px', md: '40px' }}
          >
            <Hide below="lg">
              {lgCards?.map((_, index) => (
                <Box
                  key={index}
                  borderRadius={'4px'}
                  minW={'86px'}
                  minH="6px"
                  bgColor={
                    background
                      ? currentSlide === index
                        ? '#FFFFFF'
                        : '#4D4D4D'
                      : currentSlide === index
                      ? '#000000'
                      : '#C6C6C6'
                  }
                />
              ))}
            </Hide>
            <Hide below="md">
              <Hide above="lg">
                {mdCards?.map((_, index) => (
                  <Box
                    key={index}
                    borderRadius={'4px'}
                    minW={'86px'}
                    minH="6px"
                    bgColor={currentSlide === index ? '#FFFFFF' : '#4D4D4D'}
                  />
                ))}
              </Hide>
            </Hide>
            <Show below="md">
              {smCards?.map((_, index) => (
                <Box
                  key={index}
                  borderRadius={'4px'}
                  minW={'43px'}
                  maxW={'86px'}
                  w={`${100 / smCards.length}%`}
                  minH="6px"
                  bgColor={currentSlide === index ? '#FFFFFF' : '#4D4D4D'}
                />
              ))}
            </Show>
          </HStack>
        </Box>
      )}
    </Stack>
  );
};
const CarouselIconButton = (props) => (
  <>
    <IconButton
      // display="none"
      fontSize={{ base: '2xl', lg: '5xl' }}
      isRound
      boxShadow="base"
      color={props.background ? 'Black' : '#E3E3E3'}
      h={{ base: '56px', lg: '90px' }}
      w={{ base: '56px', lg: '90px' }}
      bgColor={useColorModeValue(
        props.background ? '#F8f8f8' : '#1e1e1e',
        'gray.800',
      )}
      // useColorModeValue('#F8f8f8', 'gray.800')
      _hover={{
        bg: useColorModeValue('#D9D9D9', 'gray.700'),
        color: '#999999',
      }}
      _active={{
        bg: useColorModeValue('#999999', 'gray.600'),
      }}
      _focus={{
        boxShadow: 'inerhit',
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
      {...props}
    />
  </>
);

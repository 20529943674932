import React, { useState, useEffect } from 'react';
import { Checkbox, Text, Flex } from '@chakra-ui/react';
import { withSearch } from '@elastic/react-search-ui';

const CustomCheckBox = withSearch(
  ({ isLoading, addFilter, removeFilter, filters }) => ({
    isLoading,
    addFilter,
    removeFilter,
    filters,
  }),
)(
  ({
    group,
    choice,
    more,
    setTags,
    tags,
    addFilter,
    removeFilter,
    filters,
  }) => {
    const tagSlug = `${group.title.toLowerCase()}=${choice}`;
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
      // Check if the tag is selected when the component mounts
      setIsChecked(
        filters.some(
          (filter) =>
            filter.field === 'tags' && filter.values.includes(tagSlug),
        ),
      );
    }, [filters, tagSlug]);

    const handleCheckboxChange = (e) => {
      const isChecked = e.target.checked;
      setIsChecked(isChecked);

      if (isChecked) {
        setTags([...tags, tagSlug]);
        addFilter('tags', tagSlug);
      } else {
        setTags(tags.filter((x) => x !== tagSlug));
        removeFilter('tags', tagSlug);
      }
    };

    return (
      <Flex align="center" display={more ? 'flex' : 'none'}>
        <Checkbox
          pr={2}
          colorScheme={'green'}
          onChange={handleCheckboxChange}
          isChecked={isChecked}
        >
          <Text noOfLines={1}>{choice}</Text>
        </Checkbox>
      </Flex>
    );
  },
);

export default CustomCheckBox;

import { Box, Flex, Link, Image, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import * as Icons from 'react-icons/fa';
import FaIcon from '../../../icons/faIcon';
import RichTextEditorMB from '../../rte';
export default function Quad(props) {
  const { data, color } = props;
  const [cards, setCards] = useState();

  useEffect(() => {
    divideCards();
  }, []);

  function divideCards() {
    const one = data.slice(0, 2);
    const two = data.slice(2, 4);
    if (data.length > 3) {
      return setCards(() => [one, two]);
    }
    return setCards(() => [one]);
  }

  return (
    <Box bgColor={color?.replace(' ', '')} pt={'24px'} pb={'32px'}>
      <Flex
        direction={'column'}
        gap={{ base: '1rem', lg: '1.5rem' }}
        maxW={'1140px'}
        mx={{ base: '1rem', xl: 'auto' }}
      >
        {cards?.map((cards, i) => {
          return (
            <Flex
              key={i}
              direction={{ base: 'column', md: 'row' }}
              gap={{ base: '1rem', lg: '1.5rem' }}
              mx={{ base: '1rem', xl: 'auto' }}
            >
              {cards?.map((card, i) => {
                return (
                  <Flex
                    key={i}
                    direction={'column'}
                    border={'solid 1px #CCCCCC'}
                    bgColor={'#FFFFFF'}
                    borderRadius={'20px'}
                    minW={{ base: '100%', md: '49%' }}
                    minH={{ base: '288px', md: '378px', lg: '334px' }}
                    p={'1.5rem'}
                    gap={'16px'}
                  >
                    {(card.fa_icon || card.image?.url) && (
                      <Flex
                        boxSizing="border-box"
                        w={'60px'}
                        h={'60px'}
                        border={'solid 1px #CCCCCC'}
                        rounded="full"
                        justify={'center'}
                        align={'center'}
                        overflow={'hidden'}
                        mb={'8px'}
                      >
                        <>
                          {card.fa_icon ? (
                            <FaIcon
                              faIcon={`fa-${card.icon
                                .replaceAll(' ', '-')
                                .toLowerCase()}`}
                              fasize={'fa-2xl'}
                              alt={card.image_alt}
                              color={'PrimaryGreen'}
                            />
                          ) : (
                            <Image src={card.image?.url} alt={card.image_alt} />
                          )}
                        </>
                      </Flex>
                    )}

                    <Heading
                      fontSize={'1rem'}
                      fontWeight={'600'}
                      lineHeight={'1.25rem'}
                      letterSpacing={'.1em'}
                    >
                      {card.title?.toUpperCase()}
                    </Heading>

                    {/* <Text
                    fontSize={{ base: '.875rem', md: '1.25rem' }}
                    fontWeight={'400'}
                    lineHeight={{ base: '1.25rem', md: '2.125rem' }}
                    letterSpacing={'-.02em'}
                  >
                    {card.description_rte}
                  </Text> */}
                    {card.description_rte && (
                      <RichTextEditorMB
                        px={'initial'}
                        data={card.description_rte}
                        fontSize={{ base: '.875rem', md: '1.25rem' }}
                        fontWeight={'400'}
                        lineHeight={{ base: '1.25rem', md: '2.125rem' }}
                        letterSpacing={'-.02em'}
                        style={['card']}
                      />
                    )}

                    {card.cta_button?.button_link?.title && (
                      <Flex
                        align={'center'}
                        gap={'1rem'}
                        fontSize={'1rem'}
                        fontWeight={'600'}
                        lineHeight={'1.25rem'}
                        color={'DarkGreen'}
                      >
                        <Link href={card.cta_button?.button_link?.href}>
                          {card.cta_button?.button_link?.title}
                        </Link>
                        <FontAwesomeIcon icon={solid('arrow-right-long')} />
                      </Flex>
                    )}
                  </Flex>
                );
              })}
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}

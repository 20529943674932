import { Field } from 'formik';
import { SimpleGrid, Checkbox, Box } from '@chakra-ui/react';
import { formatOptions } from './formhelperFunctions';
const CheckBox = (props) => {
  const { item, theme } = props;
  return (
    <>
      {!item.field_options.includes('Option') && (
        <SimpleGrid columns={[1, null, 2]} spacing={4} margin="4px 0">
          {formatOptions(item.field_options).map((option, j) => (
            <Field
              as={Checkbox}
              alignItems="top"
              fontWeight={item.field_options.includes('Option') ? 700 : 400}
              borderColor={theme.colors.BlackText}
              key={j}
              value={option || ''}
              name={item.field_name}
              _hover={{
                '& .chakra-checkbox__control': {
                  backgroundColor: theme.colors.LightGreen,
                },
              }}
              _checked={{
                '& .chakra-checkbox__control': {
                  backgroundColor: theme.colors.ValidGreen,
                  borderColor: theme.colors.ValidGreen,
                  _hover: {
                    backgroundColor: theme.colors.MedGreen,
                    borderColor: theme.colors.ValidGreen,
                  },
                },
                fontWeight: 700,
              }}
            >
              <Box marginTop="-5px">{option}</Box>
            </Field>
          ))}
        </SimpleGrid>
      )}
    </>
  );
};

export default CheckBox;

import { Box, Text } from '@chakra-ui/react';

export default function ComplexCardFooter(props) {
  const { info, iconSelect } = props;
  return (
    <Box
      position="absolute"
      borderTop="1px solid #D7D5D5"
      width="321px"
      height="140px"
      backgroundColor="#fff"
      bottom="0px"
      display="flex"
      flexDirection="column"
      paddingY="14px"
      gap="16px"
    >
      <Text fontSize="16px" fontWeight="bold" textTransform="uppercase">
        {info.card_footer_title ? info.card_footer_title : 'FUTURE DATE(S):'}
      </Text>
      <Box display="flex" alignItems="center">
        <Box
          paddingRight={
            info.footer_line_1.icon_select in iconSelect ? '8px' : '0px'
          }
        >
          {info.footer_line_1.icon_select in iconSelect
            ? iconSelect[info.footer_line_1.icon_select]
            : null}
        </Box>
        <Text
          fontWeight="600"
          fontFamily="Open Sans"
          fontSize={{ lg: '16px', base: '14px' }}
        >
          {info.footer_line_1.description_text
            ? info.footer_line_1.description_text
            : null}
        </Text>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          paddingRight={
            info.footer_line_2.icon_select in iconSelect ? '8px' : '0px'
          }
        >
          {info.footer_line_2.icon_select in iconSelect
            ? iconSelect[info.footer_line_2.icon_select]
            : null}
        </Box>{' '}
        <Text
          fontWeight="600"
          fontFamily="Open Sans"
          fontSize={{ lg: '16px', base: '14px' }}
        >
          {info.footer_line_2.description_text
            ? info.footer_line_2.description_text
            : null}
        </Text>
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../utils/api';
import {
  Box,
  Container,
  Stack,
  Grid,
  Text,
  Link,
  useTheme,
} from '@chakra-ui/react';

export default function UtilFooter() {
  const { l } = useParams();
  const [data, setData] = useState();
  const theme = useTheme();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await API.getAllEntries('utility_footer', l);

    setData(info[0]);
  }

  return (
    <Box>
      {data ? (
        <Container
          as={Stack}
          maxW={'8xl'}
          py={4}
          direction={{ base: 'column-reverse', md: 'row' }}
          spacing={4}
          justifyContent="space-between"
          // display="flex"
        >
          <Stack
            maxW={{ base: '100%', md: '45%' }}
            spacing={6}
            textAlign={{ md: 'start', base: 'center' }}
          >
            <Text
              fontSize={{ lg: '16px', base: '14px' }}
              fontWeight="400"
              lineHeight={{ lg: '22px', md: '25px', base: '21px' }}
              color="#FFFFFF"
              opacity="0.8"
            >
              {data.legal_terms_section?.copyright_text}
            </Text>
            <Text
              fontSize={{ lg: '16px', base: '14px' }}
              fontWeight="400"
              lineHeight={{ lg: '22px', md: '25px', base: '21px' }}
              color={theme.colors.White}
              opacity="0.8"
            >
              {data.legal_terms_section?.address_text}
            </Text>
          </Stack>
          <Grid
            maxW={{ base: '100%', md: '45%' }}
            align={{ base: 'start', md: 'center' }}
            templateColumns={{ base: 'repeat(2, 1fr)', md: '1fr 1fr 1fr 1fr' }}
            gap={6}
            textAlign={{ base: 'start', md: 'start' }}
          >
            {data.legal_links_section?.legal_link?.map((link, i) => {
              return (
                <Box key={i}>
                  <Link
                    // border="teal solid 2px"
                    fontSize={{ lg: '16px', md: '12px', sm: '14px' }}
                    fontWeight={{ md: '400', sm: '400' }}
                    style={{ textDecoration: 'underline' }}
                    href={link.href}
                  >
                    {link.title}
                  </Link>
                </Box>
              );
            })}
            <div
              fontSize={{ lg: '16px', md: '12px', sm: '14px' }}
              fontWeight={{ md: '400', sm: '400' }}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              className="ot-sdk-show-settings"
            >
              Manage Cookies
            </div>
          </Grid>
        </Container>
      ) : (
        ''
      )}
    </Box>
  );
}

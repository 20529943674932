import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, Link, Flex, Heading, useTheme } from '@chakra-ui/react';
import api from '../../utils/api';
import RightArrow from '../icons/rightArrow';
import logger from 'utils/logger';

export default function NewsBanner(props) {
  const { data } = props;
  const { l } = useParams();
  const [articles, setArticle] = useState([]);
  const limit = (4 - data?.article?.length) * 2;
  const theme = useTheme();

  logger.log('NEWS BANNER: ', data);

  useEffect(() => {
    setArticle([]);
    data?.article?.forEach((art) => {
      setArticle((current) => [...current, art]);
    });
  }, []);

  articles.length === data?.article?.length &&
    limit > 2 &&
    !data?.breaking &&
    fillAritcle(limit);

  // Getting data provided from contentstack references

  // geting data to fill out articles section if less than 4 articles from Contentstack
  // This will pull most recent & fill with article NOT currently present
  async function fillAritcle(limit) {
    const info = await api.getLimitedEntries(
      //   CT
      'news_article',
      // number of stories to fetch
      limit,
      // descending order by date.
      'article_details.date',
      // locale
      l,
    );
    // Checking to see if article is in CS array before adding
    info.map((entry, i) => {
      if (
        entry.uid === articles[0]?.uid ||
        entry.uid === articles[1]?.uid ||
        entry.uid === articles[2]?.uid
      ) {
        return null;
      }

      return setArticle((current) => [...current, entry]);
    });
  }

  return (
    <>
      {/* Headline Section */}
      <Box color={'White'} px={'3%'} bg={data?.breaking ? 'Red' : 'Black'}>
        <Flex
          maxW={'1440px'}
          mx={'auto'}
          justify={'space-between'}
          h={{ base: 'auto', lg: '47px' }}
          py={{ base: '11px', md: '33px', lg: 'initial' }}
          // pb={data?.breaking ? 'initial' : { base: '4%', lg: 'initial' }}
        >
          <Heading
            fontWeight={'700'}
            fontSize={{ base: '1rem', md: '1.5rem', lg: '1.375rem' }}
            lineHeight={
              data?.breaking
                ? { base: '1.2rem', md: '1.8rem', lg: '1.875rem' }
                : { base: '1.2rem', md: '1.8rem', lg: '1.65rem' }
            }
            // letterSpacing={'-0.02em'}
            my={'auto'}
          >
            {/* SWITCHED BACK PER CONVO 2/9/23 WITH CATHERINE AND LAUREN */}
            {data?.breaking ? 'BREAKING NEWS' : data?.title?.toUpperCase()}
          </Heading>
          {/* Link to Internal News Page */}
          <Flex align={'center'} gap={'16px'}>
            <Link
              href={data?.section_link?.href}
              fontWeight={'600'}
              fontSize={'1rem'}
              lineHeight={'1.25rem'}
            >
              {data?.section_link?.title}
            </Link>
            <RightArrow />
          </Flex>
        </Flex>
      </Box>
      {/* News Story Section */}
      <Box bg={'Black'} color={'White'} px={'3%'}>
        <Box maxW={'1440px'} mx={'auto'} py={'1%'}>
          {data?.breaking ? (
            // Breaking News Story
            <Flex minH={'48px'}>
              <Flex
                align={'center'}
                borderLeft={`solid 3px ${theme.colors.Red}`}
                px={'1%'}
              >
                <Heading
                  as={'h3'}
                  noOfLines={{ base: '2', md: '3' }}
                  fontWeight={'700'}
                  fontSize={'1.125rem'}
                  lineHeight={'1.35rem'}
                  // letterSpacing={'-0.02em'}
                >
                  <Link href={articles[0]?.url}>
                    {data?.article_details?.headline}
                  </Link>
                </Heading>
              </Flex>
            </Flex>
          ) : (
            // Normal Headlines Section
            <Flex>
              <Flex
                // border={'brown solid 2px'}
                direction={{ sm: 'column', lg: 'row' }}
              >
                <Flex
                  //   w={{ base: '100%', md: '50%', lg: '25%' }}
                  direction={{ sm: 'column', md: 'row' }}
                  //   border={'yellow solid 2px'}
                  w={{ base: '100%', lg: '50%' }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    // border={'solid gray 2px'}
                    flexGrow={1}
                  >
                    <Flex
                      borderLeft={'solid 3px'}
                      borderLeftColor={'PrimaryGreen'}
                      align={'start'}
                      px={'1.3%'}
                      w={{ base: '100%', lg: '50%' }}
                      mb={'4%'}
                    >
                      <Heading
                        as={'h3'}
                        noOfLines={{ base: '2', md: '3' }}
                        fontWeight={'700'}
                        fontSize={'1.125rem'}
                        lineHeight={'1.35rem'}
                        // letterSpacing={'-0.02em'}
                      >
                        <Link href={articles[0]?.url}>
                          {articles[0]?.article_details?.headline}
                        </Link>
                      </Heading>
                    </Flex>
                    <Flex
                      borderLeft={'solid 3px'}
                      borderLeftColor={'PrimaryGreen'}
                      align={'start'}
                      px={'1.3%'}
                      w={{ base: '100%', lg: '50%' }}
                      mb={'4%'}
                    >
                      <Heading
                        as={'h3'}
                        noOfLines={{ base: '2', md: '3' }}
                        fontWeight={'700'}
                        fontSize={'1.125rem'}
                        lineHeight={'1.35rem'}
                        // letterSpacing={'-0.02em'}
                      >
                        <Link href={articles[1]?.url}>
                          {articles[1]?.article_details?.headline}
                        </Link>
                      </Heading>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  //   w={{ base: '100%', md: '50%', lg: '25%' }}
                  direction={{ sm: 'column', md: 'row' }}
                  // border={'yellow solid 2px'}
                  w={{ base: '100%', lg: '50%' }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    // border={'solid gray 2px'}
                    flexGrow={1}
                  >
                    <Flex
                      borderLeft={'solid 3px'}
                      borderLeftColor={'PrimaryGreen'}
                      align={'start'}
                      px={'1.3%'}
                      w={{ base: '100%', lg: '50%' }}
                      mb={'4%'}
                    >
                      <Heading
                        as={'h3'}
                        noOfLines={{ base: '2', md: '3' }}
                        fontWeight={'700'}
                        fontSize={'1.125rem'}
                        lineHeight={'1.35rem'}
                        // letterSpacing={'-0.02em'}
                      >
                        <Link href={articles[2]?.url}>
                          {articles[2]?.article_details?.headline}
                        </Link>
                      </Heading>
                    </Flex>
                    <Flex
                      borderLeft={'solid 3px'}
                      borderLeftColor={'PrimaryGreen'}
                      align={'start'}
                      px={'1.3%'}
                      w={{ base: '100%', lg: '50%' }}
                      mb={'4%'}
                    >
                      <Heading
                        as={'h3'}
                        noOfLines={{ base: '2', md: '3' }}
                        fontWeight={'700'}
                        fontSize={'1.125rem'}
                        lineHeight={'1.35rem'}
                        // letterSpacing={'-0.02em'}
                      >
                        <Link href={articles[3]?.url}>
                          {articles[3]?.article_details?.headline}
                        </Link>
                      </Heading>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
}

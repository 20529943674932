import { Box, Link, Image, Text } from '@chakra-ui/react';
import FallBackGrey from '../../../assets/vectors/fallbackGrey.svg';

export default function ImageCard(props) {
  const { data, cardH, cardW, fontsize, lineH } = props;
  return (
    <Box
      role={'group'}
      as={Link}
      href={data.sponsor_link}
      target={data.new_window ? '_blank' : '_self'}
      _hover={
        data.sponsor_link
          ? {
              border: '2px solid #CCCCCC',
              boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
              paddingX: '13px',
              paddingY: '13px',
              textDecoration: 'none',
            }
          : { textDecoration: 'none' }
      }
      _focus={
        data.sponsor_link
          ? {
              border: '2px solid #CCCCCC',
              boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
              paddingX: '23px',
              paddingY: '23px',
              textDecoration: 'none',
            }
          : { textDecoration: 'none' }
      }
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      border="1px solid #CCCCCC"
      boxShadow="0px 24px 34px 0px rgba(0,0,0,0.03)"
      borderRadius="20px"
      h={cardH}
      w={cardW}
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="0"
        h={data.sponsor_title ? '70%' : '100%'}
        w="100%"
        objectFit="contain"
        paddingX="14px"
        paddingTop="14px"
        paddingBottom={data.sponsor_title ? '0px' : '14px'}
      >
        <Image
          loading="lazy"
          fallbackSrc={FallBackGrey}
          src={data.sponsor_image.url}
          fit="cover"
          borderRadius="16px"
          alt={data.alt_text}
          width="100%"
          height="100%"
        ></Image>
      </Box>
      {data.sponsor_title && (
        <Box
          position="absolute"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          h="30%"
          w="100%"
        >
          <Text
            fontFamily="Lato"
            paddingX="14px"
            fontWeight="700"
            letterSpacing="-2%"
            textDecoration="none"
            fontSize={fontsize}
            lineHeight={lineH}
          >
            {data.sponsor_title}
          </Text>
        </Box>
      )}
    </Box>
  );
}

import React, { useEffect } from 'react';
import { Flex, Text, Link, Box, Heading } from '@chakra-ui/react';

import RightArrow from '../../../../icons/rightArrow';
import useTitleColor from '../../../../../hooks/useTitleColor';

export default function Card(props) {
  const { data, titleProp, override, overrideAlt, ...rest } = props;

  const cardTitle =
    data?.content_type
      ?.split('_')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ') ||
    titleProp ||
    data?.title;

  const [color, colorChoice] = useTitleColor(cardTitle);

  useEffect(() => {
    colorChoice();
  }, []);

  return (
    <Flex
      align={'start'}
      // border={'pink solid 2px'}
      // w={{ base: '100%', md: '380px', lg: '393px' }}
      w={{ base: '100%', md: '49%', lg: '31.66%' }}
      mx={{ base: 'auto', md: 'initial' }}
      {...rest}
    >
      <Box
        // border={'green solid 2px'}
        bgImage={
          override ?? // used for resource entries
          data?.headline_banner?.image?.url ?? // used for resource entries
          data?.background_image?.url ??
          data?.seo?.meta_image?.url ??
          (data?.reference && data?.reference[0]?.seo?.meta_image?.url) ??
          'none'
        }
        bgSize={'cover'}
        borderRadius={'20px'}
        h={'500px'}
        w={'100%'}
        alt={overrideAlt || data?.image_alt} // overrideAlt only used on resource entry
      >
        <Flex
          // bgGradient="linear(to-b, rgba(0, 0, 0, 0) 0%,  #000000 100%)"
          h="100%"
          w="100%"
          pl={{ lg: '10%' }}
          pb={{ lg: '15%' }}
          justify={'start'}
          align="end"
          borderRadius={'10px'}
        >
          {/* Inner Card displaying data */}
          <Box
            bgColor={'white'}
            w={{ base: '100%', lg: '80%' }}
            minH={'35%'}
            borderRadius={'10px'}
            py={'5%'}
            mx={{ base: '1rem', lg: 'initial' }}
            mb={{ base: '1rem', lg: 'initial' }}
          >
            {/* Top line with Color box on left */}
            <Flex
              direction={'column'}
              mx={'auto'}
              // border={'solid 2px'}
              minH={'150px'}
              justify={'space-between'}
            >
              <Flex w={'100%'} h={'20px'} align={'center'} gap={'10px'}>
                <Box w={'5%'} h={'24px'} bgColor={color}></Box>
                <Heading fontSize={'14'} fontWeight={'700'}>
                  {cardTitle}
                </Heading>
              </Flex>
              <Text
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'22px'}
                m={'5%'}
                color={'#1E1E1E'}
                align={'start'}
              >
                {data?.article_details?.headline} {data.text} {data.title}
              </Text>
              {/* <Link
                mx={'5%'}
                align={'start'}
                href={data?.link?.href || data?.url}
                // color="DarkGreen"
              > */}
              <Flex
                as={Link}
                href={data?.link?.href || data?.url}
                gap={'16px'}
                align={'center'}
                mx={'5%'}
              >
                <Text fontSize={'16'} fontWeight={'600'}>
                  {data?.link?.title || 'Read more'}
                </Text>

                {data?.link_icon !== false && (
                  <Box _hover={{ textDecoration: 'none' }}>
                    <RightArrow />
                  </Box>
                )}
              </Flex>
              {/* </Link> */}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

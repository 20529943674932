import { Flex } from '@chakra-ui/react';
import React, {
  useEffect,
  useState,
  // useRef,
  // useLayoutEffect
} from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import Columns from '../columns';

export default function FeatureCard(props) {
  const { l } = useParams();
  // const ref = useRef(null);
  const { data } = props;
  const [section, setSection] = useState();
  // const [width, setWidth] = useState();
  useEffect(() => {
    getData();
  }, []);
  // useLayoutEffect(() => {
  //   handleResize();
  // }, []);
  async function getData() {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );
    setSection(info?.entry);
  }
  // function handleResize() {
  //   setWidth(ref?.current?.offsetWidth);
  // }
  // window.addEventListener('resize', handleResize);
  return (
    <Flex
      // ref={ref}
      // bgImage={
      //   section?.image
      //     ? width > 1440
      //       ? `${section?.bg_image?.url}?width=${width}`
      //       : { base: `${section?.bg_image?.url}` }
      //     : 'initial'
      // }
      bgImage={section?.bg_image?.url}
      bgColor={
        !section?.image
          ? section?.background_color?.replace(' ', '')
          : 'initial'
      }
      align={'center'}
      justify={'center'}
      bgPosition={{ md: 'center' }}
      bgSize={'cover'}
      w={'100%'}
      mx={'auto'}
      bgRepeat={'no-repeat'}
      // my={
      //   width < 1440 && {
      //     base: 'initial',
      //     '2xl': `${(screen.width - 1536) / 2}`,
      //   }
      // }
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        bgColor={'white'}
        maxW={{ base: '80%', md: '1006px' }}
        borderRadius={'20px'}
        mx={'4%'}
        my={'3%'}
        overflow={'hidden'}
      >
        <Columns refData={section?.columns[0]} featureCard={true} />
      </Flex>
    </Flex>
  );
}

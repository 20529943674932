import React, { useState, useEffect } from 'react';
import {
  Link,
  Flex,
  Text,
  Heading,
  Button,
  useTheme,
  Box,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useCompileTags from '../../../hooks/useCompileTags';
import useDateFormat from '../../../hooks/useDateFormat';

export default function ArticleHeader({ data, ct, ArticleTitle = null }) {
  const [tags, compileTags] = useCompileTags(data);
  const [date, setDate] = useState();
  const [dateBuilder] = useDateFormat(); // variables(timestamp, format (ie month_year or ap))
  const theme = useTheme();
  useEffect(() => {
    compileTags(data);
    setDate(() => dateBuilder(data?.article_details?.date, 'ap'));
  }, []);

  const handleTagClick = (tag) => {
    const tagGroup = data.tags_group;
    let urlWithFilters = '';
    const baseUrl = ct !== 'resource_entry' ? '/news' : '/resources';

    Object.keys(tagGroup).forEach((key) => {
      const targetArr = tagGroup[key];
      if (targetArr.includes(tag)) {
        const field = 'tags';
        const size = 'n_16_n';
        const value = `${key}=${tag}`;
        const type = 'all';

        // Encode parameters
        const encodedParams = {
          size: encodeURIComponent(size),
          'filters[0][field]': encodeURIComponent(field),
          'filters[0][values][0]': encodeURIComponent(value),
          'filters[0][type]': encodeURIComponent(type),
        };

        // Construct parameter string
        const paramString = Object.entries(encodedParams)
          .map(([param, value]) => `${param}=${value}`)
          .join('&');

        // Construct URL with parameters
        urlWithFilters = `${baseUrl}?${paramString}`;

        return;
      }
    });

    // Redirect user
    window.location.href = urlWithFilters;
  };
  // const formatDate = () => {
  //   const dateArray = data.article_details.date.split('-');
  //   const months = [
  //     'Jan.',
  //     'Feb.',
  //     'March',
  //     'April',
  //     'May',
  //     'June',
  //     'July',
  //     'Aug.',
  //     'Sept.',
  //     'Oct.',
  //     'Nov.',
  //     'Dec.',
  //   ];

  //   dateArray[1] = months[Number(dateArray[1]) - 1];

  //   setDate(dateArray.reverse().join(' '));
  // };
  return (
    <Flex
      // border="1px solid cyan"
      direction={'column'}
      align={'center'}
      mr={'72px'}
      ml={'62px'}
      mt={'48px'}
      mb={'27px'}
    >
      {/* Upper section with date, section type, and links */}
      <Flex w={'100%'} direction={{ base: 'column', md: 'row' }}>
        <Flex flex={1}></Flex>
        <Flex flex={1} justify={'center'} align={'end'}>
          <Flex
            direction={'column'}
            gap={
              ct === 'news_article'
                ? '4px'
                : ct === 'news_blurb'
                ? '10px'
                : 'initial'
            }
            mt={'4px'}
            mb={{ base: '5px', md: 'initial' }}
          >
            <Flex flex={1} justify={'center'} align={'center'}>
              {ct !== 'resource_entry' && (
                <Text
                  color={theme.colors.Grey1}
                  fontSize={'0.875rem'}
                  fontWeight={'600'}
                  letterSpacing={'.08em'}
                  lineHeight={'1.192rem'}
                >
                  {date}
                </Text>
              )}
            </Flex>
            {data &&
              data.tags_group &&
              data.tags_group.internal_class?.includes('analysis') && (
                <Box
                  color={`${theme.colors.DarkBlue}`}
                  w={'max-content'}
                  textAlign={'center'}
                  px={'12px'}
                  mt={'8px'}
                >
                  <Text
                    letterSpacing={'3.5%'}
                    fontWeight={'600'}
                    fontSize={'14px'}
                    lineHeight={'1.188rem'}
                    fontFamily={'Open Sans, semi-bold'}
                  >
                    ANALYSIS
                  </Text>
                </Box>
              )}
            <Flex flex={1} justify={'center'} align={'center'}>
              <Text
                fontWeight={'700'}
                fontSize={'1rem'}
                lineHeight={'1.362rem'}
                letterSpacing={'.045em'}
                color={theme.colors.Grey1}
              >
                {/* {data.tags_group?.sections
                  ?.join(',')
                  .replaceAll(',', ' | ')
                  .toUpperCase() || data.tags_group?.type?.toUpperCase()} */}
                {data?.tags_group?.sections?.map((section, i) => {
                  return (
                    <Text key={i} textAlign={'center'}>
                      {section.toUpperCase()}
                      <br />
                    </Text>
                  );
                })}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} justify={{ base: 'center', md: 'end' }} align={'start'}>
          <Flex direction={'column'} gap={{ md: '6px' }}>
            <Flex flex={1} justify={'end'} align={'center'}>
              {ct !== 'resource_entry' && (
                <Flex
                  gap={'13px'}
                  align={'center'}
                  color={'DarkGreen'}
                  fontSize={'.875rem'}
                  lineHeight={'1.25rem'}
                  fontWeight={'600'}
                >
                  <Link href={'/news/subscriptions'}>
                    Subscribe to Newsletters
                  </Link>
                  <FontAwesomeIcon icon={solid('arrow-right-long')} />
                </Flex>
              )}
            </Flex>
            <Flex flex={1} justify={'end'} align={'center'}>
              {ct !== 'resource_entry' && (
                <Flex
                  gap={'16px'}
                  align={'center'}
                  color={'DarkGreen'}
                  fontSize={'.875rem'}
                  lineHeight={'1.25rem'}
                  fontWeight={'600'}
                >
                  <Link href={'https://iapp.org/l/connect/'}>
                    Advertise with the IAPP
                  </Link>
                  <FontAwesomeIcon icon={solid('arrow-right-long')} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* Tags Section */}
      <Flex
        gap={'5px'}
        my={ct === 'news_blurb' ? '16px' : '24px'}
        color={theme.colors.Grey1}
        flexWrap={'wrap'}
        align={'center'}
        justify={'center'}
      >
        {tags?.map((tag, i) => {
          return (
            <Button
              onClick={() => handleTagClick(tag)}
              key={i}
              size={'md'}
              border={`2px solid ${theme.colors.Grey1}`}
              bg={theme.colors.White}
              fontFamily={'Lato'}
            >
              {tag}
            </Button>
          );
        })}
      </Flex>
      {ct !== 'resource_entry' && (
        <Flex maxW={'912px'} textAlign={'center'}>
          <Heading
            as={'h1'}
            fontSize={{ base: '1.25rem', md: '1.875rem', lg: '2.5rem' }}
            fontFamily="Lato"
            fontWeight="800"
            lineHeight={{ base: '1.5rem', md: '2.25rem', lg: '3rem' }}
            letterSpacing={'.015em'}
          >
            {ArticleTitle ?? data.seo?.meta_page_title}
          </Heading>
        </Flex>
      )}
    </Flex>
  );
}

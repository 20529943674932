import React from 'react';
import { Button, Link } from '@chakra-ui/react';

export default function CpeButton() {
  return (
    <Button
      ml={'19px'}
      size={{
        base: 'small',
        // md: `medium`,
      }}
      variant={'secondary'}
      as={Link}
      href={'https://iapp.org/certify/cpe-submit/'}
      target={'_blank'}
    >
      Submit for CPEs
    </Button>
  );
}

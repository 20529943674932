import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import BlockContainer from '../../container';
import InfoCard from '../infoCard';
export default function TwoColumns(props) {
  const { data, widths } = props;

  return (
    <Flex direction={{ base: 'column', md: 'row' }} gap={'35px'}>
      {data?.map((col, index) => {
        return (
          <Flex
            key={index}
            justify={'auto'}
            width={{ base: '100%', md: '50%', lg: `${widths[index]}%` }}
            direction={'column'}
            align={{ base: 'center' }}
          >
            {col.section?.components?.map((comp, i) => {
              return (
                <Flex key={i} w={'100%'}>
                  {comp.title_line ? (
                    <Heading
                      key={i}
                      fontWeight={'700'}
                      fontSize={'1.875rem'}
                      display={'flex'}
                      flexWrap={'wrap'}
                    >
                      {comp.title_line.title}
                    </Heading>
                  ) : comp.info_card ? (
                    <InfoCard key={i} data={comp.info_card} />
                  ) : (
                    <BlockContainer
                      key={i}
                      data={[comp]}
                      px={'initial'}
                      fit={'cover'}
                      pos={'center'}
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
}

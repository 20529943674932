import React, { useEffect, useState, memo } from 'react';
import { Gallery } from './gallery';

export default function MiniCarousel(props) {
  const { data } = props;

  const [cards, setCards] = useState([]);

  const PureGallery = memo(Gallery);
  useEffect(() => {
    data && setCards(() => [data.item_one, ...data.items]);
  }, []);

  const inputArray = cards;
  const twoSlideArray = inputArray.reduce((resultArray, item, index) => {
    const perSlide = 2; // items per slide
    const chunkIndex = Math.floor(index / perSlide);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <>
      {twoSlideArray.length > 0 && (
        <PureGallery
          {...props}
          lgCards={twoSlideArray}
          mdCards={twoSlideArray}
          smCards={cards}
          width={true}
        />
      )}
    </>
  );
}

import { Box, Button, Image, Flex, Circle } from '@chakra-ui/react';
import api from '../../../utils/api';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export default function ImageSlider(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? newData.image.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === newData.image.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;

    setCurrentIndex(newIndex);
  };

  const numDots = newData?.image?.length;

  const dotSpace = numDots * 5;
  // const buttonSpace = 60;
  // const totalSpace = dotSpace + buttonSpace;

  return (
    <Box display="flex" justifyContent="center" alignContent="center" my={10}>
      {newData && (
        <Box
          display="flex"
          justifyContent="center"
          background=""
          position="relative"
        >
          <Box
            position="absolute"
            left={{ md: '0', base: `${80 - dotSpace}px` }}
            bottom={{ md: 'initial', base: '-3' }}
            zIndex="1"
          >
            <Button
              h={{ lg: '605px', md: '413px' }}
              w={{ lg: '70px', md: '30px' }}
              variant="unstyled"
              onClick={goToPrevious}
            >
              <ChevronLeftIcon boxSize="10" />
            </Button>
          </Box>
          <Box
            position="absolute"
            right={{ lg: '-60px', md: '-20px', base: `${80 - dotSpace}px` }}
            bottom={{ md: 'initial', base: '-3' }}
            zIndex="1"
          >
            <Button
              variant="unstyled"
              h={{ lg: '605px', md: '413px' }}
              w={{ lg: '70px', md: '30px' }}
              onClick={goToNext}
            >
              <ChevronRightIcon boxSize="10" />
            </Button>
          </Box>
          <Box>
            <Box
              display="inline-flex"
              width={{ lg: '1140px', md: '730px', base: '280px' }}
            >
              {/* Left image */}
              <Image
                // opacity="0.1"
                left={1}
                h={{ lg: '605px', md: '413px', base: '247px' }}
                w={{ lg: '80px', md: '57px', base: '33px' }}
                objectFit="cover"
                borderRadius="20px 0 0 20px"
                src={
                  newData.image[
                    currentIndex === 0
                      ? currentIndex + newData.image.length - 1
                      : currentIndex - 1
                  ]?.image_file?.url
                }
                alt={
                  newData.image[
                    currentIndex === 0
                      ? currentIndex + newData.image.length - 1
                      : currentIndex - 1
                  ]?.image_file?.url
                }
                marginRight={{ lg: '-20px', md: '-15px', base: '-10px' }}
                zIndex={-1}
                opacity="0.1"
              />
              {/* Middle image */}
              <Image
                h={{ lg: '605px', md: '413px', base: '247px' }}
                // w={{ lg: '1080px', md: '670px', base: '244px' }}
                minW={{ lg: '1080px', md: '670px', base: '244px' }}
                maxW={{ lg: '1080px', md: '670px', base: '244px' }}
                borderRadius="20px"
                objectFit="cover"
                src={newData.image[currentIndex]?.image_file?.url}
                alt={newData.image[currentIndex]?.image_file_alt_text}
              />
              {/* Right image */}
              <Image
                // opacity="0.1"
                h={{ lg: '605px', md: '413px', base: '247px' }}
                w={{ lg: '80px', md: '57px', base: '33px' }}
                borderRadius="0 20px 20px 0"
                border="1px solid yellow"
                objectFit="cover"
                zIndex={-1}
                marginLeft={{ lg: '-20px', md: '-15px', base: '-10px' }}
                opacity="0.1"
                src={
                  newData.image[
                    currentIndex === newData.image.length - 1
                      ? 0
                      : currentIndex + 1
                  ]?.image_file?.url
                }
                alt={
                  newData.image[
                    currentIndex === newData.image.length - 1
                      ? 0
                      : currentIndex + 1
                  ]?.image_file_alt_text
                }
              />
            </Box>
            <Flex w="100%" justify="center" marginTop="15px">
              {newData.image.map((_, index) => (
                <Circle
                  key={index}
                  size={{ lg: '15px', base: '10px' }}
                  bg={currentIndex === index ? '#000000' : '#FFFFFF'}
                  border="1px solid #000000"
                  mx="2px"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  );
}

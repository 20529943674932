// src/utils/logger.js
const env = process.env.REACT_APP_ENVIRONMENT;

const logger = {
  log: (...args) => {
    if (env === 'sandbox' || env === 'development' || env === 'test') {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (env === 'sandbox' || env === 'development' || env === 'test') {
      console.warn(...args);
    }
  },
  error: (...args) => {
    console.error(...args); // Log errors in all environments
  },
};

export default logger;

import React, { useEffect, useRef, useState } from 'react';
import api from '../utils/api';
import InteriorHeader from '../components/modularBlocks/section/interior_section_header';
import BlockContainer from '../components/modularBlocks/container';
import {
  Heading,
  Link,
  Text,
  Box,
  Image,
  Flex,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';
import ChevronLeft from '../components/icons/chevronLeft/index';
import ChevronRight from '../components/icons/chevronRight/index';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import Loading from '../components/loadingIndicator/loading';
import logger from 'utils/logger';

export default function ListingPage({ ct }) {
  logger.log('ct prop in ListingPage', ct);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState();
  const cardsSectionRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const info = await api.getAllEntriesWithRef(
      ct,
      'interior_section_header,page',
    );
    logger.log('info in ListingPage', info);
    setData(info[0]);
  }
  logger.log('Data in ListingPage', data);

  // Indexing of cards for pagination
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data?.page?.slice(indexOfFirstCard, indexOfLastCard);

  // Breakpoints set up for each page
  const cardsPerPageViewpoint = useBreakpointValue({
    base: 12,
    md: 14,
    lg: 15,
  });
  useEffect(() => {
    setCardsPerPage(cardsPerPageViewpoint);
  }, [cardsPerPageViewpoint]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data?.page?.length / cardsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Card section ref to bring user to top of the section onClick
  const scrollToTopOfCards = () => {
    cardsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Pagination buttons
  const pageNumberButtons = pageNumbers.map((number) => {
    if (number === currentPage) {
      return (
        <Box
          as="button"
          h="32px"
          w="32px"
          border={`solid 1px ${theme.colors.DarkGreen}`}
          borderRadius="4px"
          textColor={theme.colors.DarkGreen}
          fontWeight="700"
          fontSize="14px"
          title={`Go to page ${number + 1}`}
          key={number}
          onClick={() => {
            setCurrentPage(number);
            scrollToTopOfCards();
          }}
          _hover={{
            backgroundColor: 'DarkGreen',
            textColor: 'white',
          }}
        >
          {number}
        </Box>
      );
    } else {
      return (
        <Box
          as="button"
          h="32px"
          w="32px"
          border={`solid 1px ${theme.colors.Grey4}`}
          borderRadius="4px"
          fontWeight="700"
          fontSize="14px"
          key={number}
          onClick={() => {
            setCurrentPage(number);
            scrollToTopOfCards();
          }}
          _hover={{
            backgroundColor: 'DarkGreen',
            textColor: 'White',
          }}
        >
          {number}
        </Box>
      );
    }
  });

  const prevButton = () => {
    if (currentPage === 1) {
      return (
        <Box
          as="button"
          h="32px"
          w="32px"
          border={`solid 1px ${theme.colors.Grey4}`}
          borderRadius="4px"
          _hover={{
            cursor: 'not-allowed',
          }}
          backgroundColor="DarkGreen"
          textColor="White"
        >
          <ChevronLeft />
        </Box>
      );
    } else {
      return (
        <Box
          as="button"
          h="32px"
          w="32px"
          border={`solid 1px ${theme.colors.Grey4}`}
          borderRadius="4px"
          onClick={() => {
            setCurrentPage(currentPage - 1);
            scrollToTopOfCards();
          }}
          textColor={theme.colors.Green}
          _hover={{
            backgroundColor: 'DarkGreen',
            textColor: 'white',
          }}
        >
          <ChevronLeft />
        </Box>
      );
    }
  };

  const nextButton = () => {
    if (currentPage === pageNumbers.length) {
      return (
        <Box
          as="button"
          h="32px"
          w="32px"
          border={`solid 1px ${theme.colors.Grey4}`}
          borderRadius="4px"
          _hover={{
            cursor: 'not-allowed',
          }}
          backgroundColor="DarkGreen"
          textColor="white"
        >
          <ChevronRight />
        </Box>
      );
    } else {
      return (
        <Box
          as="button"
          h="32px"
          w="32px"
          border={`solid 1px ${theme.colors.Grey4}`}
          borderRadius="4px"
          onClick={() => {
            setCurrentPage(currentPage + 1);
            scrollToTopOfCards();
          }}
          textColor="Green"
          _hover={{
            backgroundColor: 'DarkGreen',
            textColor: 'white',
          }}
        >
          <ChevronRight />
        </Box>
      );
    }
  };

  return (
    <main className="main">
      <SkipNavContent />
      {data ? (
        <div>
          {data.interior_section_header?.length > 0 && (
            <InteriorHeader data={data.interior_section_header[0]} />
          )}
          {data.page?.length > 0 && (
            <Box
              maxH="100%"
              position="relative"
              maxW={{ lg: '1200px', base: '100%' }}
              mx={{ base: '1rem', lg: 'auto' }}
              paddingTop={{ md: '60px', base: '20px' }}
              paddingBottom={{ base: '25px', md: '45px', lg: '50px' }}
              px={{ base: 'none', md: '24px', lg: '32px' }}
              ref={cardsSectionRef}
            >
              <Box
                // border="1px solid pink"
                display="flex"
                justifyContent="center"
                flexDir="row"
                flexWrap="wrap"
                columnGap={{ lg: '20px', md: '20px' }}
                rowGap={{ lg: '50px', md: '45px', base: '25px' }}
              >
                {currentCards.map((entry, i) => {
                  logger.log('ENTRY: ', entry);
                  return (
                    <Box key={i}>
                      <Box
                        role={'group'}
                        as={Link}
                        href={entry.url}
                        _hover={{
                          textDecoration: 'none',
                          border: `2px solid ${theme.colors.White}`,
                          boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
                          paddingX: '17px',
                          paddingTop: '17px',
                        }}
                        _focus={{
                          textDecoration: 'none',
                          border: `2px solid ${theme.colors.White}`,
                          boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
                          paddingX: '23px',
                          paddingTop: '23px',
                        }}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        border={`solid 1px ${theme.colors.White}`}
                        boxShadow="0px 24px 34px 0px rgba(0,0,0,0.03)"
                        borderRadius="20px"
                        minH="385px"
                        w={{ lg: '360px', md: '360px', base: '346px' }}
                        paddingX="18px"
                        paddingY="18px"
                        gap="16px"
                      >
                        {entry.seo?.meta_image?.url && (
                          <Image
                            loading="lazy"
                            src={entry.seo?.meta_image?.url}
                            alt="fill"
                            fit="cover"
                            borderRadius="16px 16px 0px 0px"
                            h="131px"
                            w={{ md: '321px', base: '298px' }}
                          />
                        )}
                        {(entry.banner?.headline || entry.banner?.sub_text) && (
                          <Box display="flex" flexDir="column" gap="16px">
                            {entry.banner?.headline && (
                              <Heading
                                fontWeight="700"
                                fontSize="30px"
                                lineHeight="30px"
                                _groupHover={{ textDecoration: 'underline' }}
                                _groupFocus={{ textDecoration: 'underline' }}
                              >
                                {entry.banner?.headline}
                              </Heading>
                            )}
                            {entry.banner?.sub_text && (
                              <Text
                                fontFamily="Open Sans"
                                fontWeight="400"
                                textColor="Grey1"
                                fontSize={{ md: '15px', base: '14px' }}
                                lineHeight="24.5px"
                                letterSpacing="-6%"
                              >
                                {entry.banner?.sub_text}
                              </Text>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Flex
                mt={{ md: '100px', base: '60px' }}
                justifyContent="center"
                gap="5px"
              >
                {prevButton()}
                {pageNumberButtons}
                {nextButton()}
              </Flex>
            </Box>
          )}
          {data.page_contents && <BlockContainer data={data.page_contents} />}
        </div>
      ) : (
        <Loading min={'100vh'} />
      )}
    </main>
  );
}

import axios from 'axios';
import logger from 'utils/logger';

const env = process.env.REACT_APP_ENVIRONMENT;
const url =
  env === 'production'
    ? 'https://content.iapp.org'
    : `https://${env}-content.iapp.org`;

export default {
  getSpecificEntry: function (contentUid, entryUid, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentUid}/entries/${entryUid}?environment=${process.env.REACT_APP_ENVIRONMENT}&locale=${locale}`,
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        logger.error('error getSpecificEntry:', error);
      });
  },
  getSpecificEntryWithRef: function (
    contentUid,
    entryUid,
    locale = 'en-us',
    ref,
  ) {
    return axios

      .get(
        `${url}/content_types/${contentUid}/entries/${entryUid}?environment=${
          process.env.REACT_APP_ENVIRONMENT
        }&locale=${locale}&include[]=${ref.replaceAll(',', '&include[]=')}`,
      )

      .then((res) => {
        return res.data;
      })

      .catch((error) => {
        logger.error(error);
      });
  },
  getAllContentTypes: function () {
    return axios
      .get(`${url}/content_types?include_count=false`)
      .then((res) => {
        return res.data.content_types;
      })
      .catch((error) => {
        logger.error('ERROR getAllContentTypes: ', error);
      });
  },
  getAllEntries: function (contentTypeUid, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentTypeUid}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&locale=${locale}&include_fallback=true`,
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getAllEntries: ', error);
      });
  },
  getAllEntriesWithRef: function (contentTypeUid, ref, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentTypeUid}/entries?environment=${
          process.env.REACT_APP_ENVIRONMENT
        }&locale=${locale}&include[]=${ref.replaceAll(
          ',',
          '&include[]=',
        )}&include_fallback=true`,
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getAllEntriesWithRef: ', error);
      });
  },
  getArticle: async function (
    contentTypeUid,
    articleName,
    locale = 'en-us',
    ref,
  ) {
    // Helper function to construct URL
    const constructUrl = (uid, name) => {
      name = name.replace(/\/+$/, ''); // Remove trailing slashes

      return `${url}/content_types/${uid}/entries?environment=${
        process.env.REACT_APP_ENVIRONMENT
      }&locale=${locale}&query={"url": "${name}"}&include[]=${ref?.replaceAll(
        ',',
        '&include[]=',
      )}`;
    };

    // First attempt with original content type and article name
    let constructedUrl = constructUrl(contentTypeUid, articleName);
    try {
      let res = await axios.get(constructedUrl);
      if (res.data.entries.length > 0) {
        return res.data.entries;
      }
      // If no entries found, try with 'news_blurb' and modified path

      const newPath = articleName.replace('news/a', 'news/b');
      constructedUrl = constructUrl('news_blurb', newPath);
      res = await axios.get(constructedUrl);

      return res.data.entries;
    } catch (err) {
      logger.error('ERROR getArticle: ', err);
      return [];
    }
  },

  getLimitedEntries: function (contentTypeUid, limit, desc, locale = 'en-us') {
    return axios
      .get(
        `${url}/content_types/${contentTypeUid}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&locale=${locale}&limit=${limit}&desc=${desc}`,
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getLimitedEntries: ', error);
      });
  },
  getBanner: function (parent) {
    return axios
      .get(
        `${url}/content_types/${parent}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&query={ "section_header_for_subpages": { "$exists": true } }&only[BASE][]=section_header_for_subpages&include[]=section_header_for_subpages`,
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR getBanner: ', error);
      });
  },
  checkResource: function (ct, path) {
    return axios
      .get(
        `${url}/content_types/${ct}/entries?environment=${process.env.REACT_APP_ENVIRONMENT}&query={"url": "${path}"}&only[BASE][]=uid`,
      )
      .then((res) => {
        return res.data.entries;
      })
      .catch((error) => {
        logger.error('ERROR checkResource: ', error);
      });
  },
  getUID: function (path) {
    return axios
      .get(
        `${url}/content_types?environment=${process.env.REACT_APP_ENVIRONMENT}&query={"options.url_pattern": "${path}"}&only[BASE][]=uid}`,
      )
      .then((res) => {
        return res.data.content_types;
      })
      .catch((error) => {
        logger.error('ERROR getUID: ', error);
      });
  },
  getSchema: function (ct) {
    return axios
      .get(
        `${url}/content_types/${ct}?environment=${process.env.REACT_APP_ENVIRONMENT}`,
      )
      .then((res) => {
        return res.data.content_type.schema;
      })
      .catch((error) => {
        logger.error('ERROR getSchema: ', error);
      });
  },
};

import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import api from '../../../../utils/api';
import { FullCard, HalfCard } from '../cards';
export default function ThreeCard(props) {
  const { data } = props;
  const { l } = useParams();
  const [single, setSingle] = useState();
  const [double1, setDouble1] = useState();
  const [double2, setDouble2] = useState();

  useEffect(() => {
    getData(
      data.single.entry[0]._content_type_uid,
      data.single.entry[0].uid,
      l,
      setSingle,
      data.single.theme[0].uid,
      data.single.image,
      data.single.link_title,
    );
    getData(
      data.double.entry_one.entry[0]._content_type_uid,
      data.double.entry_one.entry[0].uid,
      l,
      setDouble1,
      data.double.entry_one.theme[0].uid,
      data.double.entry_one.icon,
      data.double.entry_one.link_title,
      data.double.entry_one.round,
    );
    getData(
      data.double.entry_two.entry[0]._content_type_uid,
      data.double.entry_two.entry[0].uid,
      l,
      setDouble2,
      data.double.entry_two.theme[0].uid,
      data.double.entry_two.icon,
      data.double.entry_two.link_title,
      data.double.entry_two.round,
    );
  }, []);
  async function getData(cT, entryUid, l, sF, themeUid, image, link, shape) {
    const info = await api.getSpecificEntry(cT, entryUid, l);
    const theme = await api.getSpecificEntry('theme', themeUid, l);

    if (info) {
      sF({ info, theme, image, link, shape });
    }
  }
  return (
    <Box maxW={'1440px'} mx={'auto'}>
      <Flex
        // border={'solid green 2px'}
        justify={'center'}
        p={'.5%'}
        gap={'16px'}
        direction={{ base: 'column', lg: 'row' }}
      >
        {data.single_left && (
          <Box flexGrow={1}>
            <FullCard
              info={single?.info}
              theme={single?.theme}
              image={single?.image}
              link={single?.link}
            />
          </Box>
        )}

        <Flex flexGrow={1} direction={'column'} gap={'16px'}>
          <Box flexGrow={1}>
            <HalfCard
              info={double1?.info}
              theme={double1?.theme}
              image={double1?.image}
              link={double1?.link}
              round={double1?.shape}
            />
          </Box>
          <Box flexGrow={1}>
            <HalfCard
              info={double2?.info}
              theme={double2?.theme}
              image={double2?.image}
              link={double2?.link}
              round={double2?.shape}
            />
          </Box>
        </Flex>

        {!data.single_left && (
          <Box flexGrow={1}>
            <FullCard
              info={single?.info}
              theme={single?.theme}
              image={single?.image}
              link={single?.link}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
}

import React from 'react';
import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
  Hide,
  Show,
  Text,
  // Heading,
} from '@chakra-ui/react';
import { VSlide } from './slides';

export default function Vertical(props) {
  const { data } = props;

  return (
    <Box
      color={data?.bg_color !== 'White' ? 'white' : 'black'}
      bgColor={data?.bg_color?.replace(' ', '')}
      py={'5%'}
      // px={'1rem'}
    >
      <Hide below={'md'}>
        <Box maxW={'1220px'} mx={{ md: '35px', lg: '60px' }}>
          <Tabs
            orientation="vertical"
            mx={'auto'}
            isLazy
            // border={'violet solid 2px'}
            justifyContent={'center'}
          >
            <Flex
              direction={'row-reverse'}
              gap={{ lg: '5%' }}
              flex={1}
              // border={'blue dashed 2px'}
            >
              <TabList
                justifyContent={'space-between'}
                my={'auto'}
                // maxW={{ md: '30%', lg: 'initial' }}
                // border={'lime solid 2px'}
                flex={1}
              >
                {data?.entries.map((card, i) => {
                  return (
                    <Tab
                      key={i}
                      py={'1rem'}
                      textAlign={'left'}
                      borderLeftWidth={'10px'}
                      borderLeftColor={'white'}
                      _selected={{
                        color: `${
                          data?.tab_color?.replace(' ', '') || 'white'
                        }`,
                        borderLeftColor: `${
                          card.underline_color?.replace(' ', '') ||
                          'PrimaryGreen'
                        }`,
                        fontFamily: 'Lato',

                        fontWeight: '700',
                        lineHeight: '1.8rem',
                      }}
                      fontWeight={'400'}
                      lineHeight={'2rem'}
                      fontFamily={'Open Sans'}
                      justifyContent={'start'}
                    >
                      <Text fontSize={'1.5rem'} noOfLines={4}>
                        {card.title}
                      </Text>
                    </Tab>
                  );
                })}
              </TabList>
              <TabPanels
                // maxW={{ md: '80%', lg: '60%' }}
                flex={1}
                // border={'solid cyan 2px'}
              >
                {data?.entries.map((card, i) => {
                  return (
                    <TabPanel key={i} w={'100%'}>
                      <VSlide data={card} />
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Flex>
          </Tabs>
        </Box>
      </Hide>
      <Show below={'md'}>
        <Flex direction={'column'} gap={'1rem'} align={'center'}>
          {data?.entries?.map((card, i) => {
            return (
              <Flex key={i}>
                <VSlide data={card} />
              </Flex>
            );
          })}
        </Flex>
      </Show>
    </Box>
  );
}

import {
  Box,
  Button,
  Image,
  Flex,
  Circle,
  Heading,
  Text,
} from '@chakra-ui/react';
// import { Box } from '@chakra-ui/react';
import api from '../../../utils/api';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import RichTextEditorMB from '../../modularBlocks/rte';

export default function ImageRTESlider(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? newData.image_slider.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === newData.image_slider.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;

    setCurrentIndex(newIndex);
  };

  //   const numDots = newData?.image_slider?.length;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      my={10}
      // border={'1px solid lime'}
    >
      {newData && (
        <>
          <Box
            display={'flex'}
            // border={'1px dotted blue'}
            // minW={'900px'}
            justifyContent={'left'}
            background=""
            position="relative"
          >
            {/* Left button - - - -  */}
            <Box
              // border={'1px solid cyan'}
              position="absolute"
              left={'-15px'}
              bottom={{ md: 'initial', base: '-3' }}
              zIndex="1"
            >
              <Button
                h={{ lg: '412px', md: '208px' }}
                w={{ lg: '35px', md: '15px' }}
                variant="unstyled"
                onClick={goToPrevious}
              >
                <ChevronLeftIcon boxSize="16" />
              </Button>
            </Box>
            {/* Right button - - - - */}
            <Box
              position="absolute"
              bottom={{ md: 'initial', base: '-3' }}
              zIndex="1"
              right={'163px'}
            >
              <Button
                variant="unstyled"
                h={{ lg: '412px', md: '208px' }}
                w={{ lg: '35px', md: '15px' }}
                onClick={goToNext}
              >
                <ChevronRightIcon boxSize="16" />
              </Button>
            </Box>
            <Box>
              <Box
                // border={'1px solid cyan'}
                display="inline-flex"
                // width={{ lg: '1140px', md: '730px', base: '280px' }}
                witdh={{ lg: '570px', md: '365px', base: '140px' }}
              >
                {/* Left image----------------------------- */}
                <Image
                  // h={{ lg: '605px', md: '413px', base: '247px' }}
                  w={{ lg: '35px', md: '25px', base: '15px' }}
                  objectFit="cover"
                  objectPosition={'left'}
                  borderRadius="20px 0 0 20px"
                  src={newData.image_slider[currentIndex]?.image_file?.url}
                  alt={newData.image_slider[currentIndex]?.image_file?.url}
                  // marginRight={{ lg: '-5px', md: '-15px', base: '-10px' }}
                  zIndex={-1}
                  opacity="0.1"
                />
                {/* Middle image----------------------------- */}
                <Image
                  // h={{ lg: '605px', md: '413px', base: '247px' }}
                  h={{ lg: '412px', md: '208px', base: '124px' }}
                  minW={{ lg: '674px', md: '670px', base: '244px' }}
                  maxW={{ lg: '674px', md: '670px', base: '244px' }}
                  borderRadius="20px"
                  objectFit="cover"
                  src={newData.image_slider[currentIndex]?.image_file?.url}
                  alt={newData.image_slider[currentIndex]?.image_file_alt_text}
                />
                {/* Right image----------------------------- */}
                <Image
                  w={{ lg: '35px', md: '25px', base: '15px' }}
                  borderRadius="0 20px 20px 0"
                  objectFit="cover"
                  objectPosition={'right'}
                  zIndex={-1}
                  // marginLeft={{ lg: '-20px', md: '-15px', base: '-10px' }}
                  opacity="0.1"
                  src={newData.image_slider[currentIndex]?.image_file?.url}
                  alt={newData.image_slider[currentIndex]?.image_file_alt_text}
                />
              </Box>
              <Flex w="100%" justify="center" marginTop="15px">
                {newData.image_slider.map((_, index) => (
                  <Circle
                    key={index}
                    size={{ base: '10px' }}
                    bg={currentIndex === index ? '#000000' : '#FFFFFF'}
                    border="1px solid #000000"
                    mx="2px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
          <Flex border={'1px solid tomato'} direction={'column'} maxW={'372px'}>
            <Heading>{newData?.title}</Heading>
            <Text
              px={{ base: '16px', md: '24px', lg: '32px' }}
              fontStyle={'italic'}
              fontWeight={'500'}
              textTransform={'uppercase'}
            >
              {newData?.designation}
            </Text>
            <RichTextEditorMB
              data={newData?.modular_blocks[0].rte.rich_text_editor}
              style={newData?.modular_blocks[0].rte.custom_styles}
            />
          </Flex>
        </>
      )}
    </Box>
  );
}

import { AspectRatio } from '@chakra-ui/react';
import React from 'react';
import Video from './video';
import VideoModal from './videoModal';

export default function VideoPlayer(props) {
  const { data } = props;
  // return data?.full_screen ? <VideoModal {...props} /> : <Video {...props} />;
  return (
    <AspectRatio ratio={20 / 12}>
      {data?.full_screen ? <VideoModal {...props} /> : <Video {...props} />}
    </AspectRatio>
  );
}

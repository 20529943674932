export const formatDate = (date) => {
  if (isNaN(date)) {
    return;
  }
  const months = {
    Jan: 'Jan.',
    Feb: 'Feb.',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'Aug.',
    Sep: 'Sept.',
    Oct: 'Oct.',
    Nov: 'Nov.',
    Dec: 'Dec.',
  };
  const dateArray = (() => {
    // we have found bad data where some timestamps are unix epochs in seconds
    //   and some are unix epochs in milliseconds. If the year is greater than
    //   10000, we switch to using the epoch alone as the argument for the date
    const unixDate = new Date(date * 1000);
    if (unixDate.getFullYear() > 10000) {
      return new Date(date);
    }
    return unixDate;
  })()
    .toUTCString()
    .split(' ');

  if (dateArray.length > 3) {
    return `${dateArray[1] - 0} ${months[dateArray[2]]} ${dateArray[3]}`;
  }
  return dateArray.join(' ');
};

import axios from 'axios';
import logger from '../utils/logger';

class CustomConnector {
  constructor(host, index, { prefilter = null, category = null }) {
    Object.assign(this, { host, index, prefilter, category });
  }
  onSearch = async (query, options) => {
    const filterMatrix = new Map();
    const addTag = (tagValue) => {
      const [tagKey, valuePart] = tagValue.split('=');
      // The format that Algolia requires for "and" "or" operators is
      // [[item1 or item1 or item3], and [item4 or item5]] which is then url encoded
      // For reference:
      // https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filters-and-facetfilters/
      if (!filterMatrix.has(tagKey)) {
        filterMatrix.set(tagKey, []);
      }
      const currentTags = filterMatrix.get(tagKey);
      currentTags.push(`tags:${tagKey}=${valuePart}`);
      return;
    };
    const dates = [];
    let urlBase;
    this.index?.includes('overview')
      ? (urlBase = `/general_desc`)
      : this.prefilter
      ? (urlBase = `/general`)
      : (urlBase = `/${this.index}`);

    query.filters.map((f) => {
      switch (f.field) {
        case 'tags':
          f.values.map((value) => {
            if (!value.includes('language')) {
              addTag(value);
              return;
            }
            const a = value.split('=');
            switch (a[1]) {
              case 'English':
                a[1] = 'en';
                break;
              case 'Spanish':
                a[1] = 'sp';
                break;
              case 'German':
                a[1] = 'de';
                break;
              case 'French':
                a[1] = 'fr';
                break;
              case 'Brazillian-Portuguese':
                a[1] = 'pt-br';
                break;
            }
            addTag(a.join(':'));
          });
          break;
        case 'Start':
          dates.push(`published_dtm>${Math.floor(new Date(f.values[0]))}`);
          break;
        case 'End':
          dates.push(`published_dtm<${Math.floor(new Date(f.values[0]))}`);
          break;
        case 'sort':
          if (f.values[0]?.includes('overview')) {
            urlBase = `/general`;
          } else {
            urlBase = `/${f.values[0]}`;
          }
          break;
        default:
          logger.log('unknown filter type');
      }
    });
    const requestUrl = new URL(this.host + urlBase);
    logger.log('request url -- ', requestUrl);
    const params = requestUrl.searchParams;
    params.append('page', query.current - 1);
    params.append('hitsPerPage', query.resultsPerPage);
    params.append('attributesToRetrieve', '*');

    if (query.searchTerm) {
      params.append('query', query.searchTerm);
    }
    if (typeof this.prefilter === 'string') {
      try {
        filterMatrix.set('prefilter', JSON.parse(this.prefilter));
      } catch (_) {
        filterMatrix.set('prefilter', this.prefilter);
      }
    }
    if (Array.isArray(this.prefilter)) {
      filterMatrix.set('prefilter', this.prefilter);
    }
    if (this.category) {
      params.append('filters', `category:${this.category}`);
    }
    if (filterMatrix.size > 0) {
      params.append('facetFilters', JSON.stringify([...filterMatrix.values()]));
    }
    if (dates.length > 0) {
      params.append('numericFilters', JSON.stringify(dates));
    }
    const res = await axios
      .get(requestUrl.toString())
      .catch((err) => console.error('RES ERROR: ', err));

    const newHits = [];
    if (!res.data?.hits) {
      throw new Error('No hits');
    }
    res.data.hits.map((hit) => {
      hit.id = { raw: hit.objectID };
      return newHits.push(hit);
    });

    return {
      autocompletedResults: [],
      autocompletedResultsRequestId: '',
      autocompletedSuggestions: {},
      autocompletedSuggestionsRequestId: '',
      current: query.current,
      error: '',
      facets: options.facets,
      filters: query.filters,
      pagingEnd: res.data.nbPages,
      // pagingEnd: 40,
      pagingStart: 1,
      rawResponse: res,
      requestId: res.data.params,
      resultsSearchTerm: res.data.query,
      results: newHits,
      resultsPerPage: 16,
      searchTerm: query.searchTerm,
      sortDirection: '',
      sortField: '',
      sortList: query.sortList,
      totalPages: res.data.nbPages,
      totalResults: res.data.nbHits,
      wasSearched: true,
    };
  };

  // possible future functionality not part of current release
  onResultClick(params) {
    logger.log(
      'perform a call to the API to highlight a result has been clicked',
    );
  }

  onAutocompleteResultClick(params) {
    logger.log(
      'perform a call to the API to highlight an autocomplete result has been clicked',
    );
  }
}

export default CustomConnector;

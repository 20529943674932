import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

// Utils
import api from './utils/api';
import useOneTrustScript from 'hooks/useOneTrustScript';

// Pages
import PageTemplate from './pages/page';
import HomePage from './pages';
import Article from './pages/article';
import TestPage from './pages/test';
import NoPage from './pages/404';
import SubPage from './pages/subPage';
import OverviewPage from './pages/overview';
import ListingPage from './pages/listing';
import PageFilter from './pageFilter';
import SearchPage from './pages/SearchPage';

// Components
import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/loadingIndicator/loading';
import GTMNoScript from 'components/metadata/GTMNoScript';
import GTMHeadInjector from 'components/metadata/GTMHeadInjector';

// Styles
import Fonts from './Fonts';
import theme from './theme';

function App() {
  const [pages, setPages] = useState();
  const [contentType, setCT] = useState();
  const [parent, setParent] = useState();

  const location = useLocation();
  useOneTrustScript();

  const type = typeof contentType;

  useEffect(() => {
    getPages();
    buildPath();
  }, []);

  // This function sets the parent for the inherited banner on sub pages
  // and builds out the default path for searching the content type
  function buildPath() {
    const x = location.pathname.split('/');
    let y;
    if (x.length > 1) {
      switch (x[1]) {
        case 'certify':
          setParent('certification_overview');
          break;
        case 'train':
          setParent('training_overview');
          break;
        case 'enterprise-services':
          setParent('enterprise_overview');
          break;
        case 'c':
          setParent('corporate_landing_page');
          break;
        case 'l':
          setParent('marketing_landing_page');
          break;
        case 'lang':
          setParent('foreign_landing_page');
          break;
        default:
          setParent(`${x[1]}_overview`);
      }
    }

    if (x.length > 2) {
      x.splice(x.length - 1, 1, ':title');
      y = x.join('/');
    } else {
      y = x;
    }
    return getCT(y);
  }

  // This function takes the default path and gets all content types back that use that url structure
  // If there are multiple then it returns an array that will be used by a filter to determine correct
  // content type for rendering.
  async function getCT(path) {
    const ct = await api.getUID(path);
    ct.length === 1
      ? setCT(ct[0].uid)
      : ct.length > 1
      ? setCT(() => {
          const a = [];
          ct.map((ct) => {
            a.push(ct.uid);
          });
          return [...a];
        })
      : setCT();
  }

  async function getPages() {
    const res = await api.getAllEntries('page');
    setPages(res);
  }

  // The ContentType Filter function returns the correct component based on the returned data from the getCT() method
  function ctFilter() {
    if (type === 'string') {
      return <SubPage ct={contentType} parent={parent} />;
    } else if (type === 'object') {
      return <PageFilter ct={contentType} />;
    }

    return <Loading minH={'100vh'} />;
  }

  return (
    <ChakraProvider theme={theme}>
      <Fonts />

      <div className="App">
        <Helmet>
          <title>IAPP</title>
          <meta
            name="description"
            content="The International Association of Privacy Professionals: Policy neutral, we are the world’s largest information privacy organization."
          />
        </Helmet>
        <GTMHeadInjector />
        <GTMNoScript />
        <ScrollToTop>
          <Routes>
            <Route element={<Header />}>
              {/* 01. Home Page Section */}
              <Route index element={<HomePage />} />

              {/* SEARCH PAGES */}
              <Route path="search">
                <Route index element={<SearchPage />} />
              </Route>

              {/* 02. Certification Pages */}
              <Route path={'certify'}>
                {/*  path:  /certify  */}
                <Route
                  index
                  element={<OverviewPage ct={'certification_overview'} />}
                />

                {/*  path: /certify/:title  */}
                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 03. Training Pages */}
              <Route path={'train'}>
                {/*  path:  /train  */}
                <Route
                  index
                  element={<OverviewPage ct={'training_overview'} />}
                />

                {/*  path: /train/:title*/}
                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 04. Membership Pages */}
              <Route path={'membership'}>
                <Route
                  index
                  element={<OverviewPage ct={'membership_overview'} />}
                />
                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 05. Community Pages */}

              <Route path={'community'}>
                <Route
                  index
                  element={<OverviewPage ct={'community_overview'} />}
                />
                <Route path={'find-your-chapter'}>
                  {/* path: /community/find-your-chapter/:local-chapter-title */}
                  <Route index element={ctFilter()} />
                  <Route path={'*'} element={ctFilter()} />
                </Route>

                <Route path={'sections'}>
                  {/* path: /community/sections/:title */}
                  <Route index element={ctFilter()} />
                  <Route path={'*'} element={ctFilter()} />
                </Route>
                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* take this section (04) out and uncomment above when we are giving the green light */}
              {/* 04. Membership Pages */}
              {/* <Route path={'membership'}>
                <Route
                  index
                  element={<OverviewPage ct={'membership_overview'} />}
                /> */}
              {/* path: /membership/community */}
              {/* <Route path={'community'}>
                  <Route index element={ctFilter()} />
                  <Route path={'find-your-chapter'}> */}
              {/* path: /membership/community/find-your-chapter/:local-chapter-title */}
              {/* <Route index element={ctFilter()} />
                    <Route path={'*'} element={ctFilter()} />
                  </Route>

                  <Route path={'sections'}> */}
              {/* path: /membership/community/sections/:title */}
              {/* <Route index element={ctFilter()} />
                    <Route path={'*'} element={ctFilter()} />
                  </Route>
                </Route>

                <Route path={'*'} element={ctFilter()} />
              </Route> */}

              {/* 06. Conferences Pages */}
              <Route path={'conferences'}>
                <Route
                  index
                  element={<SubPage ct={'conferences_overview'} />}
                />

                {/* path:  /conferences/:title */}
                <Route path={'*'} element={ctFilter()} />
                <Route
                  path={'*'}
                  element={<SubPage ct={'conference_microsite'} />}
                />
              </Route>

              {/* 07. News Pages Section */}
              <Route path={'news'}>
                <Route index element={<SearchPage ct={'news_overview'} />} />
                {/* <Route path="c" element={<h1>Hello there!</h1>} /> */}
                <Route path={'a'}>
                  <Route path={'*'} element={<Article ct={'news_article'} />} />
                </Route>
                <Route path={'b'}>
                  <Route path={'*'} element={<Article ct={'news_blurb'} />} />
                </Route>
                <Route
                  path={'daily-dashboard'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'europe-data-protection-digest'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'united-states-dashboard-digest'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'asia-pacific-dashboard-digest'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'canada-dashboard-digest'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'dpo-confessional'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'privacy-tracker'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'privacy-advisor'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'privacy-perspectives'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route
                  path={'privacy-tech'}
                  element={<SearchPage ct={'news_overview'} />}
                />
                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 08. Resources Pages */}
              <Route path={'resources'}>
                <Route
                  index
                  element={<SearchPage ct={'resources_overview'} />}
                />

                <Route path={'tools-and-trackers'}>
                  <Route
                    index
                    element={
                      <ListingPage ct={'tools_and_trackers_listing_page'} />
                    }
                  />
                  <Route
                    path={'*'}
                    element={
                      <SearchPage
                        parent={'tools-and-trackers'}
                        ct={'tools_and_trackers_page'}
                      />
                    }
                  />
                </Route>
                <Route path={'web-conferences'}>
                  <Route
                    index
                    element={
                      <ListingPage ct={'web_conferences_listing_page'} />
                    }
                  />
                  <Route
                    path={'*'}
                    element={
                      <SearchPage
                        parent={'web-conferences'}
                        ct={'web_conferences_page'}
                      />
                    }
                  />
                </Route>
                <Route path={'subjects'}>
                  <Route
                    index
                    element={<ListingPage ct={'subjects_listing_page'} />}
                  />
                  <Route
                    path={'*'}
                    element={
                      <SearchPage ct={'subject_page'} parent={'subjects'} />
                    }
                  />
                </Route>
                <Route path={'legislation'}>
                  <Route
                    index
                    element={<ListingPage ct={'legislation_listing_page'} />}
                  />
                  <Route
                    path={'*'}
                    element={
                      <SearchPage
                        ct={'legislation_page'}
                        parent={'legislation'}
                      />
                    }
                  />
                </Route>

                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 09. Enterprise Services Pages */}
              <Route path={'enterprise-services'}>
                {/*  path:  /enterprise-services  */}
                <Route
                  index
                  element={<OverviewPage ct={'enterprise_overview'} />}
                />

                {/*  path:  /enterprise-services/:title  */}
                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 10. About Page */}
              <Route path={'about'}>
                <Route index element={<SubPage ct={'about_overview'} />} />
                <Route path={'media'}>
                  {/* Press Releases */}
                  {/* path: /about/media/:press-release-title */}
                  <Route path={'*'} element={ctFilter()} />
                </Route>

                <Route path={'*'} element={ctFilter()} />
              </Route>

              {/* 11. Site Search Page */}
              <Route path={'search'}>
                <Route index element={<SearchPage />} />
              </Route>

              {/* 12. Non-Section Pages */}
              <Route path={'c'}>
                <Route path={'*'} element={ctFilter()} />
              </Route>
              <Route path={'l'}>
                <Route path={'*'} element={ctFilter()} />
              </Route>
              <Route path={'lang'}>
                <Route path={'*'} element={ctFilter()} />
              </Route>
              <Route path="test">
                <Route index element={<TestPage />} />
                <Route path="*" element={<NoPage />} />
              </Route>
              {pages?.map((page, i) => {
                return (
                  <Route key={i} path={page.url}>
                    <Route index element={<PageTemplate info={page} />} />
                    <Route path="*" element={<NoPage />} />
                  </Route>
                );
              })}
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </ScrollToTop>
        <Footer />
      </div>
    </ChakraProvider>
  );
}
export default App;

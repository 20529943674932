import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import ChevronDownIcon from '../../../icons/chevronDown';

export default function DropdownMenu({ data, selectedEntry, index }) {
  const { menu_items: menuItems } = data;

  return (
    <Menu>
      <MenuButton
        variant={'unstyled'}
        fontSize={'20px'}
        fontWeight={'700'}
        lineHeight={'normal'}
        color={selectedEntry === index ? 'white' : 'Grey1'}
        _groupHover={{ color: 'white' }}
        cursor={'default'}
      >
        <Link
          as={RouterLink}
          to={{ pathname: menuItems?.link?.href, state: selectedEntry }}
        >
          General Info
        </Link>
        <ChevronDownIcon px={'9px'} />
      </MenuButton>

      <MenuList p={'0'}>
        {menuItems?.map((item, index) => (
          <MenuItem
            as={RouterLink}
            to={{ pathname: item?.link?.href, state: selectedEntry }}
            textDecoration={'none'}
            cursor={'default'}
            key={index}
          >
            {item?.link?.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

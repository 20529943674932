import {
  Button,
  Link,
  Flex,
  Heading,
  // Text,
  Image,
  Box,
} from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { NavLink } from 'react-router-dom';
import RichTextEditorMB from '../../rte';
import FaIcon from '../../../icons/faIcon';
import RightArrow from '../../../icons/rightArrow';

// import ButtonMB from '../../button';

export default function MemberX2(props) {
  const { data, greyBg, newTab, contained } = props;
  const cards = data?.slice(0, 2);

  return (
    <Box
      backgroundColor={greyBg ? '#f4f4f4' : 'white'}
      py={'32px'}
      px={contained ? 0 : { base: '10%', md: '40px', lg: '60px' }}
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        maxW={'1287px'}
        align={{ base: 'center', md: 'start' }}
        justify={{ base: 'start', md: 'center' }}
        mx={'auto'}
        gap={{ base: '44px', md: '4%' }}
        // border={'teal solid 2px'}
      >
        {cards?.map((card, i) => {
          return (
            <Flex
              direction={'column'}
              align={'start'}
              alignSelf={'stretch'}
              key={i}
              border={'solid 3px #CFCFCF'}
              bgColor={card.theme[0]?.colors?.background_color}
              borderRadius={'20px'}
              p={{ base: '40px 18px 38px 32px', md: '3.5%' }}
              minH={{ base: '310px', md: '393px', lg: '497px' }}
              w={{ base: 'fit-content', md: '48%', lg: '48%' }}
              my={{ base: '.5Srem', md: 'initial' }}
              gap={'24px'}
            >
              <Box h={'60px'} w={'60px'}>
                {(card.fa_icon || card.image?.url) && (
                  <>
                    {card.fa_icon ? (
                      <FaIcon
                        faIcon={`fa-${card.icon
                          .replaceAll(' ', '-')
                          .toLowerCase()}`}
                        fasize={'fa-2xl'}
                        alt={card.image_alt}
                        color={card.theme[0]?.colors?.text_color?.toLowerCase()}
                        transform={'scale(2)'}
                        ml={'13px'}
                        mt={'25px'}
                      />
                    ) : (
                      <Image src={card.image?.url} alt={card.image_alt} />
                    )}
                  </>
                )}
              </Box>

              <Heading
                // mb={{ base: '1rem', lg: 'initial' }}
                color={card.theme[0]?.colors?.text_color?.toLowerCase()}
                fontSize={{ base: '1.75rem', lg: '2.25rem' }}
                lineHeight={{ base: '2.1rem', lg: '2.25rem' }}
                fontWeight={'700'}
              >
                {card.title}
              </Heading>
              {/* <Text
                // mb={{ base: '1rem', lg: 'initial' }}
                color={card.theme[0]?.colors?.text_color?.toLowerCase()}
                fontSize={{ base: '1.125rem', lg: '1.25rem' }}
                lineHeight={{ base: '1.75rem', lg: '2.125rem' }}
                fontWeight={'400'}
              >
                {card.description}
              </Text> */}
              {card.description_rte && (
                <RichTextEditorMB
                  data={card.description_rte}
                  style={['card']}
                  color={card.theme[0]?.colors?.text_color?.toLowerCase()}
                  fontSize={{ base: '1.125rem', lg: '1.25rem' }}
                  lineHeight={{ base: '1.75rem', lg: '2.125rem' }}
                  fontWeight={'400'}
                  px="initial"
                />
              )}
              {card.cta_button?.button_link?.title && (
                // <ButtonMB buttonObj={card.cta} alignment={'start'} />
                <>
                  {card.cta_button?.external_link ? (
                    <Button
                      alignment={'start'}
                      size={{
                        base: 'small',
                        md: card.cta_button?.button_size?.toLowerCase(),
                      }}
                      variant={card.cta_button?.button_type?.toLowerCase()}
                      as={Link}
                      href={card.cta_button?.button_link?.href}
                      target={
                        card.cta_button?.new_window
                          ? '_blank'
                          : newTab
                          ? newTab
                          : '_self'
                      }
                      gap="10px"
                    >
                      {card.cta_button?.button_link?.title}
                      <RightArrow />
                    </Button>
                  ) : (
                    <Button
                      mx={{ base: 'auto', md: 'initial' }}
                      alignment={'start'}
                      size={{
                        base: 'small',
                        md: card.cta_button?.button_size?.toLowerCase(),
                      }}
                      variant={card.cta_button?.button_type?.toLowerCase()}
                      as={NavLink}
                      to={card.cta_button?.button_link?.href}
                      target={
                        card.cta_button?.new_window
                          ? '_blank'
                          : newTab
                          ? newTab
                          : '_self'
                      }
                    >
                      {card.cta_button?.button_link?.title}
                    </Button>
                  )}
                </>
              )}
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import {
  Carousel,
  CarouselSlide,
  // SingleCarouselSlide,
  useCarousel,
} from '../carousel';
import Card from '../carouselCard';
import useResize from '../../../../../hooks/useResize';

export const Gallery = (props) => {
  const { lgCards, mdCards, smCards, rootProps, width } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [size, handleResize] = useResize();
  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
  });
  const hasPrevious = currentSlide !== 0;
  const hasNextLg = currentSlide < lgCards?.length - 1;
  const hasNextMd = currentSlide < mdCards?.length - 1;
  let cards;

  useEffect(() => {
    handleResize();
  }, []);
  switch (size) {
    case 'sm':
      cards = undefined;
      break;
    case 'md':
      cards = mdCards;
      break;
    default:
      cards = lgCards;
  }
  window.addEventListener('resize', handleResize);
  return (
    <Stack w={width ? width : '100%'} spacing="24px" {...rootProps}>
      <Box
        position="relative"
        sx={{
          _hover: {
            '> button': {
              display: 'inline-flex',
            },
          },
        }}
      >
        {cards ? (
          <Carousel ref={ref}>
            {cards.map((slide, i) => (
              <CarouselSlide key={i}>
                {slide.map((card, i) => {
                  return <Card key={i} data={card} fallback={<Skeleton />} />;
                })}
              </CarouselSlide>
            ))}
          </Carousel>
        ) : (
          <Flex
            direction={'column'}
            gap={'16px'}
            w={'100%'}
            // border={'purple dotted 2px'}
          >
            {smCards?.map((card, i) => {
              return <Card key={i} data={card} fallback={<Skeleton />} />;
            })}
          </Flex>
        )}
        {hasPrevious && (
          <CarouselIconButton
            pos="absolute"
            left={{ base: '-6', lg: '-8' }}
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.prev()}
            icon={<IoChevronBackOutline />}
            aria-label="Previous Slide"
          />
        )}

        {size === 'lg' && hasNextLg && (
          <CarouselIconButton
            pos="absolute"
            right="-8"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => {
              return slider.current?.next();
            }}
            icon={<IoChevronForwardOutline />}
            aria-label="Next Slide"
          />
        )}

        {size === 'md' && hasNextMd && (
          <CarouselIconButton
            pos="absolute"
            right="-6"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.next()}
            icon={<IoChevronForwardOutline />}
            aria-label="Next Slide"
          />
        )}

        <HStack width="full" justify="center" py="4">
          {size === 'lg' &&
            lgCards?.length > 1 &&
            lgCards?.map((_, index) => (
              <Box
                key={index}
                borderRadius={'4px'}
                minW={'86px'}
                minH="6px"
                bgColor={currentSlide === index ? 'gray' : 'gray.400'}
              />
            ))}

          {size === 'md' &&
            mdCards?.length > 1 &&
            mdCards?.map((_, index) => (
              <Box
                key={index}
                borderRadius={'4px'}
                minW={'86px'}
                minH="6px"
                bgColor={currentSlide === index ? 'gray' : 'gray.400'}
              />
            ))}
        </HStack>
      </Box>
    </Stack>
  );
};
const CarouselIconButton = (props) => (
  <IconButton
    // display="none"
    fontSize={{ base: '2xl', lg: '5xl' }}
    isRound
    boxShadow="base"
    color={'white'}
    h={{ md: '56px', lg: '90px' }}
    w={{ md: '56px', lg: '90px' }}
    bg={useColorModeValue('#1E1E1E', 'gray.800')}
    _hover={{
      bg: useColorModeValue('#1E1E1E', 'gray.700'),
    }}
    _active={{
      bg: useColorModeValue('#1E1E1E', 'gray.600'),
    }}
    _focus={{
      boxShadow: 'inerhit',
    }}
    _focusVisible={{
      boxShadow: 'outline',
    }}
    {...props}
  />
);

import { Box } from '@chakra-ui/react';
import MemberX3 from './membershipX3';
import MemberX2 from './membershipX2';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import Quad from './trainingQuad';
import TrainingStacks from './trainingStacks';
import logger from 'utils/logger';

export default function CardLayout(props) {
  const { data, ...rest } = props;
  const { l } = useParams();
  const [cardInfo, setCardInfo] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntryWithRef(
      data._content_type_uid,
      data.uid,
      l,
      'card.theme',
    );
    setCardInfo(info.entry);
  }
  return (
    <Box>
      {cardInfo?.card_styles === 'Membership 2' ? (
        <MemberX2
          data={cardInfo.card}
          greyBg={cardInfo.light_grey_background}
          {...rest}
        />
      ) : cardInfo?.card_styles === 'Membership 3' ? (
        <MemberX3
          data={cardInfo.card}
          greyBg={cardInfo.light_grey_background}
          {...rest}
        />
      ) : cardInfo?.card_styles === 'Training Quadrant' ? (
        <Quad
          data={cardInfo.card}
          color={cardInfo.background_color}
          {...rest}
        />
      ) : cardInfo?.card_styles === 'Training Cards' ? (
        <TrainingStacks
          data={cardInfo.card}
          color={cardInfo.background_color}
          {...rest}
        />
      ) : (
        logger.warn('UNKNOWN CARD TYPE')
      )}
    </Box>
  );
}

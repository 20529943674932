import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import AdBanner from './adBanner';
import AdGroup from './adBlockGroup';

export default function BlockGroup(props) {
  const { type, numOfSlots } = props;
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getAllEntriesWithRef(
      'advertisement_group',
      'block_groups.advertisement_blocks,banners.advertisement_banner,block_groups.slot_1.advertisement_blocks,block_groups.slot_2.advertisement_blocks,block_groups.slot_3.advertisement_blocks,block_groups.slot_4.advertisement_blocks',
    );

    setData(info[0]);
  }

  return type === 'banner' ? (
    data?.banners?.advertisement_banner.length > 0 && (
      <AdBanner data={data?.banners?.advertisement_banner} />
    )
  ) : (
    // : data?.block_groups?.advertisement_blocks.length > 0 && (
    //     <AdGroup data={data?.block_groups?.advertisement_blocks} />
    //   );
    <AdGroup numOfSlots={numOfSlots} data={data?.block_groups} />
  );
}

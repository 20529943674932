import React, { useEffect } from 'react';
import ConferenceDesktopNav from './nav/desktopSubnav';
import ConferenceMobileNav from './nav/modalSubnav';
import useResize from '../../hooks/useResize';

export default function ConferenceNav(props) {
  const [size, handleResize] = useResize();

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener('resize', handleResize);

  switch (size) {
    case 'lg':
      return <ConferenceDesktopNav {...props} />;
    case 'md':
      return <ConferenceMobileNav {...props} />;
    case 'sm':
      return <ConferenceMobileNav {...props} />;
  }
}

import React, { useState } from 'react';
import SectionHeader from './section_header';
import { Box } from '@chakra-ui/react';
import TabMB from '../tabs';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';
// import Carousel from '../carousel';

export default function Section(props) {
  const { l } = useParams();
  const { data, refData, ...rest } = props;
  const [newData, setNewData] = useState();
  // useEffect(() => {
  //   getData();
  // }, []);

  async function getData() {
    const info = await api.getSpecificEntry(
      refData?._content_type_uid,
      refData?.uid,
      l,
    );

    setNewData(info.entry);
  }

  let headerData;
  let tabs;

  newData?.section_blocks
    ? newData?.section_blocks?.map((block) =>
        block.section_header
          ? (headerData = block.section_header)
          : (tabs = block.tabs.tab_blocks),
      )
    : newData?.contents?.map((block) =>
        block.section_header
          ? (headerData = block.section_header)
          : (tabs = block.tabs.tab_blocks),
      );
  data && !newData ? setNewData(data) : !newData && refData ? getData() : null;

  return (
    <Box>
      {headerData && <SectionHeader data={headerData} {...rest} />}
      {tabs && <TabMB data={tabs} {...rest} />}
    </Box>
  );
}

import React from 'react';
import { WithSearch } from '@elastic/react-search-ui';
import { Button, useTheme } from '@chakra-ui/react';

export default function ClearAll({ setTags, clearPad, setDates, tags, dates }) {
  const theme = useTheme();
  return (
    <WithSearch
      mapContextToProps={({ filters, clearFilters }) => ({
        filters,
        clearFilters,
      })}
    >
      {({ filters, clearFilters }) => {
        if (!filters || filters.length === 0) {
          return null; // Do not render if no filters are applied
        }

        // Check if there are any non-sorting filters
        const hasNonSortFilters = filters.some(
          (filter) => filter.field !== 'sort',
        );
        // filter.field !== 'End' &&
        // filter.field !== 'Start',

        if (!hasNonSortFilters) {
          return null; // Do not render the button if only sorting filters are applied
        }

        return (
          <Button
            onClick={() => {
              setTags([]);
              setDates([]);
              return clearFilters();
            }}
            bgColor={'inherit'}
            color={'DarkGreen'}
            fontFamily={'Lato'}
            weight={'600'}
            fontSize={'.875rem'}
            lineHeight={'1.25rem'}
            borderRight={{ md: `solid 1px ${theme.colors.Grey3}` }}
            borderLeft={{ base: `solid 1px ${theme.colors.Grey3}`, md: 'none' }}
            borderRadius={'none'}
            w={clearPad ? 'fit-content' : '100%'}
            maxW={'30%'}
            pr={{ base: 0, md: '24px' }}
            pl={{ base: '24px', md: 0 }}
            mr={{ base: 0, md: '24px' }}
            ml={{ base: '0px', md: '24px' }}
          >
            Clear all filters
          </Button>
        );
      }}
    </WithSearch>
  );
}

import React from 'react';
import { Box } from '@chakra-ui/react';

const ChevronDownIcon = ({ variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-chevron-down ${fasize}`}
      {...rest}
    />
  );
};

export default ChevronDownIcon;

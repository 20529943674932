import React from 'react';
import { Box, Text, Image, Link, Flex, Hide, Heading } from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import fallbackGrey from '../../../../assets/vectors/fallbackGrey.svg';
import RightArrow from '../../../icons/rightArrow';

export function FullCard(props) {
  const { info, theme, image, link } = props;
  return (
    <Flex
      direction={{ base: 'column', md: 'row-reverse', lg: 'column' }}
      // border={'gray solid 2px'}
      color={theme?.entry?.colors?.text_color}
      bg={theme?.entry?.colors?.background_color}
      h={'100%'}
      borderRadius={'1.25rem'}
    >
      <Box
        // border={'solid teal 2px'}
        minW={{ sm: '100%', md: '48%', lg: '100%' }}
        minH={{ sm: '50%', md: '100%', lg: '50%' }}
      >
        <Image
          fallbackSrc={fallbackGrey}
          h={'100%'}
          w={'100%'}
          src={image?.url || info?.entry?.seo.meta_image.url}
          alt={image?.description}
        ></Image>
      </Box>

      <Flex
        // flexGrow={1}
        // border={'teal solid 2px'}
        gap={'16px'}
        direction={'column'}
        justify={{ base: 'space-around', lg: 'space-between' }}
        my={'auto'}
        minH={'246px'}
        mx={'8%'}
        pb={'35px'}
        pt={{ base: '22px', md: '35px', lg: 'initial' }}
      >
        <Heading
          fontWeight={'700'}
          fontSize={{ sm: '1.625rem', md: '1.5rem', lg: '2.25rem' }}
          lineHeight={{ sm: '1.95rem', md: '1.8rem', lg: '2.25rem' }}
        >
          {info?.entry?.seo?.meta_page_title || info?.entry?.title}
        </Heading>
        <Text fontWeight={'400'} fontSize={'1.125rem'} lineHeight={'1.5rem'}>
          {info?.entry?.seo?.meta_description || info?.entry?.description}
        </Text>
        <Flex
          align={'center'}
          gap={'16px'}
          fontWeight={'600'}
          fontSize={'1rem'}
        >
          <Link href={info?.entry?.url || info?.entry?.link?.href}>
            {link || info?.entry?.link?.title}
          </Link>
          {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
          <RightArrow />
        </Flex>
      </Flex>
    </Flex>
  );
}

export function HalfCard(props) {
  const { info, theme, image, link, round } = props;
  return (
    <Box
      border={
        theme?.entry?.colors?.background_color === 'White'
          ? 'gray solid 1px'
          : `${theme?.entry?.colors?.text_color}solid 1px`
      }
      color={theme?.entry?.colors?.text_color}
      bg={theme?.entry?.colors?.background_color}
      h={'100%'}
      borderRadius={'1.25rem'}
      px={{ base: '8%', md: 'initial' }}
      pl={{ md: '8%' }}
      py={'2%'}
    >
      <Flex
        align={'center'}
        my={{ base: '35px', md: 'auto' }}
        // border={'yellow solid 2px'}
      >
        <Flex
          flexGrow={1}
          // border={'teal solid 2px'}
          direction={'column'}
          justify={{ base: 'space-around', lg: 'space-between' }}
          maxW={
            image?.url
              ? { base: '100%', md: '45%' }
              : { base: '100%', md: '75%' }
          }
          minH={'246px'}
          gap={'19px'}
        >
          <Heading
            fontWeight={'700'}
            fontSize={{ sm: '1.625rem', md: '1.5rem', lg: '2.25rem' }}
            lineHeight={'2.25rem'}
          >
            {info?.entry?.seo?.meta_page_title || info?.entry?.title}
          </Heading>
          <Text fontWeight={'400'} fontSize={'1.125rem'} lineHeight={'1.5rem'}>
            {info?.entry?.seo?.meta_description || info?.entry?.description}
          </Text>

          <Flex align={'center'} lineHeight={'1.25rem'} gap={'16px'}>
            <Link
              fontWeight={'600'}
              fontSize={'1rem'}
              href={info?.entry?.url || info?.entry?.link?.href}
            >
              {link || info?.entry?.link?.title}
            </Link>
            {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
            <RightArrow />
          </Flex>
        </Flex>
        <Hide below="md">
          {image?.url && <IconBox image={image} round={round}></IconBox>}
        </Hide>
      </Flex>
    </Box>
  );
}

function IconBox(props) {
  const { image, round } = props;
  // logger.log('promo image', image);
  return (
    <Flex justify={'center'} w={'40%'} mx={'auto'}>
      <Image borderRadius={round ? '400px' : '30px'} src={image?.url}></Image>
    </Flex>
  );
}

// Unused Box with White Circle Background

// function IconBox(props) {
//   const { image } = props;
//   return (
//     <Flex justify={'center'} align={'center'}>
//       <Flex
//         align={'center'}
//         justify={'center'}
//         // border={'red solid 2px'}
//         h={'100px'}
//         w={'100px'}
//         borderRadius={'100%'}
//         bg={'white'}
//       >
//         <Image src={image.url}></Image>
//       </Flex>
//     </Flex>
//   );
// }

import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react';
import { useKeenSlider } from 'keen-slider/react'; // using version 6.6.10
import * as React from 'react';

export const Carousel = React.forwardRef(function Carousel(props, ref) {
  return (
    <Flex
      ref={ref}
      className="chakra-carousel"
      overflow="hidden"
      position="relative"
      userSelect="none"
      {...props}
    />
  );
});
export const CarouselSlide = (props) => (
  <Flex
    // border={'orange solid 2px'}
    gap={{ base: '', md: '16px', lg: '31px' }}
    className="chakra-carousel__slide"
    position="relative"
    overflow="hidden"
    w={'100%'}
    minH="100%"
    mx={'auto'}
    _focus={{ bg: 'red' }}
    {...props}
  />
);
export const SingleCarouselSlide = (props) => (
  <Flex
    // border={'brown solid 2px'}
    gap={{ base: '', md: '16px', lg: '31px' }}
    position="relative"
    overflow="hidden"
    w={'100%'}
    minH="100%"
    // maxW={'1240px'}
    // mx={'auto'}
    {...props}
  />
);

export const CarouselIconButton = (props) => (
  <IconButton
    variant="unstyled"
    boxSize="auto"
    minW="auto"
    display="inline-flex"
    fontSize="2xl"
    color={useColorModeValue('gray.600', 'gray.400')}
    _hover={{
      color: useColorModeValue('blue.500', 'blue.300'),
      _disabled: {
        color: useColorModeValue('gray.600', 'gray.400'),
      },
    }}
    _active={{
      color: useColorModeValue('blue.600', 'blue.400'),
    }}
    _focus={{
      boxShadow: 'none',
    }}
    _focusVisible={{
      boxShadow: 'outline',
    }}
    {...props}
  />
);
export const useCarousel = (options) => {
  const defaultOptions = {
    selector: '.chakra-carousel__slide',
  };
  return useKeenSlider({
    ...defaultOptions,
    ...options,
  });
};

import { Field } from 'formik';
import { Textarea } from '@chakra-ui/react';
const MultiLine = (props) => {
  const { item, errorOrValidColor, values } = props;
  return (
    <Field
      as={Textarea}
      name={item.field_name}
      id={item.field_name}
      placeholder={item.placeholder_text}
      focusBorderColor={errorOrValidColor}
      border={`2px solid ${errorOrValidColor}`}
      value={values[item.field_name] || ''}
      _hover={{
        borderColor: '#1E1E1E',
      }}
      _focus={{
        boxShadow: 'none',
      }}
      isRequired={item.required}
    />
  );
};

export default MultiLine;

import React, { useState, useLayoutEffect } from 'react';
import { Menu, MenuButton, IconButton } from '@chakra-ui/react';

import SubMenu from './subMenu';
import MainMobileMenu from './mainMenu';

export default function MobileMenu(props) {
  const {
    data,
    setOpen,
    icon,
    defOpen,
    direction,
    viewBack,
    smv,
    setSMV,
    setMenuGroup,
  } = props;

  // smd is Secondary Menu Data
  const [smd, setSMD] = useState();

  const [height, setHeight] = useState();
  useLayoutEffect(() => {
    handleResize();
  }, []);
  function handleResize() {
    setHeight(window?.innerHeight);
  }
  window.addEventListener('resize', handleResize);

  return (
    <Menu
      defaultIsOpen={defOpen}
      direction={direction}
      onClose={() => {
        setOpen(false);
        viewBack(false);
        setSMV(false);
        setMenuGroup(true);
      }}
      onOpen={() => {
        setMenuGroup(false);
      }}
      eventListeners={{ resize: true }}
      isLazy
      closeOnBlur={false}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            size={'lg'}
            // aria-label="Options"

            icon={icon}
            variant="outline"
            color={'inherit'}
            border={'none'}
            onClick={() => {
              setOpen(!defOpen);
            }}
          />

          {isOpen && !smv && (
            <MainMobileMenu
              data={data}
              {...props}
              setSMD={setSMD}
              height={height}
            />
          )}
          {isOpen && smv && smd && <SubMenu {...props} smd={smd} />}
        </>
      )}
    </Menu>
  );
}

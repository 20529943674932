import React from 'react';
import { Flex, Text, Hide, Box, Link, useTheme } from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import MainButtonGroup from '../../../buttonGroup';
import UtilNav from '../../../../util_nav';
import RightArrow from '../../../../../icons/rightArrow';
import logger from 'utils/logger';

export default function MainMobileMenu(props) {
  const {
    data,
    height,
    setOpen,
    defOpen,
    setActive,
    viewBack,
    setSMV,
    setMenuGroup,
    setSMD,
    buttonData,
  } = props;
  const theme = useTheme();
  logger.log('ButtonData: ', buttonData);
  return (
    <Flex
      direction={'column'}
      position={'absolute'}
      top={'100%'}
      bg={theme.colors.Grey5}
      borderRadius={'none'}
      // border={'limegreen dashed 2px'}
      m={0}
      minW={'100%'}
      minH={height - 104}
      zIndex={100000}
    >
      <Hide above="md">
        <Flex
          borderBottom={`1px solid ${theme.colors.Grey3}`}
          py={'20px'}
          w={'100%'}
          color={'black'}
        >
          <UtilNav
            color={'black'}
            setOpen={setOpen}
            defOpen={defOpen}
            setActive={setActive}
            viewBack={viewBack}
            setSMV={setSMV}
            setMenuGroup={setMenuGroup}
          />
        </Flex>
      </Hide>

      {data?.map((item, i) => {
        return (
          <Box
            key={i}
            w={'100%'}
            color={theme.colors.Grey1}
            _hover={{ color: 'DarkGreen' }}
            _focus={{ color: 'DarkGreen' }}
            _active={{ color: 'DarkGreen' }}
          >
            {item.menu ? (
              <Flex
                onClick={() => {
                  setSMV(true);
                  setSMD(item.menu.menu[0]);
                  viewBack(true);
                }}
                px={'30px'}
                borderBottom={`1px solid ${theme.colors.Grey3}`}
                borderTop={i === 0 ? `1px solid ${theme.colors.Grey3}` : 'none'}
              >
                <Flex
                  w={'100%'}
                  justify={'space-between'}
                  fontWeight={'700'}
                  fontSize={'1.25rem'}
                  lineHeight={'1.7rem'}
                  my={'20px'}
                  _hover={{
                    // textDecor: 'underline',
                    color: 'DarkGreen',
                  }}
                >
                  <Text
                    color={'black'}
                    _hover={{ color: 'DarkGreen', textDecoration: 'underline' }}
                    _focus={{ color: 'DarkGreen', textDecoration: 'underline' }}
                    _active={{
                      color: 'DarkGreen',
                      textDecoration: 'underline',
                    }}
                  >
                    {item.menu.menu[0]?.title}
                  </Text>
                  <RightArrow
                    _hover={{ textDecor: 'none' }}
                    _active={{ textDecor: 'none' }}
                    _focus={{ textDecor: 'none' }}
                  />
                  {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
                </Flex>
              </Flex>
            ) : (
              <Flex
                onClick={() => setOpen(!defOpen)}
                px={'30px'}
                borderBottom={theme.colors.Grey1}
              >
                <Flex
                  // as={Button}
                  w={'100%'}
                  justify={'space-between'}
                  fontWeight={'700'}
                  fontSize={'1.25rem'}
                  lineHeight={'1.7rem'}
                  my={'20px'}
                  _hover={{
                    // textDecor: 'underline',
                    color: 'DarkGreen',
                  }}
                >
                  <Text
                    as={Link}
                    href={item.link?.link?.href}
                    color={'black'}
                    onClick={() => {
                      setOpen(false);
                      viewBack(false);
                      setSMV(false);
                      setMenuGroup(true);
                    }}
                    _hover={{ color: 'DarkGreen', textDecoration: 'underline' }}
                    _focus={{ color: 'DarkGreen', textDecoration: 'underline' }}
                    _active={{
                      color: 'DarkGreen',
                      textDecoration: 'underline',
                    }}
                  >
                    {item.link?.link?.title}
                  </Text>
                  <RightArrow
                    _hover={{ textDecor: 'none' }}
                    _active={{ textDecor: 'none' }}
                    _focus={{ textDecor: 'none' }}
                  />
                  {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
                </Flex>
              </Flex>
            )}
          </Box>
        );
      })}
      <Flex
        onClick={() => setOpen(!defOpen)}
        px={'30px'}
        borderBottom={`1px solid ${theme.colors.Grey3}`}
        color={theme.colors.Grey1}
      >
        <Flex
          // as={Button}
          w={'100%'}
          justify={'space-between'}
          fontWeight={'700'}
          fontSize={'1.25rem'}
          lineHeight={'1.7rem'}
          my={'20px'}
          _hover={{
            // textDecor: 'underline',
            color: 'DarkGreen',
          }}
        >
          <Text
            as={Link}
            href={buttonData?.store_link?.href}
            color={'black'}
            onClick={() => {
              setOpen(false);
              viewBack(false);
              setSMV(false);
              setMenuGroup(true);
            }}
            _hover={{ color: 'DarkGreen', textDecoration: 'underline' }}
            _focus={{ color: 'DarkGreen', textDecoration: 'underline' }}
            _active={{ color: 'DarkGreen', textDecoration: 'underline' }}
          >
            {buttonData?.store_link?.title}
          </Text>
          <RightArrow
            _hover={{ textDecor: 'none' }}
            _active={{ textDecor: 'none' }}
            _focus={{ textDecor: 'none' }}
          />
          {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
        </Flex>
      </Flex>
      <Flex alignSelf={'stretch'} flexGrow={2}></Flex>

      <Flex py={'48px'} borderTop={`1px solid ${theme.colors.Grey3}`}>
        <MainButtonGroup data={buttonData} store hideStore={true} />
      </Flex>
      {/* </MenuList> */}
    </Flex>
  );
}

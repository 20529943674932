import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../utils/api';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import useLanguageCheck from '../hooks/useLanguageCheck';
import BlockContainer from '../components/modularBlocks/container';
import Metadata from '../components/metadata';
import BannerGroup from '../components/bannerGroup';
import HomeAlert from '../components/message_alert/home_alert';
import NewsBanner from '../components/newsBanner';
import Banner from '../components/modularBlocks/banner';
import Loading from '../components/loadingIndicator/loading';

export default function HomePage(props) {
  let { l } = useParams();
  l = useLanguageCheck(l);
  const [data, setData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await API.getAllEntriesWithRef(
      'home_page',
      'alert,headlines.article',
      l,
    );

    info.map((entry) => {
      if (entry.locale === l) {
        return setData(entry);
      }
    });

    if (!data) {
      setData(info[0]);
    }
  }
  return (
    <main className="main">
      <SkipNavContent />
      {data ? (
        <div>
          {data.seo && <Metadata data={data.seo} override={data} />}
          <HomeAlert />
          {data.hero_banner && <Banner data={data.hero_banner} />}
          {data.banner_group && <BannerGroup refData={data.banner_group[0]} />}
          {data.headlines && <NewsBanner data={data.headlines} />}
          {data.page_content && <BlockContainer data={data.page_content} />}
        </div>
      ) : (
        <Loading minH={'100vh'} />
      )}
    </main>
  );
}

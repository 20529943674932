import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AllAlert from '../components/message_alert/all_alert';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import Metadata from '../components/metadata';
import Banner from '../components/modularBlocks/banner';
import BlockContainer from '../components/modularBlocks/container';
// import BreadCrumbs from '../components/navigation/breadcrumbs';
import InteriorHeader from '../components/modularBlocks/section/interior_section_header';
import api from '../utils/api';
import Loading from '../components/loadingIndicator/loading';
import BannerGroup from '../components/bannerGroup';
import SectionBanner from '../components/modularBlocks/sectionBanner';
import Navigation from '../components/navigation';
import ConferenceNav from '../components/conferences/index';

export default function SubPage(props) {
  const { ct, parent } = props;
  const [data, setData] = useState();
  const [banner, setBanner] = useState();
  const path = window.location.pathname;
  const location = useLocation();

  useEffect(() => {
    getData();
  }, [ct, path]);

  function toHash() {
    const element = document.getElementById(location.hash.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      return observer.disconnect();
    }
    return;
  }

  const observer = new MutationObserver(() => {
    toHash();
  });

  async function getData() {
    // contentTypeUid, articleName, locale = 'en-us', ref
    const info = await api.getArticle(ct, path, 'en-us', 'sub_nav');
    setData(info[0]);

    if (parent) {
      const banner = await api.getBanner(parent);
      setBanner(banner ? banner[0] : undefined);
    }
  }

  return (
    <main className="main">
      <SkipNavContent />
      {data ? (
        <div>
          {data.seo && <Metadata data={data.seo} override={data} />}
          {/* Message Alert */}
          <AllAlert />
          {data.hero_banner && <Banner data={data.hero_banner} />}
          {/* Interior Section Header. Option to include section header set by parent page */}
          {data.inherit_section_header_set_by_overview_page
            ? banner?.section_header_for_subpages[0] && (
                <InteriorHeader data={banner.section_header_for_subpages[0]} />
              )
            : data.interior_section_header?.length > 0 && (
                <InteriorHeader data={data.interior_section_header[0]} />
              )}
          {/* Banner Group */}
          {data.banner_group && <BannerGroup refData={data.banner_group[0]} />}
          {/* Section Hero Banner */}
          {data.section_hero_banner?.length > 0 && (
            <SectionBanner data={data.section_hero_banner[0]} />
          )}
          {/* Breadcrumbs */}
          {/* <BreadCrumbs
            data={data.breadcrumb}
            currentPage={data.title}
            display={data.breadcrumb}
          />*/}
          {data.sub_nav && <ConferenceNav data={data.sub_nav[0]} />}
          {data.show_breadcrumb && <Navigation />}
          {/* Page Content consisting of Modular Blocks */}
          {data.page_content && <BlockContainer data={data.page_content} />}
        </div>
      ) : (
        <Loading minH={'100vh'} />
      )}
      {location.hash &&
        observer.observe(document.body, {
          childList: true, // observe direct children
          subtree: true, // and lower descendants too
          attributes: true,
          attributeFilter: ['id'],
        })}
    </main>
  );
}

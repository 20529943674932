import React from 'react';
import { Link, Flex } from '@chakra-ui/react';

import LeftArrow from '../../icons/leftArrow';

export default function LinkBanner(props) {
  const { data } = props;
  return (
    <Flex w={'100%'} bg={'DarkGreen'}>
      <Flex
        maxW={'1440px'}
        w="100%"
        px={'30px'}
        mx={'auto'}
        gap={'16px'}
        align={'center'}
        lineHeight={'2.25rem'}
        fontSize={{ base: '18px', md: '18px', lg: '24px' }}
        fontFamily={'Lato'}
        fontWeight={'700'}
        color={'white'}
        py={'7px'}
      >
        <LeftArrow />
        <Link href={data?.href}>{data?.title}</Link>
      </Flex>
    </Flex>
  );
}

import React from 'react';
import { Box } from '@chakra-ui/react';

const RightCircleArrow = ({ variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-solid'}  fa-circle-right ${fasize}`}
      {...rest}
    />
  );
};

export default RightCircleArrow;

import React, { useEffect, useState } from 'react';
import { Flex, Text, Button, Checkbox } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { WithSearch } from '@elastic/react-search-ui';
import logger from 'utils/logger';

export default function EntityFilter({
  filters,
  filter,
  more,
  setMore,
  tags,
  setTags,
  ...rest
}) {
  const [show, setShow] = useState(false);
  // useEffect is checking to see if any of the filters from CS match filters from schema and setting show based on positive matches only
  useEffect(() => {
    filter?.map((filter) => {
      if (filters?.includes(filter.title?.toLowerCase().replaceAll(' ', '_'))) {
        return setShow(true);
      }
    });
  }, [filter]);
  logger.log('Filters: ', filters, 'filter: ', filter);
  if (show) {
    return (
      <Flex
        // onClick={() => {
        //   if (more.includes(true)) {
        //     setMore((current) => {
        //       const n = current.map((x) => false);
        //       return n;
        //     });
        //   }
        // }}
        {...rest}
      >
        <Text
          fontSize={'1.5rem'}
          lineHeight={'1.875rem'}
          fontWeight={'700'}
          letterSpacing={'-.02em'}
          mr={'26px'}
        >
          Filter by:{' '}
        </Text>
        <Flex gap={'10px'}>
          {filter?.map((filter, index) => {
            const cols = [[], [], []];

            filter.options.map((choice, i) => {
              return cols[i % 3].push(choice);
            });

            if (
              filters?.includes(
                filter.title?.toLowerCase().replaceAll(' ', '_'),
              )
            ) {
              return (
                <Flex
                  direction={'column'}
                  key={index}
                  pos={'relative'}
                  //   border={'hotpink solid 2px'}
                  zIndex={199}
                  overflow={'visible'}
                >
                  <Button
                    onClick={() =>
                      setMore((current) => {
                        const x = !current[index];
                        const n = current.map((x) => false);
                        return [...n.slice(0, index), x, ...n.slice(index + 1)];
                      })
                    }
                    // rightIcon={
                    //   more[index] ? <ChevronUpIcon /> : <ChevronDownIcon />
                    // }
                    border={'solid 1px'}
                    borderColor={more[index] ? 'PrimaryGreen' : '#00000020'}
                    bgColor={'inherit'}
                  >
                    {' '}
                    {filter.title}
                    <FontAwesomeIcon
                      icon={solid('chevron-down')}
                      style={{ margin: 8 }}
                    />
                  </Button>
                  <Flex
                    pos={'absolute'}
                    zIndex={200}
                    top={'100%'}
                    mt={'7px'}
                    display={more[index] ? 'flex' : 'none'}
                    bgColor={'#FFFFFF'}
                    flexWrap={'wrap'}
                    p={'35px'}
                    w={'fit-content'}
                    // border={'solid lime 2px'}
                    boxShadow="0px 4px 10px 0px rgba(0,0,0,0.18)"
                    border={'solid 1px #cccccc'}
                    borderRadius={'10px'}
                    // onMouseLeave={() =>
                    //   setMore(current => [
                    //     ...current.slice(0, index),
                    //     !current[index],
                    //     ...current.slice(index + 1),
                    //   ])
                    // }
                  >
                    <Flex direction={'column'} gap={'20px'}>
                      <Flex w={'100%'} gap={'20px'}>
                        {cols.map((col, i) => {
                          return (
                            <Flex
                              key={i}
                              flex={1}
                              //   border={'cyan 1px solid'}
                              direction={'column'}
                              gap={'.5rem'}
                            >
                              {col.map((choice, i) => {
                                return (
                                  <Checkbox
                                    // border={'solid 2px violet'}
                                    key={i}
                                    w={'max-content'}
                                    fontFamily={'Open Sans'}
                                    fontSize={'1rem'}
                                    lineHeight={'1.375rem'}
                                    colorScheme={'green'}
                                    onChange={(e) => {
                                      return e.target.checked
                                        ? setTags([
                                            ...tags,
                                            `${filter.title.toLowerCase()}=${choice}`,
                                          ])
                                        : setTags(() => {
                                            return tags.filter((x) => {
                                              return (
                                                x !==
                                                `${filter.title.toLowerCase()}=${choice}`
                                              );
                                            });
                                          });
                                    }}
                                    isChecked={
                                      tags.includes(
                                        `${filter.title.toLowerCase()}=${choice}`,
                                      )
                                        ? true
                                        : false
                                    }
                                  >
                                    {choice}
                                  </Checkbox>
                                );
                              })}
                            </Flex>
                          );
                        })}
                      </Flex>
                      <Flex
                        w={'100%'}
                        // border={'teal solid 2px'}
                        justify={'center'}
                        align={'center'}
                      >
                        <WithSearch
                          mapContextToProps={({ addFilter }) => ({
                            addFilter,
                          })}
                        >
                          {({ addFilter }) => {
                            return (
                              <Button
                                variant={'primary'}
                                size={'md'}
                                borderRadius={'54px'}
                                onClick={() => {
                                  setMore((current) => [
                                    ...current.slice(0, index),
                                    !current[index],
                                    ...current.slice(index + 1),
                                  ]);
                                  tags?.map((tag) => {
                                    return addFilter('tags', tag);
                                  });
                                }}
                              >
                                Apply Filters
                              </Button>
                            );
                          }}
                        </WithSearch>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              );
            }
          })}
        </Flex>
      </Flex>
    );
  }
}

import { useState } from 'react';

export default function useCompileTags(data) {
  const [tags, setTags] = useState([]);

  const compileTags = () => {
    setTags([]);
    const compiler = [];
    data?.tags_group?.industry?.length > 0 &&
      compiler.push(data?.tags_group?.industry);
    data?.tags_group?.region?.length > 0 &&
      compiler.push(data?.tags_group?.region);
    data?.tags_group?.subject?.length > 0 &&
      compiler.push(data?.tags_group?.subject);
    setTags(() => [...compiler.flat(1)]);
  };
  return [tags, compileTags];
}

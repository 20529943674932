import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Link } from '@chakra-ui/react';
import UpRightFromSquare from '../../../icons/upRightFromSq';
// import { Modal } from '@chakra-ui/react';
import api from '../../../../utils/api';

export default function CtaButton(props) {
  const { data, newTab } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }
  return (
    <Box
      maxW={{ lg: '1200px', base: '100%' }}
      mx={{ base: '1rem', lg: 'auto' }}
      my={'1rem'}
      display="flex"
      justifyContent={newData?.button_alignment}
    >
      {newData && (
        <Button
          size={newData.cta_button.button_size?.toLowerCase()}
          variant={newData.cta_button.button_type?.toLowerCase()}
          as={Link}
          href={newData.cta_button.button_link?.href}
          target={
            newData.cta_button.new_window ? '_blank' : newTab ? newTab : '_self'
          }
          gap="10px"
        >
          {newData.cta_button.button_link?.title}
          {/* <FontAwesomeIcon icon={solid('up-right-from-square')} /> */}
          {newData.cta_button.external_link && (
            <UpRightFromSquare fasize={'fa-sm'} />
          )}
        </Button>
      )}
    </Box>
  );
}

import { Box, Flex, Icon, Link, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RightArrow from '../../icons/rightArrow';
import logger from 'utils/logger';

export default function CTACallout(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntryWithRef(
      data?._content_type_uid,
      data?.uid,
      l,
      'theme',
    );

    setNewData(info.entry);
  }

  const justifyContentValue =
    newData?.cta_item.length < 3 ? 'center' : 'space-between';

  const gapValue = newData?.cta_item.length < 3 ? '34px' : '10px';

  return (
    <Box>
      {newData && (
        <Box
          as={Flex}
          flexDirection="column"
          backgroundColor={newData.theme[0]?.colors?.background_color?.replace(
            ' ',
            '',
          )}
          justifyContent="center"
          alignContent="center"
          py={'30px'}
        >
          <Box
            alignSelf="center"
            maxW={{ lg: '66%', md: '88%', base: '86%' }}
            minW={{ lg: '66%', md: '88%', base: '86%' }}
            paddingY="24px"
          >
            <Box
              as={Flex}
              alignSelf="center"
              justifyContent="space-around"
              alignContent="center"
              textAlign="center"
            >
              <Heading
                color={newData.theme[0]?.colors?.text_color}
                fontWeight="400"
                fontSize={{ lg: '26px', md: '20px', sm: '16px', base: '16px' }}
                lineHeight={{
                  lg: '34px',
                  md: '34px',
                  sm: '22px',
                  base: '22px',
                }}
                // letterSpacing="-0.02em"
                textAlign="center"
              >
                {newData.section_heading}
              </Heading>
            </Box>
            <Box
              as={Flex}
              flexDirection={{ md: 'row', base: 'column' }}
              justifyContent={justifyContentValue}
              alignSelf="center"
              paddingTop={{ lg: '24px', md: '8px', base: '8px' }}
              paddingBottom="8px"
              gap={gapValue}
            >
              {newData.cta_item?.map((item, i) => {
                return (
                  <Box
                    as={Flex}
                    border="1px solid #CCCCCC"
                    borderRadius="20px"
                    boxShadow="0px 24px 34px rgba(0, 0, 0, 0.03)"
                    backgroundColor="white"
                    h={
                      item?.cta_link?.title
                        ? { md: '198px', sm: '144px', base: '144px' }
                        : { md: '150px', sm: '144px', base: '144px' }
                    }
                    w={{ md: '31%', base: '100%' }}
                    key={i}
                  >
                    <Box
                      as={Flex}
                      marginLeft="5%"
                      marginY={{ lg: '6%', md: '6%', sm: '5%', base: '5%' }}
                      gap={{ lg: '24px', md: '24px', sm: 'none' }}
                      justifyContent={{
                        md: 'flex-start',
                        sm: 'space-around',
                      }}
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Box
                        as={Flex}
                        border="1px solid #CCCCCC"
                        h={{
                          lg: '60px',
                          md: '60px',
                          sm: '60px',
                          base: '60px',
                        }}
                        w={{
                          lg: '60px',
                          md: '60px',
                          sm: '60px',
                          base: '60px',
                        }}
                        borderRadius="50%"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                        aspectRatio={1}
                      >
                        <FontAwesomeIcon
                          as={Icon}
                          fontSize="32px"
                          color="#5E9C44"
                          icon={
                            item?.font_awesome === 'School'
                              ? icon({ name: 'school' })
                              : item?.font_awesome === 'Envelope'
                              ? icon({ name: 'envelope' })
                              : item?.font_awesome === 'Certificate'
                              ? icon({ name: 'certificate' })
                              : item?.font_awesome === 'Calendar'
                              ? icon({ name: 'calendar' })
                              : item?.font_awesome === 'Users'
                              ? icon({ name: 'users' })
                              : item?.font_awesome === 'User'
                              ? icon({ name: 'user' })
                              : item?.font_awesome === 'Rectangle'
                              ? icon({ name: 'rectangle' })
                              : item?.font_awesome === 'Presentation Screen'
                              ? icon({ name: 'presentation-screen' })
                              : item?.font_awesome === 'Laptop'
                              ? icon({ name: 'laptop' })
                              : item?.font_awesome === 'Computer'
                              ? icon({ name: 'computer' })
                              : item?.font_awesome === 'Megaphone'
                              ? icon({ name: 'megaphone' })
                              : item?.font_awesome === 'People Roof'
                              ? icon({ name: 'people-roof' })
                              : item?.font_awesome === 'Globe'
                              ? icon({ name: 'globe' })
                              : item?.font_awesome === 'Inbox'
                              ? icon({ name: 'inbox' })
                              : item?.font_awesome === 'Map Location Dot'
                              ? icon({ name: 'map-location-dot' })
                              : item?.font_awesome === 'Arrow Up Right Dots'
                              ? icon({ name: 'arrow-up-right-dots' })
                              : item?.font_awesome === 'Person Chalkboard'
                              ? icon({ name: 'person-chalkboard' })
                              : item?.font_awesome === 'Screen Users'
                              ? icon({ name: 'screen-users' })
                              : item?.font_awesome === 'Certificate'
                              ? icon({ name: 'certificate' })
                              : item?.font_awesome === 'Award'
                              ? icon({ name: 'award' })
                              : item?.font_awesome === 'Building'
                              ? icon({ name: 'building' })
                              : item?.font_awesome === 'Podium'
                              ? icon({ name: 'podium' })
                              : item?.font_awesome === 'Handshake'
                              ? icon({ name: 'handshake' })
                              : item?.font_awesome === 'Circle Question'
                              ? icon({ name: 'circle-question' })
                              : item?.font_awesome === 'Square Question'
                              ? icon({ name: 'square-question' })
                              : item?.font_awesome === 'Question'
                              ? icon({ name: 'question' })
                              : item?.font_awesome === 'Arrow Progress'
                              ? icon({ name: 'arrow-progress' })
                              : item?.font_awesome === 'Calendar Star'
                              ? icon({ name: 'calendar-star' })
                              : item?.font_awesome === 'Book Open'
                              ? icon({ name: 'book-open' })
                              : item?.font_awesome === 'Books'
                              ? icon({ name: 'books' })
                              : item?.font_awesome === 'Book Open Cover'
                              ? icon({ name: 'book-open-cover' })
                              : item?.font_awesome === 'Print'
                              ? icon({ name: 'print' })
                              : item?.font_awesome === 'Newspaper'
                              ? icon({ name: 'newspaper' })
                              : logger.log('unknown icon')
                          }
                        />
                      </Box>
                      <Heading
                        fontWeight="800"
                        fontStyle="normal"
                        fontSize="16px"
                        lineHeight="20px"
                        letterSpacing={{
                          md: '0.1em',
                          sm: '0.1em',
                          base: '0.1em',
                        }}
                      >
                        {item?.cta_text?.toUpperCase()}
                      </Heading>
                      {item?.cta_link?.title && (
                        <Link
                          color="#006954"
                          fontWeight="600"
                          fontSize="16px"
                          lineHeight="20px"
                          href={item?.cta_link?.href}
                        >
                          {item?.cta_link?.title}{' '}
                          {/* <FontAwesomeIcon icon={solid('arrow-right-long')} /> */}
                          <RightArrow />
                        </Link>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

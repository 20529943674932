import React, { useEffect, useState } from 'react';
import Article from './pages/article';
import ResourceEntry from './pages/resourceEntry';
import SubPage from './pages/subPage';
import api from './utils/api';

export default function PageFilter({ ct }) {
  const path = window.location.pathname;
  const [data, setData] = useState();

  useEffect(() => {
    typeComponent();
  }, []);

  async function typeComponent(iteration = 0) {
    const info = await api.checkResource(ct[iteration], path);
    if (!Array.isArray(info) || info.length === 0) {
      return typeComponent(iteration + 1);
    }
    setData(ct[iteration]);
  }

  if (data) {
    if (data === 'news_article' || data === 'news_blurb') {
      return <Article ct={data} />;
    }
    if (data === 'resource_entry') {
      return <ResourceEntry ct={data} />;
    }
    return <SubPage ct={data} />;
  }
  return <></>;
}

import React, { useEffect } from 'react';
import { WithSearch } from '@elastic/react-search-ui';
import { Flex, Box, Button, useTheme } from '@chakra-ui/react';

export default function TagContainer({ setTags }) {
  useEffect(() => {
    // Fetch initial tags when component mounts
    const initialTags = localStorage.getItem('tags');
    if (initialTags) {
      setTags(JSON.parse(initialTags));
    }
  }, [setTags]);

  const theme = useTheme();

  return (
    <WithSearch
      mapContextToProps={({ filters, removeFilter }) => ({
        filters,
        removeFilter,
      })}
    >
      {({ filters, removeFilter }) => {
        if (!filters || filters.length === 0) {
          return null; // Do not render if no filters are applied
        }
        return (
          <Flex gap={'6px'} flexWrap={'wrap'} justify={'start'} w={'100%'}>
            {filters.map((filter, i) => {
              // Check if the filter is related to sorting or start/end dates
              if (
                filter.field === 'sort' ||
                filter.field === 'Start' ||
                filter.field === 'End'
              ) {
                return null;
              }
              return filter.values.map((tagValue, index) => {
                // Accessing the tag value from the filter object
                const valueWithoutPrefix = tagValue.split('=')[1];
                return (
                  <Button
                    key={`${i}-${index}`} // Using a combination of i and index for the key to ensure uniqueness
                    bgColor={'inherit'}
                    border={`solid 1px ${theme.colors.Grey3}`}
                    color={'Grey1'}
                    fontFamily={'Lato'}
                    weight={'600'}
                    fontSize={'.875rem'}
                    lineHeight={'1.25rem'}
                    onClick={() => {
                      setTags((prevTags) => {
                        return prevTags.filter(
                          (tag) => tag !== valueWithoutPrefix,
                        );
                      });
                      return removeFilter('tags', tagValue);
                    }}
                  >
                    {valueWithoutPrefix}
                    <Box className="fa-solid fa-close" ml={'16px'} />
                  </Button>
                );
              });
            })}
          </Flex>
        );
      }}
    </WithSearch>
  );
}

// import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import HomeBanner from './homeBanner';

export default function BannerGroup(props) {
  const { refData } = props;

  const [banners, setBanners] = useState([]);

  const random = Math.floor(Math.random() * banners.length);
  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    const info = await api.getSpecificEntryWithRef(
      refData?._content_type_uid,
      refData?.uid,
      'en-us',
      'home_banner.theme',
    );

    info?.entry?.home_banner && filterBanners(info.entry.home_banner);
  }

  function filterBanners(banners) {
    banners.map((banner) => {
      banner.publish_details?.time &&
        setBanners((current) => [...current, banner]);
    });
  }
  return (
    <>
      {/* <Text>{data?.home_banner[random]?.image_style}</Text> */}
      <HomeBanner data={banners[random]} />
    </>
  );
}

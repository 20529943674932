import React from 'react';
import { Flex } from '@chakra-ui/react';

import { FiveCard, FourCard, SixCard, ThreeCard, TwoCard } from './layouts';

export default function TrainingStacks(props) {
  const { data, ...rest } = props;

  return (
    <Flex direction={'column'} my={'16px'}>
      {data?.length === 2 && <TwoCard data={data} {...rest} />}
      {data?.length === 3 && <ThreeCard data={data} {...rest} />}
      {data?.length === 4 && <FourCard data={data} {...rest} />}
      {data?.length === 5 && <FiveCard data={data} {...rest} />}
      {data?.length === 6 && <SixCard data={data} {...rest} />}
    </Flex>
  );
}

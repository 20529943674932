import { Flex, Show, useTheme } from '@chakra-ui/react';
import React from 'react';
import DesktopMenu from './desktopMenu';

export default function Menu(props) {
  const theme = useTheme();
  return (
    <Flex
      direction={'column'}
      position={'absolute'}
      top="100%"
      left="0"
      w={'100%'}
      zIndex={100000000}
      color={theme.colors.Black}
    >
      <Show above={'lg'}>
        <DesktopMenu {...props} />
      </Show>
    </Flex>
  );
}

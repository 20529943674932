import { Flex, Text, Link, Image, useTheme } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Fallback from '../../../../assets/vectors/fallbackGrey.svg';

export default function AdBanner(props) {
  const { data } = props;
  const [banners, setBanners] = useState([]);
  const random = Math.floor(Math.random() * banners.length);
  const theme = useTheme();
  useEffect(() => {
    filterAdBanner();
  }, [data]);
  function filterAdBanner() {
    const set = [];
    data?.map((x) => x.publish_details?.time && set.push(x));
    setBanners(set);
  }

  return (
    banners.length > 0 && (
      <Link href={banners[random]?.ad?.link} target={'_blank'} w={'100%'}>
        <Flex
          direction={'column'}
          w={'100%'}
          maxW={'1264px'}
          justify={'start'}
          mx={'auto'}
        >
          <Flex bg={theme.colors.Grey4} h="19px">
            <Text
              mx={'auto'}
              textAlign={'center'}
              color={theme.colors.Grey1}
              fontWeight={'600'}
              fontSize={'.875rem'}
              lineHeight={'1.192rem'}
              letterSpacing={'.22em'}
            >
              ADVERTISEMENT
            </Text>
          </Flex>
          <Flex
            // h={h || '125px'}
            w={'100%'}
            align={'start'}
            justify={'start'}
            // bgImage={`${banners[0]?.ad?.ad_image?.url}`}
            // bgSize={'contain'}
            // bgRepeat={'no-repeat'}
            // bgPos={'top'}
            // border={'2px red solid'}
          >
            <Image
              src={`${banners[random]?.ad?.ad_image?.url}?width=1264`}
              fit={'contain'}
              fallbackSrc={Fallback}
              loading={'lazy'}
              alt={banners[random]?.ad.alt_text}
            ></Image>
          </Flex>
        </Flex>
      </Link>
    )
  );
}

import { Box, Heading, Link, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../utils/api';
import ImageCard from './ImageCard';
import RichTextEditorMB from '../rte/index';

// This card corresponds to the Cards, Image content model in ContentStack

export default function Sponsors(props) {
  const { data } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }
  return (
    <Box
      maxW={'1440px'}
      mx={'auto'}
      px={{ base: '16px', md: '24px', lg: '60px' }}
      py="32px"
    >
      {newData && (
        <Box>
          {newData.headline_text && (
            <Box paddingBottom={{ md: '48px', base: '16px' }}>
              <Heading
                as="h2"
                display="inline-block"
                paddingBottom={{ md: '16px', base: '8px' }}
                borderBottom="2px solid #cccccc"
                fontSize={{ md: '36px', base: '25px' }}
              >
                {newData.headline_text}
              </Heading>

              <Box paddingTop={{ md: '16px', base: '8px' }}>
                <Text
                  as={Link}
                  href={newData.cta.cta_title.href}
                  target={newData.cta.new_window ? '_blank' : '_self'}
                  fontWeight="400"
                  fontSize={{ md: '20px', base: '16px' }}
                  letterSpacing="-2%"
                  textColor="#006955"
                  fontStyle="italic"
                >
                  {newData.cta.cta_title.title}
                </Text>
              </Box>
            </Box>
          )}

          <Box display="flex" flexDir="column">
            {newData?.primary_group.length > 0 && (
              <Box
                display="flex"
                flexDir="column"
                justifyContent={{ md: 'flex-start', base: 'center' }}
                alignContent={{ md: 'flex-start', base: 'center' }}
                paddingBottom={{ lg: '48px', base: '24px' }}
                rowGap={{ lg: '40px', base: '24px' }}
              >
                {newData?.primary_group?.map((primaryGroup, i) => {
                  return (
                    <Box key={i}>
                      {primaryGroup?.primary_group_title && (
                        <Box
                          marginBottom={
                            primaryGroup.primary_group_subtext ? '0px' : '24px'
                          }
                        >
                          <Text
                            as="h3"
                            fontWeight="700"
                            fontSize={{ lg: '32px', md: '30px', base: '20px' }}
                          >
                            {primaryGroup?.primary_group_title}
                          </Text>
                        </Box>
                      )}
                      {primaryGroup?.primary_group_subtext && (
                        <RichTextEditorMB
                          data={primaryGroup.primary_group_subtext}
                          paddingLeft="0px"
                          marginLeft="0px"
                          px={'initial'}
                          fontSize={{ md: '18px', base: '14px' }}
                          paddingBottom={{ md: '10px', base: '5px' }}
                        ></RichTextEditorMB>
                      )}
                      <Box
                        display="flex"
                        flexDir="row"
                        flexWrap="wrap"
                        columnGap="30px"
                        rowGap={{ lg: '40px', base: '24px' }}
                      >
                        {primaryGroup?.primary_card.map((primarySponsor, i) => {
                          return (
                            <ImageCard
                              key={i}
                              data={primarySponsor}
                              cardH={{ md: '243px', base: '223px' }}
                              cardW={{ md: '360px', base: '330px' }}
                              fontsize={{ lg: '24px', base: '20px' }}
                              lineH="30px"
                            ></ImageCard>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

            {newData?.secondary_sponsors_group?.some(
              (group) => group.secondary_card[0].sponsor_image,
            ) && (
              <Box
                display="flex"
                flexDir="column"
                justifyContent={{ md: 'flex-start', base: 'center' }}
                alignContent={{ md: 'flex-start', base: 'center' }}
                rowGap={{ lg: '40px', base: '24px' }}
              >
                {newData?.secondary_sponsors_group?.map((secondaryGroup, i) => {
                  return (
                    <Box key={i}>
                      {secondaryGroup?.secondary_group_title && (
                        <Box
                          marginBottom={
                            secondaryGroup.secondary_group_subtext
                              ? '0px'
                              : '24px'
                          }
                        >
                          <Text
                            as="h3"
                            fontWeight="700"
                            fontSize={{
                              lg: '32px',
                              md: '30px',
                              base: '20px',
                            }}
                          >
                            {secondaryGroup?.secondary_group_title}
                          </Text>
                        </Box>
                      )}
                      {secondaryGroup?.secondary_group_subtext && (
                        <RichTextEditorMB
                          data={secondaryGroup.secondary_group_subtext}
                          paddingLeft="0px"
                          marginLeft="0px"
                          px={'initial'}
                          fontSize={{ md: '18px', base: '14px' }}
                          paddingBottom={{ md: '10px', base: '5px' }}
                        ></RichTextEditorMB>
                      )}
                      <Box
                        display="flex"
                        flexDir="row"
                        flexWrap="wrap"
                        columnGap="30px"
                        rowGap={{ lg: '40px', base: '24px' }}
                      >
                        {secondaryGroup?.secondary_card.map(
                          (secondarySponsor, i) => {
                            return (
                              <ImageCard
                                key={i}
                                data={secondarySponsor}
                                cardH={'190px'}
                                cardW={'280px'}
                                fontsize={'20px'}
                                lineH="25px"
                              ></ImageCard>
                            );
                          },
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

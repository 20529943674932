import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { SkipNavContent } from '@chakra-ui/skip-nav';
import BlockContainer from '../components/modularBlocks/container';
import AllAlert from '../components/message_alert/all_alert';
import Metadata from '../components/metadata';
import SectionBanner from '../components/modularBlocks/sectionBanner';
import InteriorHeader from '../components/modularBlocks/section/interior_section_header';
import BannerGroup from '../components/bannerGroup';
import ResourcesHero from '../components/modularBlocks/resourcesHero';
import Loading from '../components/loadingIndicator/loading';
export default function OverviewPage(props) {
  const { ct } = props;
  const path = window.location.pathname;
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, [ct, path]);

  async function getData() {
    // contentTypeUid, articleName, locale = 'en-us', ref
    const info = await api.getArticle(ct, path);

    setData(info[0]);
  }
  return (
    <main id="main">
      <SkipNavContent />
      {data ? (
        <div>
          {data.seo && <Metadata data={data.seo} override={data} />}
          <AllAlert />
          {data.section_hero_banner && (
            <SectionBanner data={data.section_hero_banner[0]} />
          )}
          {data.interior_section_header?.length > 0 && (
            <InteriorHeader data={data.interior_section_header[0]} />
          )}
          {data.banner_group && <BannerGroup refData={data.banner_group[0]} />}
          {data.resources_hero && (
            <ResourcesHero refData={data.resources_hero[0]} />
          )}
          {data.page_content && <BlockContainer data={data.page_content} />}
        </div>
      ) : (
        <Loading minH={'100vh'} />
      )}
    </main>
  );
}

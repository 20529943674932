import { Box, Image, Link, Text } from '@chakra-ui/react';

export default function SimpleCardBody(props) {
  const { group } = props;
  return (
    <Box
      role={'group'}
      as={group.card_url && Link}
      href={group.card_url ? group.card_url : ''}
      target={group.new_window ? '_blank' : '_self'}
      _hover={
        group.card_url
          ? {
              textDecoration: 'none',
              border: '2px solid #CCCCCC',
              boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
              paddingX: '17px',
              paddingTop: '17px',
            }
          : {}
      }
      _focus={
        group.card_url
          ? {
              textDecoration: 'none',
              border: '2px solid #CCCCCC',
              boxShadow: '0px 24px 34px 0px rgba(0,0,0,0.13)',
              paddingX: '23px',
              paddingTop: '23px',
            }
          : {}
      }
      display="flex"
      flexDirection="column"
      border="1px solid #CCCCCC"
      boxShadow="0px 24px 34px 0px rgba(0,0,0,0.03)"
      borderRadius="20px"
      h="385px"
      w={{ md: '362px', base: '348px' }}
      paddingX="18px"
      paddingTop="18px"
      paddingBottom="20px"
      overflow="hidden"
    >
      <Box paddingBottom="18px" display="flex" justifyContent="center">
        <Image
          loading="lazy"
          alt={group.alt_text}
          src={group.image.url}
          fit="cover"
          borderRadius="16px 16px 0px 0px"
          height="131px"
          //   width="321px"
          w={{ md: '321px', base: '307px' }}
        ></Image>
      </Box>
      <Box>
        <Text
          as={'h3'}
          lineHeight="30px"
          marginBottom="18px"
          _groupHover={group.card_url ? { textDecoration: 'underline' } : {}}
          _groupFocus={group.card_url ? { textDecoration: 'underline' } : {}}
        >
          {group.card_title}
        </Text>
        <Box display="flex" gap="14px" paddingBottom="15px">
          <Text fontSize="16px" fontWeight="600">
            {group.card_subtext}
          </Text>
          {group.card_gated_toggle && (
            <Box
              border="1px solid #006954"
              borderRadius="20px"
              paddingX="14px"
              paddingY="1px"
            >
              <Text
                fontWeight="600"
                textColor="#006954"
                fontSize="14px"
                lineHeight="19px"
                letterSpacing="3.5%"
              >
                Members only
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box overflow="hidden">
        <Text
          fontFamily="Open Sans"
          fontWeight="400"
          textColor="#4D4D4D"
          fontSize={{ md: '15px', base: '14px' }}
          lineHeight="24.5px"
          letterSpacing="-6%"
        >
          {group.card_text}
        </Text>
      </Box>
    </Box>
  );
}

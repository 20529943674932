import { Box, Heading, ModalBody, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../../utils/api';
import logger from 'utils/logger';

export default function LegendModal(props) {
  const { data, alt } = props;
  const { l } = useParams();
  const [newData, setNewData] = useState();

  useEffect(() => {
    getData(l);
  }, [l]);

  async function getData(l) {
    const info = await api.getSpecificEntry(
      data?._content_type_uid,
      data?.uid,
      l,
    );

    setNewData(info.entry);
  }

  return (
    <Box>
      {newData && (
        <Box
          as={ModalBody}
          display="flex"
          //   border="1px solid red"
          justifyContent="space-evenly"
          rowGap="20px"
          flexWrap="wrap"
          minH="430px"
        >
          {newData.instances.map((instance, i) => (
            <Box
              key={i}
              display="flex"
              flexDir="column"
              justifyContent="flex-start"
              gap="8px"
              textAlign="center"
              //   border="1px solid blue"
              maxW={{ md: '25%', base: '50%' }}
              minH="150px"
            >
              <Box
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="space-evenly"
                borderRadius="50%"
                height="46px"
                width="46px"
                backgroundColor={
                  instance.icon === 'Globe'
                    ? '#5C1D78'
                    : instance.icon === 'Users'
                    ? '#5E9C44'
                    : instance.icon === 'Handshake Simple'
                    ? '#8C0A39'
                    : instance.icon === 'Chalkboard User'
                    ? '#1B5F8E'
                    : instance.icon === 'Microphone'
                    ? '#D8A931'
                    : instance.icon === 'Gear'
                    ? '#51A1AA'
                    : instance.icon === 'Comment Dots'
                    ? '#006954'
                    : '#5E9C44'
                }
              >
                <FontAwesomeIcon
                  data-icon={alt}
                  height="50%"
                  width="50%"
                  color="white"
                  icon={
                    instance.icon == 'Globe'
                      ? solid('globe')
                      : instance.icon === 'Users'
                      ? solid('users')
                      : instance.icon === 'Handshake Simple'
                      ? solid('handshake-simple')
                      : instance.icon === 'Chalkboard User'
                      ? solid('chalkboard-user')
                      : instance.icon === 'Microphone'
                      ? solid('microphone')
                      : instance.icon === 'Gear'
                      ? solid('gear')
                      : instance.icon === 'Comment Dots'
                      ? solid('comment-dots')
                      : logger.log('unkown icon')
                  }
                />
              </Box>
              <Heading
                // whiteSpace={{ lg: 'nowrap', base: 'normal' }}
                fontSize="20px"
              >
                {instance.title}
              </Heading>
              <Box paddingX={{ lg: '40px', base: '15px' }}>
                <Text fontWeight="400" fontSize="12px">
                  {instance.info}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

// import { useState } from 'react';
// format should be "month_year" or "ap"
export default function useDateFormat() {
  //   const [date, setDate] = useState();

  const fullMonths = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const months = [
    'Jan.',
    'Feb.',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];

  //  date: '2023-07-21';

  const dateBuilder = (timestamp, format) => {
    let day;
    let month;
    let year;
    if (typeof timestamp === 'string') {
      const [yearStr, monthStr, dayStr] = timestamp.split('-');
      day = parseInt(dayStr);
      month = parseInt(monthStr) - 1;
      year = parseInt(yearStr);
    } else {
      const dateObj = new Date(timestamp);
      day = dateObj.getDate();
      month = dateObj.getMonth();
      year = dateObj.getFullYear();
    }

    let m;
    if (format === 'month_year') {
      m = fullMonths;
      return `${m[month]} ${year}`;
    } else {
      m = months;
      return `${day} ${m[month]} ${year}`;
    }
  };
  return [dateBuilder];
}

import React from 'react';
import { Box } from '@chakra-ui/react';

const PhoneIcon = ({ variant, fasize, ...rest }) => {
  return (
    <Box className={`${variant || 'fa-solid'}  fa-phone ${fasize}`} {...rest} />
  );
};

export default PhoneIcon;

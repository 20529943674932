import React from 'react';
import { Flex, Text, useTheme } from '@chakra-ui/react';
export default function AuthorBlock(props) {
  const { data, time } = props;
  const theme = useTheme();

  return (
    <Flex direction={'column'}>
      <Flex direction={'column'} gap={'16px'}>
        {data?.map((author, i) => {
          return (
            <Flex key={i} direction={'column'}>
              {author.name && (
                <Text
                  fontWeight={'600'}
                  letterSpacing={{ base: '.03em' }}
                  fontSize={{ base: '1.125rem', md: '1.375rem' }}
                  lineHeight={{ base: '1.531rem', md: '1.875rem' }}
                >
                  {author.name}
                </Text>
              )}
              {author.contributor_type && (
                <Text
                  fontWeight={'400'}
                  letterSpacing={{ base: '.03em' }}
                  fontSize={{ base: '.875rem', md: '1.125rem' }}
                  lineHeight={{ base: '1.188rem', md: '1.563rem' }}
                  mt={{ base: '2px', md: '5px' }}
                  mb={{ base: '5px', md: 'initial' }}
                >
                  {author.contributor_type}
                </Text>
              )}
              {/* {author.job_title && (
                <Text
                  fontWeight={'400'}
                  letterSpacing={{ base: '.03em' }}
                  fontSize={{ base: '.875rem', md: '1.125rem' }}
                  lineHeight={{ base: '1.188rem', md: '1.563rem' }}
                  mt={{ base: '2px', md: '5px' }}
                  mb={{ base: '5px', md: 'initial' }}
                >
                  {author.job_title.toUpperCase()}
                </Text>
              )} */}
              {author.designations && (
                <Text
                  color={theme.colors.Grey1}
                  fontWeight={'400'}
                  letterSpacing={{ base: '.03em' }}
                  fontSize={{ base: '.875remrem', md: '1rem' }}
                  lineHeight={{ base: '1.188rem', md: '1.362rem' }}
                >
                  {author.designations.join(', ')}
                </Text>
              )}
              {/* {author.certifications && (
                <Text
                  color={'#4D4D4D'}
                  fontWeight={'400'}
                  letterSpacing={{ base: '.03em' }}
                  fontSize={{ base: '.875remrem', md: '1rem' }}
                  lineHeight={{ base: '1.188rem', md: '1.362rem' }}
                >
                  {author.certifications.toUpperCase()}
                </Text>
              )} */}
            </Flex>
          );
        })}
      </Flex>
      {time && (
        <Text
          color={theme.colors.Grey1}
          fontWeight={'400'}
          letterSpacing={{ base: '.03em' }}
          fontSize={{ base: '.875rem' }}
          lineHeight={{ base: '1.188rem' }}
          mt={{ base: '5px', md: '2px' }}
        >{`${time} Minute Read`}</Text>
      )}
    </Flex>
  );
}

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

export default function ResourceInteriorHeader(props) {
  const { data } = props;

  return (
    <Box
      as={Flex}
      flexDirection={'column'}
      // border="solid 1px black"
      justifyContent={'center'}
      alignContent={'center'}
      backgroundColor={'#4D4D4D'}
      h={'255px'}
      wrap={'wrap'}
    >
      <Box
        my={10}
        mx={{ lg: 60, md: 20, base: 5 }}
        as={Flex}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        //  border="solid 1px red"
        textAlign="center"
        maxW={'826px'}
      >
        <Heading
          as={'h1'}
          fontWeight="700"
          fontSize={{ base: '25px', md: '32px', lg: '40px' }}
          lineHeight={{ base: '74px', md: '74px', lg: '74px' }}
          color={'white'}
        >
          {data?.headline}
        </Heading>
        <Text
          fontWeight="400"
          fontSize={{ base: '16px', md: '20px', lg: '22px' }}
          lineHeight={{ base: '22px', md: '27px', lg: '30px' }}
          color={'white'}
        >
          {data?.sub_text}
        </Text>
      </Box>
    </Box>
  );
}

import React, { useState } from 'react';
import { Flex, Image } from '@chakra-ui/react';
import useToggle from '../../../../hooks/useToggle';
import PlayIcon from '../../../../assets/vectors/playIcon.svg';
export default function Video(props) {
  const [control, setControl] = useToggle(false);
  const [hidden, setHidden] = useState(false);
  const { data } = props;
  return (
    <Flex w={'100%'} h={'inherit'} align={'stretch'}>
      <Flex
        onClick={() => setHidden(true)}
        display={hidden ? 'none' : 'block'}
        w={'100%'}
        alignSelf={'stretch'}
        // minH={{ sm: '314', md: '364px', lg: '250px' }}
        h={'100%'}
      >
        <Thumbnail url={data?.thumbnail?.url} />
      </Flex>
      <Flex
        display={!hidden ? 'none' : 'block'}
        w={'100%'}
        // minH={{ sm: '314', md: '364px', lg: '0' }}
        alignItems={'center'}
        // border={'solid blue 2px'}
      >
        <video
          autoPlay
          muted
          style={{ borderRadius: '30px', width: '100%' }}
          controls={control}
          onMouseOver={() => setControl()}
          onMouseLeave={() => setControl()}
        >
          <source src={data?.video_url} type="video/mp4" />
        </video>
      </Flex>
    </Flex>
  );
}

function Thumbnail(props) {
  const { url } = props;

  return (
    <Flex
      w={'100%'}
      bgImage={url}
      // minH={{ sm: '314', md: '364px', lg: '250px' }}
      mx={'auto'}
      h={'100%'}
      style={{ cursor: 'pointer' }}
      bgSize={'cover'}
      bgPos={'center'}
      bgRepeat={'no-repeat'}
      borderRadius={'30px'}
      align={'stretch'}
      // border={'hotpink solid 2px'}
    >
      <Flex
        borderRadius={'30px'}
        bgColor={'rgba(35,35,35,.45)'}
        alignSelf={'stretch'}
        w={'100%'}
        // minH={{ sm: '314', md: '364px', lg: '250px' }}
        h={'100%'}
        align={'center'}
        justify={'center'}
      >
        <Image m={'50px'} w={'64px'} h={'64px'} src={PlayIcon} />
      </Flex>
    </Flex>
  );
}

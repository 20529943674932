import { Field } from 'formik';
import { Input } from '@chakra-ui/react';
const Date = (props) => {
  const { item, errorOrValidColor, values } = props;
  return (
    <Field
      as={Input}
      name={item.field_name}
      id={item.field_name}
      type="date"
      variant="outline"
      focusBorderColor={errorOrValidColor}
      borderColor={errorOrValidColor}
      isRequired={item.required}
      value={values[item.field_name] || ''}
    />
  );
};

export default Date;

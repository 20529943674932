import React from 'react';
import { chakra, useTheme } from '@chakra-ui/react';
import MainFooter from './main_footer';
import UtilFooter from '../footer/util_footer';
import { Outlet } from 'react-router-dom';

function Footer() {
  const theme = useTheme();
  return (
    <>
      <chakra.footer
        bgColor={theme.colors.Black}
        textColor={theme.colors.White}
        paddingTop={2}
      >
        <MainFooter />
        <UtilFooter />
      </chakra.footer>
      <Outlet />
    </>
  );
}

export default React.memo(Footer);

import React from 'react';
import { Flex, Heading, Image, Box, Link } from '@chakra-ui/react';
import FaIcon from '../../../icons/faIcon';
import RightArrow from '../../../icons/rightArrow';
import UpRightFromSquare from '../../../icons/upRightFromSq';
import RichTextEditorMB from '../../rte';
// import { NavLink } from 'react-router-dom';
import logger from 'utils/logger';

export default function MemberX3(props) {
  const { data, greyBg } = props;
  const cards = data?.slice(0, 3);
  logger.log('MEMBER 3', data);
  return (
    <Box backgroundColor={greyBg ? '#f4f4f4' : 'white'} py={'32px'}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        maxW={{ base: '100%', md: '983px', lg: '1176px' }}
        w={'100%'}
        mx={'auto'}
        px={'1%'}
        gap={'1%'}
      >
        {cards?.map((card, i) => {
          return (
            <Flex
              key={i}
              direction="column"
              maxW={{ base: '263px', md: '30%' }}
              border={'1px solid #D2D5DA'}
              borderRadius={'20px'}
              mx={'auto'}
              my={'.5rem'}
              flexGrow={1}
              overflow={'none'}
            >
              {(card.fa_icon || card.image?.url) && (
                <Flex
                  h={{ base: '230px', lg: '319px' }}
                  borderRadius={'20px 20px 0 0'}
                  w={'100%'}
                  // border={'lime solid 2px'}
                >
                  {card.fa_icon ? (
                    // <Flex h={'50%'} justify={'center'} align={'end'}>
                    //   <FontAwesomeIcon
                    //     icon={
                    //       card.icon === 'User'
                    //         ? solid('user')
                    //         : card.icon === 'Building'
                    //         ? solid('building')
                    //         : null
                    //     }
                    //     size={'10x'}
                    //   />{' '}
                    // </Flex>
                    <FaIcon
                      faIcon={`fa-${card.icon
                        .replaceAll(' ', '-')
                        .toLowerCase()}`}
                      fasize={'fa-2xl'}
                      alt={card.image_alt}
                      color={card.theme[0]?.colors?.text_color?.toLowerCase()}
                      transform={'scale(4)'}
                      // justifySelf={'center'}
                      alignSelf={'center'}
                      mx={'auto'}
                    />
                  ) : (
                    <Image
                      w={'100%'}
                      // border={'solid black 3px'}
                      borderRadius={'20px 20px 0 0'}
                      src={card.image?.url}
                      alt={card.image_alt}
                      objectFit={'cover'}
                    />
                  )}
                </Flex>
              )}
              <Flex
                m={{ base: '1.25rem', lg: '1.5rem' }}
                gap={{ base: '.69rem', lg: '1rem' }}
                direction={'column'}
                // border={'lime dashed 3px'}
              >
                <Heading
                  fontWeight={'800'}
                  fontSize={{ base: '1rem' }}
                  lineHeight={'1.25rem'}
                  letterSpacing={'.1em'}
                >
                  {card.title?.toUpperCase()}
                </Heading>
                {/* <Text
                  fontWeight={'400'}
                  fontSize={{ base: '1rem', lg: '1.125rem' }}
                  lineHeight={{ base: '1.625rem', lg: '2.125rem' }}
                >
                  {card.description}
                </Text> */}
                {card.description_rte && (
                  <RichTextEditorMB
                    data={card.description_rte}
                    px={'initial'}
                    mx={'inital'}
                    style={['card']}
                    fontWeight={'400'}
                    fontSize={{ base: '1rem', lg: '1.125rem' }}
                    lineHeight={{ base: '1.625rem', lg: '2.125rem' }}
                  />
                )}
                {card.cta_button?.button_link?.title && (
                  <>
                    {
                      <Flex gap={'.5rem'}>
                        <Link
                          color="#006954"
                          fontWeight="600"
                          fontSize="16px"
                          lineHeight="20px"
                          href={card.cta_button?.button_link?.href}
                          target={
                            card.cta_button?.new_window ? '_blank' : '_self'
                          }
                          gap="20px"
                        >
                          {card.cta_button?.button_link?.title}
                        </Link>
                        <Box color="#006954">
                          {card.cta_button?.external_link ? (
                            <UpRightFromSquare fasize={'fa-sm'} />
                          ) : (
                            <RightArrow />
                          )}
                        </Box>
                      </Flex>
                    }
                  </>
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}

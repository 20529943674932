import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import ThreeColumns from './threeColumn';
import TwoColumns from './twoColumn';
import FourColumns from './fourColumns';
import api from '../../../utils/api';
import { useParams } from 'react-router-dom';
import logger from 'utils/logger';

export default function Columns(props) {
  const { l } = useParams();
  const { data, refData, contained } = props;
  const [newData, setNewData] = useState();
  const columnNum = newData?.column_layout.split('/');

  async function getData() {
    const info = await api.getSpecificEntry(
      refData?._content_type_uid,
      refData?.uid,
      l,
    );
    setNewData(info?.entry);
  }

  data && !newData ? setNewData(data) : !newData && refData ? getData() : null;

  return (
    <>
      {columnNum && (
        <Box
          backgroundColor={newData?.light_grey_background ? '#f4f4f4' : 'white'}
          // py={'30px'}
        >
          <Box
            // my={'5%'}
            // py={'47px'}
            maxW={'1440px'}
            px={contained ? 0 : { base: '16px', md: '24px', lg: '60px' }}
            mx={'auto'}
          >
            <Box
              py={'24px'}
              borderBottom={newData?.bottom_border ? '1px black solid' : 'none'}
            >
              {columnNum.length === 2 ? (
                <TwoColumns
                  data={newData?.content}
                  widths={columnNum}
                  {...props}
                />
              ) : columnNum.length === 3 ? (
                <ThreeColumns data={newData?.content} {...props} />
              ) : columnNum.length === 4 ? (
                <FourColumns
                  data={newData?.content}
                  widths={columnNum}
                  {...props}
                />
              ) : (
                logger.warn('unsuported column length')
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

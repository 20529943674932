import React, { useState, useEffect } from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react'; // import from 'keen-slider/react.es' for to get an ES module
import { Box, Button, Flex, Text, useTheme } from '@chakra-ui/react';
import ChevronLeftIcon from '../../../icons/chevronLeft';
import ChevronRightIcon from '../../../icons/chevronRight';
import useResize from '../../../../hooks/useResize';

export default function Keen({ lgCards, mdCards, smCards }) {
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged() {
        return setCurrentSlide(instanceRef.current.track.details.rel);
      },
    },
    [
      // add plugins here
    ],
  );

  const [currentSlide, setCurrentSlide] = useState(0);
  const [size, handleResize] = useResize();
  const hasPrevious = currentSlide !== 0;
  const hasNextLg = currentSlide < lgCards?.length - 1;
  const hasNextMd = currentSlide < mdCards?.length - 1;
  const hasNextSm = currentSlide < smCards?.length - 1;

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener('resize', handleResize);

  const theme = useTheme();

  if (size === 'lg') {
    return (
      <Box pt={'24px'}>
        <Flex
          align={'center'}
          p={'25px'}
          border={' solid 1px'}
          borderColor={
            lgCards?.length > 1 ? 'theme.colors.Grey2' : 'transparent'
          }
          pl={lgCards?.length > 1 ? '25px' : 'initial'}
          borderRadius={'20px'}
          gap={'24px'}
        >
          {hasPrevious && (
            <PrevButton
              current={currentSlide}
              setCurrent={setCurrentSlide}
              instanceRef={instanceRef}
            />
          )}
          <Box ref={sliderRef} className="keen-slider">
            {lgCards?.map((slide, index) => {
              return (
                <Flex className="keen-slider__slide" key={index} gap={'24px'}>
                  {slide.map((card, i) => {
                    return (
                      <Flex
                        key={i}
                        direction={'column'}
                        flex={1}
                        pr={'28px'}
                        pl={i === 0 ? '0' : '28px'}
                        borderLeft={
                          i === 0 ? 'none' : `solid 2px ${theme.colors.Grey2}`
                        }
                      >
                        <Text
                          fontWeight={'600'}
                          letterSpacing={'.03em'}
                          fontSize={'1.375rem'}
                          lineHeight={'1.875rem'}
                        >
                          {card.name}
                        </Text>
                        <Text
                          fontWeight={'400'}
                          fontSize={'1rem'}
                          letterSpacing={'.03em'}
                          lineHeight={'1.362rem'}
                          color={theme.colors.Grey1}
                        >
                          {card.designations.join(', ')}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              );
            })}
          </Box>
          {hasNextLg && (
            <NextButton
              current={currentSlide}
              setCurrent={setCurrentSlide}
              instanceRef={instanceRef}
            />
          )}
        </Flex>
        {lgCards?.length > 1 && (
          <Flex justify={'center'} py={'24px'} gap={'.5rem'}>
            {lgCards?.map((_, index) => (
              <Box
                key={index}
                borderRadius={'full'}
                minW={'10px'}
                minH="10px"
                border={`${theme.colors.Black} solid 2px`}
                bgColor={
                  currentSlide === index
                    ? theme.colors.White
                    : theme.colors.Black
                }
              />
            ))}
          </Flex>
        )}
      </Box>
    );
  }
  if (size === 'md') {
    return (
      <Box pt={'24px'}>
        <Flex
          align={'center'}
          p={'25px'}
          border={' solid 1px'}
          borderColor={mdCards?.length > 1 ? theme.colors.Grey2 : 'transparent'}
          pl={mdCards?.length > 1 ? '25px' : 'initial'}
          borderRadius={'20px'}
          gap={'24px'}
        >
          {hasPrevious && (
            <PrevButton
              current={currentSlide}
              setCurrent={setCurrentSlide}
              instanceRef={instanceRef}
            />
          )}
          <Box ref={sliderRef} className="keen-slider">
            {mdCards?.map((slide, index) => {
              return (
                <Flex className="keen-slider__slide" key={index} gap={'24px'}>
                  {slide.map((card, i) => {
                    return (
                      <Flex
                        key={i}
                        direction={'column'}
                        flex={1}
                        pr={'28px'}
                        pl={i === 0 ? '0' : '28px'}
                        borderLeft={
                          i === 0 ? 'none' : `${theme.colors.Grey3} solid 2px`
                        }
                      >
                        <Text
                          fontWeight={'600'}
                          letterSpacing={'.03em'}
                          fontSize={'1.125rem'}
                          lineHeight={'1.5rem'}
                        >
                          {card.name}
                        </Text>
                        <Text
                          fontWeight={'400'}
                          fontSize={'.875rem'}
                          letterSpacing={'.03em'}
                          lineHeight={'1.25rem'}
                          color={theme.colors.Grey2}
                        >
                          {card.designations.join(', ')}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              );
            })}
          </Box>
          {hasNextMd && (
            <NextButton
              current={currentSlide}
              setCurrent={setCurrentSlide}
              instanceRef={instanceRef}
            />
          )}
        </Flex>
        {mdCards?.length > 1 && (
          <Flex justify={'center'} py={'24px'} gap={'.5rem'}>
            {mdCards?.map((_, index) => (
              <Box
                key={index}
                borderRadius={'full'}
                minW={'10px'}
                minH="10px"
                border={`${theme.colors.Black} solid 2px`}
                bgColor={
                  currentSlide === index
                    ? theme.colors.White
                    : theme.colors.Black
                }
              />
            ))}
          </Flex>
        )}
      </Box>
    );
  }
  if (size === 'sm') {
    return (
      <Box py={'12px'}>
        <Flex
          align={'center'}
          p={'25px'}
          pl={smCards?.length > 1 ? '25px' : 'initial'}
          border={' solid 1px'}
          borderColor={smCards?.length > 1 ? theme.colors.Grey2 : 'transparent'}
          borderRadius={'20px'}
          gap={'24px'}
        >
          {hasPrevious && (
            <PrevButton
              current={currentSlide}
              setCurrent={setCurrentSlide}
              instanceRef={instanceRef}
            />
          )}
          <Box ref={sliderRef} className="keen-slider">
            {smCards?.map((slide, index) => {
              return (
                <Flex
                  className="keen-slider__slide"
                  key={index}
                  direction={'column'}
                  flex={1}
                >
                  <Text
                    fontWeight={'600'}
                    letterSpacing={'.03em'}
                    fontSize={'1rem'}
                    lineHeight={'1.25rem'}
                  >
                    {slide.name}
                  </Text>
                  <Text
                    fontWeight={'400'}
                    letterSpacing={'.03em'}
                    fontSize={'.75rem'}
                    lineHeight={'1rem'}
                    color={theme.colors.Grey2}
                  >
                    {slide.certifications}
                  </Text>
                </Flex>
              );
            })}
          </Box>
          {hasNextSm && (
            <NextButton
              current={currentSlide}
              setCurrent={setCurrentSlide}
              instanceRef={instanceRef}
            />
          )}
        </Flex>
        {smCards?.length > 1 && (
          <Flex justify={'center'} py={'24px'} gap={'.5rem'}>
            {smCards.map((_, index) => (
              <Box
                key={index}
                borderRadius={'full'}
                minW={'10px'}
                minH="10px"
                border={`${theme.colors.Black} solid 2px`}
                bgColor={
                  currentSlide === index
                    ? theme.colors.White
                    : theme.colors.Black
                }
              />
            ))}
          </Flex>
        )}
      </Box>
    );
  }
}

const NextButton = ({ instanceRef }) => {
  return (
    <Button onClick={() => instanceRef.current?.next()} bg={'inherit'}>
      <ChevronRightIcon />
    </Button>
  );
};
const PrevButton = ({ instanceRef }) => {
  return (
    <Button onClick={() => instanceRef.current?.prev()} bg={'inherit'}>
      <ChevronLeftIcon />
    </Button>
  );
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Link } from '@chakra-ui/react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import api from '../../../utils/api';
import UpRightFromSquare from '../../icons/upRightFromSq';

export default function ButtonMB(props) {
  const { refData, buttonObj, newTab, ...rest } = props;
  const { l } = useParams();
  const [data, setData] = useState();
  async function getData() {
    const info = await api.getSpecificEntry(
      refData?._content_type_uid,
      refData?.uid,
      l,
    );
    setData(info.entry.info || info.entry.cta_button);
  }

  buttonObj && !data ? setData(buttonObj) : !data ? getData() : null;
  return (
    <Box
      maxW={{ lg: '1200px', base: '100%' }}
      mx={{ base: '1rem', lg: 'auto' }}
      display="flex"
      justifyContent={data?.button_alignment}
      // border={'solid 2px red'}
      {...rest}
    >
      {data?.button_link?.title && (
        <Button
          size={data?.button_size?.toLowerCase()}
          variant={data?.button_type?.toLowerCase()}
          as={Link}
          href={data?.button_link?.href}
          target={data?.new_window ? '_blank' : newTab ? newTab : '_self'}
          gap="10px"
        >
          {data?.button_link?.title}
          {/* <FontAwesomeIcon icon={solid('up-right-from-square')} /> */}
          {data?.external_link && <UpRightFromSquare fasize={'fa-sm'} />}
        </Button>
      )}
    </Box>
  );
}

// <Box>
//   {data?.external_link ? (
//     <Button
//       size={data?.button_size?.toLowerCase()}
//       variant={data?.button_type?.toLowerCase()}
//       as={Link}
//       href={data?.button_link?.href}
//       target={data?.new_window ? '_blank' : newTab ? newTab : '_self'}
//       gap="10px"
//     >
//       {data?.button_link?.title}
//       <FontAwesomeIcon icon={solid('up-right-from-square')} />
//     </Button>
//   ) : (
//     // <Button
//     //   as={NavLink}
//     //   to={data?.path?.link?.href || data?.href}
//     //   target={data?.path?.new_window ? '_blank' : newTab ? newTab : '_self'}
//     // >
//     //   <Box
//     //     as="button"
//     //     bg={bgColor || 'PrimaryGreen'}
//     //     color={
//     //       color ? color : bgColor === 'white' ? 'PrimaryGreen' : 'white'
//     //     }
//     //     fontWeight="600"
//     //     borderRadius="54px"
//     //     py={'.5rem'}
//     //     px={data?.styling?.size?.width ? '.5rem' : '1rem'}
//     //     w={data?.styling?.size?.width || width}
//     //     minW={'172px'}
//     //     border={' solid 2px'}
//     //     borderColor={
//     //       color
//     //         ? bgColor
//     //         : bgColor === 'white'
//     //         ? 'OutlineGreen'
//     //         : 'PrimaryGreen'
//     //     }
//     //     minH={minH || 'initial'}
//     //   >
//     //     {data?.path?.link?.title || data?.title}
//     //   </Box>
//     // </Button>
//     <Button
//       size={data?.button_size?.toLowerCase()}
//       variant={data?.button_type?.toLowerCase()}
//       as={NavLink}
//       to={data?.button_link?.href}
//       target={data?.new_window ? '_blank' : newTab ? newTab : '_self'}
//     >
//       {data?.button_link?.title}
//     </Button>
//   )}
// </Box>

import { Box, Text } from '@chakra-ui/react';

export default function ComplexCardImgOverlay(props) {
  const { info, iconSelect } = props;

  const overlay = info.information_overlay;
  return (
    <Box
      position="absolute"
      bg="#fff"
      height="79px"
      width="234px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="12px"
      z-index="1000"
      top="22px"
    >
      <Box display="flex" alignItems="center">
        <Box paddingRight="5px">
          {overlay.first_line.icon_select in iconSelect
            ? iconSelect[overlay.first_line.icon_select]
            : null}
        </Box>
        <Text fontFamily="Open Sans" fontSize="14px" fontWeight="600">
          {overlay.first_line.description_text_field
            ? overlay.first_line.description_text_field
            : null}
        </Text>
      </Box>
      <Box display="flex" alignItems="center">
        <Box paddingRight="5px">
          {overlay.second_line.icon_select in iconSelect
            ? iconSelect[overlay.second_line.icon_select]
            : null}
        </Box>
        <Text fontFamily="Open Sans" fontSize="14px" fontWeight="600">
          {overlay.second_line.description_text_field
            ? overlay.second_line.description_text_field
            : null}
        </Text>
      </Box>
    </Box>
  );
}

import React from 'react';
import { Flex, Heading, Button, useTheme } from '@chakra-ui/react';
import CustomCheckBox from '../checkbox';

export default function DeskFilter({
  filters,
  filter,
  tags,
  setTags,
  more,
  setMore,
  ...rest
}) {
  const theme = useTheme();
  return (
    <>
      {filter?.map((group, index) => {
        // This controls which tag fields will be viewable on the front end

        return (
          <Flex
            direction={'column'}
            key={index}
            p={'16px'}
            border={`solid 1px ${theme.colors.Grey4}`}
            display={
              filters?.includes(group.title?.toLowerCase().replaceAll(' ', '_'))
                ? 'block'
                : 'none'
            }
            {...rest}
          >
            <Heading
              weight={'800'}
              fontSize={'1rem'}
              lineHeight={'1.25rem'}
              mb={'16px'}
            >
              {group.title}
            </Heading>
            <Flex direction={'column'} gap={'8px'}>
              {group.options?.map((choice, i) => {
                if (group.options.length > 8) {
                  if (i < 8) {
                    return (
                      <CustomCheckBox
                        key={i}
                        group={group}
                        choice={choice}
                        more={true}
                        setTags={setTags}
                        tags={tags}
                      />
                    );
                  }
                  if (i + 1 === group.options.length) {
                    return (
                      <Flex
                        key={i}
                        direction={'column'}
                        // border={'solid 2px hotpink'}
                      >
                        <CustomCheckBox
                          key={i}
                          group={group}
                          choice={choice}
                          more={more[index]}
                          setTags={setTags}
                          tags={tags}
                        />
                        <Button
                          background={'inherit'}
                          color={'DarkGreen'}
                          fontFamily={'Lato'}
                          fontSize={'1.25rem'}
                          lineHeight={'1.5rem'}
                          justifyContent={'start'}
                          onClick={() => {
                            return setMore((current) => [
                              ...current.slice(0, index),
                              !current[index],
                              ...current.slice(index + 1),
                            ]);
                          }}
                        >
                          {more[index] ? 'Show less' : 'Show more'}
                        </Button>
                      </Flex>
                    );
                  }

                  return (
                    <CustomCheckBox
                      key={i}
                      group={group}
                      choice={choice}
                      more={more[index]}
                      setTags={setTags}
                      tags={tags}
                    />
                  );
                }
                return (
                  <CustomCheckBox
                    key={i}
                    group={group}
                    choice={choice}
                    more={true}
                    setTags={setTags}
                    tags={tags}
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </>
  );
}

import React from 'react';
import { Box } from '@chakra-ui/react';

const ChevronRightIcon = ({ variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-chevron-right ${fasize}`}
      {...rest}
    />
  );
};

export default ChevronRightIcon;

import { Field } from 'formik';
import { Select } from '@chakra-ui/react';
import { ReactComponent as SortArrows } from 'components/icons/sortArrows/sortArrows.svg';
import { formatOptions } from './formhelperFunctions';

const SingleDropdown = (props) => {
  const { item, errorOrValidColor, values } = props;
  return (
    <>
      {!item.field_options.includes('Option') && (
        <Field
          as={Select}
          name={item.field_name}
          id={item.field_name}
          icon={<SortArrows />}
          placeholder={item.placeholder_text || 'Select'}
          variant="outline"
          focusBorderColor={errorOrValidColor}
          borderColor={errorOrValidColor}
          isRequired={item.required}
          value={values[item.field_name] || ''}
        >
          {formatOptions(item.field_options).map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </Field>
      )}
    </>
  );
};

export default SingleDropdown;

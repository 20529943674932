import React from 'react';
import { Box } from '@chakra-ui/react';

const EnvelopeIcon = ({ variant, fasize, ...rest }) => {
  return (
    <Box
      className={`${variant || 'fa-solid'} fa-envelope ${fasize}`}
      {...rest}
    />
  );
};

export default EnvelopeIcon;

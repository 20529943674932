import { useEffect } from 'react';

const CustomPagination = () => {
  useEffect(() => {
    const items = document.querySelectorAll('.rc-pagination-item');
    items.forEach((item) => {
      // Setting title to empty or any logic you want to apply
      item.setAttribute('title', '');
    });
  });

  return null;
};

export default CustomPagination;

import React from 'react';
import {
  Button,
  Link,
  Flex,
  Heading,
  Divider,
  Box,
  Text,
  Hide,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import RichTextEditorMB from '../../../modularBlocks/rte/index';

// import ButtonMB from '../../../modularBlocks/button';

export default function MultiBanner(props) {
  const { data, newTab } = props;
  return (
    <Box
      bgColor={data?.theme[0]?.background_color?.replace(' ', '') || 'White'}
      //  border={'red solid 1px'}
      minH={'597px'}
    >
      <Flex
        direction={
          data?.img_align_left
            ? { base: 'column', lg: 'row' }
            : { base: 'column-reverse', lg: 'row' }
        }
        // border={'plum solid 2px'}
        maxW={'1440px'}
        mx={'auto'}
        h={'100%'}
        minH={'inherit'}
        p={{ base: '16px', lg: 'initial' }}
        gap={{ base: '16px', lg: 'initial' }}
      >
        {data?.image_align_left && <PictureBlock data={data} />}
        <Flex
          // border={'orange solid 2px'}
          direction={'column'}
          justify={'center'}
          align={'start'}
          color={data?.theme[0]?.text_color || 'Black'}
          w={{ base: '100%', lg: '35%' }}
          pl={'2%'}
          gap={'1.5rem'}
          mb={'1%'}
        >
          <Heading
            as={'h1'}
            fontWeight={'800'}
            fontSize={'40px'}
            lineHeight={'45px'}
            letterSpacing={'-.05rem'}
          >
            {data?.headline}
          </Heading>
          <RichTextEditorMB
            fontWeight={'500'}
            fontSize={{ base: '1rem', lg: '1.25rem' }}
            lineHeight={{ base: '1.362rem', lg: '1.5rem' }}
            data={data?.rich_text_editor}
            mx="0"
            px="0"
          ></RichTextEditorMB>
          {/* {data?.button && (
            <Box>
              {data?.theme[0]?.background_color === 'Primary Green' ? (
                <ButtonMB buttonObj={data?.button} bgColor={'white'} />
              ) : (
                <ButtonMB buttonObj={data?.button} />
              )}
            </Box>
          )} */}
          {data?.cta_button?.button_link?.title && (
            <>
              {data?.cta_button?.external_link ? (
                <Button
                  size={data?.cta_button?.button_size?.toLowerCase()}
                  variant={data?.cta_button?.button_type?.toLowerCase()}
                  as={Link}
                  href={data?.cta_button?.button_link?.href}
                  target={
                    data?.cta_button?.new_window
                      ? '_blank'
                      : newTab
                      ? newTab
                      : '_self'
                  }
                >
                  {data?.cta_button?.button_link?.title}
                </Button>
              ) : (
                <Button
                  size={data?.cta_button?.button_size?.toLowerCase()}
                  variant={data?.cta_button?.button_type?.toLowerCase()}
                  as={NavLink}
                  to={data?.cta_button?.button_link.href}
                  target={
                    data?.cta_button?.new_window
                      ? '_blank'
                      : newTab
                      ? newTab
                      : '_self'
                  }
                >
                  {data?.cta_button?.button_link?.title}
                </Button>
              )}
            </>
          )}
        </Flex>
        {!data?.image_align_left && <PictureBlock data={data} />}
      </Flex>
      <Divider />
    </Box>
  );
}

function PictureBlock(props) {
  const { data } = props;
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '100%', lg: '65%' }}
      h={'29rem'}
      gap={{ base: '16px', lg: '24px' }}
      // border={'teal solid 2px'}
      my={'auto'}
      color={'white'}
      justify={'center'}
      px={{ base: '16px', lg: 'initial' }}
      align={'center'}
    >
      <Flex
        direction={'column'}
        justify={'end'}
        bgImage={data?.image?.url}
        alt={data?.image_alt_text}
        h={'100%'}
        maxW={'250px'}
        w={'100%'}
        bgPos={'center'}
        bgSize={'cover'}
        borderRadius={'20px'}
        p={'1rem'}
      >
        <Heading as="h1">{data?.image_title}</Heading>
        <Text>{data?.image_text}</Text>
      </Flex>
      <Hide below="md">
        <Flex
          direction={'column'}
          justify={'end'}
          bgImage={data?.image_2?.url}
          alt={data?.image_2_alt_text}
          h={'100%'}
          maxW={'250px'}
          w={'100%'}
          bgPos={'center'}
          bgSize={'cover'}
          borderRadius={'20px'}
          p={'1rem'}
        >
          <Heading>{data?.image_2_title}</Heading>
          <Text>{data?.image_2_text}</Text>
        </Flex>
      </Hide>
      <Hide below="md">
        <Flex
          direction={'column'}
          justify={'end'}
          bgImage={data?.image_3?.url}
          alt={data?.image_3_alt_text}
          h={'100%'}
          maxW={'250px'}
          w={'100%'}
          bgPos={'center'}
          bgSize={'cover'}
          borderRadius={'20px'}
          p={'1rem'}
        >
          <Heading>{data?.image_3_title}</Heading>
          <Text>{data?.image_3_text}</Text>
        </Flex>
      </Hide>
    </Flex>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

export default function LazyBackground({
  src,
  fallbackSrc,
  alt,
  children,
  ...rest
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            setIsLoaded(true);
          };
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [src]);

  return (
    <Box
      ref={ref}
      backgroundImage={isLoaded ? `url(${src})` : `url(${fallbackSrc})`}
      loading="lazy"
      // alt={alt}
      {...rest}
    >
      {children}
    </Box>
  );
}

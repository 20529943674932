import { useState } from 'react';

export default function useResize() {
  const [size, setSize] = useState();

  const handleResize = async () => {
    const width = window.innerWidth;

    width < 750 ? setSize('sm') : width < 960 ? setSize('md') : setSize('lg');
  };

  return [size, handleResize];
}

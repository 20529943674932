import { useMemo } from 'react';

const lightGreen = '#6DB650';
const primaryGreen = '#38761E';
const blue = '#1B5F8E';
const purple = '#5B1F69';
const PinkRaspberry = '#8C0A39';
const burgundy = '#6F1A39';
const lightBlue = '#0097FF';
const teal = '#51A1AA';
const darkGreen = '#006955';
const darkGrey = '#5C5C5C';

const defaultTheme = {
  Report: darkGreen,
  Article: darkGrey,
  'News Article': darkGrey,
  'Resource Article': darkGrey,
  Whitepaper: lightGreen,
  'Case Study': primaryGreen,
  'Tool & Tracker': blue,
  'Graphic Report': purple,
  Infographic: purple,
  Podcast: PinkRaspberry,
  Video: PinkRaspberry,
  'Video Post': PinkRaspberry,
  Book: burgundy,
  'Web Conference': lightBlue,
  'Research Article': teal,
};

/**
 * @description Return the color for the title of a card based on the type of card
 * @param {keyof (typeof defaultTheme)} cardTitle
 * @param {Partial<typeof defaultTheme> | undefined} userTheme DEPRECATED
 * @returns {[string, () => void]}
 */
export default function useTitleColor(cardTitle, userTheme = defaultTheme) {
  const theme = { ...defaultTheme, ...userTheme };
  return [useMemo(() => theme[cardTitle] || '#6DB650', [cardTitle]), () => {}];
}

import { Flex, Text } from '@chakra-ui/react';
import {
  Paging,
  Results,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import RichTextEditorMB from 'components/modularBlocks/rte';
import { useState, useEffect } from 'react';
import CustomConnector from 'searchConfig/connector';
import 'searchConfig/styling/search.css';
import CustomDatePicker from './datePicker/CustomDatePicker';
import FilterContainer from './filters';
import CustomResultView from './resultView/newsCards';
import CustomResourceView from './resultView/resourcesCards';
import CustomSearchBox from './searchBox';
import Sort from './sortField';
import TagsSection from './tagsSection';
import CustomPagination from './customPagination';
import logger from 'utils/logger';

const SearchBoxBlock = ({ ct }) => {
  logger.log('search -box build test');
  return (
    <Flex
      gap={{ md: '2.5%' }}
      //   border={'lime dotted 2px'}
      w={'100%'}
      className="non-overview-container"
    >
      {ct === 'news_overview' && (
        <Flex
          maxW={{ base: '100%', md: '29%', lg: '22.5%' }}
          flex={{ base: 0, md: 1 }}
        />
      )}
      <CustomSearchBox />
    </Flex>
  );
};

const FilterContainerBlock = ({ ct, tags, setTags, filters }) => {
  logger.log('FilterContainerBlock - rendered');
  if (ct == 'news_overview' || !filters) {
    return <></>;
  }
  return (
    <Flex
      display={{ base: 'none', md: 'flex' }}
      w={'100%'}
      className="non-overview-container"
    >
      <FilterContainer
        type={'entity'}
        ct={ct}
        tags={tags}
        setTags={setTags}
        filters={filters}
      />
    </Flex>
  );
};

const CTABlock = ({ cta, ct }) => {
  logger.log('CTABlock - rendered');
  if (ct !== 'news_overview') {
    return <></>;
  }

  return (
    <Flex
      border={cta ? 'solid 1px #999999' : 'none'}
      //   border={'5px solid red'}
      borderRadius={'20px'}
      py={'27px'}
      px={{ base: '22px', lg: '38px' }}
      maxW={{ base: '100%', md: '29%', lg: '22.5%' }}
      mt="30px"
    >
      <RichTextEditorMB
        fontSize={{ base: '.875rem', md: '1rem', lg: '1.125rem' }}
        lineHeight={'1.563rem'}
        letterSpacing={'-.02em'}
        data={cta}
        style={['card']}
        px={'initial'}
      />
    </Flex>
  );
};

const SortBlock = ({
  ct,
  filters,
  setTags,
  tags,
  sortfilters,
  dates,
  setDates,
  sort,
  setSort,
  index,
}) => {
  logger.log('SortBlock - rendered');
  // TODO take out the 'sections' portion and remove sections tag from Contentstack news article and blurb content types: line 134, 190, 306 - Fix article headers index.js
  const filteredFilters =
    filters?.filter((filter) => filter !== 'sections') ?? [];
  return (
    <Flex
      h={'auto'}
      // justify={'center'}
      align={'center'}
      //   border={'cyan solid 3px'}
      flex={1}
      direction={{ base: 'column', md: 'row' }}
      className="non-overview-container"
      width={'100%'}
    >
      <Flex
        flex={1}
        maxW={{ md: '30%' }}
        minW={{ base: '100%', md: 'initial' }}
        align={'center'}
        justify={'space-between'}
        // border={'solid lime 2px'}
        width={'100%'}
        my={'25px'}
      >
        {' '}
        {filters && (
          <FilterContainer
            setTags={setTags}
            tags={tags}
            type={'mobile'}
            ct={ct}
            filters={filteredFilters}
            display={{ base: 'flex', md: 'none' }}
          />
        )}
        {sortfilters?.length > 0 && (
          <Sort
            index={index}
            sort={sort}
            setSort={setSort}
            sortFilters={sortfilters}
          />
        )}
      </Flex>
      {filters && (
        <Flex
          gap={'29px'}
          flex={2}
          direction={{ base: 'row-reverse', md: 'row' }}
          w={{ base: '100%', md: 'auto' }}
          align={'center'}
        >
          <TagsSection
            dates={dates}
            tags={tags}
            setTags={setTags}
            setDates={setDates}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default function Search({
  cta,
  index,
  ct,
  filters,
  prefilter,
  sortfilters,
}) {
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState();
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Simulate data fetching delay
    const delay = setTimeout(() => {
      setLoading(false); // Set loading to false after delay
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  // TODO take out the 'sections' portion and remove sections tag from Contentstack news article and blurb content types
  const filteredFilters =
    filters?.filter((filter) => filter !== 'sections') ?? [];

  // This section Deals with Elastic Search-UI configurations
  const env = process.env.REACT_APP_ENVIRONMENT;
  const baseUrl =
    env === 'production'
      ? 'https://search.iapp.org'
      : `https://${env}-search.iapp.org`;
  const connector = new CustomConnector(baseUrl, index, { prefilter });
  const config = {
    debug: false,
    alwaysSearchOnInitialLoad: true,
    apiConnector: connector,
    initialState: {
      resultsPerPage: 16,
    },
    searchQuery: {
      search_fields: { name: { weight: 3 } },
      filters: [],
    },
    result_fields: {
      name: {
        snippet: {
          size: 100,
          fallback: true,
        },
      },
    },
  };

  const sortProps = {
    ct,
    filters,
    setTags,
    tags,
    sortfilters,
    dates,
    setDates,
    sort,
    setSort,
    index,
  };

  return (
    <SearchProvider config={config}>
      <Flex
        direction={'column'}
        pl={'42px'}
        pr={'50px'}
        className={['search-container', ct].join(' ')}
      >
        <Flex
          direction={'column'}
          mx={'auto'}
          w={'100%'}
          maxW={'1440px'}
          gap={'30px'}
        >
          <Flex
            justifyContent={{ base: 'center', md: 'space-between' }}
            direction={{ base: 'column', md: 'row' }}
          >
            <CTABlock cta={cta} ct={ct} />
            <Flex direction={'column'} width={'100%'}>
              <SearchBoxBlock ct={ct} />
              <FilterContainerBlock
                ct={ct}
                tags={tags}
                setTags={setTags}
                filters={filters}
              />
              <Flex
                pl={{ md: '2.5%' }}
                direction={{ base: 'column', md: 'row' }}
                // border={'dashed green 3px'}
                gap={'2.5%'}
                w={'100%'}
              >
                <SortBlock {...sortProps} />
              </Flex>
            </Flex>
          </Flex>

          <Flex
            gap={{ md: '2.5%' }}
            // border={'red dashed 2px'}
            direction={{ base: 'column', md: 'row' }}
            maxW={ct !== 'news_overview' && '980px'}
            mx={ct !== 'news_overview' && 'auto'}
          >
            {ct === 'news_overview' && (
              <Flex
                direction={'column'}
                // border={'solid brown 2px'}
                maxW={{ md: '29%', lg: '22.5%' }}
                minH={{ md: '1000px' }}
                gap={'37px'}
                flex={1}
                visibility={{ base: 'hidden', md: 'visible' }}
              >
                <Flex
                  h={'100%'}
                  direction={'column'}
                  // align={'center'}
                  // border={'gold solid 2px'}
                  display={{ base: 'none', md: 'initial' }}
                >
                  <Flex p={'16px'} border={'solid #00000010 1px'}>
                    <CustomDatePicker dates={dates} setDates={setDates} />
                  </Flex>
                  <FilterContainer
                    type={'desktop'}
                    ct={ct}
                    tags={tags}
                    setTags={setTags}
                    filters={filteredFilters}
                  />
                </Flex>
              </Flex>
            )}
            <Flex direction={'column'} flex={1}>
              {/* If we get rid of this layer, it will stretch results container and put more space between cards */}
              <Flex direction={'column'}>
                <Flex
                  // border={'purple solid 2px'}
                  minH={'30px'}
                  h={'132%'}
                  direction={'column'}
                  // maxW={{ base: '100%', lg: '68%' }}
                >
                  <WithSearch
                    mapContextToProps={({ totalResults }) => ({
                      totalResults,
                    })}
                  >
                    {(resultsObj) => {
                      const { totalResults } = resultsObj;
                      logger.log('overviewSearch, resultsObj:', resultsObj);
                      if (loading) {
                        return <div>Loading...</div>; // Show loading indicator
                      } else if (totalResults > 0) {
                        logger.log(
                          'has totalResults > 0, totalResults:',
                          totalResults,
                          ct,
                        );
                        return (
                          <Flex
                            as={Results}
                            columnGap={{ base: '20px', md: '16px', lg: '2%' }}
                            flexWrap={'wrap'}
                            resultView={
                              ct === 'news_overview'
                                ? CustomResultView
                                : CustomResourceView
                            }
                            titleField="name"
                            // border={'lime solid 2px'}
                            h={'inherit'}
                          />
                        );
                      } else {
                        return (
                          <Flex justifyContent={'center'}>
                            <Text>No results found</Text>
                          </Flex>
                        );
                      }
                    }}
                  </WithSearch>
                </Flex>
                <Flex
                  // border={'lightblue solid 2px'}
                  py={{ base: '36px', lg: '100px' }}
                  justify={'center'}
                >
                  <Paging />
                  <CustomPagination />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            // border={'lightblue solid 2px'}
            py={{ base: '36px', lg: '100px' }}
            justify={'center'}
            visibility={{ lg: 'hidden' }}
          ></Flex>
        </Flex>
      </Flex>
    </SearchProvider>
  );
}

import { Flex, Show, Text } from '@chakra-ui/react';
import {
  Paging,
  Results,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui';
import FilterContainer from 'components/searchComponents/filters';
import CustomSearchBox from 'components/searchComponents/searchBox';
import Sort from 'components/searchComponents/sortField';
import TagsSection from 'components/searchComponents/tagsSection';
import { useEffect, useState } from 'react';
import CustomConnector from 'searchConfig/connector';
import api from 'utils/api';
import EntityResultView from './entityResultsView';
import { associateFilterIndices } from 'utils/associateFilterIndices';
import logger from 'utils/logger';

export default function EntityListing({ refData }) {
  const [data, setData] = useState();
  const [tags, setTags] = useState([]);
  const [index, setIndex] = useState();
  const [sort, setSort] = useState();
  const [connector, setConnector] = useState();
  const [dates, setDates] = useState([]);
  const [category, setCategory] = useState();
  const env = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    getData();
  }, [refData]);
  useEffect(() => {
    const baseUrl =
      env === 'production'
        ? 'https://search.iapp.org'
        : `https://${env}-search.iapp.org`;
    index &&
      setConnector(
        () =>
          new CustomConnector(baseUrl, index, {
            category,
          }),
      );
  }, [index, category]);

  const getData = async () => {
    const info = await api.getSpecificEntry(
      refData._content_type_uid,
      refData.uid,
    );
    logger.log('ENTRY', info);
    setData(info.entry);
    setCategory(info.entry?.listing_type);
    setIndex(info.entry?.sort_filter_indices?.[0] || 'general');
  };

  const config = {
    apiConnector: connector,
    debug: env === 'production' ? false : true,
    alwaysSearchOnInitialLoad: true,
    initialState: {
      resultsPerPage: 10,
    },
  };
  const showFilters = () => {
    return (
      data &&
      data?.sort_filters &&
      Array.isArray(data.sort_filters) &&
      data?.sort_filters?.length > 0 &&
      data?.listing_type !== 'find_your_chapter'
    );
  };
  if (connector) {
    return (
      <Flex
        direction={'column'}
        w={{ base: '81%', md: '87%', lg: '75%' }}
        mx={'auto'}
        my={'32px'}
      >
        <SearchProvider config={config}>
          <CustomSearchBox />
          {(data?.search_filters?.length > 0 ||
            data?.sort_filters?.length > 0) && (
            <Flex gap={'35px'}>
              <Show above="md">
                <FilterContainer
                  type={'entity'}
                  ct={data?.listing_type}
                  tags={tags}
                  setTags={setTags}
                  filters={data?.search_filters}
                />
              </Show>
              <FilterContainer
                display={{ md: 'none' }}
                erl={'true'}
                type={'mobile'}
                ct={data?.listing_type}
                tags={tags}
                setTags={setTags}
                filters={data?.search_filters}
              />
              {showFilters() && (
                <Flex gap={'26px'}>
                  <Text
                    display={{ base: 'none', md: 'block' }}
                    fontWeight={'700'}
                    fontSize={'1.5rem'}
                    lineHeight={'1.875rem'}
                    whiteSpace={'nowrap'}
                  >
                    Sort by:
                  </Text>
                  <Sort
                    index={index}
                    sort={sort}
                    setSort={setSort}
                    sortFilters={associateFilterIndices(data)}
                    sortDirection={sort}
                  />
                </Flex>
              )}
            </Flex>
          )}
          <Show above="md">
            <Flex pt="24px" w={'100%'}>
              <TagsSection
                dates={dates}
                tags={tags}
                setTags={setTags}
                setDates={setDates}
                clearPad={true}
              />
            </Flex>
          </Show>
          <Flex
            mt={'30px'}
            as={Results}
            resultView={EntityResultView}
            direction={'column'}
          ></Flex>
          <WithSearch
            mapContextToProps={({ totalPages }) => ({
              totalPages,
            })}
          >
            {({ totalPages }) => {
              if (totalPages > 1) {
                logger.log({ totalPages });
                return (
                  <Flex
                    my={{ base: '45px', lg: '60px' }}
                    align={'center'}
                    justify={'center'}
                  >
                    <Paging />
                  </Flex>
                );
              }
            }}
          </WithSearch>
        </SearchProvider>
      </Flex>
    );
  }
  return <></>;
}
